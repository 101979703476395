import React from "react";

export const homeStyles = {
    outletAddress :{
        
        // marginLeft: "5px",
        marginTop: "-3px",
      }
      ,
      bussinessContactNumber:{    
        marginLeft: '5px',
        marginRight: '5px',
        textDecoration: 'underline',
        color: 'black',
        fontWeight: '700',
    
    },
    flexWrap :{
      flexWrap: "wrap",
      display: "flex",
      flexDirection: "row",
      paddingLeft: 26,
      paddingTop: 10,
    } as React.CSSProperties,

    btnAlertModal:{
      display: "flex",
      width: "90%",
      color: "white",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      marginLeft: 13,
      marginRight: 13,
      marginBottom: 20,
      height: "4.5rem",
      borderRadius: 4,
    }as React.CSSProperties,

}