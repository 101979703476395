import React from "react";

const PhonePreOrderSvg: React.FC = () => {
  return (
    <svg
      id="Group_8535"
      data-name="Group 8535"
      xmlns="http://www.w3.org/2000/svg"
      width="13.616"
      height="13.97"
      viewBox="0 0 13.616 13.97"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_437"
            data-name="Rectangle 437"
            width="13.616"
            height="13.97"
            fill="#ffa600"
          />
        </clipPath>
      </defs>
      <g
        id="Group_8509"
        data-name="Group 8509"
        transform="translate(0 0)"
        clip-path="url(#clip-path)"
      >
        <path
          id="Path_9247"
          data-name="Path 9247"
          d="M126.253,0c.136.027.27.064.407.08A5.31,5.31,0,0,1,128.1.49,6.322,6.322,0,0,1,129.808,1.6a6.374,6.374,0,0,1,1.3,1.621,5.294,5.294,0,0,1,.611,1.512,10.623,10.623,0,0,1,.149,1.12c.022.206.025.414.029.621a.553.553,0,0,1-1.089.186,1.978,1.978,0,0,1-.029-.2c-.057-.413-.091-.831-.176-1.238a7.579,7.579,0,0,0-.319-1.059,4.425,4.425,0,0,0-.735-1.266,4.375,4.375,0,0,0-1.624-1.2,6.062,6.062,0,0,0-1.4-.439c-.339-.063-.681-.105-1.02-.167a.475.475,0,0,1-.43-.464c0-.341.169-.575.436-.6.01,0,.019-.015.028-.023h.71"
          transform="translate(-118.286)"
          fill="#ffa600"
        />
        <path
          id="Path_9248"
          data-name="Path 9248"
          d="M2.4,12.978a1.293,1.293,0,0,1,.992.435,8.8,8.8,0,0,1,1.246,1.525A5.131,5.131,0,0,1,5.3,16.2a1.2,1.2,0,0,1-.412,1.2,2.714,2.714,0,0,0-.631.545.831.831,0,0,0-.088.775,4.508,4.508,0,0,0,.824,1.473,7.393,7.393,0,0,0,.622.676,6,6,0,0,0,1.865,1.169.709.709,0,0,0,.892-.316,1.993,1.993,0,0,1,.681-.667,1.277,1.277,0,0,1,.795-.148,3.325,3.325,0,0,1,1.413.7c.3.21.6.426.886.654a3.526,3.526,0,0,1,.855.784,1.113,1.113,0,0,1,.184.817,2.747,2.747,0,0,1-1.05,1.663,3.129,3.129,0,0,1-1.3.675,1.514,1.514,0,0,1-.863-.062,11.661,11.661,0,0,1-1.989-.931c-.521-.293-1.034-.6-1.53-.935-.441-.3-.859-.627-1.28-.95a13.242,13.242,0,0,1-1.639-1.537c-.385-.416-.734-.866-1.082-1.314A16.053,16.053,0,0,1,.612,17.387,8.549,8.549,0,0,1,.1,16.079,2.264,2.264,0,0,1,.48,14.016a2.934,2.934,0,0,1,1.7-1.036.968.968,0,0,1,.216,0"
          transform="translate(0 -12.268)"
          fill="#ffa600"
        />
        <path
          id="Path_9249"
          data-name="Path 9249"
          d="M133.741,36.785c0,.3-.008.5,0,.7a.488.488,0,0,1-.487.507c-.449.039-.569-.267-.606-.609a4.192,4.192,0,0,0-.624-1.972,2.332,2.332,0,0,0-1.13-.852c-.338-.132-.691-.223-1.041-.323-.181-.052-.377-.062-.551-.13a.481.481,0,0,1-.3-.62c.12-.472.365-.46.756-.428a5.866,5.866,0,0,1,.9.175,3.9,3.9,0,0,1,1.368.657,4.147,4.147,0,0,1,1.526,2.022,3.366,3.366,0,0,1,.188.877"
          transform="translate(-121.98 -31.246)"
          fill="#ffa600"
        />
        <path
          id="Path_9250"
          data-name="Path 9250"
          d="M135.559,69.028c-.015.13-.021.238-.042.342a.515.515,0,0,1-.488.412.525.525,0,0,1-.547-.408c-.034-.122-.054-.248-.08-.373a1.181,1.181,0,0,0-1.11-1,.678.678,0,0,1-.474-.252.576.576,0,0,1,.384-.84,1.728,1.728,0,0,1,1.334.353,2.394,2.394,0,0,1,.96,1.336,3.922,3.922,0,0,1,.064.427"
          transform="translate(-125.531 -63.257)"
          fill="#ffa600"
        />
      </g>
    </svg>
  );
};

export default PhonePreOrderSvg;
