import {
  httpGetWithAuth,
  httpPost,
  httpGet,
} from "../../../setup/axios/axiosUtils";
import {
  StoreData,
  Categories,
  setThemeColor,
  getSelectedLanguage,
  functionChangeLanguge,
  getShopDataFromStrg,
} from "../../pages/Home/utils";
import { DateTime } from "luxon";
import { useState } from "react";
import { useAppDispatch } from "../useRedux";
import { setShopInfo } from "../../../setup/redux/action-creators/outlet-info";
import { URL_CONSTANTS } from "../urlsLink";
import { useSearchParams } from "react-router-dom";
import { TOKEN_EXPIRIES_MESSAGE } from "../../pages/Home/Components/Popups/CartPages/utils";
// import { setSelectedOutlet } from "../../../setup/redux/action-creators/selectedOutlet";
const useBussinessPage = () => {
  const dispatch = useAppDispatch();
  const [useData, setUserData] = useState<StoreData>();
  const [categories, setCategories] = useState<Categories[]>([]);
  const [preOrderCatg, setPreOrderCatg] = useState<Categories[]>([]);
  const [userToken, setUserToken] = useState();
  const [bussinessColors, setBussinessColors] = useState({});
  const [notFound, setNotFound] = useState<boolean>(false);
  const [notFoundReason, setNotFoundReason] = useState<string>(
    `We couldn't find the page you're trying to reach.`
  );
  const [loading, setLoading] = useState(true);

  const getNewToken = async (outletName: string) => {
    var newToken = "";
    try {
      const res = await httpGet(
        `${URL_CONSTANTS.get("URL_Get_Bussiness_Token")}${outletName}`
      );

      if (res.type !== "error") {
        newToken = res.data.access_token;
        let getLocalData = getShopDataFromStrg("/" + outletName);
        getLocalData.accessToken = newToken;
        localStorage.setItem(
          "shop" + outletName,
          JSON.stringify({
            // accessToken: token,
            ...getLocalData,
          })
        );
      }
      return newToken;
    } catch (error) {
      console.log(error);
    }
  };

  const getUserToken = async (
    outletName: string,
    stopBussinessCall?: boolean
  ) => {
    try {
      setLoading(true);
      const res = await httpGet(
        `${URL_CONSTANTS.get("URL_Get_Bussiness_Token")}${outletName}`
      );

      if (res.type === "error") {
        if (res.code === "unable-place-order") {
          setNotFound(true);
        }
      } else {
        setUserToken(res.data.access_token);
        getBussinessData(res.data.access_token, outletName, stopBussinessCall);
        if (stopBussinessCall) {
          return res.data.access_token;
        }
      }
    } catch (error: any) {
      console.log(error.message);
      setNotFound(true);
      setLoading(false);
    }
  };
  const getBussinessData = async (
    token: string,
    outletName: string,
    stopBussinessCall?: boolean
  ) => {
    let getLocalData = localStorage.getItem("shop" + outletName);
    var outledId: number | undefined = 0;
    var finalData;
    let parseLocalData;

    if (getLocalData !== null) {
      parseLocalData = JSON.parse(getLocalData);
    } else {
      parseLocalData = {};
    }
    // setLoading(true);
    try {
      const res = await httpGetWithAuth(
        URL_CONSTANTS.get("URL_Bussienss_Info")!,
        {},
        token
      );
      // console.log(res);
      if (res.code == "unable-place-order") {
        setNotFound(true);
        setNotFoundReason(res.message);
        return;
      }
      if (
        res.type === "error" &&
        TOKEN_EXPIRIES_MESSAGE.includes(res.message)
      ) {
        getUserToken(outletName);
      } else {
        finalData = res.data;
        setUserData(res.data);
        if (res.data.resto_meta?.BUSINESS_COLORS.length === 0) {
          var resto_meta = res.data.resto_meta;
          resto_meta.BUSINESS_COLORS = {
            DELIVERY_PICKUP_COLOR: res.data?.default_color,
            GENERAL_COLOR_THEME: res.data?.default_color,
            HIGHLIGHTED_BACKGROUND_COLOR: "#fffbf3",
          };
          finalData = res.data;
          finalData.resto_meta = resto_meta;
          setUserData(finalData);
        }
        var shopData = {
          id: res.data.id,
          name: res.data.name,
          payment_method: res.data.payment_method,
          accessToekn: token,
          outlets: res.data.outlets,
          default_color: res.data.default_color,
          arabic_name: res.data.arabic_name,
          english_name: res.data.arabic_name,
          coordinates: res.data.coordinates,
          delivery_zones: res.data.delivery_zones,
          default_lang: res.data.default_lang,
          pickup_discounts: res.data.pickup_discounts, // todo remove all other discounts types
          discounts: res.data.discounts,
          delivery_discounts: res.data.delivery_discounts, // todo remove all other discounts types
          country: res.data.country,
          loyalties: res.data.loyalties,
          country_code: res.data.country_code,
          short_description: res.data.short_description,
          country_short_name: res.data.country_short_name,
          site_logo: res.data.site_logo,
          resto_meta: res.data.resto_meta,
          resto_unique_name: res.data.resto_unique_name,
          phone: res.data.phone,
          place: res.data.place,
          rating: res.data.rating,
          whole_order_discounts: res.data.whole_order_discounts, // todo remove all other discounts types
          time_zone: res.data.time_zone,
          outletName: outletName,
        };
        parseLocalData.accessToken = token;
        parseLocalData.expiredAt = DateTime.now().plus({
          minutes: res.data.resto_meta.CACHE_EXPIRE_IN_MINS_USRDEVICE,
        });
        parseLocalData.shopInfo = shopData;
        localStorage.setItem(
          "shop" + outletName,
          JSON.stringify({
            // accessToken: token,
            ...parseLocalData,
          })
        );
        setThemeColor(shopData);
        var currentLanguage = getSelectedLanguage(outletName.replace("/", ""));

        if (currentLanguage !== "") {
          // setSelectedLanguageCode(currentLanguage);
          functionChangeLanguge(currentLanguage, outletName.replace("/", ""));
        } else {
          // setSelectedLanguageCode(useData?.default_lang);
          functionChangeLanguge(
            shopData?.default_lang,
            outletName.replace("/", "")
          );
        }
        dispatch(setShopInfo(shopData));
        /** this code is for categories based on outlet id uncomment it future 
        if (searchParams.get("outlet") !== null) {
          var name = searchParams.get("outlet")!.toString();
          console.log( name);
          var outledIdTemp =shopData?.outlets?.[name].outlet_id ;
         
          outledId = outledIdTemp 
         
        }
        */
        if (!stopBussinessCall) {
          getCategories(token, outledId, finalData);
        }
      }
    } catch (error) {
      //@ts-ignore
    }
  };
  const getCategories = async (
    userToken: string,
    outledId: number | undefined,
    useData: StoreData | undefined
  ) => {
    // console.log(userToken);
    try {
      const res = await httpPost(
        URL_CONSTANTS.get("URL_Bussienss_Categories")!,
        {
          outlet_id: outledId,
        },
        {},
        userToken
      );
      // console.log(useData)
      if (
        useData &&
        useData.resto_meta?.ALLOW_PRE_ORDERS &&
        useData.resto_meta?.ALLOW_PRE_ORDERS === "Yes"
      ) {
        console.log("called");
        const resPreOrder = await httpPost(
          URL_CONSTANTS.get("URL_PREORDER_CATEGORIES")!,
          undefined,
          {},
          userToken
        );
        if (null !== resPreOrder.data) {
          setPreOrderCatg(resPreOrder.data);
        }
      }
      setCategories(res.data);
      setLoading(false);
    } catch (error: any) {
      console.log(error.message);
      setLoading(false);
    }
  };
  return {
    getUserToken,
    notFound,
    userToken,
    getBussinessData,
    getCategories,
    useData,
    notFoundReason,
    preOrderCatg,
    bussinessColors,
    loading,
    categories,
  };
};
export default useBussinessPage;
