import React from "react";
interface Props {
  title: string;
  onPressClose: () => void;
  iconProp: any;
}
const HeaderCart: React.FC<Props> = (props: Props) => {
  const { onPressClose, title, iconProp } = props;
  return (
    <div className="cart_head_container">
      <div className="">
        <div className="header_cart_page">
          <div className="cart_heading">{title}</div>
          <div className="home_icon_svg" onClick={onPressClose}>
            {iconProp}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderCart;
