import React, { useEffect, useState } from "react";
import { discountedValue } from "../../pages/Home/Components/Popups/CartPages/utils";
import {
  DiscountAvailable,
  Recipes,
  StoreData,
  checkDiscountOnSelectedItem,
  numberWithCommas,
} from "../../pages/Home/utils";
interface Props {
  recipe: Recipes;
  selectedLanguageCode: string;
  useData: StoreData | undefined;
  isDiscountAvailabel: DiscountAvailable;
  isSelectedItem: boolean;
  isGridView?: boolean;
}
const BlockItemPrice: React.FC<Props> = (props: Props) => {
  const {
    recipe,
    selectedLanguageCode,
    useData,
    isDiscountAvailabel,
    isSelectedItem,
    isGridView,
  } = props;
  const correctPrice = () => {
    var value = recipe?.price.toString().replace(",", "");
    return parseInt(value);
  };
  const [isValidDiscount, setIsValidDiscount] =
    useState<boolean>(isSelectedItem);
  const renderDiscountedPrice = () => {
    var finalValue = parseFloat(
      discountedValue(
        correctPrice(),
        //@ts-ignore
        useData[isDiscountAvailabel.discountType].discount_type,

        //@ts-ignore
        useData[isDiscountAvailabel.discountType].amount,

        //@ts-ignore
        useData[isDiscountAvailabel.discountType]
      )
    );

    return finalValue.toString();
    // return recipe?.price.toString();
  };

  return (
    <div
      style={{
        ...styles.mainDiv,
        flexDirection: isValidDiscount ? "row-reverse" : "row",
      }}
    >
      <div
        className={`product_main_price ${
          isValidDiscount ? "product_main_price_discount" : ""
        }`}
      >
        {numberWithCommas(recipe.price)}{" "}
        {selectedLanguageCode === "ar"
          ? useData?.resto_meta.BUSSINESS_CCY_LANG.ar
          : useData?.resto_meta.BUSSINESS_CCY_LANG.en}
      </div>
      {isValidDiscount && (
        <div
          className="product_main_price"
          style={{
            // right: 0,
            margin: isGridView ? "0 12px 0 22px" : undefined,
            left: isDiscountAvailabel ? 0 : undefined,
          }}
        >
          {renderDiscountedPrice()}

          {selectedLanguageCode === "ar"
            ? useData?.resto_meta.BUSSINESS_CCY_LANG.ar
            : useData?.resto_meta.BUSSINESS_CCY_LANG.en}
        </div>
      )}
    </div>
  );
};
const styles = {
  mainDiv: {
    display: "flex",

    justifyContent: "space-between",
  } as React.CSSProperties,
};
export default BlockItemPrice;
