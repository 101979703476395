import React from "react";
interface SideLogoNameProp {
  logoUrl: string | undefined;
  bussinessName: string | undefined;
}
const SideLogoName: React.FC<SideLogoNameProp> = (props: SideLogoNameProp) => {
  const { logoUrl, bussinessName } = props;
  return (
    <div className="landscape_logo">
      <img src={logoUrl} className="landscape_logo_img " alt="" />
      <div className="landscape_logo_text">{bussinessName}</div>
    </div>
  );
};

export default SideLogoName;
