import React from "react";

const BackArrow: React.FC = () => {
  return (
    <svg
      id="Layer_1"
      height={25}
      className="arrow_back_svg"
      // style={{enable-background: '0 0 64 64'}}
      version="1.1"
      viewBox="0 0 64 64"
      width={25}
      //   xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      {/* <style type="text/css">
	.st0{fill:#134563;}
</style> */}
      <g>
        <g id="Icon-Chevron-Left" transform="translate(237.000000, 335.000000)">
          <polyline
            className="st0"
            id="Fill-35"
            points="-199.1,-289 -212.9,-302.7 -199.1,-316.4 -197.1,-314.4 -208.9,-302.7 -197.1,-291      -199.1,-289    "
          />
        </g>
      </g>
    </svg>
  );
};

export default BackArrow;
