import React from "react";
import Loader from "../../modules/Loader";
interface SimpleButtonProps {
  onPressHandler: () => void;
  titleProp: string;
  t: any;
  loading?: boolean;
  width?: string | number;
}
const SimpleButton: React.FC<SimpleButtonProps> = (
  props: SimpleButtonProps
) => {
  const { loading, onPressHandler, titleProp, width } = props;
  return (
    <div
      className="cart_button_order"
      style={{
        backgroundColor: "white",

        padding: "26px 0px",
        position: "relative",
      }}
    >
      <button
        disabled={loading}
        style={{ width: width ? width : undefined }}
        // style={{ zIndex: 1000 }}
        className="cart_foot_button simple_button_wrapper"
        onClick={onPressHandler}
      >
        {!loading ? (
          <div className="">{titleProp}</div>
        ) : (
          <Loader height={15} width={15} colorProp={"white"} />
        )}
      </button>
    </div>
  );
};
export default SimpleButton;
