import React from "react";
import { useTranslation } from "react-i18next";
import { isValidURL } from "../../pages/Home/utils";
interface FooterProps {
  bussinessDetails: any;
}
const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const { bussinessDetails } = props;
  const { t } = useTranslation();
  const logo = require("../../assets/img/main_logo.png");
  const openUrl = () => {
    window.open("https://dukany.io/", "_blank");
  };
  const openTermsAndCondition = (url: string) => {
    window.open(url, "_blank");
  };
  const openEmail = (email: string) => {
    // window.open('mailto:email@example.com?subject=Subject&body=Body%20goes%20here')
    window.open(`mailto:${email}`);
  };
  return (
    <div className="footer">
      <div className="font_s">{t("powered_by_arabic")}</div>
      <div className="footer_logo">
        <a href="" onClick={openUrl}>
          <img src={logo} className="round_cor" alt="" />
        </a>
      </div>
      <div style={{ borderBottom: "1px solid #F3F3F3" }} />

      {bussinessDetails?.resto_meta?.CONTACT_US &&
        bussinessDetails?.resto_meta?.CONTACT_US !== "CONTACT_US" &&
        bussinessDetails?.resto_meta?.CONTACT_US !== "" && (
          <>
            <div style={{ ...styles.labelStyle, textDecoration: "none" }}>
              {t("contact_us")}
            </div>
            <div
              onClick={() =>
                openEmail(bussinessDetails?.resto_meta?.CONTACT_US)
              }
              style={{ ...styles.labelStyle, color: "blue", fontSize: 14 }}
            >
              {bussinessDetails?.resto_meta?.CONTACT_US}
            </div>
          </>
        )}
      {bussinessDetails?.resto_meta?.REFUND_POLICY &&
        bussinessDetails?.resto_meta?.REFUND_POLICY !== "REFUND_POLICY" &&
        bussinessDetails?.resto_meta?.REFUND_POLICY !== "" && (
          <div
            onClick={() =>
              openTermsAndCondition(bussinessDetails?.resto_meta?.REFUND_POLICY)
            }
            style={styles.labelStyle}
          >
            {t("return_policy")}
          </div>
        )}
      {bussinessDetails?.resto_meta?.TERM_AND_CONDITIONS &&
        isValidURL(bussinessDetails?.resto_meta?.TERM_AND_CONDITIONS) && (
          <div
            onClick={() =>
              openTermsAndCondition(
                bussinessDetails?.resto_meta?.TERM_AND_CONDITIONS
              )
            }
            style={styles.labelStyle}
          >
            {" "}
            {t("termsconditon")}
          </div>
        )}
      <br />
    </div>
  );
};

export default Footer;
const styles = {
  labelStyle: {
    marginTop: 10,
    fontSize: 12,
    textDecoration: "underline",
    cursor: "pointer",
  },
};
