import React from "react";
interface Props {
  style?: any;
}
const Seperator: React.FC<Props> = (props: Props) => {
  const { style } = props;
  return <div className="seperater-bussiness-info" style={style} />;
};

export default Seperator;
