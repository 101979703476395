import React, { useEffect } from "react";
interface IAddMoreItem {
  basketValues: any;
  shopInfo: any;
  selectedLanguageCode: any;
  onPresshandeler: () => void;
}
const AddMoreItem: React.FC<IAddMoreItem> = (props: IAddMoreItem) => {
  const { basketValues, shopInfo, onPresshandeler, selectedLanguageCode } =
    props;
  return (
    <div className={`cart-minimum ${"border-error"}`}>
      <div>
        A Minimum Order Value{" "}
        <span>
          {basketValues?.min_basket_price}{" "}
          {shopInfo?.resto_meta?.BUSSINESS_CCY_LANG[selectedLanguageCode]}
        </span>
      </div>
      <span className="add-more-item" onClick={onPresshandeler}>
        Add more items
      </span>
    </div>
  );
};

export default AddMoreItem;
