import React from "react";
import DiscountSvg from "../../assets/img/svgs/DiscountSvg";
import WelcomeOffer from "../../assets/img/svgs/WelcomeOffer";
interface DiscountMesasgeProp {
  message: string;
  showIcon: boolean;
}
const DisplayDiscountMessage: React.FC<DiscountMesasgeProp> = (
  props: DiscountMesasgeProp
) => {
  const { message, showIcon } = props;
  return (
    <div className="discountWrapper">
      <span style={{ margin: "0 4px" }}>{showIcon && <WelcomeOffer />}</span>

      <div style={{ fontSize: "12px", color: "black",margin: "0 4px" }} className="">
        <span>{message}</span>
      </div>
    </div>
  );
};

export default DisplayDiscountMessage;
