import React from "react";

function SquareUpIos() {
  return (
    <svg
      style={{
        marginBottom: "-10px",
      }}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      width="17.334"
      height="26.4746"
    >
      <g>
        <rect height="26.4746" opacity="0" width="17.334" x="0" y="0" />
        <path
          d="M3.06641 23.5352L14.2676 23.5352C16.3086 23.5352 17.334 22.5195 17.334 20.5078L17.334 10.7617C17.334 8.75 16.3086 7.73438 14.2676 7.73438L3.06641 7.73438C1.02539 7.73438 0 8.75 0 10.7617L0 20.5078C0 22.5195 1.02539 23.5352 3.06641 23.5352ZM1.57227 20.4199L1.57227 10.8496C1.57227 9.83398 2.10938 9.30664 3.08594 9.30664L14.2383 9.30664C15.2051 9.30664 15.7617 9.83398 15.7617 10.8496L15.7617 20.4199C15.7617 21.4355 15.2051 21.9629 14.2383 21.9629L3.08594 21.9629C2.10938 21.9629 1.57227 21.4355 1.57227 20.4199Z"
          fill="#0a78f4"
          fill-opacity="0.85"
        />
        <path
          d="M11.3281 15.127L11.3281 2.64648C11.3281 1.19141 10.127 0 8.66211 0C7.20703 0 6.00586 1.19141 6.00586 2.64648L6.00586 15.127C6.00586 16.5723 7.20703 17.7734 8.66211 17.7734C10.127 17.7734 11.3281 16.5723 11.3281 15.127Z"
          fill="#eee"
        />
        <path
          d="M8.66211 15.8887C9.08203 15.8887 9.44336 15.5371 9.44336 15.127L9.44336 5.09766L9.38477 3.63281L10.0391 4.32617L11.5234 5.9082C11.6602 6.06445 11.8555 6.14258 12.0508 6.14258C12.4512 6.14258 12.7637 5.84961 12.7637 5.44922C12.7637 5.24414 12.6758 5.08789 12.5293 4.94141L9.22852 1.75781C9.0332 1.5625 8.86719 1.49414 8.66211 1.49414C8.4668 1.49414 8.30078 1.5625 8.0957 1.75781L4.79492 4.94141C4.64844 5.08789 4.57031 5.24414 4.57031 5.44922C4.57031 5.84961 4.86328 6.14258 5.27344 6.14258C5.45898 6.14258 5.67383 6.06445 5.81055 5.9082L7.28516 4.32617L7.94922 3.63281L7.89062 5.09766L7.89062 15.127C7.89062 15.5371 8.24219 15.8887 8.66211 15.8887Z"
          fill="#0a78f4"
          fill-opacity="0.85"
        />
      </g>
    </svg>
  );
}

export default SquareUpIos;
