import React from "react";

const VoucherSvg: React.FC = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      height={20}
      width={20}
      fill="var(--background-color-store)"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
    >
      <path
        d="M24.8417969,33.4814453c-0.9638672,0.9638672-0.9638672,2.5327148,0,3.4975586
	c0.4824219,0.4824219,1.1152344,0.7231445,1.7490234,0.7231445c0.6328125,0,1.265625-0.2407227,1.7480469-0.7226562
	c0.4677734-0.4677734,0.7255859-1.0888672,0.7246094-1.75c0-0.6606445-0.2578125-1.2817383-0.7255859-1.7485352
	C27.3740234,32.515625,25.8056641,32.5180664,24.8417969,33.4814453z M26.9248047,35.5649414
	c-0.1855469,0.1845703-0.4833984,0.1845703-0.6689453,0c-0.1845703-0.1845703-0.1845703-0.4848633,0-0.6694336
	c0.0917969-0.0922852,0.2128906-0.1381836,0.3339844-0.1381836c0.1220703,0,0.2431641,0.0458984,0.3349609,0.1381836
	c0,0.0004883,0,0.0004883,0.0009766,0.0004883c0.1201172,0.1206055,0.1376953,0.2607422,0.1376953,0.3339844
	C27.0644531,35.3032227,27.0458984,35.4438477,26.9248047,35.5649414z"
      />
      <path
        d="M37.5800781,33.4814453c-0.9638672,0.9638672-0.9638672,2.5327148,0,3.4975586
	c0.4824219,0.4819336,1.1152344,0.7231445,1.7480469,0.7231445c0.6337891,0,1.2675781-0.2412109,1.7490234-0.7231445
	c0.9638672-0.9648438,0.9638672-2.5336914,0-3.4975586C40.1123047,32.5180664,38.5449219,32.5161133,37.5800781,33.4814453z
	 M39.6630859,35.5649414c-0.1855469,0.1845703-0.4833984,0.1845703-0.6689453,0
	c-0.1845703-0.1845703-0.1845703-0.4848633,0-0.6694336c0.0917969-0.0922852,0.2128906-0.1381836,0.3349609-0.1381836
	c0.1210938,0,0.2421875,0.0458984,0.3339844,0.1381836C39.8476562,35.0800781,39.8476562,35.3803711,39.6630859,35.5649414z"
      />
      <path
        d="M31.2519531,23.6303711c-0.1220703-0.5385742-0.6582031-0.8754883-1.1962891-0.7548828
	c-0.5380859,0.1220703-0.8769531,0.6572266-0.7548828,1.1962891l4.7285156,20.9150391
	c0.1054688,0.4643555,0.5175781,0.7792969,0.9746094,0.7792969c0.0732422,0,0.1474609-0.0078125,0.2216797-0.0244141
	c0.5380859-0.1220703,0.8769531-0.6572266,0.7548828-1.1962891L31.2519531,23.6303711z"
      />
      <path
        d="M44.4697266,20.9692383c-1.3632812,0-2.4726562,1.109375-2.4726562,2.4731445s1.109375,2.4736328,2.4726562,2.4736328
	c1.3642578,0,2.4736328-1.1098633,2.4736328-2.4736328S45.8339844,20.9692383,44.4697266,20.9692383z M44.4697266,23.9160156
	c-0.2607422,0-0.4726562-0.2124023-0.4726562-0.4736328c0-0.2607422,0.2119141-0.4731445,0.4726562-0.4731445
	s0.4736328,0.2124023,0.4736328,0.4731445C44.9433594,23.7036133,44.7304688,23.9160156,44.4697266,23.9160156z"
      />
      <path
        d="M53.4775391,16.9086914c1.3632812,0,2.4726562-1.109375,2.4726562-2.4731445s-1.109375-2.4736328-2.4726562-2.4736328
	c-1.3642578,0-2.4736328,1.1098633-2.4736328,2.4736328S52.1132812,16.9086914,53.4775391,16.9086914z M53.4775391,13.9619141
	c0.2607422,0,0.4726562,0.2124023,0.4726562,0.4736328c0,0.2607422-0.2119141,0.4731445-0.4726562,0.4731445
	s-0.4736328-0.2124023-0.4736328-0.4731445C53.0039062,14.1743164,53.2167969,13.9619141,53.4775391,13.9619141z"
      />
      <path
        d="M38.4960938,13.6357422L56.6279297,25.081543c0.1660156,0.1044922,0.3505859,0.1542969,0.5332031,0.1542969
	c0.3320312,0,0.65625-0.1650391,0.8466797-0.4663086c0.2949219-0.4667969,0.1552734-1.0844727-0.3115234-1.3793945
	L39.5644531,11.9443359c-0.4677734-0.2944336-1.0839844-0.1542969-1.3798828,0.3120117
	C37.8896484,12.7231445,38.0292969,13.3408203,38.4960938,13.6357422z"
      />
      <path
        d="M8.6884766,21.5753784v8.255188c0,0.2729492,0.1113281,0.5341797,0.3085938,0.7226562l25.3515625,24.2319336
	c0.1933594,0.1850586,0.4423828,0.2773438,0.6914062,0.2773438s0.4980469-0.0922852,0.6914062-0.2773438l15.9814453-15.2758789
	c0.1972656-0.1884766,0.3085938-0.449707,0.3085938-0.7226562s-0.1113281-0.5341797-0.3085938-0.7226562l-8.0161133-7.6621094
	h17.0200195c0.5527344,0,1-0.4477539,1-1V7.7998047c0-0.5522461-0.4472656-1-1-1H24.8671875
	c-0.2578125,0-0.5048828,0.0991211-0.6904297,0.2768555l-6.6308594,6.3378906
	c-0.0419312,0.0400391-0.0631104,0.0940552-0.0970459,0.1401367h-0.4638672
	c-0.2893677-0.3149414-0.6008301-0.635376-0.9801025-0.9985352C11.1601562,7.9257812,5.7109375,6.1518555,3.3330078,8.4262695
	c-0.6865234,0.65625-1.0498047,1.5942383-1.0498047,2.7128906c0,2.6972656,2.0615234,6.3427734,5.3789062,9.5146484
	C8.0287476,21.0045166,8.3656006,21.3032227,8.6884766,21.5753784z M35.0400391,52.6791992L10.6884766,29.402832v-6.2874756
	c1.3531494,0.9307251,2.7190552,1.6465454,3.9961548,2.0909424c0.2180176,0.8806152,0.6715088,1.6922607,1.3554077,2.3459473
	c0.9902344,0.9467773,2.2910156,1.4199219,3.5917969,1.4199219c1.2998047,0,2.6005859-0.4731445,3.5908203-1.4194336
	c0.9736328-0.9311523,1.5097656-2.1723633,1.5097656-3.4946289c0-0.7325439-0.1795654-1.4331665-0.4916992-2.0769043
	c-0.0227051-0.0602417-0.0583496-0.1103516-0.0917969-0.1644287c-0.2426147-0.4540405-0.5390625-0.8834839-0.9262695-1.2537842
	c-0.8203125-0.7839355-1.8553467-1.2237549-2.9251709-1.3579712c-0.118103-0.0342407-0.2382812-0.0448608-0.364624-0.0332642
	c-1.3994751-0.0789185-2.8256836,0.3724976-3.8928223,1.3912354c-0.7201538,0.6887207-1.1971436,1.5482788-1.3987427,2.4837646
	c-1.1464233-0.4865723-2.3626099-1.2080078-3.5654907-2.1171875l5.6234131-5.3748779h1.5235596
	c0.005127,0.000061,0.0093994,0.0029297,0.0145264,0.0029297c0.005188,0,0.0094604-0.0028687,0.0145874-0.0029297h7.0166626
	l15.2114868,14.5394897c0.0093384,0.0096436,0.0188599,0.0180664,0.0285645,0.0273438l9.0656128,8.6651001L35.0400391,52.6791992z
	 M22.7324219,24.0581055c0,0.7709961-0.3164062,1.4985352-0.8925781,2.0488281
	c-1.2167969,1.1650391-3.2001953,1.1635742-4.4169922-0.0004883c-0.1228638-0.1172485-0.2276001-0.2462769-0.3262939-0.378418
	c0.1466064,0.0101318,0.3025513,0.0375977,0.4434814,0.0375977c1.1416016,0,2.0927734-0.3305664,2.7744141-0.9824219
	c0.789856-0.7553101,1.1311646-1.8488159,1.0299072-3.1593628c0.1752319,0.1100464,0.3416138,0.2380981,0.4954834,0.385437
	C22.4160156,22.559082,22.7324219,23.2866211,22.7324219,24.0581055z M19.2694092,21.1688232
	c0.1611938,0.7497559,0.2008667,1.6542969-0.3377686,2.1690674c-0.4447021,0.4244385-1.288269,0.5203247-2.359314,0.3117676
	c0.0917969-0.616394,0.3778687-1.1893921,0.8505249-1.6403809C17.9389648,21.5150757,18.5940552,21.2437134,19.2694092,21.1688232z
	 M25.2685547,8.7998047h34.4482422v19.6020508H41.6043701l-15.243042-14.5698242
	c-0.1865234-0.1782227-0.4335938-0.2773438-0.6914062-0.2773438h-5.3761597L25.2685547,8.7998047z M4.7148438,9.8720703
	c1.3183594-1.262207,5.8378906,0.2382812,9.9072266,4.1293945c0.1369629,0.1308594,0.2576904,0.2675781,0.3893433,0.399353
	L9.5299072,19.640564c-0.1604004-0.1451416-0.3261108-0.2800293-0.4849854-0.4320679
	c-3.2890625-3.1445312-4.7617188-6.3183594-4.7617188-8.0693359C4.2832031,10.5717773,4.4287109,10.1455078,4.7148438,9.8720703z"
      />
    </svg>
  );
};

export default VoucherSvg;
