import React from "react";

const AddToHomeScreenIcon: React.FC = () => {
  return (
    <svg
      width="64px"
      height="52px"
      viewBox="0 0 64 52"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>BEA94F87-5E8F-46F6-8E16-DE4DF9541E0C</title>
      <g
        id="Option_02"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="02.0_Add_to_Home_Button_UI_Android-Copy"
          transform="translate(-35.000000, -689.000000)"
        >
          <g id="popup" transform="translate(15.000000, 669.000000)">
            <g
              id="Add_homescreen-illustration"
              transform="translate(20.000000, 20.000000)"
            >
              <path
                d="M19.7343546,0 C27.4803334,0 34.1839742,4.52285362 37.4143464,11.1049076 C39.0744871,10.6453559 40.8220742,10.4 42.626206,10.4 C53.5251891,10.4 62.3605607,19.354305 62.3605607,30.4 C62.3605607,32.0547844 62.1622612,33.6626301 61.7884314,35.2004613 L6.90932638,35.2011686 C2.68025225,31.532986 0,26.0837096 0,20 C0,8.954305 8.83537152,0 19.7343546,0 Z"
                id="Combined-Shape"
                fill="#DAFBF0"
              ></path>
              <line
                x1="6.16505864"
                y1="34.9357143"
                x2="62.7552478"
                y2="34.8"
                id="Path-17"
                stroke="#223136"
                stroke-width="0.793702644"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></line>
              <g id="infographics" transform="translate(17.366232, 0.800000)">
                <g id="phone">
                  <rect
                    id="Rectangle"
                    stroke="#223136"
                    stroke-width="0.793702644"
                    fill="#55C6AF"
                    x="0.396851322"
                    y="0.396851322"
                    width="22.7747552"
                    height="42.1920116"
                    rx="3.32599203"
                  ></rect>
                  <rect
                    id="Rectangle-Copy-4"
                    fill="#60EFC1"
                    x="1.69576973"
                    y="2.25"
                    width="20.1769184"
                    height="36.8"
                    rx="2.07874502"
                  ></rect>
                  <g id="Group-12" transform="translate(3.721335, 8.428571)">
                    <rect
                      id="Rectangle"
                      fill="#FFFFFF"
                      x="0"
                      y="0"
                      width="16.5392686"
                      height="9.27142857"
                      rx="1.66299602"
                    ></rect>
                    <rect
                      id="Rectangle"
                      fill="#55C6AF"
                      x="2.4808903"
                      y="5.9"
                      width="11.9909698"
                      height="1"
                      rx="0.5"
                    ></rect>
                    <rect
                      id="Rectangle-Copy-5"
                      fill="#55C6AF"
                      x="5.37526231"
                      y="7.58571429"
                      width="6.20222574"
                      height="1"
                      rx="0.5"
                    ></rect>
                  </g>
                  <g id="Group-11" transform="translate(3.721335, 19.807143)">
                    <rect
                      id="Rectangle-Copy-6"
                      fill="#FFFFFF"
                      x="0"
                      y="0"
                      width="16.5392686"
                      height="5.05714286"
                      rx="0.831498008"
                    ></rect>
                    <rect
                      id="Rectangle"
                      fill="#55C6AF"
                      x="0.826963432"
                      y="0.842857143"
                      width="3.30785373"
                      height="3.37142857"
                      rx="0.415749004"
                    ></rect>
                    <rect
                      id="Rectangle"
                      fill="#55C6AF"
                      x="5.37526231"
                      y="1.26428571"
                      width="9.51007947"
                      height="1"
                      rx="0.5"
                    ></rect>
                    <rect
                      id="Rectangle-Copy-7"
                      fill="#55C6AF"
                      x="5.37526231"
                      y="2.10714286"
                      width="9.51007947"
                      height="1"
                      rx="0.5"
                    ></rect>
                    <rect
                      id="Rectangle-Copy-8"
                      fill="#55C6AF"
                      x="5.37526231"
                      y="2.95"
                      width="4.34155802"
                      height="1"
                      rx="0.5"
                    ></rect>
                  </g>
                  <g
                    id="Group-11-Copy"
                    transform="translate(3.721335, 26.550000)"
                  >
                    <rect
                      id="Rectangle-Copy-6"
                      fill="#FFFFFF"
                      x="0"
                      y="0"
                      width="16.5392686"
                      height="5.05714286"
                      rx="0.831498008"
                    ></rect>
                    <rect
                      id="Rectangle"
                      fill="#55C6AF"
                      x="0.826963432"
                      y="0.842857143"
                      width="3.30785373"
                      height="3.37142857"
                      rx="0.415749004"
                    ></rect>
                    <rect
                      id="Rectangle"
                      fill="#55C6AF"
                      x="5.37526231"
                      y="1.26428571"
                      width="9.51007947"
                      height="1"
                      rx="0.5"
                    ></rect>
                    <rect
                      id="Rectangle-Copy-7"
                      fill="#55C6AF"
                      x="5.37526231"
                      y="2.10714286"
                      width="9.51007947"
                      height="1"
                      rx="0.5"
                    ></rect>
                    <rect
                      id="Rectangle-Copy-8"
                      fill="#55C6AF"
                      x="5.37526231"
                      y="2.95"
                      width="4.34155802"
                      height="1"
                      rx="0.5"
                    ></rect>
                  </g>
                  <g
                    id="Group-11-Copy-2"
                    transform="translate(3.721335, 32.871429)"
                  >
                    <rect
                      id="Rectangle-Copy-6"
                      fill="#FFFFFF"
                      x="0"
                      y="0"
                      width="16.5392686"
                      height="5.05714286"
                      rx="0.831498008"
                    ></rect>
                    <rect
                      id="Rectangle"
                      fill="#55C6AF"
                      x="0.826963432"
                      y="0.842857143"
                      width="3.30785373"
                      height="3.37142857"
                      rx="0.415749004"
                    ></rect>
                    <rect
                      id="Rectangle"
                      fill="#55C6AF"
                      x="5.37526231"
                      y="1.26428571"
                      width="9.51007947"
                      height="1"
                      rx="0.5"
                    ></rect>
                    <rect
                      id="Rectangle-Copy-7"
                      fill="#55C6AF"
                      x="5.37526231"
                      y="2.10714286"
                      width="9.51007947"
                      height="1"
                      rx="0.5"
                    ></rect>
                    <rect
                      id="Rectangle-Copy-8"
                      fill="#55C6AF"
                      x="5.37526231"
                      y="2.95"
                      width="4.34155802"
                      height="1"
                      rx="0.5"
                    ></rect>
                  </g>
                </g>
                <g id="Group" transform="translate(12.066148, 23.807143)">
                  <path
                    d="M1.12673527,0.30382809 L1.12673527,0.30382809 C2.20422109,-0.324403788 3.59368134,0.0535816346 4.21432273,1.14287706 L7.46798715,6.83809584 C6.84325329,5.74958823 7.21578416,4.34366882 8.29352338,3.71411506 L8.29352338,3.71411506 C9.3710092,3.0887453 10.7609889,3.46333395 11.3811108,4.55107498 L12.307839,6.17041985 C11.6851716,5.08398359 12.0556586,3.67884905 13.1333984,3.05062307 L13.1333984,3.05062307 C14.2108842,2.42106931 15.6003444,2.79775114 16.2209685,3.88837377 L17.2465971,5.68401328 C16.6259499,4.59601908 16.9984843,3.18958626 18.0741767,2.55794345 L18.0741767,2.55794345 C19.149117,1.93257369 20.5411227,2.30718594 21.1617641,3.39778319 C23.0668526,6.73258107 24.999509,9.96654979 26.9316863,13.354648 L11.209152,22.5379738 L11.1577933,22.4494081 L11.1442491,22.4956657 L2.60714707,20.1775569 C1.40983094,19.8546829 0.687760916,18.5940151 1.008434,17.3803533 L1.008434,17.3803533 C1.33215307,16.1638293 2.57751274,15.4389523 3.77944084,15.763127 L7.9966253,16.9036307 L7.14550103,15.4196256 L0.302705006,3.42735259 C-0.321232296,2.33726934 0.0512974204,0.934229748 1.12671738,0.303885214 L1.12671738,0.303885214 L1.12673527,0.30382809 Z"
                    id="Path"
                    fill="#F1C7A7"
                  ></path>
                  <path
                    d="M14.1230144,27.3928571 L29.7706836,18.25444 L27.1652515,13.6879696 L11.5204107,22.827685 L14.1230259,27.3928571 L14.1230144,27.3928571 Z M26.5689168,17.0828602 C26.2991017,16.6129187 25.7088629,16.4525156 25.2479318,16.7255079 C24.7849533,16.9971754 24.6273077,17.5914608 24.8963522,18.0580096 C25.1613267,18.5253486 25.7523135,18.6841701 26.212477,18.4174526 C26.6736829,18.1465747 26.8359179,17.5481065 26.5689202,17.082856 L26.5689202,17.082856 L26.5689168,17.0828602 Z"
                    id="Shape"
                    fill="#EEB907"
                  ></path>
                </g>
              </g>
              <ellipse
                id="Oval"
                fill="#60EFC1"
                cx="4.34155802"
                cy="27.6"
                rx="1.97343546"
                ry="2"
              ></ellipse>
              <ellipse
                id="Oval-Copy-2"
                fill="#60EFC1"
                cx="51.7040092"
                cy="10.8"
                rx="1.18406128"
                ry="1.2"
              ></ellipse>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AddToHomeScreenIcon;
