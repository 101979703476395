import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeliveryZones, OutLets } from "../../../app/pages/Home/utils";
import { StoreMeta } from "../../../app/pages/Home/utils";
import { IDiscounts } from "../../../app/pages/Home/Components/Popups/CartPages/utils";
interface Shopinfo {
  accessToekn: string;
  id: number;
  name: string;
  payment_method: string;
  outlets: OutLets;
  default_color: string;
  default_lang: string;
  delivery_zones: DeliveryZones;
  country: string;
  country_code: number;
  discounts:IDiscounts;
  outletName: string;
  phone: number;
  delivery_discounts:any;
  pickup_discounts:any;
  site_logo: string;
  place: string;
  rating: number;
  resto_meta: StoreMeta;
  resto_unique_name: string;
  short_description: string;
  country_short_name: string;
  whole_order_discounts:any;
  time_zone: string;
  orderDetails?:any;
}
const initialState: Shopinfo = {} as Shopinfo;
export const StoreSlice = createSlice({
  name: "shopinfo",
  initialState,
  
  reducers: {
    setShopInfo: (data, action: PayloadAction<Shopinfo>) => {
      // console.log(action)
      return {
        
        ...data,
        ...action.payload,
      };
    },
  },
});
export const { setShopInfo } = StoreSlice.actions;
export default StoreSlice.reducer;
