import { httpPost } from "../../../setup/axios/axiosUtils";
import { useState } from "react";
import { useAppSelector } from "../useRedux";
import {
  OtpRespone,
  OtpResData,
} from "../../pages/Home/Components/Popups/CartPages/utils";
import { URL_CONSTANTS, apiVersion } from "../urlsLink";
const useSendOtp = () => {
  const [loading, setLoading] = useState(false);
  const [otpData, setOtpData] = useState<OtpRespone>();
  const [errorMessage ,setErrorMessage] = useState('');
  const [customerData, setCustomerData] = useState<OtpResData>();
  const shopInfo = useAppSelector((state) => state.shopInfo);
  const sendOtpHandler = async (mobile_number: string, country: string,msgType:'SMS' |'Whatsapp') => {
    
   
    try {
      setLoading(true);
      const res = await httpPost(
        URL_CONSTANTS.get('URL_Send_Otp')!,
        {
          id: shopInfo.id,
          mobile_number,
          name: shopInfo.name,
          lang: shopInfo.default_lang,
          country,
          msgType,
        },
        {},
        shopInfo.accessToekn
      );
      setLoading(false);
      if (res.type === "success") {
        setOtpData(res);
        localStorage.setItem('otpDataTemp',JSON.stringify(res))
        // dispatch(setOtpResponse(res));
        return;
      
      }
      if (res.type === 'error'){
        setErrorMessage(res.message);
        return;
      }
     
    } catch (error: any) {
      setErrorMessage(error.response.data.message)
      setLoading(false);
    }
  };
  const validOtpHandler = async (
    req_id: string,
    mobile_number: string,
    otp: string
  ) => {
    setLoading(true);

    try {
      const res = await httpPost(
        URL_CONSTANTS.get('URL_Validate_Otp')!,
        {
          req_id,
          otp,
          mobile_number,
          apiVersion,
        },
        {},
        shopInfo.accessToekn
      );
      console.log(res);
      if (res.type === "success") {
        setCustomerData(res);
      }
      else{
        setErrorMessage(res.message)
      }

      setLoading(false);
    } catch (error: any) {
      setErrorMessage(error.message)
      setLoading(false);
    }
  };
  return {
    sendOtpHandler,
    validOtpHandler,
    loading,
    otpData,
    errorMessage,
    customerData,
  };
};
export default useSendOtp;
