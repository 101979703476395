import React, { useEffect, useState } from "react";
import {
  basketFinalValue,
  isValidOrderType,
  discountedValue,
  MINIMUMORDERVALUEMSG,
  Number_Of_uses_Per_Customer,
  checkDiscountOnDeliveryFee,
  getDeliveryFeeDisVal,
  IDiscountsDeliveryFee,
  getLoyaltyPoints,
  IProxyIp,
} from "./utils";
import BottomButton from "../../../../../shared/components/BottomButton";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useBussinessPage from "../../../../../hooks/useBussiness";
import EmptyCart from "../../../../../shared/components/EmptyCart";
import IconMinus from "../../../../../shared/components/IconMinus";
import IconPlus from "../../../../../shared/components/IconPlus";
import SideLogoName from "../../../../../shared/components/SideLogoName";
import BackArrow from "../../../../../assets/img/svgs/BackArrow";
import {
  getCustomerInfoFromStroge,
  getProxyData,
  getShopDataFromStrg,
  numberWithCommas,
} from "../../../utils";
import DisplayBasketItem from "../../../../../shared/components/DisplayBasketItem";
import PaymentRow from "./CartComponents/PaymentRow";
import DisplayDiscountAlert from "../../../../../shared/components/DisplayDiscountAlert";
import IconInfo from "../../../../../assets/img/svgs/IconInfo";
import Loyalty from "../../../../../shared/components/Loyalty";
import AddMoreItem from "./CartComponents/AddMoreItem";
const BasketPage: React.FC = () => {
  const navigate = useNavigate();
  const { bussinessName } = useParams();
  const { getBussinessData } = useBussinessPage();
  const [basketValues, setBasketValues] = useState<any>({});
  const [isFullMin, setIsFullMin] = useState<boolean>(false);
  const [deliveryInstrucion, setDeliveryInstrucion] = useState<string>("");
  const [totalDiscount, setTotalDiscount] = useState<string>("");
  const [checkDiscountType, setCheckDiscountType] = useState({
    available: false,
    discountType: "",
  });
  let temp = getShopDataFromStrg("/" + bussinessName!);
  const shopInfo = temp !== null && temp?.shopInfo;
  const outletUrl = temp !== null && temp?.outletUrl;
  const { t, i18n } = useTranslation();
  const selectedLanguageCode = i18n.language;
  const [deliveryFee, setDeliveryFee] = useState<number>(0);
  const [disOnDelFee, setDisOnDelFee] = useState<IDiscountsDeliveryFee>(
    {} as IDiscountsDeliveryFee
  );
  const [currency, setCurrency] = useState<string>(
    shopInfo?.resto_meta?.BUSSINESS_CCY_LANG[selectedLanguageCode]
  );
  const [showExeedMaxOrderLimit, setExeedMaxOrderLimit] =
    useState<boolean>(false);
  const [showDiscountErroMsg, setShowDiscMsg] = useState<string>("");
  const [borderError, setBorderError] = useState<boolean>(false);
  const [showDiscountAlert, setShowDiscountAlert] = useState<boolean>(false);
  const [usrCC, setUsrCC] = useState<string>("");
  const [originalDeliveryFee, setOriginalDeliveryFee] = useState<number>(0);
  const [ip, setIP] = useState<string>("");
  const [basketQunatity, setBasketQuantity] = useState<any>({
    total: 0,
    price: 0,
  });
  const checkDiscountValue = (
    value: any,
    isDiscount: any,
    totalDiscount: string
  ) => {
    var finalValue: number = 0;

    if (isDiscount) {
      finalValue = parseInt(value) - parseFloat(totalDiscount);
      return finalValue.toFixed(2);
    } else {
      return value.toString();
    }
  };
  const getDiscountOnDelivery = (
    isDiscount: any,
    discountObj: any,
    deliveryFee: number
  ) => {
    var newDeliveryFee = 0;
    var checkDisObj = checkDiscountOnDeliveryFee(discountObj);
    setDeliveryFee(deliveryFee);
    if (isDiscount && checkDisObj.isDiscountDelivery) {
      setDisOnDelFee(checkDisObj);
      var discounteDF = getDeliveryFeeDisVal(deliveryFee, checkDisObj);
      newDeliveryFee = deliveryFee - discounteDF;
      newDeliveryFee = parseFloat(newDeliveryFee.toFixed(2));
      setDeliveryFee(newDeliveryFee);
    }
  };
  const getValuesFromStrg = () => {
    let temp = getShopDataFromStrg("/" + bussinessName!);
    var total = "";
    if (temp !== null) {
      setBasketValues(temp);
      var isVaildOrder = isValidOrderType(
        temp?.shopInfo?.time_zone,
        temp?.shopInfo.discounts,
        temp?.orderType
      );

      let tempPrice = 0;
      var totlaDiscount = 0;
      let isWholeOrder = false;
      let discountObj = shopInfo[isVaildOrder.discountType];
      let isFulFillMinimun = true;
      temp.cart?.forEach((item: any, index: any) => {
        if (index == 0 && item.is_for_whole_order === "Yes") {
          isWholeOrder = true;
        }
        tempPrice =
          tempPrice +
          parseFloat(
            item.final_item_price
            // item.multiple_option.length > 1 ? item.price : item.actual_price
          ) *
            parseInt(item.quantity);
        totlaDiscount +=
          item.is_discount === "Yes" && item.is_for_whole_order === "No"
            ? parseFloat(item.discounted_amount)
            : 0;
      });
      if (isVaildOrder.available && isWholeOrder) {
        totlaDiscount = parseFloat(
          discountedValue(
            tempPrice,
            discountObj.discount_type,
            discountObj.amount,
            discountObj,
            true
          )
        );
      }
      if (isVaildOrder.available && !isWholeOrder) {
        if (
          discountObj?.maximum_discount != "0" &&
          totlaDiscount > parseFloat(discountObj?.maximum_discount)
        )
          totlaDiscount = parseFloat(discountObj?.maximum_discount);
      }

      setCheckDiscountType({
        available: isVaildOrder.available,
        discountType: isVaildOrder.discountType,
      });

      let totalValue = tempPrice;
      if (totalValue < parseInt(discountObj?.minimum_order_value)) {
        isFulFillMinimun = false;
        setShowDiscMsg(MINIMUMORDERVALUEMSG);
      }
      if (
        shopInfo?.orderDetails &&
        parseInt(shopInfo?.orderDetails[0]?.orderCount) >=
          parseInt(
            shopInfo[isVaildOrder.discountType]?.number_of_uses_per_customer
          )
      ) {
        setExeedMaxOrderLimit(true);
        setShowDiscMsg(Number_Of_uses_Per_Customer);
      }

      setIsFullMin(isFulFillMinimun);
      total = checkDiscountValue(
        totalValue,

        isVaildOrder.available && isFulFillMinimun,
        totlaDiscount.toFixed(2)
      );

      setBasketQuantity({
        total: total,
        price: tempPrice,
      });
      setOriginalDeliveryFee(temp?.outletInfo?.delivery_fee);
      getDiscountOnDelivery(
        isVaildOrder.available && isFulFillMinimun,
        temp?.shopInfo[isVaildOrder.discountType],
        temp?.outletInfo?.delivery_fee
      );
      setTotalDiscount(totlaDiscount.toFixed(2));
    }
  };
  const getValues = async () => {
    const res: IProxyIp = await getProxyData();
    localStorage.setItem("userIpInfo", JSON.stringify(res));
  };
  const showPopUp = () => {
    let parsedData: any;
    let customerInfo = getCustomerInfoFromStroge();
    if (temp !== null) {
      parsedData = temp;
      parsedData.deliveryInstruction = deliveryInstrucion;
      localStorage.setItem("shop/" + bussinessName, JSON.stringify(parsedData));
    }
    if (
      basketValues?.cart !== undefined &&
      basketValues.cart?.length !== 0 &&
      basketQunatity?.price >=
        (basketValues?.outletInfo
          ? basketValues?.outletInfo?.min_basket_price
          : 0)
    ) {
      if (customerInfo !== null) {
        if (customerInfo.name !== "New") {
          navigate(`/${bussinessName}/place-order`);
        } else {
          navigate(`/${bussinessName}/create-customer`);
        }
      } else {
        navigate(`/${bussinessName}/send-otp`);
      }
    } else {
      setBorderError(true);
    }
  };
  const onPressIcon = () => {
    setShowDiscountAlert(true);
  };
  const updateBasket = (
    action: string,
    item: any,
    index: number,
    value?: string
  ) => {
    var new_qunatity = 0;
    let old = basketValues.cart;
    let totalDiscount = old[index].discount_amount;
    var isValidDiscountType =
      checkDiscountType.available &&
      checkDiscountType.discountType !== "whole_order_discounts" &&
      old[index].is_discount === "Yes";
    var getDiscountedPrice = checkDiscountValue(
      item.final_item_price,
      isValidDiscountType,
      totalDiscount
    );

    var oldDiscAmnt = parseFloat(old[index].discount_amount);
    switch (action) {
      case "add":
        new_qunatity = parseInt(item.quantity) + 1;
        break;
      case "sub":
        if (parseInt(item.quantity) !== 0) {
          new_qunatity = parseInt(item.quantity) - 1;
        }
        break;
      case "keyboard":
        if (value && value !== "0" && value !== "") {
          new_qunatity = parseInt(value);
        } else {
          // @ts-ignore
          new_qunatity = 1;
        }
        break;

      default:
        break;
    }

    if (new_qunatity !== 0) {
      console.log(new_qunatity, parseFloat(getDiscountedPrice));
      old[index].quantity = new_qunatity.toString();
      old[index].price = new_qunatity * parseFloat(getDiscountedPrice);
      old[index].discounted_amount = isValidDiscountType
        ? new_qunatity * oldDiscAmnt
        : undefined;
    } else {
      old.splice(index, 1);
    }
    let newBasket = {
      cart: old,
      ...basketValues,
    };
    localStorage.setItem("shop/" + bussinessName, JSON.stringify(newBasket));

    // getValues();
    getValuesFromStrg();
    // }
  };
  const onPressClose = () => {
    navigate(outletUrl);
  };
  useEffect(() => {
    if (shopInfo === undefined) {
      if (temp !== null) {
        getBussinessData(JSON.parse(temp).accessToken, bussinessName!);
      }
    }
    getValues();
    getValuesFromStrg();
  }, []);
  useEffect(() => {
    window.onpopstate = (e) => {
      e.preventDefault();
      onPressClose();
    };
  });
  return (
    // <div id="pop_cart_page1" className="overlay">
    <div lang={selectedLanguageCode}>
      <>
        <div
          id="pop_cart_page1"
          className="basketPage pop_up_maps_main pop_main_menu_bg"
          // style={{ position: "relative", overflowY: "hidden" }}
        >
          <div className="popup_cart_full popup_from_bottom">
            <div className="content" id="">
              <div className="cart_head_container">
                <div className="container">
                  <div className="header_cart_page">
                    <div className="cart_heading">{t("my_basket")}</div>

                    <span
                      onClick={onPressClose}
                      className="target_slide glyphicon"
                    >
                      <BackArrow />
                    </span>
                  </div>
                </div>
              </div>
              {/* <!-- content start here --> */}
              {basketValues?.cart && basketValues?.cart?.length !== 0 ? (
                <div id="cart_details">
                  <div className="cart_content">
                    {(basketValues?.cart === undefined ||
                      basketValues.cart?.length === 0 ||
                      basketQunatity?.price <
                        basketValues?.outletInfo?.min_basket_price) && (
                      <AddMoreItem
                        shopInfo={shopInfo}
                        basketValues={basketValues?.outletInfo}
                        onPresshandeler={onPressClose}
                        selectedLanguageCode={selectedLanguageCode}
                      />
                    )}

                    {/* @ts-ignore */}

                    {basketValues.cart?.map((item, index) => {
                      return (
                        <>
                          {item?.color_size?.img_url === undefined ? (
                            <div className="cart-item-selected cart_content_row">
                              <div className="cart_content_col_70 flo_left">
                                <div className="title_acc_cell">
                                  {item.name[selectedLanguageCode]}
                                </div>
                                {/* <div
                                  className="light_grey_text font_s pad_bottom_20 font_ar_s1 "
                                  dangerouslySetInnerHTML={{
                                    __html: item?.single_item_name,
                                  }}
                                /> */}
                                <div>
                                  {item.multiple_option.length > 0 &&
                                    item.multiple_option.map(
                                      (multipleItem: any, index: number) => {
                                        return (
                                          <div key={index}>
                                            {shopInfo?.resto_meta
                                              ?.BUSSINESS_TYPE !==
                                              "Florist" && (
                                              <h5>
                                                {
                                                  multipleItem.parent_name[
                                                    selectedLanguageCode
                                                  ]
                                                }
                                              </h5>
                                            )}

                                            <div
                                              className="light_grey_text font_s pad_bottom_20 font_ar_s1 "
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  multipleItem.name_lang[
                                                    selectedLanguageCode
                                                  ],
                                              }}
                                            />
                                            {/* <text className="light_grey_text">
                                              {
                                                multipleItem.name_lang[
                                                  selectedLanguageCode
                                                ]
                                              }
                                            </text> */}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                                {item.color_size !== null && (
                                  <p style={{ width: "22em" }}>
                                    {
                                      <span className="text-muted prdColor">
                                        {t("color")} :{" "}
                                        {/* { item?.color_size?.img_url === undefined ? */}
                                        <span
                                          className="icon-circle me-2"
                                          style={{
                                            backgroundColor:
                                              item.color_size.color,
                                          }}
                                        />
                                      </span>
                                    }
                                    {item?.color_size?.size && (
                                      <span className="text-muted prdSize">
                                        {t("size")}: {item.color_size.size}
                                      </span>
                                    )}
                                  </p>
                                )}
                              </div>

                              <div className="cart_content_col_30">
                                <div
                                  className="align_opp"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span
                                    className="circle-icon-plus"
                                    style={{
                                      borderColor: shopInfo?.default_color,
                                    }}
                                    onClick={() =>
                                      updateBasket("sub", item, index)
                                    }
                                  >
                                    <IconMinus
                                      height={14}
                                      width={14}
                                      color={shopInfo?.default_color}
                                    />
                                  </span>
                                  <input
                                    style={{
                                      width: item.quantity.length > 2 ? 64 : 36,
                                    }}
                                    readOnly={
                                      shopInfo?.resto_meta?.EDIT_ADD_ITEMS ===
                                      "Yes"
                                        ? false
                                        : true
                                    }
                                    type="text"
                                    className="prod_counter"
                                    value={item.quantity}
                                    onChange={(e) => {
                                      const enteredValue = e.target.value;
                                      const numericValue = enteredValue.replace(
                                        /\D/g,
                                        ""
                                      ); // Remove non-numeric characters

                                      updateBasket(
                                        "keyboard",
                                        item,
                                        0,
                                        numericValue
                                      );
                                    }}
                                  />
                                  <span
                                    style={{
                                      borderColor: `var(--background-color-store)`,
                                    }}
                                    className="circle-icon-plus"
                                    onClick={() =>
                                      updateBasket("add", item, index)
                                    }
                                  >
                                    <IconPlus
                                      height={14}
                                      width={14}
                                      color={`var(--background-color-store)`}
                                    />
                                  </span>
                                </div>
                                <div className="cart_product_list_price">
                                  {selectedLanguageCode === "en" && currency}{" "}
                                  {numberWithCommas(item.price.toFixed(2))}{" "}
                                  {selectedLanguageCode === "ar" && currency}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <DisplayBasketItem
                              t={t}
                              selectedLanguageCode={selectedLanguageCode}
                              shopInfo={shopInfo}
                              itemProp={item}
                              index={index}
                              onUpdateBasket={updateBasket}
                            />
                          )}
                        </>
                      );
                    })}

                    <div className="cart_content_row pad_bot_10">
                      <div className="title_acc_cell">
                        {t("additional_instruction")}
                      </div>
                      {/* <div className="order_rows_border"> */}
                      <textarea
                        style={{ border: "0.5px solid #000000" }}
                        name="comment_page_order"
                        className="cart_text_area_order"
                        placeholder={t("additional_instruction_txt")}
                        value={deliveryInstrucion}
                        onChange={(e) => setDeliveryInstrucion(e.target.value)}
                      >
                        {/* Add greetings (Optional) */}
                      </textarea>
                      {/* </div> */}
                    </div>

                    <div className="cart_content_row"></div>
                  </div>

                  {/* <!-- content end here --> */}

                  <div className="cart_foot">
                    <div id="cart_details" className="cart_content">
                      {/* parseInt(basketQunatity?.total) <
                        parseInt(
                          shopInfo[checkDiscountType.discountType]
                            ?.minimum_order_value
                        ) */}
                      {shopInfo.loyalties !== null && (
                        <Loyalty
                          setUserLoyaltyPoints={() => {}}
                          makeApiCall={false}
                          showIcon
                          message={t("lbl_loyalty_order_orderpoints")
                            .replace(
                              "{#pointsoncurrentorder}",
                              ` ${getLoyaltyPoints(
                                bussinessName!,
                                basketQunatity.price
                              )}`
                            )
                            .replace("{#currency}", currency)}
                          // message={`You will earn ${currency} ${getLoyaltyPoints(
                          //   bussinessName!,
                          //   basketQunatity.price
                          // )} in loyalty points when you complete this order.`}
                          wrapperStyles={{ marginLeft: 0, width: "100%" }}
                        />
                      )}
                      {showExeedMaxOrderLimit && (
                        <div
                          style={{
                            fontSize: 12,
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 10,
                          }}
                          className="bussinessTextColor fontFamilyRegular"
                        >
                          {showDiscountErroMsg}{" "}
                          {/* {shopInfo?.whole_order_discounts?.minimum_order_value} */}
                          <IconInfo
                            onPress={onPressIcon}
                            style={{ marginLeft: 6, marginRigth: 6 }}
                          />
                        </div>
                      )}
                      {!isFullMin && !showExeedMaxOrderLimit && (
                        <div
                          style={{
                            fontSize: 12,
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 10,
                          }}
                          className="bussinessTextColor fontFamilyRegular"
                        >
                          {showDiscountErroMsg}{" "}
                          {shopInfo?.discounts?.minimum_order_value}
                          <IconInfo
                            onPress={onPressIcon}
                            style={{ marginLeft: 6, marginRigth: 6 }}
                          />
                        </div>
                      )}
                      {/* <AddVoucher /> */}

                      <div className="cart_content_row">
                        <div className="cart_content_col_70 flo_left">
                          <div className="title_acc_cell">
                            {t("payment_summary")}
                          </div>
                        </div>
                        <div className="cart_content_col_30">&nbsp;</div>
                      </div>
                      <div className="cart_content_row">
                        <div className="cart_content_col_70 flo_left">
                          {t("sub_total")}
                        </div>
                        <div className="cart_content_col_30">
                          {selectedLanguageCode === "en" && currency}{" "}
                          {numberWithCommas(basketQunatity?.price.toFixed(2))}{" "}
                          {selectedLanguageCode === "ar" && currency}{" "}
                        </div>
                      </div>
                      {checkDiscountType.available && isFullMin && (
                        <PaymentRow
                          labelValue={t("Discount")}
                          onPressIcon={onPressIcon}
                          actualValue={totalDiscount}
                          currency={currency}
                          selectedLang={selectedLanguageCode}
                          isShow={true}
                        />
                      )}
                      {shopInfo?.resto_meta?.DISABLED_LOCATION_SERVICES ===
                      "Yes" ? (
                        <div className="cart_content_row"></div>
                      ) : (
                        <div>
                          {basketValues?.orderType !== "pickup" && (
                            <>
                              <div className="cart_content_row">
                                <div className="cart_content_col_70 flo_left">
                                  {t("delivery_fee")}
                                </div>

                                <div className="cart_content_col_30">
                                  {selectedLanguageCode === "en" && currency}{" "}
                                  {basketValues?.outletInfo?.delivery_fee}{" "}
                                  {selectedLanguageCode === "ar" && currency}
                                </div>
                              </div>
                              {checkDiscountType.available &&
                                disOnDelFee.isDiscountDelivery && (
                                  <PaymentRow
                                    currency={currency}
                                    selectedLang={selectedLanguageCode}
                                    onPressIcon={() => {
                                      setShowDiscountAlert(true);
                                    }}
                                    labelValue={t("discount_on_delivery_fee")}
                                    actualValue={(
                                      originalDeliveryFee - deliveryFee
                                    ).toFixed(2)}
                                    isShow={true}
                                  />
                                )}
                            </>
                          )}
                        </div>
                      )}
                      {/* <div>{isDiscountApplied}</div> */}

                      <div className="cart_content_row alert_color">
                        <div className="cart_content_col_70 flo_left">
                          {t("grand_total")}{" "}
                          {shopInfo?.resto_meta
                            ?.DISPLAY_TAX_INFO_TRANSLATION && (
                            <small>
                              (
                              {
                                shopInfo?.resto_meta
                                  ?.DISPLAY_TAX_INFO_TRANSLATION[
                                  selectedLanguageCode
                                ]
                              }
                              )
                            </small>
                          )}
                        </div>
                        <div className="cart_content_col_30">
                          {selectedLanguageCode === "en" && currency}{" "}
                          {shopInfo?.resto_meta?.DISABLED_LOCATION_SERVICES !==
                          "Yes"
                            ? numberWithCommas(
                                basketFinalValue(
                                  deliveryFee,
                                  basketQunatity?.total
                                )
                              )
                            : numberWithCommas(
                                parseFloat(basketQunatity?.total).toFixed(2)
                              )}{" "}
                          {selectedLanguageCode === "ar" && currency}
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              ) : (
                <EmptyCart onPress={onPressClose} t={t} />
              )}
            </div>
            <br />
          </div>
          {showDiscountAlert && (
            <DisplayDiscountAlert
              selectedLang={selectedLanguageCode}
              onPressClose={() => {
                setShowDiscountAlert(false);
              }}
              discount={shopInfo[checkDiscountType.discountType]}
            />
          )}
          {basketValues?.cart === undefined ||
          basketValues.cart?.length === 0 ||
          basketQunatity?.price < basketValues?.outletInfo?.min_basket_price ? (
            <></>
          ) : (
            <BottomButton
              widthProp={375}
              displayBottomLine={true}
              onPress={showPopUp}
              titleProp={t("order_review")}
              loading={false}
            />
          )}
        </div>
      </>

      <SideLogoName
        logoUrl={
          shopInfo !== undefined &&
          shopInfo.site_logo !== undefined &&
          shopInfo?.site_logo
          // : logo
        }
        bussinessName={shopInfo?.name[selectedLanguageCode]}
      />
    </div>
  );
};

export default BasketPage;
