import React, { useEffect, useState } from "react";
import { pwaInstallHandler } from "pwa-install-handler";
import { useTranslation } from "react-i18next";
import CrossSvg from "../../assets/img/svgs/CrossSvg";
import SquareUpIos from "../../assets/img/svgs/SquareUpIos";
import { useAppSelector } from "../../hooks/useRedux";
import AddToHomeScreenIcon from "../../assets/img/svgs/AddToHomeScreenIcon";

const ModalAddToScreen: React.FC = () => {
  //   const { userAgent } = navigator;
  //   const platforIos = userAgent.match(/(?<=iPhone)(?:iPhone|iPod|iPad)/);
  //   const platforIos = window.matchMedia("(device-family: iPhone)").matches;
  const platforIos = /(iPhone|iPad|iPod)/.test(navigator.userAgent);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const shopInfo = useAppSelector((state) => state.shopInfo);
  const hideModalHandler = () => {
    setShowModal(false);
  };
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(true);
  // console.log(browser);
  const showInstallPrompt = async () => {
    pwaInstallHandler.install();
    setShowModal(false);
    // console.log(pwaInstallHandler.canInstall());
    // console.log(await pwaInstallHandler.getEvent().userChoice);

    //@ts-ignore
    // window;
  };
  useEffect(() => {
    if (shopInfo && shopInfo.resto_meta?.PWA_DISABLED === "Yes") {
      //   let div = document.getElementById("dynamicManifest");
      //   div?.remove();
      setShowModal(false);
    }
  }, []);
  if (!showModal) {
    return <></>;
  }

  if (!platforIos && !pwaInstallHandler.canInstall()) {
    return <></>;
  }
  if (platforIos && !isSafari) {
    return <></>;
  }
  return (
    <div className="pwaPopupWrapper">
      <div className="pwaBox">
        {platforIos && (
          <div onClick={hideModalHandler} className="pwa_CrossButton">
            <CrossSvg height={15} width={15} wrapperBgColor="black" />
          </div>
        )}
        <div className="pwa_taglineWrapper">
          <AddToHomeScreenIcon />
          <div className="pwaText">{t("pwa_msg_main")}</div>
        </div>
        {!platforIos && (
          <div className="pwaBtnWrapper ">
            <div onClick={hideModalHandler}>{t("pwa_skip_now")}</div>
            <button
              id="installButton"
              className="pwa_ButtonV2"
              onClick={showInstallPrompt}
            >
              {" "}
              + {t("pwa_button_text_android")}
            </button>
          </div>
        )}
        {platforIos && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <div
              style={{
                textAlign: "center",
                padding: "8px",
                background: "#EEE",
              }}
              //   className="pwa_ButtonV2"
              // onClick={showInstallPrompt}
            >
              <span> {t("pwa_msg_ios1")}</span>{" "}
              <span>
                {" "}
                <SquareUpIos />
              </span>{" "}
              {t("pwa_msg_ios2")}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalAddToScreen;
