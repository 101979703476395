import React, { useEffect, useState } from "react";
import { SelectedOulet } from "../../../utils";
import SelectDataModal from "./SelectDateModal";
import { useAppSelector } from "../../../../../hooks/useRedux";
import BottomButton from "../../../../../shared/components/BottomButton";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SlotsButton from "./SlotsButton";
import { DATE_FORMAT_STRING } from "../CartPages/utils";
interface SelectTimeModalProps {
  onPressClose: (selectedDateTime: string) => void;
  shopName: string;
}
const SelectTimeModal: React.FC<SelectTimeModalProps> = (
  props: SelectTimeModalProps
) => {
  const { onPressClose, shopName } = props;
  const shopInfo = useAppSelector((state) => state.shopInfo);
  const { t } = useTranslation();
  const localData = localStorage.getItem("shop/" + shopName);
  let parsedLocalData: any = JSON.parse(localData !== null ? localData : "");
  let orderType = parsedLocalData.orderType;
  var estimatedDeliveryTime = parseInt(
    orderType === "pickup"
      ? parsedLocalData?.outletInfo?.pickup_time?.split("-")[0]
      : parsedLocalData?.outletInfo?.delivery_time?.split("-")[0]
  );
  var slotsLength = parsedLocalData?.outletInfo?.outlet_delivery_slot_length;

  const [showDateModal, setShowDateModal] = React.useState<boolean>(false);

  var currentDate = new Date();
  // currentDate = new Date("2023-02-09T11:00:10");
  // currentDate.setHours(currentDate.getHours() - 1);
  var currentTime = currentDate;
  const currentDayName = currentDate.toLocaleString("en-us", {
    weekday: "long",
  });
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [dateNumberNew, setDateNumberNew] = useState<string>("");
  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [selectedOutlet] = useState<SelectedOulet>(
    localData !== null && JSON.parse(localData).outletInfo
  );
  const [showError, setShowError] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);

  /**
   * Closes the modal and triggers the onPressClose callback.
   * If option 1 is passed and both date and time are selected, saves the selected date and time to local storage.
   */
  const closeModal = (option: number) => {
    // if (selectedTime !== "" && selectedDate !== "") {
    if (option === 1) {
      if (selectedTime !== "" && selectedDate !== "") {
        onPressClose(
          moment(dateNumberNew).format(DATE_FORMAT_STRING) +
            " | " +
            selectedTime
        );
        let oldValues = parsedLocalData;
        oldValues.selectedDate = selectedDate;
        oldValues.selectedTime = selectedTime;
        parsedLocalData = oldValues;
        localStorage.setItem(
          "shop/" + shopName,
          JSON.stringify(parsedLocalData)
        );
      } else {
        setShowError(true);
      }
    } else {
      onPressClose("");
    }
  };
  /**
   * Disables a time slot if the current time plus the estimated delivery time is greater than the slot time.
   */
  const disableSlot = (timeSlot: string) => {
    let disabled = false;
    let currentTimeD = moment(currentTime, "hh:mm A")
      .add(estimatedDeliveryTime, "minutes")
      .toDate()
      .getTime();
    let slotTime = moment(timeSlot, "hh:mm A");
    if (isTodayCheck(dateNumberNew)) {
      if (currentTimeD >= new Date(slotTime.toString()).getTime()) {
        disabled = true;
      } else {
        disabled = false;
      }
    } else {
      // if()
      disabled = false;
    }
    return disabled;
  };
  /**
   * Saves the selected date to local storage.
   */
  const saveDataToLocalStorage = (
    dateNumber: any,
    formatedDayName: any,
    isCalender: boolean | undefined
  ) => {
    let parsedData: any = {};
    if (localData !== null) {
      parsedData = JSON.parse(localData);
      parsedData.dateNumber = dateNumber;
      parsedData.formatedDayName = formatedDayName;
      // parsedData.isCalender = false;
      if (isCalender) {
        parsedData.isCalender = true;
      } else {
        parsedData.isCalender = false;
      }
      localStorage.setItem("shop/" + shopName, JSON.stringify(parsedData));
    }
  };
  /**
   * Changes the selected date and generates time slots for that date.
   */
  const changeDate = (
    dayName: string,
    dateNumber: any,
    weeDayName: string,
    index?: number | undefined,
    isCalender?: boolean
  ) => {
    setShowError(false);
    setSelectedTime("");
    setIsCustom(false);

    saveDataToLocalStorage(dateNumber, dayName, isCalender);
    // let startTime = moment(currentDate, "hh:mm A");

    if (parsedLocalData?.orderType === "delivery" && weeDayName !== undefined) {
      var objFecth =
        selectedOutlet?.business_hours?.delivery[weeDayName?.toLowerCase()];
    } else {
      var objFecth =
        selectedOutlet?.business_hours?.pickup[weeDayName?.toLowerCase()];
    }

    var tempArr: any = [];
    for (let i = 0; i < objFecth?.length; i++) {
      var result = createTimeSlots(
        objFecth[i].format_start_time,
        objFecth[i].format_end_time,
        index
      );

      // if (i === 1) {
      tempArr.push(...result);
      // }
    }

    setTimeSlots(tempArr);

    setDateNumberNew(dateNumber);
    if (isCalender) {
      setShowDateModal(false);
      setIsCustom(true);
      setSelectedDate(weeDayName);
    } else {
      setSelectedDate(weeDayName);
    }
  };
  /**
   * Checks the preparation delivery type and returns the corresponding unit of time.
   */
  const checkAddLimitType = () => {
    var value: moment.unitOfTime.DurationConstructor = "minutes";
    switch (selectedOutlet.preparation_delivery_type) {
      case "DAYS":
        value = "days";
        return value;
      case "HOURS":
        value = "hours";
        return value;

      default:
        return value;
    }
  };
  /**
   * Creates time slots between the given fromTime and toTime based on the slot length.
   */
  const createTimeSlots = (
    fromTime: string,
    toTime: string,
    currentIndex?: number
  ) => {
    var currentTime = new Date();
    const minute = moment(currentTime, "hh:mm A").minute();
    const currentTimeD = moment(currentTime)
      .add((slotsLength - minute) % slotsLength, "minutes")
      .toDate()
      .getTime();
    let slotTime = moment(fromTime, "hh:mm A");
    var addType: moment.unitOfTime.DurationConstructor = checkAddLimitType();
    if (
      currentIndex !== undefined &&
      isTodayCheck(addDaysOnCurrent(currentIndex).newDate)
    ) {
      if (currentTimeD >= new Date(slotTime.toString()).getTime()) {
        fromTime = moment(currentTimeD).format("hh:mm A");
      }
    }
    let startTime: moment.Moment;
    startTime = moment(fromTime, "hh:mm A");
    if (checkAddLimitType() !== "days") {
      startTime = moment(fromTime, "hh:mm A").add(
        estimatedDeliveryTime,
        addType
      );
    }
    // if we want to add deliveryPrepartiontime uncommnet below
    // let endTime = moment(toTime, "hh:mm A").add(estimatedDeliveryTime, addType);
    let endTime = moment(toTime, "hh:mm A");

    let arr: any = [];

    while (startTime <= endTime) {
      arr?.push(moment(startTime).format("hh:mm A"));
      startTime.add(slotsLength, "minutes");
    }

    return arr;
  };
  /**
   * Updates the selected time slot.
   */
  const changeTime = (time: string) => {
    setSelectedTime(time);
  };
  /**
   * Checks if the selected date is today.
   */
  const isTodayCheck = (dateToBeCheck: number | string) => {
    var isToday = false;
    var todayDate = new Date().getDate();
    var selectedDate = new Date(dateToBeCheck).getDate();
    if (todayDate === selectedDate) {
      isToday = true;
    }
    return isToday;
  };
  /**
   * Adds a number of days to the current date and returns the new date along with formatted day and week names.
   */
  const addDaysOnCurrent = (days: number) => {
    var newDate = new Date().setDate(new Date().getDate() + days);
    var deliveryDays = parseInt(selectedOutlet.delivery_days);
    if (selectedOutlet.preparation_delivery_type === "DAYS") {
      newDate = new Date().setDate(
        new Date().getDate() + (deliveryDays + days)
      );
    }

    var formatedDayName = new Date(newDate).toLocaleString("en-us", {
      month: "short",
      day: "2-digit",
    });
    var weekDayName = new Date(newDate).toLocaleString("en-us", {
      weekday: "long",
    });

    //   const currentDayName = currentDate.toLocaleString('en-us',{weekday:'long'});
    return { formatedDayName, newDate, weekDayName };
  };
  useEffect(() => {
    if (localData !== null) {
      let pardedData: any = {};
      pardedData = JSON.parse(localData);
      if (pardedData?.selectedTime !== undefined) {
        changeDate(
          pardedData?.formatedDayName,
          pardedData?.dateNumber,
          pardedData?.selectedDate,
          0
        );
        setSelectedDate(pardedData?.selectedDate);
        setIsCustom(pardedData?.isCalender);
        setSelectedTime(
          ""
          // pardedData.selectedTime !== undefined ? pardedData.selectedTime : ""
        );
      } else {
        changeDate(
          addDaysOnCurrent(0).formatedDayName,
          addDaysOnCurrent(0).newDate,
          addDaysOnCurrent(0).weekDayName,
          0
        );
        setSelectedTime("");
      }
    }
  }, []);

  return (
    <div>
      <div
        id="pop_time_set"
        className="pop_up_maps_main pop_main_menu_bg"

        // style={{ background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div
          style={{ minHeight: "90%", overflow: "hidden" }}
          className="popup_delivery popup_from_bottom pop_time_set_height popup_slide"
        >
          <div className="content" id="">
            <div className="container">
              <div className="container">
                <div className="head_burg_menu">
                  <div className="delivery_head">
                    {orderType === "pickup"
                      ? t("pickup_time")
                      : t("delivery_timing")}
                  </div>
                  <div className="delivery_close">
                    <a className="slide_remove" onClick={() => closeModal(0)}>
                      &times;
                    </a>
                  </div>
                  <div className="delivery_time_text">
                    {/* {t("order_timeslot_txt")} */}
                    {orderType === "pickup"
                      ? t("text_time_modal_pickup")
                      : t("text_time_modal_order")}
                  </div>
                  {showError && (
                    <div style={{ marginTop: 10 }}>
                      <span style={{ color: "red", fontSize: 14 }}>
                        Please select date and time
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="container_pad">
              <div className="col_50_time_date">
                {selectedOutlet.preparation_delivery_type !== "DAYS" ? (
                  <div>
                    <button
                      className={`select_date_option ${
                        selectedDate?.toLowerCase() ===
                          currentDayName?.toLowerCase() &&
                        !isCustom &&
                        "selcted_opt"
                      }`}
                      onClick={() => {
                        changeDate(
                          addDaysOnCurrent(0).formatedDayName,
                          addDaysOnCurrent(0).newDate,
                          addDaysOnCurrent(0).weekDayName,
                          0
                        );
                      }}
                    >
                      Today
                    </button>
                    <button
                      className={`select_date_option ${
                        selectedDate?.toLocaleLowerCase() ===
                          addDaysOnCurrent(1)?.weekDayName?.toLowerCase() &&
                        !isCustom &&
                        "selcted_opt"
                      }`}
                      onClick={() => {
                        changeDate(
                          addDaysOnCurrent(1).formatedDayName,
                          addDaysOnCurrent(1).newDate,
                          addDaysOnCurrent(1).weekDayName,
                          1
                        );
                      }}
                    >
                      Tommorrow
                    </button>
                  </div>
                ) : (
                  <>
                    <SlotsButton
                      isCustom={isCustom}
                      onPress={(
                        dayName: string,
                        newDate: number,
                        weeKDayName: string
                      ) => changeDate(dayName, newDate, weeKDayName, 0)}
                      selectedDayName={selectedDate}
                      slotsObject={addDaysOnCurrent(0)}
                    />
                    <SlotsButton
                      isCustom={isCustom}
                      onPress={(
                        dayName: string,
                        newDate: number,
                        weeKDayName: string
                      ) => changeDate(dayName, newDate, weeKDayName, 1)}
                      selectedDayName={selectedDate}
                      slotsObject={addDaysOnCurrent(1)}
                    />
                  </>
                )}

                <SlotsButton
                  isCustom={isCustom}
                  onPress={(
                    dayName: string,
                    newDate: number,
                    weeKDayName: string
                  ) => changeDate(dayName, newDate, weeKDayName, 2)}
                  selectedDayName={selectedDate}
                  slotsObject={addDaysOnCurrent(2)}
                />
                <SlotsButton
                  isCustom={isCustom}
                  onPress={(
                    dayName: string,
                    newDate: number,
                    weeKDayName: string
                  ) => changeDate(dayName, newDate, weeKDayName, 3)}
                  selectedDayName={selectedDate}
                  slotsObject={addDaysOnCurrent(3)}
                />
                <SlotsButton
                  isCustom={isCustom}
                  onPress={(
                    dayName: string,
                    newDate: number,
                    weeKDayName: string
                  ) => changeDate(dayName, newDate, weeKDayName, 4)}
                  selectedDayName={selectedDate}
                  slotsObject={addDaysOnCurrent(4)}
                />
                <SlotsButton
                  isCustom={isCustom}
                  onPress={(
                    dayName: string,
                    newDate: number,
                    weeKDayName: string
                  ) => changeDate(dayName, newDate, weeKDayName, 5)}
                  selectedDayName={selectedDate}
                  slotsObject={addDaysOnCurrent(5)}
                />
                <SlotsButton
                  isCustom={isCustom}
                  onPress={(
                    dayName: string,
                    newDate: number,
                    weeKDayName: string
                  ) => changeDate(dayName, newDate, weeKDayName, 6)}
                  selectedDayName={selectedDate}
                  slotsObject={addDaysOnCurrent(6)}
                />
                {shopInfo?.resto_meta?.ENABLE_CALENDAR === "Yes" && (
                  <button
                    style={{ marginBottom: "8.2rem" }}
                    onClick={() => setShowDateModal(true)}
                    className={`select_date_option ${
                      isCustom && "selcted_opt"
                    }`}
                  >
                    {!isCustom
                      ? "Select Date"
                      : parsedLocalData?.formatedDayName}
                  </button>
                )}
              </div>
              <div className="col_50_time_date">
                {timeSlots?.length > 0 &&
                  shopInfo?.resto_meta?.ENABLED_AS_SOON_AS_POSSIBLE === "Yes" &&
                  isTodayCheck(dateNumberNew) && (
                    <button
                      className={`select_date_option ${
                        selectedTime === "ASAP" && "selcted_opt"
                      }`}
                      onClick={() => changeTime("ASAP")}
                    >
                      ASAP
                    </button>
                  )}
                {timeSlots?.length > 0 ? (
                  timeSlots?.map((item: any, index: number) => {
                    return (
                      <>
                        {!disableSlot(item) && (
                          <button
                            className={`select_date_option ${
                              selectedTime === item && "selcted_opt"
                            }`}
                            // disabled={disableSlot(item)}
                            onClick={() => changeTime(item)}
                          >
                            {item}
                          </button>
                        )}
                      </>
                    );
                  })
                ) : (
                  <button className={`select_date_option`}>Close day</button>
                )}
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>

        <BottomButton
          titleProp={t("confirm_date_time")}
          onPress={() => closeModal(1)}
          // widthProp={375}
        />
      </div>
      {showDateModal && (
        <SelectDataModal
          onChangeDay={changeDate}
          onPressClose={() => setShowDateModal(false)}
          disabledWeek={5}
        />
      )}
    </div>
  );
};

export default SelectTimeModal;
