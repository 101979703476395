import React from "react";
// import { ReactComponent as RecordIconSvg } from "../../../assets/images/record-icon.svg";
interface RecordSvgProps {
  height: number | string;
  width: number | string;
}
const RecordSvg: React.FC<RecordSvgProps> = (props: RecordSvgProps) => {
  const styles = getComputedStyle(document.documentElement);
  const bgColor = styles.getPropertyValue("--background-color-store");
  const { height, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="28"
      // height="28"
      fill={bgColor}
      width={width}
      height={height}
      viewBox="0 0 28 28"
    >
      <g id="Group_8354" data-name="Group 8354" transform="translate(-80 -426)">
        <g
          id="Ellipse_50"
          data-name="Ellipse 50"
          transform="translate(80 426)"
          fill="none"
          // fill={bgColor}
          // stroke="#ffa600"
          stroke={bgColor}
          stroke-width="2"
        >
          <circle cx="14" cy="14" r="14" stroke="none" />
          <circle cx="14" cy="14" r="13" fill="none" />
        </g>
        <g
          id="Ellipse_52"
          data-name="Ellipse 52"
          transform="translate(90 436)"
          fill="rgba(255,166,0,0.2)"
          // stroke="#ffa600"
          stroke={bgColor}
          stroke-width="1"
        >
          <circle cx="4" cy="4" r="4" stroke="none" />
          <circle cx="4" cy="4" r="3.5" fill="none" />
        </g>
      </g>
    </svg>
  );
};

export default RecordSvg;
