import React from "react";
import { AddressInterface } from "../utils";
import { useTranslation } from "react-i18next";
interface ISelectAddress {
  userAddress: AddressInterface;
  localValues: any;
  selectedLanguageCode: "en" | "ar";
}
const SelectAddress: React.FC<ISelectAddress> = (props: ISelectAddress) => {
  const { userAddress, localValues, selectedLanguageCode } = props;
  const { t } = useTranslation();
  //   shopInfo?.resto_meta?.DISABLED_LOCATION_SERVICES === "Yes" &&
  //   choose_item_location
  const disableLocationServices =
    localValues.shopInfo?.resto_meta?.DISABLED_LOCATION_SERVICES;
  return (
    <>
      <div style={{ width: "5%" }} className="cart_content_col_15">
        <div className="glyphicon glyphicon-map-marker f_size_24"></div>
      </div>
      <div className="cart_content_col_60">
        {Object.keys(userAddress)?.length !== 0 &&
        localValues.orderType !== "pickup" ? (
          <>
            <div className="title_acc_cell no_pad">{userAddress?.label}</div>
            <div className="light_grey_text add-dots">
              {userAddress?.address}
            </div>
          </>
        ) : (
          <div className="light_grey_text ">
            {localValues.orderType === "pickup"
              ? localValues?.outletInfo?.outlet_address[selectedLanguageCode]
              : disableLocationServices !== "Yes"
              ? localValues?.outletInfo?.selectedLocationName
              : t("choose_item_location")}
          </div>
        )}
      </div>
    </>
  );
};

export default SelectAddress;
