import React from "react";
interface ClockSvgProps {
  fillColor: string;
}
const ClockSvg: React.FC<ClockSvgProps> = (props: ClockSvgProps) => {
  const { fillColor } = props;

  return (
    // <div>
    <svg
      //   style={{ marginTop: 10 }}
      fill={fillColor}
      height="10"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clip-rule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7 3V8.41421L10.2929 11.7071L11.7071 10.2929L9 7.58579V3H7Z"
        // fill="#030708"
        fill={fillColor}
        fill-rule="evenodd"
      />
    </svg>
    // </div>
  );
};

export default ClockSvg;
