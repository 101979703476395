import { createSlice } from "@reduxjs/toolkit";
export const StoreSlice = createSlice({
  name: "closeAllModals",
  initialState: {
    closeAllModal: false,
  },
  reducers: {
    setCloseAllModal: (data) => {
      data.closeAllModal = true;
    },
    setShowAllModal: (data) => {
      data.closeAllModal = false;
    },
  },
});
export const { setCloseAllModal, setShowAllModal } = StoreSlice.actions;
export default StoreSlice.reducer;
