import React from "react";
import { homeStyles } from "../../../pages/Home/styles";
interface Props {
  onPressHandler: () => void;
  btnTitle: string;
  buttonStyles?: React.CSSProperties | undefined;
}
const ButtonAlertModal: React.FC<Props> = (props: Props) => {
  const { onPressHandler, btnTitle, buttonStyles } = props;
  return (
    <div
      onClick={onPressHandler}
      style={{ ...homeStyles.btnAlertModal, ...buttonStyles }}
      className="background-color-trash "
    >
      <span style={{ alignItems: "center" }}>{btnTitle}</span>
    </div>
  );
};

export default ButtonAlertModal;
