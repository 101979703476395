import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export const StoreSlice = createSlice({
  name: "UserStore",
  initialState: {
    usedata: 0,
  },
  reducers: {
    setDataFromApi: (data) => {
      data.usedata += 1;
    },
    decrement: (data) => {
      data.usedata -= 1;
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.usedata += action.payload;
    },
  },
});
export const { setDataFromApi, decrement, incrementByAmount } =
  StoreSlice.actions;
export default StoreSlice.reducer;
