import React from "react";
import ChatIconSvg from "../../../assets/img/svgs/ChatIconSvg";
import WhatsappIconSvg from "../../../assets/img/svgs/WhatsappIconSvg";
import { useTranslation } from "react-i18next";
interface ISelectOtpMethod {
  onBackDropClick: () => void;
  onSelectHandler: (type: "SMS" | "Whatsapp") => void;
  phoneNumber: string;
}
const SelectOtpMethod: React.FC<ISelectOtpMethod> = (
  props: ISelectOtpMethod
) => {
  const { onBackDropClick, onSelectHandler, phoneNumber } = props;
  const { t } = useTranslation();
  // Prevent click events from propagating to the backdrop
  const handleModalContentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };
  return (
    <div
      onClick={onBackDropClick}
      id="pop_send-otp-method"
      className="pop_up_maps_main pop_main_bg pop_main_menu_bg"
    >
      <div
        className="popup_delivery popup_from_bottom popup_slide"
        onClick={handleModalContentClick}
      >
        <br />
        <div className="container">
          <h3 style={{ marginBottom: 30 }}>{t("send_verification_code")}</h3>
          <div
            onClick={() => onSelectHandler("SMS")}
            style={{ cursor: "pointer" }}
          >
            <div className="disp_flex_row">
              <ChatIconSvg />
              <span className="otp-method-txt"> {t("send_via_sms")}</span>
            </div>
            <span style={{ marginLeft: 25, marginRight: 25, color: "#7A7A7A" }}>
              {phoneNumber}
            </span>
          </div>

          <div className="line-seperator" />

          <div
            onClick={() => onSelectHandler("Whatsapp")}
            className="disp_flex_row"
            style={{
              marginBottom: "25px",
              marginTop: "25px",
              cursor: "pointer",
            }}
          >
            <WhatsappIconSvg />
            <span className="otp-method-txt">{t("send_via_whatsapp")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectOtpMethod;
