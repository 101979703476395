import React from "react";

const WelcomeOffer: React.FC = () => {
  return (
    <svg
      id="Group_8430"
      data-name="Group 8430"
      xmlns="http://www.w3.org/2000/svg"
      width="18.63"
      height="15"
      viewBox="0 0 16.628 13.003"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_370"
            data-name="Rectangle 370"
            width="30.628"
            height="27.003"
            fill="#ffa600"
          />
        </clipPath>
      </defs>
      <g id="Group_8429" data-name="Group 8429" clip-path="url(#clip-path)">
        <path
          id="Path_9191"
          data-name="Path 9191"
          d="M16.628,4.876a1.361,1.361,0,0,1-.636.9,2.336,2.336,0,0,1-1.033.3,5.7,5.7,0,0,1-1.926-.209c-.116-.031-.162,0-.223.1a2.6,2.6,0,0,1-.381.52Q9.392,9.535,6.346,12.573a1.279,1.279,0,0,1-1.915,0q-2-1.994-4-3.993A1.279,1.279,0,0,1,.429,6.663Q3.475,3.613,6.525.568A1.841,1.841,0,0,1,7.909,0C9.1.018,10.289.028,11.48.047A1.4,1.4,0,0,1,12.9,1.1c0,.016.017.031.015.046-.03.27.147.342.358.423a7.67,7.67,0,0,1,2.189,1.244,3.037,3.037,0,0,1,1.162,1.707Zm-5.851-1.2c.349.2.669.406,1.006.575A6.347,6.347,0,0,0,14.51,5a3.207,3.207,0,0,0,.841-.124c.2-.052.239-.155.149-.348a2.11,2.11,0,0,0-.311-.488,5.319,5.319,0,0,0-1.636-1.177c-.185-.1-.377-.177-.577-.269V4.064a.734.734,0,0,1-.081-.018,7.293,7.293,0,0,1-1.75-.873A.251.251,0,0,1,11.05,3,1.046,1.046,0,0,0,9.844,1.918,1.05,1.05,0,0,0,9.894,4a1.09,1.09,0,0,0,.883-.33"
          transform="translate(0 0)"
          fill="#ffa600"
        />
      </g>
    </svg>
  );
};

export default WelcomeOffer;
