import React, { useEffect } from "react";
import ReduxStore from "../setup/redux/store";
// import { useParams } from "react-router-dom";
import { Provider } from "react-redux";
import { functionChangeLanguge } from "./pages/Home/utils";
import PageRoutes from "./routing/Routes";
import ReactGA from "react-ga4";
import { useJsApiLoader } from "@react-google-maps/api";
import {
  GOOGLE_MAPS_KEYS,
  GOOGLE_MAPS_API_KEY,
  ENVIRONMENT,
} from "./pages/Home/utils";
import { libraries } from "./pages/Home/utils";
import { api_key_1 } from "./pages/Home/utils";
const App: React.FC = () => {
  // const checkAvailableLanguage = () => {
  //   const currentLang = localStorage.getItem("i18nextLng");
  //   // console.log(currentLang);
  //   if (currentLang !== null) {
  //     // functionChangeLanguge(currentLang);
  //   }
  // };
  // const { bussinessName } = useParams();
  // let shopValuesFromLocal = localStorage.getItem("shop/" + bussinessName);
  // const dispatch = useAppDispatch();
  // const getValues = () => {
  //   console.log(bussinessName);
  //   if (shopValuesFromLocal !== null) {
  //     var parsedValues = JSON.parse(shopValuesFromLocal);
  //     document.documentElement.style.setProperty(
  //       "--background-color-store",
  //       parsedValues.shopInfo.default_color
  //     );

  //     dispatch(setShopInfo(parsedValues.shopInfo));
  //   }
  // };
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_KEYS.get(GOOGLE_MAPS_API_KEY + ENVIRONMENT)!,
    preventGoogleFontsLoading: true,
    language: "en",
    // region: "Pakistan",
    // @ts-ignore
    libraries: libraries,
  });
  useEffect(() => {
    // G-YEN0MDFNZN
    // G-12KFQWY5HB

    ReactGA.initialize("G-12KFQWY5HB");

    console.log("current environmet : " + ENVIRONMENT);
    // checkAvailableLanguage();
    // getValues();
  }, []);
  return (
    <Provider store={ReduxStore}>
      <PageRoutes />
    </Provider>
  );
};

export default App;
