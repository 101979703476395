import React from "react";

const EmptyCartSvg3: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="69.361"
      height="112.267"
      viewBox="0 0 69.361 112.267"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_354"
            data-name="Rectangle 354"
            width="69.361"
            height="112.267"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_8423" data-name="Group 8423" transform="translate(0 -0.004)">
        <g
          id="Group_8422"
          data-name="Group 8422"
          transform="translate(0 0.004)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Path_9190"
            data-name="Path 9190"
            d="M367.834,884.314a.286.286,0,0,0,.017.118c-.991-.067-2.872-.286-3.7-.974a2.959,2.959,0,0,1-1.176-1.73,1.032,1.032,0,0,1,1.5-.974,8.6,8.6,0,0,1,2.436,1.495,11.931,11.931,0,0,1,1.209,1.428c-.151.084-.286.252-.286.638m17.5-55.886c1.092,1.411-10.515,45.589-15.857,54.761,1.411-.756,3.561-1.445,4.166-.319.571,1.025-.1,1.881-1.646,2.184a4.913,4.913,0,0,1-3.259-.42c-.067.034-.134.067-.218.1.252,7.794.37,15.857.151,16.059-.353-.017-.672-12.279-.4-16.042a.552.552,0,0,1-.353-.185,6.727,6.727,0,0,1-3.88-.705c-1.529-.924-2.033-2.52-.806-3.309,1.31-.857,3.292.672,4.435,1.982-5.61-8.617-19-53.467-17.957-54.946.5-.722,2.923,13.237,9.121,30.807,2.822,7.979,5.8,17.335,9.659,24.407,1.109-5.812,1.361-9.491,1.613-17.386.437-13.371-1.31-29.816-.941-27.515,1.579,10.011,1.982,25.633,1.209,36.065a45.972,45.972,0,0,1-1.159,8.533c3.376-7,5.8-15.336,8.231-23.1,5.577-17.772,7.357-31.681,7.895-30.975m-16.176,55.265c-.084.118-.151.235-.235.336a.484.484,0,0,1-.1.521c.991.185,3.83.6,4.5-.638.336-.6.05-1.31-.689-1.26a7.314,7.314,0,0,0-2.452.521,8.29,8.29,0,0,0-1.025.521"
            transform="translate(-333.159 -788.526)"
            fill="#ada7a6"
            fill-rule="evenodd"
          />
          <path
            id="Path_9191"
            data-name="Path 9191"
            d="M31.114,25.141c-1.092,7.324-6.8,12.9-13.085,13.808a5.21,5.21,0,0,1,.4,1.277c.118.6,1.932,2.2.6,2.268-.672.017-1.512-.37-1.982-.185-.924.4-1.209,1.461-1.5,1.125-.454-.521-.1-2.62.991-4.367-3.679.1-7.458-1.461-10.515-5.241-7.643-9.44-9-25.079,1.663-31.949,11.658-7.525,25.5,9.138,23.416,23.265"
            transform="translate(0 -0.004)"
            fill="#d8d8d8"
            fill-rule="evenodd"
          />
          <path
            id="Path_9192"
            data-name="Path 9192"
            d="M819.414,40.225c-.118.6-1.949,2.2-.6,2.268.655.017,1.243-.538,1.73-.47.42.05,1.646.638,1.915.319.453-.538-.034-1.764-1.125-3.276,3.679.084,7.425-1.478,10.482-5.241,7.626-9.44,9-25.079-1.663-31.949-11.658-7.525-25.5,9.138-23.416,23.265,1.092,7.324,6.8,12.9,13.085,13.808a5.215,5.215,0,0,0-.4,1.277"
            transform="translate(-768.479 -0.004)"
            fill="#ffa600"
            fill-rule="evenodd"
          />
          <path
            id="Path_9193"
            data-name="Path 9193"
            d="M454.993,228.173c1.411,7.273,7.374,12.582,13.69,13.2a5.038,5.038,0,0,0-.336,1.293c-.1.622-1.848,2.285-.5,2.285.857,0,1.092-.638,1.663-.622.773.034,1.764.722,1.982.437.42-.554-.118-1.848-1.293-3.343a12.884,12.884,0,0,0,10.23-5.711c7.206-9.76,7.878-25.449-3.091-31.849-11.994-6.988-25.062,10.28-22.341,24.306"
            transform="translate(-433.185 -192.722)"
            fill="#ffc76c"
            fill-rule="evenodd"
          />
          <path
            id="Path_9194"
            data-name="Path 9194"
            d="M1246.229,82.977c-2.536-.168-2.62,2.3.134,3.679,1.848.924,1.747,4.737,3.948,4.535,1.629-.168,1.713-7.794-4.082-8.214"
            transform="translate(-1185.618 -79.056)"
            fill="#fff2b8"
            fill-rule="evenodd"
          />
          <path
            id="Path_9195"
            data-name="Path 9195"
            d="M873.277,282.757c-2.52-.168-2.6,2.3.134,3.679,1.865.924,1.747,4.737,3.947,4.535,1.646-.168,1.713-7.794-4.082-8.214"
            transform="translate(-830.27 -269.412)"
            fill="#fefefe"
            fill-rule="evenodd"
          />
          <path
            id="Path_9196"
            data-name="Path 9196"
            d="M265.279,36.867c-2.268,1.125-1.109,3.292,1.965,3.108,2.083-.134,3.9,3.225,5.694,1.932,1.327-.958-2.452-7.593-7.66-5.039"
            transform="translate(-251.634 -34.575)"
            fill="#fefefe"
            fill-rule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};

export default EmptyCartSvg3;
