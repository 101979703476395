import React, { useEffect, useState } from "react";
import ReactPhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useParams, useNavigate } from "react-router-dom";
import useSendOtp from "../../../../../hooks/useSendOtp";
import { useTranslation } from "react-i18next";
import useSelectedLanguge from "../../../../../hooks/useSelectedLanguge";
import "intl-tel-input/build/css/intlTelInput.css";
import SimpleButton from "../../../../../shared/components/SimpleButton";
import SideLogoName from "../../../../../shared/components/SideLogoName";
import BackArrow from "../../../../../assets/img/svgs/BackArrow";
import { getShopDataFromStrg } from "../../../utils";
import ErrorModal from "../../../../../shared/components/ErrorModal";
import { getUserIpInfo, IProxyIp, isNotValidNumber } from "./utils";
const PopConfirmPhone: React.FC = () => {
  // const { onPressClose, shopName } = props;
  const { bussinessName } = useParams();
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState<string>("");
  const [ccS, setCCS] = useState<string>("");
  const valuesFromStorage = getShopDataFromStrg("/" + bussinessName!);
  const shopInfo = valuesFromStorage !== null && valuesFromStorage?.shopInfo;
  const [userErrorMEssage, setUserErrorMessage] = useState<string>("");
  const [showUserError, setUserShowError] = useState<boolean>(false);
  const { selectedLanguageCode } = useSelectedLanguge();
  const userIpInfo: IProxyIp = getUserIpInfo();
  const contryCodeLocal = userIpInfo !== null ? userIpInfo.countryCode : "iq";
  // const shopInfo = useAppSelector((state) => state.shopInfo);
  const { t } = useTranslation();
  /*
  const otpTemp: any = {
    data: {
      msisdn: "923105599915",
      req_id: "4762fa81-e465-4319-8cc4-aaca6c99992a",
      sms_id: 17240,
      status: "SUCCESS",
    },
    message: "Customer profile data",
    type: "success",
  };*/

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);

  const { sendOtpHandler, loading, otpData, errorMessage } = useSendOtp();

  const openValidateModel = async () => {
    var objIsNotValidNumber = isNotValidNumber(phoneNumber, countryCode, ccS);
    if (objIsNotValidNumber.isNotValid) {
      setShowError(true);
    } else {
      // uncomment in production

      sendOtpHandler(
        objIsNotValidNumber.validatePhone,
        countryCode,
        "Whatsapp"
      );

      // for dev purposes
      // localStorage.setItem("otpDataTemp", JSON.stringify(otpTemp));
      // navigate(`/${bussinessName}/validate-otp`, {
      //   state: { otpTemp },
      //   replace: true,
      // });
    }
  };
  const onPressClose = () => {
    navigate(`/${bussinessName}/basket`);
  };
  const navigateToValidate = () => {
    var objIsNotValidNumber = isNotValidNumber(phoneNumber, countryCode, ccS);
    navigate(`/${bussinessName}/validate-otp`, {
      state: { countryCode, phoneNumber: objIsNotValidNumber.validatePhone },
    });
  };
  useEffect(() => {
    if (otpData !== undefined) {
      if (otpData && otpData?.data.errdesc) {
        setUserShowError(true);
        setUserErrorMessage(otpData?.data.errdesc);
        return;
      }
      navigateToValidate();
    }
  }, [otpData]);

  return (
    <div lang={selectedLanguageCode}>
      <>
        <div
          id="pop_cart_page2 "
          className="pop_confrim_phone_modal pop_up_maps_main pop_main_menu_bg"
        >
          <div className="popup_cart_full popup_from_bottom  popup_slide">
            <div className="content" id="">
              <div className="cart_head_container">
                <div className="container">
                  <div className="header_cart_page">
                    <div className="cart_heading">&nbsp;</div>
                    {/* <div
                    onClick={onPressClose}
                    className="target_slide glyphicon glyphicon-chevron-left"
                    // href="#pop_cart_page1"
                  ></div> */}
                    <span
                      onClick={onPressClose}
                      className="target_slide glyphicon"
                    >
                      <BackArrow />
                    </span>
                  </div>
                </div>
              </div>
              {/* <!-- content start here --> */}
              <div
                className="cart_content"
                style={{ padding: "0px 29px 0px 29px" }}
              >
                <div className="cart_content_to_marg">
                  <div className="cart_content_row">
                    <text className="enter-mobilephone">
                      {t("enter_mobile_number")}
                    </text>
                    <h6 className="light_grey_text">
                      {t("enter_mobile_number_txt")}
                    </h6>
                  </div>

                  <div className="cart_content_row">
                    <div
                      className="title_acc_cell"
                      style={{
                        fontSize: 12,
                        fontWeight: "500",
                        marginBottom: 9,
                        marginTop: 16,
                      }}
                    >
                      {t("mobile_number")}
                    </div>

                    <div className="" style={{ direction: "ltr" }}>
                      <ReactPhoneInput
                        inputClass={showError ? "border-error " : ""}
                        countryCodeEditable={false}
                        country={contryCodeLocal?.toLowerCase()}
                        enableLongNumbers={true}
                        enableSearch={true}
                        preferredCountries={["iq", "ae"]}
                        value={phoneNumber}
                        onChange={(phone, data: CountryData) => {
                          setPhoneNumber(phone);
                          setCCS(data.countryCode);
                          setCountryCode(data.dialCode);
                          setShowError(false);
                        }}
                      />
                    </div>
                    {showError && (
                      <div
                        style={{ color: "red", marginTop: 10, fontSize: 10 }}
                      >
                        {" "}
                        <text>Please enter valid phone number</text>{" "}
                      </div>
                    )}
                    {errorMessage !== "" && !loading && (
                      <div
                        className={
                          errorMessage !== "" ? "show-error-message" : ""
                        }
                      >
                        <text>{errorMessage}</text>
                      </div>
                    )}
                    <SimpleButton
                      titleProp={t("send_code")}
                      loading={loading}
                      onPressHandler={openValidateModel}
                      t={t}
                    />
                    {showUserError && (
                      <ErrorModal
                        errorMessage={userErrorMEssage}
                        buttonMessage="Continue"
                        headerMessage=""
                        onPressClose={() => {
                          setUserShowError(false);
                          navigateToValidate();
                        }}
                        onBackDropClick={() => {
                          setUserShowError(false);
                          navigateToValidate();
                        }}
                      />
                    )}
                  </div>
                  <div className="cart_content_row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {shopInfo !== undefined && (
          <SideLogoName
            logoUrl={
              shopInfo?.site_logo
              // : logo
            }
            // bussinessName={"sss"}
            bussinessName={shopInfo?.name[selectedLanguageCode]}
          />
        )}
      </>
    </div>
  );
};

export default PopConfirmPhone;
