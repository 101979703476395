import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
const useSelectedLanguge = ()  =>{
    const {i18n} = useTranslation();
    const [selectedLanguageCode,setSelectedLanguageCode] = useState<any>(i18n.language);
    
  return {
    selectedLanguageCode
  }
}

export default useSelectedLanguge;