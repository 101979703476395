import React from "react";
import Loader from "../../modules/Loader";
import HandSvg from "../../assets/img/svgs/HandSvg";
interface BottomButtonProps {
  onPress: () => void;
  titleProp: string;
  loading?: boolean;
  displayBottomLine?: boolean;
  widthProp?: string | number;
  position?: any;
  showHandError?: boolean;
  description?: string;
}
const BottomButton: React.FC<BottomButtonProps> = (
  props: BottomButtonProps
) => {
  const {
    onPress,
    titleProp,
    loading,
    displayBottomLine,
    widthProp,
    showHandError,
    description,
    position,
  } = props;
  return (
    <div
      id="pd_button_select_location "
      className="cart_button_order button_select_location  max-width"
      style={{
        borderTop: displayBottomLine ? "1px solid #eeee" : "0px",
        position: position ? position : "absolute",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: "1000",
          background: " rgba(0,0,0,0.5)",
        }}
      >
        {/* {showHandError && <HandSvg />} */}
      </div>

      <button
        id="pd_cart_foot_button"
        disabled={loading}
        className="cart_foot_button"
        onClick={onPress}
      >
        {!loading ? (
          <div className="" style={{ fontWeight: "bold", fontSize: "16px" }}>
            {titleProp}
            {description && (
              <div className="txt-selec-loc-first">{description}</div>
            )}
          </div>
        ) : (
          <Loader height={15} width={15} colorProp={"white"} />
        )}
      </button>
    </div>
  );
};

export default BottomButton;
