import React, { useEffect, useState } from "react";
import {
  ReceiverData,
  AddressInterface,
  discountedValue,
  isDiscountAvailabel,
  basketFinalValue,
  REQ_ENV_IS_DEV,
  isValidOrderType,
  IUserIpInfo,
  getUserIpInfo,
  IProxyIp,
  checkDiscountOnDeliveryFee,
  getDeliveryFeeDisVal,
  IDiscountsDeliveryFee,
  checkEndDateTimeDis,
  getUserTokenFromLocal,
  getLoyaltyPoints,
  checkUserPointsWithOrderValues,
} from "./utils";
import {
  getCustomerInfoFromStroge,
  getCustomerLoyaltyPoints,
  getReqJsonModalGA4WithoutReqData,
  getShopDataFromStrg,
  getUserBirthdayDiscount,
  isValidURL,
  numberWithCommas,
  removeUserBirthdayDiscount,
  SelectedOulet,
  TrackGoogleAnalyticsEvent,
} from "../../../utils";
import moment from "moment";
import BottomButton from "../../../../../shared/components/BottomButton";
import useCustomerInfo from "../../../../../hooks/useCustomerInfo";
import ReceiverAlert from "./ReceiverAlert";
import useSelectedLanguge from "../../../../../hooks/useSelectedLanguge";
import { useNavigate, useParams } from "react-router-dom";
import SelectAddressModal from "../../../../../shared/components/SelectAddressModal";
import SelectReceiverModal from "../../../../../shared/components/SelectReceiverModal";
import ErrorModal from "../../../../../shared/components/ErrorModal";
import { useTranslation } from "react-i18next";
import TermsAndConditions from "../../../../../shared/components/TermsAndConditions";
import BackArrow from "../../../../../assets/img/svgs/BackArrow";
import SelectTimeModal from "../SetTimePages/SelectTimeModal";
import useBussinessPage from "../../../../../hooks/useBussiness";
import SideLogoName from "../../../../../shared/components/SideLogoName";
import PersonSvg from "../../../../../assets/img/svgs/PersonSvg";
import ClockSvg from "../../../../../assets/img/svgs/ClockSvg";
import PaymentRow from "./CartComponents/PaymentRow";
import DisplayDiscountAlert from "../../../../../shared/components/DisplayDiscountAlert";
import axios from "axios";
import {
  IPLACE_ORDER_REQ,
  IPLACE_ORDER_REQ_loyalty,
} from "../../../../../shared/interfaces";
import AddMoreItem from "./CartComponents/AddMoreItem";
import AddVoucher from "../../../../../shared/components/AddVoucher";
import SelectAddress from "./ConfirmOrderComponents/SelectAddress";
const PopConfirmOrder: React.FC = () => {
  const { bussinessName } = useParams();
  const { getBussinessData } = useBussinessPage();
  const {
    placeOrder,
    loading,
    apiErrorMessage,
    getOrderForCustometDiscountId,
    couponLoader,
    getBirthdayDiscount,
  } = useCustomerInfo();
  var userIpInfo: IProxyIp = getUserIpInfo();
  const [accessToken, setToken] = useState<string>(
    getUserTokenFromLocal(bussinessName!)
  );
  var userLoyaltyPointsStrg = getCustomerLoyaltyPoints(bussinessName!);
  const [customerId, setCustomerId] = useState<string>("");
  const [customerInfo, setCustomerInfo] = useState<any>();
  const { selectedLanguageCode } = useSelectedLanguge();
  const [basketValues, setBasketValues] = useState<any>();
  const [greetingMessage, setGreetingMessage] = useState<string>("");
  const [reciverError, setReciverError] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<string>("");
  const [showTimeError, setShowTimeError] = useState<boolean>(false);
  const [showLocatoinError, setShowLocationError] = useState<boolean>(false);
  const [showPaymentError, setPaymnetError] = useState<boolean>(false);
  const [basketQunatity, setBasketQuantity] = useState<any>({});
  const [paymentMode, setPaymentMode] = useState<string>("");
  const [billingGateway, setBillingGateway] = useState<string>("");
  const [modalTerms, setModalTerms] = useState<boolean>(false);
  const [selectedReceiverId, setSelectedReceiverId] = useState<number>(0);
  const [ignoreReceiver, setIgnoreReceiver] = useState<boolean>(false);
  const [isCreditCardEnable, setIsCard] = useState<boolean>(false);
  const [couponErrorMsg, setCouponErrorMsg] = useState<string>("");
  const [isCodEnabled, setIsCodEnabled] = useState<boolean>(false);
  const [receiverAlertType, setReceiverAlertType] = useState<string>("");
  const [checkDiscountType, setCheckDiscountType] = useState({
    available: false,
    discountType: "",
  });
  const [showDiscountAlert, setShowDiscountAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState<boolean>(false);
  const [isDiscount, setIsDiscount] = useState<string>("No");
  const [totalDiscount, setTotalDiscount] = useState<string>("");
  const [disOnDelFee, setDisOnDelFee] = useState<IDiscountsDeliveryFee>(
    {} as IDiscountsDeliveryFee
  );
  const [isWholeOrderDis, setIsWholeOrderDis] = useState<string>("No");
  const CODSIMPLEIMAGE = require("../../../../../assets/img/COD_SIMPLE.png");
  const [discounteDF, setDiscounteDF] = useState<number>(0);
  const valuesFromStorage = getShopDataFromStrg("/" + bussinessName!);
  const shopInfo = valuesFromStorage !== null && valuesFromStorage?.shopInfo;
  const [currency, setCurrency] = useState<string>(
    shopInfo?.resto_meta?.BUSSINESS_CCY_LANG[selectedLanguageCode]
  );
  const [errorModalMessage, setErrorModalMessage] =
    useState<string>(apiErrorMessage);
  const ISFLORIST = shopInfo?.resto_meta?.BUSSINESS_RECEIVER_FLAG === "Yes";
  const [originalDeliveryFee, setOriginalDeliveryFee] = useState<number>(0);
  const [openTimeModal, setOpenTimeModal] = useState<boolean>(false);
  const [discountObjFinal, setDiscountObjFinal] = useState<any>({});
  const [deliveryFee, setDeliveryFee] = useState<number>(0);
  const [applyLoyalty, setApplyLoyalty] = useState<boolean>(false);

  const [userBDisc, setUserBDisc] = useState({
    isdiscount: "No",
    mobile: "",
    coupen: "",
    dicount: "",
  });
  const [selectedOutlet, setSelectedOutlet] = useState<
    SelectedOulet | undefined
  >({} as SelectedOulet);

  const [userLoyaltyPoints, setUserLoyaltyPoints] = useState(0);
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<ReceiverData | undefined>(
    {} as ReceiverData
  );
  const closeCnfirmOrderPop = () => {
    // navigate(-1);
    navigate(`/${bussinessName}/basket`);
    // onPressClose();
  };
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAddressModal, setShowAddressModal] = useState<boolean>(false);
  // const [showConfirmDOB, setShowConfirmDOB] = useState<boolean>(false);
  const [userAddress, setUserAddress] = useState<AddressInterface>(
    {} as AddressInterface
  );
  const [localValues, setLocalValues] = useState<any>({});
  const openSelectHandler = () => {
    setShowModal(true);
  };
  const addressModalHandler = () => {
    var getValue = getShopDataFromStrg("/" + bussinessName!);
    if (getValue !== null && getValue.orderType === "delivery") {
      setShowAddressModal(true);
    }
  };
  const changeAddressModalHandler = () => {
    var getValue = getShopDataFromStrg("/" + bussinessName!);
    if (getValue !== null && getValue.orderType === "delivery") {
      setShowAddressModal(true);
    } else {
      navigate(`/p/${bussinessName}`);
      // return;
    }
  };
  const termsModalHandler = () => {
    setModalTerms(true);
  };
  const termsModalHide = () => {
    setModalTerms(false);
  };
  const hideModal = (
    receiver: ReceiverData | undefined,
    buttonPress: string | undefined
  ) => {
    if (receiver !== undefined) {
      if (buttonPress === "select") {
        setSelectedReceiverId(receiver.id);
        setSelectedItem(receiver);

        setShowModal(false);
        setValuesHandler();
      } else {
        setSelectedItem({} as ReceiverData);
      }
    } else {
      if (buttonPress === "close") {
        setShowModal(false);
      }
    }
  };
  const addMoreItemsHandler = () => {
    // var getOutletUrl = getShopDataFromStrg('/'+ bussinessName);
    navigate(localValues.outletUrl);
  };
  const onClickCODHandler = () => {
    if (shopInfo?.resto_meta?.BUSSINESS_RECEIVER_FLAG === "Yes") {
      setReciverError(true);
      setReceiverAlertType("ConfirmCOD");
      setErrorMessage(CONFIRM_COD_MESSAGE);
    } else {
      setBillingGateway("");
      setPaymentMethod("Placed");
      setPaymentMode("COD");
    }
  };
  const handleReceiverSelection = (action: string) => {
    switch (action) {
      case "Yes":
        setReciverError(false);
        openSelectHandler();
        break;
      case "Skip":
        setIgnoreReceiver(true);
        setReciverError(false);
        break;
      case "close":
        // setIgnoreReceiver(true);
        setReciverError(false);
        break;
      case "ConfirmCOD":
        setPaymentMethod("Placed");
        setPaymentMode("COD");
        setBillingGateway("");
        setReciverError(false);
        break;
      default:
        setReciverError(false);
        break;
    }

    //
  };

  function getDukanyCart() {
    // var trnid = "T_" + Math.random().toString(16).slice(2);
    // var item1Price = randomIntFromInterval(100, 500);
    // var item1Discount = 0.1 * item1Price;
    // var item1Qty = randomIntFromInterval(1, 5);

    // var item2Price = randomIntFromInterval(40, 100);
    // var item2Discount = 0.2 * item2Price;
    // var item2Qty = randomIntFromInterval(1, 3);
    // item1Discount = 0;
    // item2Discount = 0;

    // var totalPrice =
    //   item1Price * item1Qty -
    //   item1Discount +
    //   (item2Price * item2Qty - item2Discount);
    var newItems: any = [];
    basketValues?.forEach((element: any) => {
      element.name = element.name[selectedLanguageCode];
      newItems.push(element);
    });

    var resJson = {
      transaction_id: "",
      total_order_value: parseFloat(basketQunatity.price) + deliveryFee,
      value: basketQunatity.price,
      tax: "N/A",
      is_discount: "No",
      category: "Clothes",
      shipping: deliveryFee,
      currency: currency,
      affiliation: "TikTok",
      item_brand: bussinessName,
      coupon: "N/A",
      items: newItems,
    };
    return resJson;
  }
  const returnDateTime = () => {
    // 1673248755099
    // 1673767140997
    var dateTime = "";
    if (localValues !== null) {
      if (
        localValues?.shopInfo?.resto_meta?.ENABLED_AS_SOON_AS_POSSIBLE ==
          "Yes" &&
        undefined == localValues?.selectedTime
      ) {
        // dateTime = "ASAP";
        return null;
      }
      if (localValues.selectedTime === "ASAP") {
        return null;
      }

      if (undefined != localValues.selectedTime) {
        dateTime =
          moment(localValues.dateNumber).format("YYYY-MM-DD") +
          " " +
          localValues?.selectedTime;
        return dateTime;
      }
    }
    return null;
  };
  const removeDiscountHandler = () => {
    setIsDiscount("No");
    setIsWholeOrderDis("No");
    setCouponErrorMsg("");
    updateBasketAfterNoDis();
    setDiscounteDF(0);
    setDisOnDelFee({
      isDiscountDelivery: false,
      discountValueDelivery: 0,
      deliveryDiscountType: "",
    });
    // setDeliveryFee(originalDeliveryFee);
    // getValues();
  };
  const checkIsBirthDayDiscount = () => {
    var userBirthDayDisc = getUserBirthdayDiscount();
    if (userBirthDayDisc) {
      setUserBDisc(userBirthDayDisc);
      return userBirthDayDisc;
      // applyCopunCode(userBirthDayDisc.coupen, true);
    }
    return false;
  };

  const applyCopunCode = (code: string, isBirthDay?: boolean) => {
    var payLoadDataNoraml = {
      custId: customerInfo?.id,
      discountId: "0",
      access_token: accessToken,
      discountCode: code,
      mobile_number: customerInfo?.mobile_number,
      isBirthDay: isBirthDay,
    };
    getOrderForCustometDiscountId(payLoadDataNoraml).then((val) => {
      setCouponErrorMsg("");
      if (val && val.isDiscountFound && val.isDiscountValid) {
        var shopData = {
          ...localValues,
          shopInfo: { ...shopInfo, orderDetails: val.orderDetails },
        };
        localStorage.setItem("shop/" + bussinessName, JSON.stringify(shopData));
        getValues(val.disocunt);
        return;
      }
      if (val && (!val.isDiscountFound || !val.isDiscountValid)) {
        setCouponErrorMsg(val.failReason);
        return;
      }
    });
  };
  const hideAddressModal = (
    receiver: AddressInterface | undefined,
    buttonPress: string | undefined,
    discountObjFinal: any
  ) => {
    var getSelectedOutlet = getShopDataFromStrg("/" + bussinessName!);

    if (receiver !== undefined) {
      if (buttonPress === "select") {
        var tempOutlet = {
          ...getSelectedOutlet.outletInfo,
          // delivery_fee: receiver.delivery_fee,
          selectedLtnLon: receiver.selected_lat_lng,
          selectedLocationName: receiver.google_selected_address,
          //
          //
        };
        //@ts-ignore
        setSelectedOutlet(tempOutlet);
        setShowLocationError(false);
        setOriginalDeliveryFee(getSelectedOutlet.outletInfo.delivery_fee);
        setUserAddress(receiver);
        setShowAddressModal(false);
        if (shopInfo?.resto_meta?.BUSSINESS_TIMESLOTS_FLAG === "Yes") {
          setOpenTimeModal(true);
        }
        getDiscountOnDelivery(
          checkDiscountType,
          discountObjFinal,
          getSelectedOutlet.outletInfo.delivery_fee
        );
      } else {
        setUserAddress({} as AddressInterface);
      }
    } else {
      if (buttonPress === "close") {
        setShowAddressModal(false);
      }
    }
  };
  const setCardMethod = (value: string) => {
    setBillingGateway(value);
    setPaymentMethod("Initial");
    setPaymentMode("Card");
  };
  const setPaymentMethod = (payMethod: string) => {
    setPaymnetError(false);
    setPaymentType(payMethod);
    if (payMethod === "Placed") {
      setPaymentMode("COD");
    } else {
      setPaymentMode("Card");
    }
  };
  const checkLatLngHandler = () => {
    var latLng = `${selectedOutlet?.selectedLatLon.lat},${selectedOutlet?.selectedLatLon.lng}`;
    return latLng;
  };
  const checkUserSavedAddress = () => {
    var isAddressFound = true;
    if (Object.keys(userAddress).length === 0) {
      isAddressFound = false;
    }
    return isAddressFound;
  };
  const getUserAddressD_P = () => {
    var address = "";
    if (localValues.orderType === "pickup") {
      address = localValues?.outletInfo?.outlet_address[selectedLanguageCode];
      return address;
    }
    if (localValues.orderType === "delivery") {
      address = localValues?.outletInfo?.selectedLocationName;
      return address;
    }
    return address;
  };

  const addShakeAnimationOnTimeError = () => {
    var elementBlockColor = document.getElementById("select_time");
    setShowTimeError(true);
    // shake_animation
    setTimeout(() => {
      elementBlockColor?.classList.add("shake_animation");
    }, 0);
    setTimeout(() => {
      elementBlockColor?.classList.remove("shake_animation");
    }, 500);
  };
  const removeShakeAnimationOnTimeError = () => {
    var elementBlockColor = document.getElementById("select_time");
    setShowTimeError(false);
    // shake_animation
    // setTimeout(() => {
    elementBlockColor?.classList.remove("shake_animation");
    // }, 0);
  };

  const submitOrderHandler = () => {
    // if (shopInfo.resto_meta.BUSSINESS_TYPE === BussinessTypes.florist) {
    // console.log(selectedOutlet);
    if (
      shopInfo?.resto_meta?.DISABLED_LOCATION_SERVICES === "Yes" &&
      Object.keys(userAddress).length === 0 &&
      localValues.orderType !== "pickup"
    ) {
      setShowLocationError(true);
    } else if (
      localValues?.shopInfo?.resto_meta?.ENABLED_AS_SOON_AS_POSSIBLE !==
        "Yes" &&
      localValues?.formatedDayName === undefined &&
      localValues?.selectedTime === undefined
    ) {
      addShakeAnimationOnTimeError();
    } else if (
      selectedItem !== undefined &&
      Object.keys(selectedItem).length === 0 &&
      ISFLORIST &&
      !ignoreReceiver
    ) {
      setReciverError(true);
      setReceiverAlertType("ConfirmReceiver");
      setErrorMessage(RECEIVER_ERROR_MESSAGE);
    } else if (
      localValues?.shopInfo?.resto_meta?.ENABLED_AS_SOON_AS_POSSIBLE !==
        "Yes" &&
      localValues?.formatedDayName === undefined &&
      localValues?.selectedTime === undefined
    ) {
      addShakeAnimationOnTimeError();
    } else if (paymentType === "") {
      setPaymnetError(false);
    } else {
      if (isDiscount === "No") {
        localValues?.cart.forEach((element: any) => {
          delete element.discount_type;
          delete element.discount_id;
          delete element.discount_amount;
          delete element.discounted_amount;
          delete element.is_discount;
          delete element.is_for_whole_order;
        });
      }
      if (basketValues && basketValues.length > 0) {
        var deliveryCharges = localValues?.outletInfo?.delivery_fee;
        var loyaltyObj: IPLACE_ORDER_REQ_loyalty | null = null;
        if (shopInfo.loyalties) {
          loyaltyObj = {
            loyalty_id: shopInfo.loyalties.id,
            cash_back: shopInfo.loyalties.cash_back.with_percentage,
            redeemable_amount: getLoyaltyPoints(
              bussinessName!,
              basketQunatity.price
            ),
            is_loyalty_used: applyLoyalty ? "Yes" : "No",
            points_to_be_used: userLoyaltyPoints,
          };
        }
        // return;
        //@ts-ignore
        var cartValues: IPLACE_ORDER_REQ = {
          is_loyalty: shopInfo.loyalties !== null ? "Yes" : "No",
          loyalty: shopInfo.loyalties !== null ? loyaltyObj : undefined,
          address_id: checkUserSavedAddress() ? userAddress.id : 0,
          address_value: checkUserSavedAddress()
            ? userAddress.address
            : getUserAddressD_P(),
          discounted_amt_delivery_fee: discounteDF.toString(), // is discount on delivery
          delivery_discount_type: disOnDelFee.deliveryDiscountType,
          is_delivery_discount: disOnDelFee.isDiscountDelivery ? "Yes" : "No", // delivery discount value
          delivery_discount_value: disOnDelFee.discountValueDelivery, // delivery discount type
          userIpInfo: userIpInfo, // userIpInfo
          billingGateway: billingGateway, // billing gateway
          payment_mode: paymentMode, // paymentMode
          area:
            localValues.orderType !== "pickup"
              ? selectedOutlet!.selectedLocationName
              : null, // area
          cart: JSON.stringify(basketValues), // cart values
          total_price: basketQunatity?.price.toString(), // total price
          mobile_number: customerInfo?.mobile_number || "", // mobile number
          customer_name: customerInfo?.name || "", // customer name
          customer_id: customerInfo?.id.toString() || "", // customer id
          order_instructions: localValues.deliveryInstruction, // instructions
          delivery_fee:
            localValues.orderType !== "pickup" ? deliveryCharges : "0", // delivery fees
          time: returnDateTime()!, // time
          latlng: checkLatLngHandler(),
          selected_area: getUserAddressD_P(), // user selected address
          ip_address: userIpInfo.query, // ip address
          order_type: localValues.orderType, // order type
          campaign_date: new Date().getTime().toString(), // campaign date
          status: paymentType, // payment type
          outlet_id: selectedOutlet!.outlet_id.toString(), // outlet_id id
          recipient_id: ISFLORIST ? selectedItem!.id?.toString() || "" : "", // receiver id
          exchange_rate: "1", // exchange rate
          campaign_type: localValues.campainType, // campaign name
          isDev: REQ_ENV_IS_DEV.PROD, // is dev, production 0, rehan order 2
          is_discount: isDiscount, // is_discount
          is_for_whole_order: isWholeOrderDis, // is_for_whole_order
          discount_id: isDiscount === "Yes" ? discountObjFinal.id : undefined, // discount_id
          discount_type:
            isDiscount === "Yes" ? discountObjFinal?.discount_type : undefined, // discount_type
          discount_amount:
            isDiscount === "Yes" ? discountObjFinal?.amount : undefined, // discount_amount
          discounted_amount: isDiscount === "Yes" ? totalDiscount : undefined, // discounted_amount
          greeting_message: greetingMessage, // greeting message
          isCrDev: 0, // 0 for prod 1 for testing for credit card test
          CUSTOMER_CONFIRMATION_FOR_ORDER:
            shopInfo?.resto_meta?.CUSTOMER_CONFIRMATION_FOR_ORDER, //for whatsapp confirmation
        };

        if (
          isDiscount === "Yes" &&
          !checkEndDateTimeDis(discountObjFinal, shopInfo.time_zone)
        ) {
          setShowError(true);
          setErrorModalMessage(t("flash_sale_end_message"));
          removeDiscountHandler();
          return;
        }
        placeOrder(cartValues, accessToken, bussinessName!).then(
          ({ success, orderValues }) => {
            if (success) {
              var jsonBody = getReqJsonModalGA4WithoutReqData(
                bussinessName!,
                "purchase",
                "N/A",
                window.location.pathname,
                getDukanyCart()
              );
              TrackGoogleAnalyticsEvent(
                "purchase",
                "purchase",
                "purchase",
                jsonBody
              );

              if (orderValues.payment_link === null) {
                delete localValues.cart;
                localStorage.setItem(
                  "shop/" + bussinessName,
                  JSON.stringify(localValues)
                );
                navigate(
                  `/${bussinessName}/thankyou?order=${orderValues.order_id}&ref=${orderValues.order_ref}`,
                  { replace: true }
                );
              } else {
                window.open(orderValues?.payment_link, "_self");
              }
            } else {
              setShowError(true);
            }
          }
        );
      }
    }
  };
  const closeTimeModalHandler = () => {
    setOpenTimeModal(false);
    removeShakeAnimationOnTimeError();
    setValuesHandler();
  };
  const checkOrderCount = async (checkDiscountType: any) => {
    var customerInfo = getCustomerInfoFromStroge();
    var localValues = getShopDataFromStrg("/" + bussinessName!);
    if (
      checkDiscountType.available &&
      customerInfo !== null &&
      localValues !== null
    ) {
      var isExceedMaximun = false;
      var checkBirthdayDiscount = checkIsBirthDayDiscount();
      var payLoadDataNoraml = {
        custId: customerInfo?.id,
        discountId: shopInfo[checkDiscountType.discountType]?.id,
        access_token: accessToken,
        mobile_number: customerInfo?.mobile_number,
        discountCode: undefined || "",
        isBirthDay: false,
      };

      //   checkBirthdayDiscount ? checkBirthdayDiscount.coupen : undefined

      if (checkBirthdayDiscount && checkBirthdayDiscount.isdiscount == "yes") {
        payLoadDataNoraml = {
          custId: customerInfo?.id,
          access_token: accessToken,
          discountId: "0",
          mobile_number: customerInfo?.mobile_number,
          discountCode: checkBirthdayDiscount.coupen,
          isBirthDay: true,
        };
      }

      await getOrderForCustometDiscountId(payLoadDataNoraml).then((val) => {
        if (val && val.isDiscountFound && val.isDiscountValid) {
          var shopData = {
            ...localValues,
            shopInfo: { ...shopInfo, orderDetails: val.orderDetails },
          };
          localStorage.setItem(
            "shop/" + bussinessName,
            JSON.stringify(shopData)
          );

          isExceedMaximun = false;
          return isExceedMaximun;
        }
        if (val && !val.isDiscountValid) {
          isExceedMaximun = true;
          return isExceedMaximun;
        }
      });
      return isExceedMaximun;
    }
  };
  const checkDiscountValue = async (
    value: any,
    totalDiscount: string,
    isVaildOrder: any,
    discountObj: any,
    paredeObj: any
  ) => {
    const valuesFromStorage = getShopDataFromStrg("/" + bussinessName!);
    const shopInfo = valuesFromStorage !== null && valuesFromStorage;
    var totalNumberOfOrderUsedExceed: boolean = false;
    if (isVaildOrder.discountType !== "code") {
      await checkOrderCount(isVaildOrder).then((val) => {
        if (val) {
          totalNumberOfOrderUsedExceed = val;
        }
      });
    }
    var isDiscount = isDiscountAvailabel(
      shopInfo?.time_zone,
      discountObj,
      getCustomerInfoFromStroge(),
      valuesFromStorage?.orderType,
      shopInfo?.shopInfo?.orderDetails,
      value
    );
    if (totalNumberOfOrderUsedExceed) {
      isDiscount = {
        isValid: false,
        disNotAplyReason: `Number of usages exceed for code  (${discountObj?.discount_code})`,
      };
    }
    var totalDiscountTemp: number = 0;
    if (isVaildOrder.available && isDiscount.isValid) {
      var isWholeOrder = discountObj.is_whole_order;

      getDiscountOnDelivery(
        isVaildOrder,
        discountObj,
        paredeObj?.outletInfo?.delivery_fee
      );
      setIsWholeOrderDis(isWholeOrder);
      setIsDiscount("Yes");
      setDiscountObjFinal(discountObj);
      if (isVaildOrder.discountType !== "") {
        totalDiscountTemp = parseFloat(value) - parseFloat(totalDiscount);
      }
      return totalDiscountTemp.toFixed(2);
    } else {
      setCouponErrorMsg(isDiscount.disNotAplyReason);
      return value;
    }
  };
  const setValuesHandler = () => {
    let temp = localStorage.getItem("shop/" + bussinessName);
    if (temp !== null) {
      let paredeObj: any = {};
      paredeObj = JSON.parse(temp);
      getDiscountOnDelivery(
        checkDiscountType,
        discountObjFinal,
        paredeObj?.outletInfo?.delivery_fee
      );
      // setDeliveryFee(paredeObj?.outletInfo?.delivery_fee);
      setLocalValues(paredeObj);
      setBasketValues(paredeObj?.cart);
      if (paredeObj.userAddressInfo) {
        setUserAddress(paredeObj?.userAddressInfo);
      }
    }
  };

  const getDiscountOnDelivery = (
    isVaildOrder: any,
    discountObj: any,
    deliveryFee: number
  ) => {
    var newDeliveryFee = 0;
    console.log(discountObj);
    var checkDisObj = checkDiscountOnDeliveryFee(discountObj);
    setDisOnDelFee(checkDisObj);
    setDeliveryFee(deliveryFee);

    if (isVaildOrder.available && checkDisObj.isDiscountDelivery) {
      setDisOnDelFee(checkDisObj);
      var discounteDF = getDeliveryFeeDisVal(deliveryFee, checkDisObj);
      setDiscounteDF(discounteDF);
      newDeliveryFee = deliveryFee - discounteDF;
      newDeliveryFee = parseFloat(newDeliveryFee.toFixed(2));
      setDeliveryFee(newDeliveryFee);
    }
  };
  const updateBasketAfterNoDis = () => {
    let temp = localStorage.getItem("shop/" + bussinessName);
    if (temp !== null) {
      let paredeObj: any = {};

      paredeObj = JSON.parse(temp);
      var total_basket = 0;
      var total_price = 0;
      paredeObj.cart?.forEach((element: any, index: any) => {
        var priceTobeUsed =
          element.multiple_option.length > 0
            ? element.price
            : element.actual_price;
        total_basket += parseFloat(priceTobeUsed) * parseInt(element.quantity);
        total_price += parseFloat(priceTobeUsed) * parseInt(element.quantity);
      });
      setBasketQuantity({
        total: total_basket,
        price: total_price,
      });
    }
  };
  const applyLoyaltyHandler = (e: any) => {
    setApplyLoyalty(e.target.checked);
    if (!e.target.checked) {
      var paredeObj = getShopDataFromStrg("/" + bussinessName!);
      if (
        paredeObj?.outletInfo?.[
          `outlet_${paredeObj?.orderType}_payment_methods`
        ]?.includes("card")
      ) {
        setIsCard(true);
        setPaymentMethod("Initial");
      }
    } else {
      checkCardDisabledByLoylaty(e.target.checked);
    }
  };
  const checkCardDisabledByLoylaty = (applyLoyalty: boolean) => {
    var finalPrice = basketFinalValue(
      deliveryFee,
      basketQunatity?.total,
      applyLoyalty,
      userLoyaltyPoints
    );
    if (applyLoyalty && finalPrice == 0) {
      setIsCard(false);
      setPaymentMethod("Placed");
    }
  };
  const getValues = async (discountFromCoupon?: any) => {
    // 2023-01-05 11:30 AM
    let temp = localStorage.getItem("shop/" + bussinessName);
    let customerInfoLocal = localStorage.getItem("customerInfo");
    var birthdiscData = checkIsBirthDayDiscount();
    let isWholeOrder = false;
    if (customerInfoLocal !== null) {
      setCustomerId(JSON.parse(customerInfoLocal)?.id);
      setCustomerInfo(JSON.parse(customerInfoLocal));
    }
    if (temp !== null) {
      let paredeObj: any = {};
      paredeObj = JSON.parse(temp);
      setLocalValues(paredeObj);
      var isCard = false;
      var isVaildOrder = { available: false, discountType: "" };
      let discountObj: any = paredeObj?.shopInfo.discounts ?? {};
      if (
        birthdiscData &&
        birthdiscData.isdiscount === "yes" &&
        !discountFromCoupon
      ) {
        if (customerInfoLocal !== null) {
          var customer = JSON.parse(customerInfoLocal);
          discountObj = await getBirthdayDiscount(customer, birthdiscData);
        }
      }
      isVaildOrder = isValidOrderType(
        paredeObj?.shopInfo?.time_zone,
        discountObj,
        paredeObj?.orderType
      );
      if (!birthdiscData) {
        discountObj = paredeObj?.shopInfo[isVaildOrder.discountType];
      }
      if (discountFromCoupon) {
        discountObj = discountFromCoupon;
        isVaildOrder = {
          available: true,
          discountType: "code",
        };
      }
      setCheckDiscountType(isVaildOrder);
      setBasketValues(paredeObj?.cart);
      setSelectedOutlet(paredeObj.outletInfo);
      if (paredeObj.userAddressInfo) {
        setUserAddress(paredeObj?.userAddressInfo);
      }
      if (
        paredeObj?.outletInfo?.[
          `outlet_${paredeObj?.orderType}_payment_methods`
        ]?.includes("card") &&
        paymentMode === ""
      ) {
        setIsCard(true);
        isCard = true;
        setBillingGateway(shopInfo?.resto_meta?.BILLING_GATEWAY[0]?.value);
        setPaymentMethod("Initial");
        setPaymentMode("Card");
      }
      if (
        paredeObj?.outletInfo?.[
          `outlet_${paredeObj?.orderType}_payment_methods`
        ]?.includes("cash") &&
        paymentMode === ""
      ) {
        setIsCodEnabled(true);
        if (!isCard) {
          setPaymentMethod("Placed");
          setPaymentMode("COD");
        }
      }
      // }

      var total_basket = 0;
      var total_price = 0;
      var totalDiscount = 0;
      paredeObj.cart?.forEach((element: any, index: any) => {
        if (index == 0 && element.is_for_whole_order === "Yes") {
          isWholeOrder = true;
        }
        var priceTobeUsed =
          element.multiple_option.length > 0
            ? element.price
            : element.actual_price;

        total_basket += parseFloat(priceTobeUsed) * parseInt(element.quantity);
        total_price += parseFloat(priceTobeUsed) * parseInt(element.quantity);
        totalDiscount +=
          element.is_discount === "Yes" && element.is_for_whole_order === "No"
            ? parseFloat(element.discounted_amount)
            : 0;
      });

      if (isVaildOrder.available && isWholeOrder) {
        totalDiscount = parseFloat(
          discountedValue(
            total_basket,
            discountObj.discount_type,
            discountObj.amount,
            discountObj,
            true
          )
        );
      }

      if (isVaildOrder.available && !isWholeOrder) {
        if (
          discountObj?.maximum_discount != "0" &&
          totalDiscount > parseFloat(discountObj?.maximum_discount)
        )
          totalDiscount = parseFloat(discountObj?.maximum_discount);
      }
      if (
        birthdiscData &&
        isVaildOrder.available &&
        birthdiscData.isdiscount === "yes"
      ) {
        totalDiscount = parseFloat(
          discountedValue(
            total_basket,
            discountObj.discount_type,
            discountObj.amount,
            discountObj,
            true
          )
        );
      }
      // finalValue > parseFloat(discount?.maximum_discount)
      var finalBasketValues = {
        total: await checkDiscountValue(
          total_basket,
          totalDiscount.toFixed(2),
          isVaildOrder,
          discountObj,
          paredeObj
        ),

        price: total_price,
      };
      setBasketQuantity(finalBasketValues);
      setTotalDiscount(totalDiscount.toFixed(2));
      setOriginalDeliveryFee(paredeObj?.outletInfo?.delivery_fee);

      // setDeliveryFee(paredeObj?.outletInfo?.delivery_fee);
    }
  };

  useEffect(() => {
    window.onpopstate = (e) => {
      e.preventDefault();
      closeCnfirmOrderPop();
    };
  });

  useEffect(() => {
    setValuesHandler();
  }, [showAddressModal, openTimeModal]);

  useEffect(() => {
    console.log(userLoyaltyPointsStrg);
    setUserLoyaltyPoints(
      checkUserPointsWithOrderValues(
        basketQunatity.total + deliveryFee,
        userLoyaltyPointsStrg
      )
    );
  }, [basketQunatity, deliveryFee]);
  useEffect(() => {
    console.log(userLoyaltyPoints);
  }, [userLoyaltyPoints]);
  useEffect(() => {
    if (shopInfo === undefined) {
      getBussinessData(accessToken, bussinessName!);
    }
    getValues();
    if (shopInfo?.resto_meta?.DISABLED_LOCATION_SERVICES === "Yes") {
      addressModalHandler();
    }
  }, []);
  useEffect(() => {
    if (apiErrorMessage !== "") {
      setErrorModalMessage(apiErrorMessage);
    }
  }, [apiErrorMessage]);

  const { t } = useTranslation();
  const RECEIVER_ERROR_MESSAGE = t("add_receiver_question");
  const CONFIRM_COD_MESSAGE = t("order_payment_receiver_note");
  return (
    <div lang={selectedLanguageCode}>
      <>
        <div className="pop_confirm_order">
          <div
            id="pop_cart_page6"
            className="pop_up_maps_main pop_main_menu_bg"
          >
            <div className="popup_cart_full popup_from_bottom popup_slide">
              <div className="content" id="">
                <div className="cart_head_container">
                  <div className="container">
                    <div className="header_cart_page">
                      <div className="cart_heading">{t("your_order")}</div>
                      <span
                        onClick={closeCnfirmOrderPop}
                        className="target_slide glyphicon"
                      >
                        <BackArrow />
                      </span>
                    </div>
                  </div>
                </div>
                {/* <!-- content start here --> */}
                <div className="cart_content">
                  <div className="cart_content_row pad_bot_10">
                    <div className="title_acc_cell">
                      {localValues?.orderType === "delivery"
                        ? t("choose_delivery")
                        : t("picking_up_from")}
                    </div>
                    <div
                      className={`order_rows_border ${
                        showLocatoinError && "border-error"
                      }`}
                    >
                      <SelectAddress
                        localValues={localValues}
                        selectedLanguageCode={selectedLanguageCode}
                        userAddress={userAddress}
                      />
                      <div
                        className="cart_content_col_25 cir_cart_butt"
                        onClick={changeAddressModalHandler}
                      >
                        {t("change_time_location")}
                      </div>
                    </div>
                  </div>
                  <div className="cart_content_row pad_bot_10 ">
                    <div
                      className={`title_acc_cell ${
                        showTimeError ? "show-error-message" : ""
                      }`}
                    >
                      {localValues?.orderType === "delivery"
                        ? t("delivery_timing")
                        : t("pickup_time")}
                    </div>

                    <div
                      id="select_time"
                      className={`order_rows_border ${
                        showTimeError && "border-error"
                      }`}
                    >
                      {/* {showTimeError && <HandSvg onPressHandler={() => {}} />} */}
                      <div className="cart_content_col_60">
                        <div className="title_acc_cell no_pad">
                          <span>
                            <ClockSvg
                              fillColor={
                                shopInfo?.resto_meta?.BUSINESS_COLORS
                                  ?.GENERAL_COLOR_THEME
                              }
                            />
                          </span>
                          <span style={{ padding: "0px 09px 0px 09px" }}>
                            {localValues?.selectedTime !== undefined
                              ? localValues?.formatedDayName +
                                " | " +
                                localValues?.selectedTime
                              : localValues?.shopInfo?.resto_meta
                                  ?.ENABLED_AS_SOON_AS_POSSIBLE == "Yes"
                              ? t("delivery_time")
                              : "Please select time"}
                            {/* 07-07-2022 | 11:30 pm */}
                          </span>
                        </div>
                      </div>
                      <div
                        className="cart_content_col_25 cir_cart_butt"
                        onClick={() => setOpenTimeModal(true)}
                      >
                        {t("change_time_location")}
                      </div>
                    </div>
                  </div>
                  {shopInfo?.resto_meta?.BUSSINESS_RECEIVER_FLAG === "Yes" && (
                    <>
                      <div className="cart_content_row pad_bot_10">
                        <div className="title_acc_cell">
                          {t("receiver_information")}
                        </div>
                        <div
                          className={`order_rows_border`}
                          onClick={openSelectHandler}
                        >
                          <div className={`cart_content_col_60`}>
                            <div
                              style={{
                                color: "#686868",
                                fontSize: 14,
                                fontWeight: 400,
                                alignItems: "center",
                              }}
                              className="title_acc_cell no_pad"
                            >
                              <span
                                style={
                                  {
                                    // marginRight: "11px",
                                  }
                                }
                              >
                                <PersonSvg
                                  fillColor={
                                    shopInfo?.resto_meta?.BUSINESS_COLORS
                                      ?.GENERAL_COLOR_THEME
                                  }
                                />
                              </span>
                              {/* Rand | 0331 547 2862 */}

                              <span style={{ padding: "0px 09px 0px 09px" }}>
                                {/* @ts-ignore */}
                                {Object.keys(selectedItem)?.length !== 0
                                  ? selectedItem?.recipient_name +
                                    "|" +
                                    selectedItem?.recipient_phone
                                  : t("no_receiver_selected")}
                              </span>
                            </div>
                          </div>
                          <div className="cart_content_col_25 cir_cart_butt">
                            {/* {t("change_time_location")} */}
                            {t("select")}
                          </div>
                        </div>
                      </div>
                      <div className="cart_content_row">
                        <textarea
                          style={{ border: "0.5px solid #9B9B9B", height: 73 }}
                          name="comment_page_8"
                          className="cart_text_area"
                          rows={5}
                          placeholder={t("add_greetin_placeholder")}
                          value={greetingMessage}
                          onChange={(e) => setGreetingMessage(e.target.value)}
                        ></textarea>
                      </div>
                    </>
                  )}

                  <div className="cart_content_row pad_bot_10">
                    <div className="title_acc_cell">{t("payment_methods")}</div>
                    <div
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        alignContent: "center",
                      }}
                      className={`order_rows_border ${
                        showPaymentError && "border-error"
                      }`}
                    >
                      {isCodEnabled && (
                        <div className="display_flex_wrapper marg_bot_10">
                          <input
                            type="radio"
                            name="cart_card_opt"
                            readOnly
                            value="cash on delivery"
                            className={`cart_radio `}
                            onClick={() => onClickCODHandler()}
                            checked={paymentType === "Placed"}
                          />
                          <div
                            // className="swiper-slide"

                            style={{
                              height: "22px",
                              width: "22px",
                              marginLeft: "10px",
                              // marginRight: "10px",
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundImage: `url(${CODSIMPLEIMAGE})`,
                            }}
                          ></div>
                          <label
                            style={{ marginLeft: 10 }}
                            className={`cart_lable ${
                              paymentType === "Placed" &&
                              "background-color-payment"
                            }`}
                          >
                            {t(
                              localValues?.orderType === "delivery"
                                ? "cod"
                                : "cop"
                            )}
                            {/* {t("cop")} */}
                          </label>
                        </div>
                      )}
                      {isCreditCardEnable &&
                        shopInfo?.resto_meta?.BILLING_GATEWAY &&
                        shopInfo?.resto_meta?.BILLING_GATEWAY.map(
                          (item: any, index: number) => {
                            return (
                              <div
                                className="display_flex_wrapper "
                                // style={{ marginTop: 10 }}
                              >
                                <input
                                  type="radio"
                                  // id="op2_cart"
                                  name="cart_card_opt"
                                  readOnly
                                  value="credit card"
                                  className={`cart_radio`}
                                  onClick={() => {
                                    setCardMethod(item?.value);
                                  }}
                                  // checked={paymentType === "Initial"}
                                  checked={billingGateway === item?.value}
                                />
                                <div
                                  style={{
                                    height: "22px",
                                    width: "22px",
                                    marginLeft: "10px",
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    backgroundImage: `url(${
                                      item?.is_show_icon === 0
                                        ? item?.img
                                        : item?.icon
                                    })`,
                                  }}
                                ></div>
                                <label
                                  onClick={() => {
                                    setCardMethod(item?.value);
                                  }}
                                  style={{ marginLeft: 10 }}
                                  className={`margin_left_10 cart_lable ${
                                    billingGateway === item?.value &&
                                    "background-color-payment"
                                  }`}
                                >
                                  {item?.is_show_icon === 0
                                    ? item.displayname
                                    : t("master_card")}
                                </label>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                  {selectedOutlet &&
                    (basketValues === undefined ||
                      basketValues.length === 0 ||
                      basketQunatity?.price <
                        selectedOutlet?.min_basket_price) && (
                      <AddMoreItem
                        basketValues={selectedOutlet}
                        shopInfo={shopInfo}
                        onPresshandeler={addMoreItemsHandler}
                        selectedLanguageCode={selectedLanguageCode}
                      />
                    )}
                  <AddVoucher
                    discountObj={discountObjFinal}
                    isDiscount={isDiscount}
                    onPressRemove={removeDiscountHandler}
                    onPressSubmit={(code) => applyCopunCode(code)}
                    loading={couponLoader}
                    couponErrorMsg={couponErrorMsg}
                  />
                  {/* loyalty toggle button */}
                  <div
                    className="disp_flex_row"
                    style={{
                      justifyContent: "space-between",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    {userLoyaltyPoints > 0 && (
                      <>
                        <div style={{ fontSize: 12 }}>
                          {t("lbl_loyalty_order_usepoints").replace(
                            "{#amtoff}",
                            `${currency} ${userLoyaltyPoints}`
                          )}
                          {/* {`Use your loyalty balance to get ${currency} ${userLoyaltyPoints} off`} */}
                        </div>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={applyLoyalty}
                            onChange={(e) => applyLoyaltyHandler(e)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </>
                    )}
                  </div>
                  <div className="cart_content_row">
                    <div className="cart_content_col_70 flo_left">
                      {t("sub_total")}
                    </div>
                    <div className="cart_content_col_30">
                      {selectedLanguageCode === "en" && currency}{" "}
                      {numberWithCommas(basketQunatity?.price?.toFixed(2))}{" "}
                      {selectedLanguageCode === "ar" && currency}
                    </div>
                  </div>
                  {isDiscount === "Yes" && (
                    <PaymentRow
                      currency={currency}
                      selectedLang={selectedLanguageCode}
                      onPressIcon={() => {
                        setShowDiscountAlert(true);
                      }}
                      labelValue={t("Discount")}
                      actualValue={totalDiscount}
                      isShow={true}
                    />
                  )}

                  {localValues.orderType !== "pickup" && (
                    <div>
                      <div className="cart_content_row">
                        <div className="cart_content_col_70 flo_left">
                          {t("delivery_fee")}
                        </div>
                        <div className="cart_content_col_30">
                          {selectedLanguageCode === "en" && currency}{" "}
                          {originalDeliveryFee}{" "}
                          {selectedLanguageCode === "ar" && currency}
                        </div>
                      </div>
                      {checkDiscountType.available &&
                        disOnDelFee.isDiscountDelivery && (
                          <PaymentRow
                            currency={currency}
                            selectedLang={selectedLanguageCode}
                            onPressIcon={() => {
                              setShowDiscountAlert(true);
                            }}
                            labelValue={t("discount_on_delivery_fee")}
                            actualValue={discounteDF.toFixed(2)}
                            isShow={true}
                          />
                        )}
                    </div>
                  )}
                  {applyLoyalty && (
                    <PaymentRow
                      currency={currency}
                      selectedLang={selectedLanguageCode}
                      onPressIcon={() => {}}
                      labelValue={t("lbl_loyalty_order_showloyalty")}
                      actualValue={userLoyaltyPoints.toString()}
                      isShow={true}
                      isShowButton={true}
                    />
                  )}
                  <div className="cart_content_row alert_color">
                    <div className="cart_content_col_70  flo_left">
                      {t("grand_total")}{" "}
                      {shopInfo?.resto_meta?.DISPLAY_TAX_INFO_TRANSLATION && (
                        <small>
                          (
                          {
                            shopInfo?.resto_meta?.DISPLAY_TAX_INFO_TRANSLATION[
                              selectedLanguageCode
                            ]
                          }
                          )
                        </small>
                      )}
                    </div>
                    <div className="cart_content_col_30">
                      {selectedLanguageCode === "en" && currency}{" "}
                      {basketQunatity?.total !== undefined
                        ? numberWithCommas(
                            basketFinalValue(
                              deliveryFee,
                              basketQunatity?.total,
                              applyLoyalty,
                              userLoyaltyPoints
                            )
                          )
                        : 0}{" "}
                      {selectedLanguageCode === "ar" && currency}
                    </div>
                  </div>
                  {shopInfo?.resto_meta?.TERM_AND_CONDITIONS && (
                    <>
                      {isValidURL(
                        shopInfo?.resto_meta?.TERM_AND_CONDITIONS
                      ) && (
                        <div className="cart_content_row light_grey_text font_s">
                          {t("terms_condition")}{" "}
                          <span
                            className="terms-conditons-text"
                            onClick={termsModalHandler}
                          >
                            {t("termsconditon")}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {/* <!-- content end here --> */}
              </div>
              <br />
              <br />
              <br />
              <br />
              {modalTerms && (
                <TermsAndConditions
                  onPressClose={termsModalHide}
                  url={shopInfo?.resto_meta?.TERM_AND_CONDITIONS}
                />
              )}
              {showModal && (
                <SelectReceiverModal
                  selectedId={selectedReceiverId}
                  onPressClose={(
                    receiver: ReceiverData | undefined,
                    buttonPressed: string | undefined
                  ) => hideModal(receiver, buttonPressed)}
                  // dataValue={receiverInfo}
                  accesssToken={accessToken}
                  customerId={customerId}
                />
                // </div>
              )}
              {showAddressModal && (
                <SelectAddressModal
                  selectedId={userAddress?.id}
                  selectedLatLng={
                    selectedOutlet !== undefined
                      ? selectedOutlet?.selectedLatLon
                      : undefined
                  }
                  onPressClose={(
                    receiver: AddressInterface | undefined,
                    buttonPress: string | undefined
                  ) =>
                    hideAddressModal(receiver, buttonPress, discountObjFinal)
                  }
                  // dataValue={receiverInfo}
                  accesssToken={accessToken}
                  customerId={customerId}
                />
              )}
              {reciverError && (
                <ReceiverAlert
                  alertType={receiverAlertType}
                  titleString={t("title_cod")}
                  onPressClose={(action: string) =>
                    handleReceiverSelection(action)
                  }
                  errorMessage={errorMessage}
                />
              )}
              {showError && (
                <ErrorModal
                  headerMessage="Error"
                  buttonMessage="Ok"
                  onPressClose={() => {
                    setShowError(false);
                  }}
                  onBackDropClick={() => setShowError(false)}
                  errorMessage={errorModalMessage}
                />
              )}
              {showLocatoinError && (
                <ErrorModal
                  headerMessage={t("choose_item_location")}
                  buttonMessage="Ok"
                  onPressClose={() => {
                    setShowLocationError(false);
                  }}
                  onBackDropClick={() => setShowError(false)}
                  errorMessage={"Please select delivery location"}
                />
              )}
              {showDiscountAlert && (
                <DisplayDiscountAlert
                  selectedLang={selectedLanguageCode}
                  onPressClose={() => {
                    setShowDiscountAlert(false);
                  }}
                  discount={discountObjFinal}
                />
              )}
              {openTimeModal && (
                <SelectTimeModal
                  onPressClose={closeTimeModalHandler}
                  shopName={bussinessName!}
                />
              )}
              {/* { ConfirmDOB && <ConfirmDOB
                onSuccess={() => {}}
                closeModal={() => {}}
                backDropPress={() => {}}
              />} */}
            </div>
            {selectedOutlet &&
              !showModal &&
              !modalTerms &&
              !showAddressModal &&
              !openTimeModal &&
              !reciverError &&
              !showDiscountAlert &&
              basketQunatity?.price >= selectedOutlet?.min_basket_price && (
                <BottomButton
                  widthProp={375}
                  displayBottomLine={true}
                  loading={loading}
                  onPress={submitOrderHandler}
                  titleProp={t("place_order")}
                />
              )}
          </div>
        </div>
        {shopInfo && (
          <SideLogoName
            logoUrl={
              shopInfo?.site_logo
              // : logo
            }
            // bussinessName={""}
            bussinessName={
              shopInfo?.name[
                selectedLanguageCode !== null ? selectedLanguageCode : "en"
              ]
            }
          />
        )}
      </>
    </div>
  );
};

export default PopConfirmOrder;
