import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useParams,
} from "react-router-dom";
import OrderPage from "../pages/Home/HomePage";
import PopConfirmPhone from "../pages/Home/Components/Popups/CartPages/PopConfirmPhone";
import PopPageValidateOtp from "../pages/Home/Components/Popups/CartPages/PopPageValidateOtp";
import BasketPage from "../pages/Home/Components/Popups/CartPages/BasketPage";
import PopConfirmOrder from "../pages/Home/Components/Popups/CartPages/PopConfirmOrder";
import OutletPage from "../pages/LandingPage";
import PaymentFail from "../pages/PaymnetFailed/PaymentFail";
import PageNotFound from "../shared/components/PageNotFound";
import MenuPage from "../pages/MenuPage/MenuPage";
import PopOrderStatus from "../pages/Home/Components/Popups/CartPages/PopOrderStatus";
import PopGreeting from "../pages/Home/Components/Popups/CartPages/PopGreeting";
import { useAppDispatch } from "../hooks/useRedux";
import CreateNewCustomer from "../pages/Home/Components/Popups/CartPages/CreateNewCustomer";
import { setShopInfo } from "../../setup/redux/action-creators/outlet-info";
import {
  functionChangeLanguge,
  getSelectedLanguage,
} from "../pages/Home/utils";

const PageRoutes: React.FC = () => {
  const getPathName = window.location.pathname;
  const { bussinessName } = useParams();
  const dispatch = useAppDispatch();
  const finalPathName = getPathName.slice(getPathName.lastIndexOf("/"));

  const getValues = () => {
    let shopValuesFromLocal = localStorage.getItem(
      "shop/" + getPathName.split("/")[1]
    );
    if (shopValuesFromLocal !== null) {
      var parsedValues = JSON.parse(shopValuesFromLocal);
      document.documentElement.style.setProperty(
        "--background-color-store",
        parsedValues.shopInfo.default_color
      );
      //@ts-ignore
      favicon.href = parsedValues.shopInfo.site_logo;

      dispatch(setShopInfo(parsedValues.shopInfo));
      if (parsedValues?.shopInfo?.resto_meta?.BUSINESS_COLORS.length !== 0) {
        document.documentElement.style.setProperty(
          "--background-color-store",
          parsedValues?.shopInfo?.resto_meta?.BUSINESS_COLORS
            ?.GENERAL_COLOR_THEME
        );
        document.documentElement.style.setProperty(
          "--DELIVERY_PICKUP_COLOR",
          parsedValues?.shopInfo?.resto_meta?.BUSINESS_COLORS
            ?.DELIVERY_PICKUP_COLOR
        );
        document.documentElement.style.setProperty(
          "--HIGHLIGHTED_BACKGROUND_COLOR",
          parsedValues?.shopInfo?.resto_meta?.BUSINESS_COLORS
            ?.HIGHLIGHTED_BACKGROUND_COLOR
        );
        var currentLanguage = getSelectedLanguage(getPathName.split("/")[1]);

        if (currentLanguage !== "") {
          console.log(currentLanguage);
          functionChangeLanguge(currentLanguage, getPathName.split("/")[1]);
        }
      }
    }
  };

  useEffect(() => {
    //@ts-ignore
    console.log("title", window.dynamicTitle);
    getValues();
    // console.log(finalPathName);
  }, []);

  // useEffect(() => {
  //   console.log(window.location.pathname.split("/")[1]);
  //   detectRoute(window.location.pathname.split("/")[1]);
  // }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PageNotFound />} />
        <Route path="/d/:bussinessName" element={<OrderPage orderType="d" />} />
        <Route path="/p/:bussinessName" element={<OrderPage orderType="p" />} />
        <Route
          path="/p/:bussinessName/item/:itemId"
          element={<OrderPage orderType="p" />}
        />
        <Route
          path="/d/:bussinessName/item/:itemId"
          element={<OrderPage orderType="d" />}
        />
        <Route path="/m/:bussinessName" element={<MenuPage />} />
        {/* for instagram */}
        <Route
          path="/id/:bussinessName"
          element={<Navigate to={`/d${finalPathName}?a=instagram`} />}
        />
        <Route
          path="/ip/:bussinessName"
          element={<Navigate to={`/p${finalPathName}?a=instagram`} />}
        />
        <Route
          path="/im/:bussinessName"
          element={<Navigate to={`/m${finalPathName}?a=instagram`} />}
        />
        {/* for instagram */}

        {/* for facebook */}
        <Route
          path="/fd/:bussinessName"
          element={<Navigate to={`/d${finalPathName}?a=facebook`} />}
        />
        <Route
          path="/fp/:bussinessName"
          element={<Navigate to={`/p${finalPathName}?a=facebook`} />}
        />
        <Route
          path="/fm/:bussinessName"
          element={<Navigate to={`/m${finalPathName}?a=facebook`} />}
        />
        {/* for facebook */}

        {/* for snapchat */}
        <Route
          path="/sd/:bussinessName"
          element={<Navigate to={`/d${finalPathName}?a=Snapchat`} />}
        />
        <Route
          path="sp/:bussinessName"
          element={<Navigate to={`/p${finalPathName}?a=Snapchat`} />}
        />
        <Route
          path="/sm/:bussinessName"
          element={<Navigate to={`/m${finalPathName}?a=Snapchat`} />}
        />
        {/* for snapchat */}

        {/* for google */}
        <Route
          path="/gd/:bussinessName"
          element={<Navigate to={`/d${finalPathName}?a=google`} />}
        />
        <Route
          path="/gp/:bussinessName"
          element={<Navigate to={`/p${finalPathName}?a=google`} />}
        />
        <Route
          path="/gm/:bussinessName"
          element={<Navigate to={`/m${finalPathName}?a=google`} />}
        />
        {/* for google */}

        {/* for whatsapp */}
        <Route
          path="/wd/:bussinessName"
          element={<Navigate to={`/d${finalPathName}?a=whatsapp`} />}
        />

        <Route
          path="/wp/:bussinessName"
          element={<Navigate to={`/p${finalPathName}?a=whatsapp`} />}
        />
        <Route
          path="/wm/:bussinessName"
          element={<Navigate to={`/m${finalPathName}?a=whatsapp`} />}
        />
        {/* for google */}

        {/* for tiktok */}
        <Route
          path="/td/:bussinessName"
          element={<Navigate to={`/d${finalPathName}?a=TikTok`} />}
        />
        <Route
          path="/tp/:bussinessName"
          element={<Navigate to={`/p${finalPathName}?a=TikTok`} />}
        />
        <Route
          path="/tm/:bussinessName"
          element={<Navigate to={`/m${finalPathName}?a=TikTok`} />}
        />
        {/* for tiktok */}
        {/* routes for basket pages */}
        <Route path="/:bussinessName/basket" element={<BasketPage />} />
        <Route
          path="/:bussinessName/payment-failed"
          element={<PaymentFail />}
        />
        <Route
          path="/:bussinessName/create-customer"
          element={<CreateNewCustomer />}
        />
        <Route path="/:bussinessName/send-otp" element={<PopConfirmPhone />} />
        <Route
          path="/:bussinessName/validate-otp"
          element={<PopPageValidateOtp />}
        />
        <Route
          path="/:bussinessName/place-order"
          element={<PopConfirmOrder />}
        />
        <Route path="/:bussinessName/thankyou" element={<PopGreeting />} />
        <Route
          path="/:bussinessName/track/order"
          element={<PopOrderStatus />}
        />
        <Route path="*" element={<PageNotFound />} />

        {/* routes for basket pages */}
      </Routes>
    </BrowserRouter>
  );
};

export default PageRoutes;
