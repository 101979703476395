import  { useState } from "react";
import { useTranslation } from "react-i18next";
import { getShopDataFromStrg } from "../pages/Home/utils";
import { useParams } from "react-router-dom";
const useGetCurrency = () => {
  const { i18n } = useTranslation();
  const { bussinessName } = useParams();
  const valuesFromStorage = getShopDataFromStrg("/" + bussinessName!);
  const [selectedLanguageCode] = useState<any>(i18n.language);
  const shopInfo = valuesFromStorage !== null && valuesFromStorage?.shopInfo;
  const [currency] = useState<string>(
    shopInfo?.resto_meta?.BUSSINESS_CCY_LANG[selectedLanguageCode]
  );

  return {
    currency,
  };
};

export default useGetCurrency;
