import React from "react";
import { useTranslation } from "react-i18next";
import { IDiscounts } from "../../pages/Home/Components/Popups/CartPages/utils";
import {
  getDiscountAlertMessage,
  getShopDataFromStrg,
} from "../../pages/Home/utils";
import { useParams } from "react-router-dom";
interface DisplayDiscountAlertProp {
  onPressClose: () => void;
  discount: IDiscounts;
  selectedLang: string;
}
const DisplayDiscountAlert: React.FC<DisplayDiscountAlertProp> = (
  props: DisplayDiscountAlertProp
) => {
  const { t } = useTranslation();
  const { onPressClose, discount, selectedLang } = props;
  const { bussinessName } = useParams();

  const GetDiscountApplicationType = () => {
    var getShopInfo = getShopDataFromStrg("/" + bussinessName!);
    var finalString = "";
    if (null !== getShopInfo && discount.discount_type === "percentage") {
      finalString = `${discount.amount}%`;
      return finalString;
    }
    // ${getShopInfo.shopInfo.BUSSINESS_CCY_LANG[selectedLang]}
    console.log(getShopInfo);
    if (null !== getShopInfo && discount.discount_type === "fixed_value") {
      finalString = `${getShopInfo.shopInfo.resto_meta.BUSSINESS_CCY_LANG[selectedLang]}  ${discount.amount}`;
      return finalString;
    }
    return finalString;
  };

  return (
    <div
      id="pop_time_set"
      className="pop_up_maps_main pop_main_menu_bg "

      // style={{ background: "rgba(0, 0, 0, 0.5)" }}
    >
      <div
        style={styles.container}
        className=" popup_delivery popup_from_bottom pop_time_set_height popup_slide"
      >
        <div className="delivery_close" style={{ marginBottom: 10 }}>
          <span
            className="slide_remove cross_button"
            onClick={() => onPressClose()}
          >
            &times;
          </span>
        </div>
        <div
          style={{
            fontSize: 22,
            fontWeight: "bold",
            marginBottom: 10,
          }}
        >
          {/* {`Get ${discount.discount_type === 'fixed_value' ? `AED ${discount.amount}`:`${discount.amount}%`}`} */}
          {/* @ts-ignore */}
          {getDiscountAlertMessage(discount, selectedLang)}
          {/* {discount?.discount_name[selectedLang]} */}
        </div>
        <div style={styles.labelStyle}>{t("applicable_discount")} </div>
        <div style={styles.mgBottom}>{GetDiscountApplicationType()}</div>
        <div style={styles.labelStyle}>{t("minimum_order_value")}</div>
        <div style={styles.mgBottom}>{discount?.minimum_order_value}</div>
        <div style={styles.labelStyle}>{t("maximum_discount")} </div>
        <div style={styles.mgBottom}>{discount?.maximum_discount}</div>
        <div style={styles.labelStyle}>
          {t("times_user_can_avail_discount")}{" "}
        </div>
        <div style={styles.mgBottom}>
          {discount?.number_of_uses_per_customer}
        </div>
        <br />
      </div>
    </div>
  );
};

export default DisplayDiscountAlert;

const styles = {
  container: {
    maxHeight: "90%",
    overflow: "hidden",
    paddingLeft: 25,
    paddingRight: 15,
    paddingTop: 10,
  },
  labelStyle: {
    // fontFamily: "Roboto-Medium",
    color: "black",
  },
  mgBottom: {
    marginBottom: 10,
    color: "#828282",
  },
};
