import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Loyalties,
  getCustomerInfoFromStroge,
  getCustomerLoyaltyPoints,
} from "../../pages/Home/utils";
import { useParams } from "react-router-dom";
import useSelectedLanguge from "../../hooks/useSelectedLanguge";
import useGetCurrency from "../../hooks/useGetCurrency";
interface Prop {
  onPressClose: () => void;
  loyalties: Loyalties;
  selectedLang: string;
}
const LoyaltyModal: React.FC<Prop> = (props: Prop) => {
  const { t } = useTranslation();

  const { onPressClose, loyalties, selectedLang } = props;
  const { bussinessName } = useParams();
  const [customerInfo, setCustomerInfo] = useState(getCustomerInfoFromStroge());
  const { currency } = useGetCurrency();
  const [customerPoints, setCustomerPoints] = useState<number | string>(
    getCustomerLoyaltyPoints(bussinessName!)
  );
  const LoyaltyTick = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      data-spec="icon-check"
      className="w-5 h-5 pt-1"
      style={{ width: "16px", height: "16px" }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.962 15.548l-3.255-3.255-1.414 1.414 4 4a1 1 0 001.45-.038l9-10-1.486-1.338-8.295 9.217z"
        fill="#000"
      ></path>
    </svg>
  );
  const RenderDetailsRow = ({ title }: any) => {
    return (
      <div className="disp_flex_row" style={{ marginBottom: 10 }}>
        <LoyaltyTick />
        <div style={styles.mgBottom}>{title}</div>
      </div>
    );
  };

  return (
    <div
      id="pop_time_set"
      className="pop_up_maps_main pop_main_menu_bg "

      // style={{ background: "rgba(0, 0, 0, 0.5)" }}
    >
      <div
        style={styles.container}
        className=" popup_delivery popup_from_bottom pop_time_set_height popup_slide"
      >
        <div className="delivery_close" style={{ marginBottom: 10 }}>
          <span
            className="slide_remove cross_button"
            onClick={() => onPressClose()}
          >
            &times;
          </span>
        </div>
        <div
          style={{
            fontSize: 22,
            fontWeight: "bold",
            marginBottom: 10,
          }}
        >
          {t("lbl_loyalty_pop_heading")}
        </div>
        <div
          style={{
            color: "rgba(140, 140, 140, 1)",
            marginBottom: 15,
          }}
        >
          {t("lbl_loyalty_pop_desc")}
        </div>
        {customerInfo !== null && (
          <div style={styles.walletInfo}>
            {t("lbl_loyalty_pop_points")
              .replace("{#points}", customerPoints.toString())
              .replace("{#currency}", currency)}
            {/* {`You have AED ${customerPoints} in your wallet`} */}
          </div>
        )}
        <div style={styles.labelStyle}>{t("lbl_loyalty_pop_notes_title")}</div>

        <RenderDetailsRow
          title={t("lbl_loyalty_pop_notes_text1").replace(
            "{#cashback}",
            `${loyalties.cash_back.with_percentage}`
          )}
          // title={`Get ${loyalties.cash_back.with_percentage} back as loyalty credits.`}
        />
        {loyalties.redeemable_percent.normal_amount && (
          <RenderDetailsRow
            title={t("lbl_loyalty_pop_notes_text2").replace(
              "{#cashback}",
              `${loyalties?.redeemable_percent?.with_percentage}`
            )}
          />
        )}
        {loyalties.maximum_redeemable_amt && (
          <RenderDetailsRow
            title={t("lbl_loyalty_pop_notes_text2").replace(
              "{#cashback}",
              `${loyalties.maximum_redeemable_amt}`
            )}
          />
        )}

        {/* </ol> */}

        <br />
      </div>
    </div>
  );
};

export default LoyaltyModal;

const styles = {
  container: {
    maxHeight: "90%",
    overflow: "hidden",
    paddingLeft: 25,
    paddingRight: 15,
    paddingTop: 10,
  },
  labelStyle: {
    color: "black",
    fontSize: "1.7rem",
  },
  mgBottom: {
    // marginBottom: 10,
    marginLeft: 5,
    color: "#828282",
  },
  walletInfo: {
    marginTop: 20,
    marginBottom: 20,
    paddingTop: "1rem",
    width: "32rem",
    paddingBottom: "1rem",
    borderRadius: "99999px",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    border: "1px solid #00d2a3",
    color: "#00d2a3",
  } as React.CSSProperties,
  borderSuccess: {
    borderColor: "#00d2a3",
  },
};
