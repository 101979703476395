import React, { useEffect, useRef } from "react";
import VanillaCalendar from "@uvarov.frontend/vanilla-calendar";
import { FormatDateString } from "@uvarov.frontend/vanilla-calendar/src/types";
import { DAYS_NAME_NUMBER, OBJECT_KEY_DAYS } from "../../../utils";
import "@uvarov.frontend/vanilla-calendar/build/vanilla-calendar.min.css";
import moment from "moment";
import ReactHelmet, { Helmet } from "react-helmet";

interface SelectDataModalProps {
  onPressClose: () => void;
  disabledWeek: any;
  onChangeDay: (
    dayName: string,
    dateNumber: any,
    weeDayName: string,
    index?: number | undefined,
    isCalender?: boolean
  ) => void;
}
const SelectDateModal: React.FC<SelectDataModalProps> = (
  props: SelectDataModalProps
) => {
  const { onPressClose, disabledWeek, onChangeDay } = props;
  const calendarEl = useRef<HTMLDivElement>(null);

  var maxValue = new Date();
  maxValue.setDate(maxValue.getDate() + 30);
  const closeModal = () => {
    onPressClose();
  };
  const onClickDay = (event: any, dates: any) => {
    var dateWeek = new Date(dates[0]).getDay();
    console.log(dateWeek);
    var dateNumber = new Date(dates[0]).getTime();
    var dayName = DAYS_NAME_NUMBER[dateWeek as OBJECT_KEY_DAYS];
    // console.log(dateNumber);
    onChangeDay(dates[0], dateNumber, dayName, undefined, true);
  };
  useEffect(() => {
    // console.log(moment(maxValue).format("YYYY-MM-DD") as FormatDateString);
    if (!calendarEl.current) return;

    const calendar = new VanillaCalendar(calendarEl.current, {
      settings: {
        lang: "en",
        range: {
          disablePast: true,
          // disableWeekday: [5],
          max: moment(maxValue).format("YYYY-MM-DD") as FormatDateString,
          // max: "2023-03-23",
        },
      },
      actions: {
        clickDay(event, dates) {
          onClickDay(event, dates);
        },
      },
    });
    // console.log(calendar);
    calendar.init();
  }, [calendarEl]);

  return (
    <div id="pop_time_set_2" className="pop_up_maps_main pop_main_menu_bg">
      <div
        className="popup_delivery popup_from_bottom pop_time_set_height popup_slide"
        style={{ height: "80%" }}
      >
        <div className="content" id="">
          <div className="container">
            <div className="container">
              <div className="head_burg_menu">
                <div className="calender_header">
                  <a
                  // className="target_slide glyphicon glyphicon-chevron-left"
                  // href="#pop_time_set"
                  >
                    <span> Delivery Time</span>
                  </a>
                  <div className="calender_close">
                    <a
                      onClick={closeModal}
                      className="slide_remove"
                      //  href="#Accordion_tabs"
                    >
                      &times;
                    </a>
                  </div>
                </div>
                <div className="delivery_head">Select Date</div>
                <div className="delivery_time_text">
                  Schedule when you want to receive your order
                </div>
              </div>
            </div>
            <div ref={calendarEl}></div>
          </div>

          {/* <div className="container_foot">
            <button className="confirm_time_date">
              <div className="">Select Date</div>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SelectDateModal;
