import React from "react";
import { Categories } from "../../utils";
import { useTranslation } from "react-i18next";
interface Props {
  hideMenuCategories: (path: string | undefined) => void;
  categories: Categories[];
  selectedLanguageCode: any;
}
const BlockSideMenu: React.FC<Props> = (props: Props) => {
  const { hideMenuCategories, categories, selectedLanguageCode } = props;
  const { t } = useTranslation();
  return (
    <div id="pop_menu_items" className="pop_up_maps_main pop_main_menu_bg ">
      <div className="popup_delivery popup_from_bottom half_height popup_slide ">
        <div className="content" id="">
          <div className="container">
            <div className="container head_burg_menu ">
              <div className="delivery_head custom_font menu_categories_head">
                {t("menu_categories")}
              </div>
              <div className="delivery_close">
                <a
                  className="slide_remove"
                  onClick={() => hideMenuCategories(undefined)}
                >
                  &times;
                </a>
              </div>
            </div>
            {categories.map((item, index) => {
              return (
                <span key={index} className="pop_b">
                  <a
                    className="link_btn pop_a slide_remove menu-name"
                    onClick={() => {
                      hideMenuCategories(`list-item${index}`);
                    }}
                  >
                    {item?.name[selectedLanguageCode]}{" "}
                  </a>
                </span>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockSideMenu;
