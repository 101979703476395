import React from "react";
interface PersonSvgProps {
  fillColor: string;
}
const PersonSvg: React.FC<PersonSvgProps> = (props: PersonSvgProps) => {
  const { fillColor } = props;
  return (
    <svg
      height={15}
      fill={fillColor}
      width={15}
      enable-background="new 0 0 24 24"
      id="Layer_1"
      version="1.0"
      viewBox="0 0 20 20"
      //   xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <circle cx="12" cy="8" r="4" />
      <path d="M12,14c-6.1,0-8,4-8,4v2h16v-2C20,18,18.1,14,12,14z" />
    </svg>
  );
};

export default PersonSvg;
