import React from "react";
import ReactLoading from "react-loading";
interface LoaderProps {
  colorProp: string;
  height: string | number;
  width: string | number;
}
const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const { colorProp, height, width } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ReactLoading
        type={"bubbles"}
        color={"black"}
        height={height}
        width={width}
      />
    </div>
  );
};

export default Loader;
