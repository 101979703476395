import React from "react";
import CrossSvg from "../../assets/img/svgs/CrossSvg";
interface TermsAndConditionsProps {
  onPressClose: () => void;
  url: string;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = (
  props: TermsAndConditionsProps
) => {
  const { onPressClose, url } = props;
  return (
    <div className=" pop_up_maps_main">
      <div className="col-12 popup_cart_full popup_from_bottom  popup_slide">
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            justifyContent: "center",
            paddingTop: 20,
          }}
          onClick={onPressClose}
        >
          <CrossSvg height={30} width={30} wrapperBgColor={"black"} />
        </div>
        <br />
        <br />
        <iframe
          style={{ borderWidth: "inherit" }}
          height={"100%"}
          width={"100%"}
          src={url}
          loading="eager"
          title="Custom title"
        ></iframe>
        <div className="text-center"></div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
