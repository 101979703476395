import { t } from "i18next";
import moment from "moment";
import React from "react";
import CrossSvg from "../../../../../../assets/img/svgs/CrossSvg";
import DoneIconSvg from "../../../../../../assets/img/svgs/DoneIconSvg";
import { useTranslation } from "react-i18next";
import RecordSvg from "../../../../../../assets/img/svgs/RecordSvg";
interface Props {
  trackOrderVal: any;
  shopInfo: any;
  selectedLanguageCode: any;
  array: any;
  backgroundColor: string;
  index: any;
}
const TrackOrderStatus: React.FC<Props> = (props: Props) => {
  const {
    trackOrderVal,
    shopInfo,
    selectedLanguageCode,
    array,
    index,
    backgroundColor,
  } = props;
  const STATUS_Success = "Success";
  const STATUS_FAIL = "Fail";
  const STATUS_NA = "N/A";

  const { t } = useTranslation();
  return (
    <div className="trackOrderStatusWrapper" key={index}>
      <div className="disp_flex">
        <div className="" style={{ width: "11%" }}>
          {trackOrderVal !== undefined && trackOrderVal?.updated_at ? (
            <div
              className="light_grey_text pad_10_up_down"
              style={{ paddingRight: 0 }}
            >
              {moment(trackOrderVal?.updated_at).format("hh:mm")}
            </div>
          ) : (
            <div className="light_grey_text pad_10_up_down">
              <div style={{ width: "11%" }} />{" "}
            </div>
          )}
        </div>

        <div className="" style={{ padding: "0 12px 0 12px" }}>
          {trackOrderVal !== undefined &&
          trackOrderVal?.statusReason === STATUS_Success ? (
            <DoneIconSvg
              wrapperBgColor={backgroundColor}
              wrapperHeigth={25}
              wrapperWidth={25}
              height={25}
              width={25}
            />
          ) : trackOrderVal?.statusReason !== STATUS_FAIL ? (
            <RecordSvg height={25} width={25} />
          ) : (
            <CrossSvg wrapperBgColor={backgroundColor} height={25} width={25} />
          )}
        </div>
        <div className="cart_content_col_70_">
          <div
            style={{ paddingRight: 0 }}
            className={` ${
              trackOrderVal?.statusRecorded === "Yes"
                ? "light_grey_text"
                : "black_color"
            } pad_10_up_down`}
          >
            {/* {trackOrderVal?.statusReason !== STATUS_FAIL */}

            {trackOrderVal?.msg[selectedLanguageCode]}
            {/* : t("cancelled")} */}
          </div>
        </div>
      </div>
      {array.length - 1 !== index && (
        <div className="disp_flex">
          <div className="cart_content_col_21_5 border_right marg_neg" />
        </div>
      )}
    </div>
  );
};

export default TrackOrderStatus;
