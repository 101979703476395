import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DeliveryZoneObj,
  SelectedOulet,
  navHighlighter,
  languages,
  getWindowSize,
  imagePlaceHolder,
  closeModal,
  getShopDataFromStrg,
  setThemeColor,
  functionChangeLanguge,
  getSelectedLanguage,
} from "../Home/utils";

import i18next, { use } from "i18next";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useBussinessPage from "../../hooks/useBussiness";

import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { useScrollLock } from "../../hooks/useScrollLock";
import "./MenuPage.css";
import ProductDetail from "./Components/ProductDetail";
import Loader from "../../modules/Loader";
import { appSharedColors } from "../../shared/styles/reactStyles";
import SideLogoName from "../../shared/components/SideLogoName";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Footer from "../../shared/components/Footer";
const logo = require("../../assets/img/main_logo.png");

type Props = {
  children?: React.ReactNode;
};
const MenuPage: React.FC<Props> = ({ children }) => {
  const { bussinessName } = useParams();
  const pageHistory = useNavigate();
  //console.log('menu-secreen');
  const api_key_org = "AIzaSyDuy-r_oc1Wo_i9_ocrnE82j8N3wyJWjzM";
  // const api_key_1 = "AIzaSyAJfzOqR9u2eyXv6OaiuExD3jzoBGGIVKY";
  const { lockScroll, unlockScroll } = useScrollLock();
  const count = useAppSelector((state) => state.testData.usedata);
  const shopInfo = useAppSelector((state) => state.shopInfo);
  const { t, i18n } = useTranslation();
  const [isGridView, setIsGridView] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [outletAddres, setOutletAddress] = useState<any>();
  // const [selectedLanguageCode, setSelectedLanguage] = useState<any>("");
  const [selectedLanguageCode, setSelectedLanguageCode] = useState<any>("");
  const [showDelivery, setShowDelivery] = useState(false);
  const getPathName = window.location.pathname;
  const finalPathName = "/" + bussinessName; //getPathName.slice(getPathName.lastIndexOf("/"));
  const { getBussinessData, loading, useData, categories, getUserToken } =
    useBussinessPage();
  //console.log("getB data start");
  // console.log(getBussinessData);
  //console.log("getB data end ");
  const parsedData = getShopDataFromStrg(finalPathName);
  const [selectedRecipe, setSelectedRecipe] = useState({});
  const [selectedId, setSelectedId] = useState<number | undefined>();
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [showBasketPop, setShowBasketPop] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [basketItems, setBasketItems] = useState<any>({});
  const [currentLanguageCode, setCurrentLanguageCode] = useState<any>("");

  const tokenCheckHandler = async () => {
    if (parsedData !== null) {
      //@ts-ignore
      getBussinessData(
        //@ts-ignore
        parsedData.accessToken,
        finalPathName
      );
    } else {
      getUserToken(finalPathName);
    }
  };
  const showMenuHandler = () => {
    lockScroll();
    setShowMenu(true);
  };
  const setSelectedCategory = (item: any) => {
    setSelectedId(item.id);
  };
  const showDetailLayout = (recipe: any) => {
    // alert('IT me');

    setSelectedRecipe(recipe);
    lockScroll();
    setShowDetailModal(true);
  };

  const hideMenuCategories = (path: string | undefined) => {
    if (path) {
      var my_element = document.getElementById(path);
      // console.log(my_element);

      closeModal();
      setTimeout(() => {
        setShowMenu(false);
        unlockScroll();

        my_element?.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "start",
        });
      }, 400);
    } else {
      closeModal();
      setTimeout(() => {
        setShowMenu(false);
        unlockScroll();
      }, 400);
    }
  };

  const checkFromBasketHandler = (catrgoreyItem: any) => {
    var isFound: boolean = false;
    basketItems.cart?.forEach((item: any) => {
      if (item.id === catrgoreyItem.shared_unique_id) {
        isFound = true;
      }
    });
    return isFound ? isFound : false;
  };

  useEffect(() => {
    tokenCheckHandler();
  }, []);
  useEffect(() => {
    console.log(selectedLanguageCode);
  }, [selectedLanguageCode]);

  useEffect(() => {
    window.addEventListener("scroll", navHighlighter); // Add an event listener listening for scroll
    const links = document.querySelectorAll(".navigation ul a");

    function clickHandler(this: any, e: { preventDefault: () => void }) {
      e.preventDefault();
      const href = this.getAttribute("href");
      // const offsetTop = document.querySelector(href).offsetTop - 80;
      const menu_height =
        document.getElementById("Accordion_tabs")?.clientHeight; // get the height of menu scroll cause it changes in mobile/tab views
      const offsetTop =
        document.querySelector(href).offsetTop - menu_height! + 1;

      // eslint-disable-next-line no-restricted-globals
      scroll({
        top: offsetTop,
        behavior: "smooth",
      });
      // Hash bang added for two reason
      // 1- simple #location cancles the smoth scroll
      // 2- google crawller wont consider just #location a sperate link.
      // for SEO we need hashbang #!location.
      // eslint-disable-next-line no-restricted-globals
      // location.hash = "!" + href.replace("#", "");

      // reseting scroll in menu tab to top .half_height
      document.querySelector(".half_height")!.scrollTop = 0;
    }
    for (const link of links) {
      link.addEventListener("click", clickHandler);
    }
  });

  //console.log(shopInfo);
  useEffect(() => {
    if (shopInfo) {
      // setCurrentLanguageCode(shopInfo.default_lang);
      setThemeColor(shopInfo);
      if (shopInfo.default_lang === "ar") {
        document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
        document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
      }
    }
  }, [shopInfo]);
  useEffect(() => {
    if (useData) {
      const favicon = document.getElementById("favicon");
      // functionChangeLanguge(selectedLanguageCode, bussinessName!);
      //@ts-ignore
      favicon.href = useData?.site_logo;
      // setSelectedLanguage(useData?.default_lang);
      // document.title = t("order_title") + useData?.name[selectedLanguageCode];
      if (useData?.resto_meta?.PRODUCT_ORIENTATION !== undefined) {
        if (useData?.resto_meta?.PRODUCT_ORIENTATION === "LIST") {
          setIsGridView(false);
        } else {
          setIsGridView(true);
        }
      }
      if (useData?.is_language_option_available === "Yes") {
        var currentLanguage = getSelectedLanguage(bussinessName!);

        if (currentLanguage !== "default" && currentLanguage !== "") {
          setSelectedLanguageCode(currentLanguage);
          functionChangeLanguge(currentLanguage, bussinessName!);
        } else {
          setSelectedLanguageCode(useData?.default_lang);
          functionChangeLanguge(useData?.default_lang, bussinessName!);
        }
      } else {
        setSelectedLanguageCode(useData?.default_lang);
        functionChangeLanguge(useData?.default_lang, bussinessName!);
      }
      if (searchParams.get("outlet") !== null) {
        var outlet_address =
          useData?.outlets[searchParams.get("outlet")!].outlet_address;
        setOutletAddress(outlet_address);
      } else {
        setOutletAddress("");
      }

      // selectedOutInfo(
      //   firstoOutLetName + "_ao_" + areName,
      //   firstDeliveryZonesData,
      //   locationText,
      //   {},
      //   useData,
      //   finalPathName
      // );
    }
  }, [useData]);

  return (
    <div className="homePage main menu-page" lang={selectedLanguageCode}>
      {!loading ? (
        <div className="landscape" id="">
          <div id="myCarousel" className="carousel slide" data-ride="carousel">
            {/* code for language switcher */}
            {/* <div className="language_switch">
              <div className={`language_switch_circle  "select_lang"`}>
                {currentLanguageCode}
              </div>
            </div> */}
            {/* code for language switcher */}
            {useData?.is_language_option_available === "Yes" && (
              <div className="language_switch">
                {useData?.language_options?.length > 0 &&
                  useData?.language_options?.map((item, index) => (
                    <div
                      className={`language_switch_circle  ${
                        selectedLanguageCode !== item ? "select_lang" : ""
                      }`}
                      onClick={() => {
                        // setSelectedLanguage(item);
                        functionChangeLanguge(item, bussinessName!);
                        setSelectedLanguageCode(item);
                      }}
                    >
                      {item?.toUpperCase()}
                      {/* {"en".toUpperCase()} */}
                    </div>
                  ))}
              </div>
            )}

            <div className="carousel-inner" role="listbox">
              {/* <div className="item active">
                <img src={useData?.backgroud_image} alt="" />

                <div className="carousel-caption"></div>
              </div> */}
              <div className="carousel-inner" role="listbox">
                <div
                  // className="item active carousel_background_image"
                  className="backkground-image-style caurosel_heigth_width"
                  style={{
                    backgroundImage: `url(${useData?.cover_image})`,
                  }}
                >
                  {/* <img src={useData?.cover_image} alt="" /> */}
                  {/* <img src={logo} alt="" /> */}
                  {/* <div className="carousel-caption"></div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="body_warp" style={{ marginBottom: "10px" }}>
            <div className="container">
              <div className="container">
                <h2 className="bussiness-name" style={{ marginTop: "16px" }}>
                  {useData?.name[selectedLanguageCode]}
                </h2>
                {outletAddres && (
                  <p className="light_grey_text res_font_size1 margin-top-five font-family">
                    {/* {shopInfo.default_lang === "ar"
                  ? useData?.address[currentLanguageCode]
                  : useData?.address} */}
                    {outletAddres[selectedLanguageCode]}
                    {/* {useData?.address[selectedLanguageCode]} */}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="sticky_tabs tab_default_2_hide" id="Accordion_tabs">
            <div className="menu_wrap">
              <div className="x-scroll" id="mainNav">
                <a className="target_slide">
                  <span
                    className="burger_custom"
                    id="burger_custom"
                    onClick={showMenuHandler}
                  >
                    <div className="burger_rect"></div>
                    <div className="burger_rect"></div>
                    <div className="burger_rect"></div>
                  </span>
                </a>
                <nav className="navigation">
                  <ul>
                    <li className="remove_dot">
                      {categories?.map((item, index) => {
                        return (
                          <>
                            {item.recipes !== undefined && (
                              <span
                                key={index}
                                id={`list${index}accordion${index}`}
                                className={
                                  item.id == selectedId || index == 0
                                    ? "under_line_b selected"
                                    : "under_line_b"
                                }
                                // onClick={(e) => setSelectedCategory(item)}
                              >
                                <a
                                  id={`list${index}accordion${index}`}
                                  className={
                                    item.id == selectedId || index == 0
                                      ? "link_btn active"
                                      : "link_btn"
                                  }
                                  // href="#My_Rose_Heart"
                                  href={`#list-item${index}`}
                                  // onClick={() => scrollToSection(index)}
                                >
                                  {/* {selectedLanguageCode === "ar"
                                            ? item.arabic_name
                                            : item.english_name} */}
                                  {item?.name[selectedLanguageCode]}
                                </a>
                              </span>
                            )}
                          </>
                        );
                      })}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          {categories.map((item, index) => {
            return (
              <>
                {item.recipes && (
                  <section
                    key={index}
                    id={`list-item${index}`}
                    className="tab_default_2_hide"
                  >
                    <div
                      className="panel-group"
                      id="accordion1"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      <div className="panel panel-default">
                        <div
                          className={`panel-heading ${
                            isGridView ? "mb_20" : ""
                          }`}
                          role="tab"
                          id="headingOne"
                        >
                          <h4 className="panel-title">
                            <a
                              role="button"
                              data-toggle="collapse"
                              data-parent={`#accordion${index}`}
                              href={`#collapseOpen${index}`}
                              aria-expanded="true"
                              aria-controls={`#collapseOpen${index}`}
                            >
                              {/* {selectedLanguageCode === "ar"
                                        ? item.arabic_name
                                        : item.english_name} */}
                              {item?.name[selectedLanguageCode]}
                            </a>
                          </h4>
                        </div>
                        <div
                          id={`collapseOpen${index}`}
                          className={`${
                            isGridView ? "grid_row" : "list_view_panel"
                          } panel-collapse  collapse in`}
                          // className="panel-collapse pad_bottom_20 collapse in"
                          role="tabpanel"
                          aria-labelledby="headingOne"
                        >
                          {item.recipes !== undefined &&
                            item.recipes.map((recipe, index, arr) => {
                              return (
                                <>
                                  <div
                                    key={index}
                                    className={`
                                    ${isGridView ? "gridView" : "columnView"}`}
                                    style={{
                                      // width: isGridView ? "33%" : undefined,
                                      // paddingTop: isGridView ? "0px" : "20px",
                                      // paddingBottom: 10,
                                      // paddingRight: 25,
                                      // paddingLeft: 28,
                                      // // height: "190px",
                                      // position: "relative",
                                      marginBottom:
                                        arr.length - 1 !== index &&
                                        !isGridView &&
                                        window.innerHeight >= 1000
                                          ? "-80px"
                                          : "18px",
                                    }}
                                    onClick={() => showDetailLayout(recipe)}
                                  >
                                    <div className="body_warp">
                                      {isGridView && (
                                        <div
                                          style={{ marginBottom: 6 }}
                                          className={`${
                                            isGridView ? "" : "col_35_percent"
                                          } `}
                                        >
                                          <span className="glyphicon glyphicon-plus"></span>
                                          {/* with  lazyload */}
                                          <LazyLoadImage
                                            src={
                                              recipe?.thumbnail !== null
                                                ? recipe?.thumbnail
                                                : imagePlaceHolder
                                            }
                                            className={`${
                                              isGridView
                                                ? "round_cor_grid_view"
                                                : "round_cor"
                                            }`}
                                            // className={`round_cor`}
                                            alt="Image Alt"
                                          />

                                          {/* with out lazyload */}
                                          {/* <div
                                            className={`${
                                              isGridView
                                                ? "round_cor_grid_view backkground-image-style product_img_heigth_width"
                                                : "round_cor"
                                            }`}
                                            style={{
                                              backgroundImage: `url(${
                                                recipe?.thumbnail !== null
                                                  ? recipe?.thumbnail
                                                  : imagePlaceHolder
                                              })`,
                                            }}
                                          />
                                          */}
                                        </div>
                                      )}
                                      <div className="">
                                        <a className="target_slide">
                                          <div
                                            className={`${
                                              !isGridView
                                                ? "col_65_percent"
                                                : ""
                                            }`}
                                            style={{
                                              marginBottom: 20,
                                              // minHeight: "50px",
                                            }}
                                          >
                                            <div className="title_acc_cell limit-two-lines">
                                              {/* {selectedLanguageCode ===
                                                      "ar"
                                                        ? recipe.arabic_name
                                                        : recipe.english_name} */}
                                              {
                                                recipe?.name[
                                                  selectedLanguageCode
                                                ]
                                              }
                                            </div>
                                            <div
                                              className="descriptionClass light_grey_text font_s  font_ar_s1 limit-two-lines"
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  recipe?.description[
                                                    selectedLanguageCode
                                                  ],
                                              }}
                                            ></div>
                                            {recipe.is_customized === "No" && (
                                              <div className="product_main_price">
                                                {/* {recipe.price} د.ع} */}
                                                {recipe.price}{" "}
                                                {selectedLanguageCode === "ar"
                                                  ? useData?.resto_meta
                                                      .BUSSINESS_CCY_LANG.ar
                                                  : useData?.resto_meta
                                                      .BUSSINESS_CCY_LANG.en}
                                                {/* {useData?.resto_meta
                                                              .BUSSINESS_CCY_LANG[selectedLanguageCode]} */}
                                              </div>
                                            )}
                                            {recipe.is_customized === "Yes" && (
                                              <div className="customize-text-color">
                                                {t("is_customizable")}
                                              </div>
                                            )}
                                          </div>
                                        </a>
                                      </div>
                                      {!isGridView && (
                                        <div className="col_35_percent">
                                          <span className="glyphicon glyphicon-plus"></span>
                                          {/* <LazyLoadImage
                                                    src={
                                                      recipe?.thumbnail !== null
                                                        ? recipe?.thumbnail
                                                        : imagePlaceHolder
                                                    }
                                                    className={"round_cor"}
                                                    alt="Image Alt"
                                                  /> */}
                                          <div
                                            className="round_cor backkground-image-style product_img_list_heigth_width"
                                            style={{
                                              backgroundImage: `url(${
                                                recipe?.thumbnail !== null
                                                  ? recipe?.thumbnail
                                                  : imagePlaceHolder
                                              })`,
                                            }}
                                          />
                                        </div>
                                      )}
                                      {/* {!isGridView && (
                                                <div className="container">
                                                  {arr.length - 1 !== index ? (
                                                    <hr />
                                                  ) : (
                                                    <div style={{}} />
                                                  )}
                                                </div>
                                              )} */}
                                    </div>
                                  </div>
                                  {arr.length - 1 !== index && !isGridView && (
                                    <div className="bottom-line" />
                                  )}
                                </>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </>
            );
          })}
          {/* Footer */}
          <Footer bussinessDetails={useData} />
          {/* <div className="container footer">
            <div className="font_s">Powered By</div>
            <div className="footer_logo">
              <a href="https://meemorder.io/">
                <img src={logo} className="round_cor" alt="" />
              </a>
            </div>
          </div> */}

          {/* Menu categories popup */}
          {showMenu && (
            <div
              id="pop_menu_items"
              className="pop_up_maps_main pop_main_menu_bg "
            >
              <div className="popup_delivery popup_from_bottom half_height popup_slide">
                <div className="content" id="">
                  <div className="container">
                    <div className="container head_burg_menu">
                      <div className="delivery_head custom_font">
                        {t("menu_categories")}
                      </div>
                      <div className="delivery_close">
                        <a
                          className="slide_remove"
                          onClick={() => hideMenuCategories(undefined)}
                          // href={`digital/menus/${params.resto}/outlet/${params.outlet}#myCarousel`}
                          // href={`#myCarousel`}
                        >
                          &times;
                        </a>
                      </div>
                    </div>
                    {categories.map((item, index) => {
                      return (
                        <span key={index} className="pop_b">
                          <a
                            className="link_btn pop_a slide_remove"
                            // href={`#list-item${index}`}
                            onClick={() => {
                              hideMenuCategories(`list-item${index}`);
                            }}
                          >
                            {item?.name[selectedLanguageCode]}{" "}
                          </a>
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Product detail popup */}
          {showDetailModal && (
            //@ts-ignore
            <ProductDetail
              onPressClose={() => {
                closeModal();
                setTimeout(() => {
                  setShowDetailModal(false);
                  unlockScroll();
                }, 400);
                // setShowDetailModal(false);
                // unlockScroll();
              }}
              outletName={finalPathName}
              userToken={parsedData?.accessToken}
              selectedRecipe={selectedRecipe}
              currentLanguage={selectedLanguageCode}
              restoDetails={useData}
            />
          )}
        </div>
      ) : (
        <div className={" loader-wrapper"}>
          <Loader
            width={50}
            height={50}
            colorProp={appSharedColors.primaryColor}
          />
          {/* <div style={{ marginTop: 10 }}></div> */}
        </div>
      )}
      <SideLogoName
        logoUrl={
          useData !== undefined && useData.site_logo !== undefined
            ? useData?.site_logo
            : logo
        }
        //@ts-ignore
        bussinessName={useData?.name[selectedLanguageCode]}
      />
    </div>
  );
};

export default MenuPage;
