import React from "react";
import { ReactComponent as DropdownIcon } from "../../../assets/img/down-arrow.svg";
const AccordionItem: React.FC = ({
  faq,
  onToggle,
  active,
  defautlLang,
}: any) => {
  const { question, answer } = faq;
  return (
    <div
      style={{ direction: defautlLang === "en" ? "ltr" : "rtl" }}
      className={`accordion_item ${active ? "active" : ""}`}
    >
      <div
        className="disp_flex_row justify-space-between"
        style={{ alignItems: "flex-start" }}
        onClick={onToggle}
      >
        <div className="button" style={{ width: "80%" }}>
          {question.split("<br />").join("\n")}
        </div>
        <span>
          <DropdownIcon
            className="glyphicon-menu-down"
            style={{
              color: "black",
              marginTop: 3,
              marginLeft: 10,
              fontSize: 10,
              float: defautlLang === "en" ? "right" : "left",
            }}
            height={12}
            width={14}
          />
        </span>
      </div>

      <div className={`answer_wrapper ${active ? "open" : ""}`}>
        <div
          className="answer"
          dangerouslySetInnerHTML={{
            __html: answer,
          }}
        >
          {/* {answer.split("<br />").join("\n")} */}
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
