import i18next, { use } from "i18next";
import { DateTime } from "luxon";
import ReactGA4 from "react-ga4";
import { IDiscounts } from "./Components/Popups/CartPages/utils";
import { UaEventOptions } from "react-ga4/types/ga4";
import axios from "axios";
export const BUSSINESSTYPEOBJ = {
  clothesStore: "ClothsStore",
};
export const api_key_1 = "AIzaSyBFh6fzq8G7dgWLfz8kccvTlmPCSI_uWXQ";
export const GOOGLE_MAPS_API_KEY = "GOOGLE_MAPS_API_KEY_";
// export const ENVIRONMENT = "DEV"; // comment in prodiction
export const ENVIRONMENT = "PROD"; // uncomment in prodiction
export const GOOGLE_MAPS_KEYS = new Map<string, string>([
  [
    GOOGLE_MAPS_API_KEY + "DEV",
    "AIzaSyBFh6fzq8G7dgWLfz8kccvTlmPCSI_uWXQ",
    // "AIzaSyBFh6fzq8G7dgWLfz8kccvTlmPCSI_uWXQ"
  ],
  [GOOGLE_MAPS_API_KEY + "PROD", "AIzaSyAODq7uToLl3uj02dzoHA9YSVSsAKW61to"],
]);
export const imagePlaceHolder = require("../../assets/img/imagePlaceholder.png");
export const libraries = ["places", "geometry", "visualization"];
export interface StoreData {
  address: string;
  arabic_name: string;
  backgroud_image: string;
  city: string;
  closing_timing: number;
  comments: number;
  country: number;
  country_code: number;
  country_short_name: string;
  cover_image: string;
  default_color: string;
  is_language_option_available: string;
  language_options: string[];
  default_lang: string;
  delivery_time_range: number;
  loyalties: Loyalties;
  delivery_zones: DeliveryZones;
  discounts: IDiscounts;
  delivery_discounts: any;
  pickup_discounts: any;
  email: string;
  english_name: string;
  gallery: any;
  id: number;
  is_pick_available: string;
  latitude: number;
  logo: string;
  longitude: number;
  main_image: string;
  min_basket_price: number;
  name: string;
  opening_timing: number;
  outlet_countries: string[];
  outlets: OutLets;
  payment_method: string;
  phone: number;
  place: string;
  rating: number;
  resto_meta: StoreMeta;
  resto_unique_name: string;
  reviews: number;
  shared_unique_id: string;
  short_description: string;
  site_logo: string;
  special_offers: number;
  time_zone: string;
  whatsapp_number: number;
  pre_order_whatsapp_numbers: number[];
  whole_order_discounts: any;
}
export interface Loyalties {
  id: string;
  cash_back: CashBack;
  redeemable_percent: RedeemablePercent;
  maximum_redeemable_amt: number;
}
export interface CashBack {
  normal_amount: number;
  with_percentage: string;
}

export interface RedeemablePercent {
  normal_amount: number;
  with_percentage: string;
}
export interface IORDERRECEIPT {
  business_info: BusinessInfo;
  order_info: OrderInfo;
  customer: Customer;
  basket: Basket;
}

export interface BusinessInfo {
  business_name: string;
  business_image: string;
  outlet_name: string;
}

export interface OrderInfo {
  id: number;
  order_ref: string;
  order_url: string;
  order_type: string;
  status: string;
  bg: string;
  order_instructions: any;
  delivery_at: string;
  channel: string;
  order_placed: string;
  payment: string;
  next_action: string;
  next_status: string;
  next_status_label: string;
  delivery_update_status_url: string;
  estimated_time: EstimatedTime;
}

export interface EstimatedTime {
  preparation_time: string;
  preparation_delivery_time: string;
  time_type: string;
}

export interface Customer {
  customer_id: number;
  customer_name: string;
  customer_phone: string;
  deliveryAddress: string;
  google_formatted_address: string;
  geo_location: string;
  delivery_instructions: string;
}

export interface Basket {
  items: Item[];
  delivery_fee: DeliveryFee;
  order_total_price: OrderTotalPrice;
  is_discount: boolean;
  is_delivery_discount: boolean;
  is_loyalty: boolean;
  loyalty_amount: LoyaltyAmountItem;
  discounted_amount: DiscountedAmount;
  delivery_discounted_amount: DeliveryDiscountedAmount;
  sub_total: SubTotal;
  grand_total: GrandTotal;
  discounted_grand_total: DiscountedGrandTotal;
}
export interface LoyaltyAmountItem {
  formatted_price: string;
  normal_price: number;
}

export interface Item {
  item_name: string;
  item_price: ItemPrice;
  item_arabic_name: string;
  item_image: string;
  quantity: number;
  sub_items: any;
}

export interface ItemPrice {
  normal_price: number;
  formatted_price: string;
}

export interface DeliveryFee {
  normal_price: number;
  formatted_price: string;
}

export interface OrderTotalPrice {
  normal_price: number;
  formatted_price: string;
}

export interface DiscountedAmount {
  normal_price: number;
  formatted_price: string;
}

export interface DeliveryDiscountedAmount {
  normal_price: number;
  formatted_price: string;
}

export interface SubTotal {
  normal_price: number;
  formatted_price: string;
}

export interface GrandTotal {
  normal_price: number;
  formatted_price: string;
}

export interface DiscountedGrandTotal {
  normal_price: number;
  formatted_price: string;
}

export interface StoreMeta {
  BILLING_GATEWAY: [{ id: number; value: string }];
  BUSSINESS_CCY_LANG: { en: string; ar: string };
  BUSSINESS_TYPE: string;
  BUSSINESS_CCY: string;
  DISPLAY_TAX_INFO: string;
  BUSSINESS_RECEIVER_FLAG: string;
  ALLOW_PRE_ORDERS: string;
  SHOW_CATEGORY_MENU: string;
  BUSSINESS_TIMESLOTS_FLAG: string;
  ENABLED_AS_SOON_AS_POSSIBLE: string;
  DEFAULT_DELIVERY_FEE: string;
  DISABLED_LOCATION_SERVICES?: string;
  PWA_DISABLED: "Yes" | "No";
  PRORUDCT_DETAILS_IMG_ZOOM: string;
  ENABLE_CALENDAR: "Yes" | "No";
  GOOGLE_CONVERSION_FOR_PURCHASE_ORDER: string;
  BUSINESS_COLORS: {
    DELIVERY_PICKUP_COLOR: string;
    GENERAL_COLOR_THEME: string;
    HIGHLIGHTED_BACKGROUND_COLOR: string;
  };
  GOOGLE_GTAG_FOR_ALL_PAGES_CODE: string;
  GOOGLE_VARIFICATOIN_CODE: string;
  PRODUCT_ORIENTATION: string;
  RECIPE_IMAGE_DISPLAY: string;
  TERM_AND_CONDITIONS: string;
}
export interface OutLets {
  [key: string]: OutLetsObj;
  // [numbers:string]:OutLetsObj;
}
export interface OutLetsObj {
  area: { [key: string]: Area };
  business_hours: any;
  closed_days: { [key: string]: string[] };
  delivery_fee?: number;
  delivery_time: string;
  discounts: [];
  estimated_time: string;
  outlet_name_translation: { en: string; ar: string };
  preparation_delivery_type: string;
  delivery_days: string;
  estimated_time_v1: {
    text_to_display: string;
    type_to_display: string;
  };
  out_let_phone_number: string;
  outlet_address: string;
  outlet_address_arabic: string;
  outlet_area: string;
  outlet_country: string;
  pauseorder_flag: {
    delivery: boolean;
    pickup: boolean;
  };
  outlet_country_short_name: string;
  outlet_delivery: number;
  outlet_delivery_payment_methods: string;
  outlet_dining_payment_methods: string;
  outlet_id: number;
  outlet_image: string;
  vat_tax: { value: number | null; type: string };
  outlet_meta: { BILLING_GATEWAY: [{ id: number; value: string }] };
  outlet_name: string;
  outlet_pickup: number;
  outlet_delivery_slot_length: number;
  outlet_pickup_payment_methods: string;
  pickup_time: string;
  time_zone: string;
  out_of_stock: string[];
  unavailable_products: string[];
  whole_order_discounts: number;
}
// export interface CustomMap {
//  testValue: Map<string, string>
// }
export interface IMap<T> {
  [index: string]: T;
  [index: number]: T;
}
export const saveOrderTypeUrlHandler = (
  parsedData: any,
  finalPathName: any,
  searchParam: any,
  orderType: string
) => {
  parsedData.orderType = orderType;
  parsedData.outletUrl = `/d${finalPathName}${searchParam}`;
  localStorage.setItem("shop" + finalPathName, JSON.stringify(parsedData));
};

// function that check variable is object or not

export const checkVarTypeObj = (variable: any) => {
  var isObject = false;
  if (typeof variable === "object") {
    isObject = true;
  }
  return isObject;
};
export interface SelectedOulet extends OutLetsObj {
  area_name: string;
  delivery_fee: number;
  min_basket_price: number;
  delivery_days: string;
  deliveryZone: DeliveryZoneObj;
  selectedOutLetName: string;
  preparation_delivery_type: string;
  selectedLatLon: any;
  selectedLtnLon: string;
  selectedLocationName: string;
}
interface Area {
  area_name: string;
  delivery_fee: number;
  min_basket_price: number;
}
export interface DeliveryZones {
  [key: string]: DeliveryZoneObj;
}
export interface DeliveryZoneObj {
  area_id: number;
  area_name: string;
  lat_lng: string;
  outlet_id: number;
  outlet_name: string;
}
export const validateDeliveryPickupPauseOrder = (
  orderType: any,
  selectedOutlet: any
) => {
  var isPauseOrder = false;
  if (selectedOutlet?.pauseorder_flag) {
    if (orderType === "delivery" && selectedOutlet?.pauseorder_flag.delivery) {
      isPauseOrder = true;
      return isPauseOrder;
    }
    if (orderType === "pickup" && selectedOutlet?.pauseorder_flag.pickup) {
      isPauseOrder = true;
      return isPauseOrder;
    }
  }
  return isPauseOrder;
};
export interface Categories {
  arabic_name: string;
  english_name: string;
  category_image: string;
  id: number;
  main_image: string;
  name: string;
  recipes?: Recipes[];
  resto_id: number;
  thumbnail: string;
}
export interface DiscountAvailable {
  available: boolean;
  discountType: string;
}
export interface Recipes {
  active: string;
  arabic_name: string;
  description: string;
  description_ar: string;
  english_name: string;
  exclude_outlets: string;
  extra_price: string;
  inventory_options: string;
  inventory_un_available_reason: string;
  id: number;
  img_popup: string;
  is_customized: string;
  is_available: string;
  name: string;
  price: number;
  rating: number;
  shared_unique_id: string;
  thumbnail: string;
}
export interface SingleItem {
  active: string;
  actual_price: string;
  arabic_name: string;
  description: { en: string; ar: string };
  description_ar: string;
  description_en: string;
  discount_amount: number;
  colors: string[];
  color_image: ColorImage[];
  product_faqs: ProductFaqsObj[];
  sizes: string[];
  discount_type: string;
  inventory_un_available_reason: { en: string; ar: string };
  is_available: "Yes" | "No";
  english_name: string;
  extra_options: { [key: string]: SingleItemExtras };
  gallery: [];
  id: number;
  is_customized: string;
  is_discount: string;
  main_image: string;
  name: {
    en: string;
    ar: string;
  };
  price: string;
  rating: number;
  shared_unique_id: string;
  show_recipe_main_price: number;
  thumbnail: string;
}
export interface ProductFaqsObj {
  answer: string;
  created_at: string;
  deleted_at: string;
  id: number;
  product_id: number;
  question: string;
  updated_at: string;
}
interface ColorImage {
  color: string;
  img_url: string;
}
export interface SingleItemExtras {
  id: number;
  is_mandatory: string;
  items: ItemExtrasDetail[];
  mandatory_amount: number;
  name: string;
  name_lang: {
    en: string;
    ar: string;
  };
  price: number;
}
interface ItemExtrasDetail {
  actual_price: number;
  discount_price: number;
  id: number;
  item_type: string;
  name_lang: {
    en: string;
    ar: string;
  };
  name: string;
  price: number;
  sub_items: string;
}
export const CLASS_NAME_PICKUP_TAB = "move-background right";
export const CLASS_NAME_PREORDER_TAB = "move-background pre_order";
export const CLASS_NAME_DELIVERY_TAB = "move-background";
export const CLASS_NAME_NO_PRE_ORDER = "move-background no_pre_order";
export type ObjectKeyLng = keyof { en: string; ar: string };
export function navHighlighter() {
  let tab_deault_id: string | null = "list-item0";
  var element = document.getElementById("list0accordion0");
  // var selectedItemLength = document.querySelectorAll("span.under_line_b.selected").length;
  const sections = document.querySelectorAll(
    "section[id]"
  ) as NodeListOf<HTMLElement> | null;
  let scrollY = window.pageYOffset; // Get current scroll position
  //const [isFirstCategory, setisFirstCategory] = useState(1);

  // console.log("Scroll: "+scrollY);

  // Now we loop through sections to get height, top and ID values for each
  var i = 1;
  sections?.forEach((current) => {
    const sectionHeight = current.offsetHeight;
    const menu_height = document.getElementById("Accordion_tabs")?.clientHeight; // get the height of menu scroll cause it changes in mobile/tab views
    const sectionTop = current.offsetTop - menu_height!; //dynamically set the section height(offset section height by sticky menu).
    const sectionId = current.getAttribute("id");
    /*
                - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
                - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
                */
    if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
      //  console.log("should remove all");
      //  document.querySelector(".navigation a") ?.classList.remove("active");
      //  document .querySelector(".navigation a") ?.closest("span") ?.classList.remove("selected");

      document
        .querySelector(".navigation a[href*=" + sectionId + "]")
        ?.classList.add("active");
      document
        .querySelector(".navigation a[href*=" + sectionId + "]")
        ?.closest("span")
        ?.classList.add("selected");

      const scrollArea_width = document.getElementById("mainNav")?.offsetWidth;
      const selected_menu_Tab_width =
        // @ts-ignore
        document.querySelector(".selected")?.offsetWidth;
      const for_righr_scroll = scrollArea_width! - selected_menu_Tab_width;
      const dir_val = document.body.getAttribute("dir");
      const burger_menu_width =
        document.getElementById("burger_custom")?.offsetWidth;
      const selected_menu_Tab = document.querySelector(
        ".selected"
      ) as HTMLElement | null;

      if (dir_val === "rtl") {
        document.getElementById("mainNav")!.scrollLeft =
          selected_menu_Tab!.offsetLeft - for_righr_scroll + burger_menu_width!;
      } else {
        const menu_width =
          document.getElementById("Accordion_tabs")?.offsetWidth; // get the width of menu scroll
        document.getElementById("mainNav")!.scrollLeft =
          selected_menu_Tab!.offsetLeft -
          burger_menu_width! +
          scrollArea_width! -
          menu_width!;
      }
    } else {
      //console.log("scrollY > sectionTop: "+(scrollY > sectionTop));
      //console.log("Class Name: "+document.querySelectorAll('.under_line_b.selected').length);
      //  console.log(sectionId)
      // if(sectionId !== tab_deault_id){
      document
        .querySelector(".navigation a[href*=" + sectionId + "]")
        ?.classList.remove("active");
      document
        .querySelector(".navigation a[href*=" + sectionId + "]")
        ?.closest("span")
        ?.classList.remove("selected");

      // }
    }
    // var selectedItemId = document.querySelectorAll("span.under_line_b.selected");
    // console.log(dates.length);
    // console.log(scrollY > sectionTop && scrollY <= sectionTop + sectionHeight);
    // if (
    //   selectedItemId.length === 2
    //   // document.querySelectorAll(".under_line_b.selected").length > 1 &&
    //   // document
    //   //   .querySelector(".navigation a[href*=list-item0]")
    //   //   ?.classList.contains("active")
    // ) {
    // document
    //   .querySelector(".navigation a[href*=list-item0]")
    //   ?.classList.remove("active");
    // document
    //   .querySelector(".navigation a[href*=list-item0]")
    //   ?.closest("span")
    //   ?.classList.remove("selected");
    // } else {
    // document
    //   .querySelector(".navigation a[href*=list-item0]")
    //   ?.classList.add("active");
    // document
    //   .querySelector(".navigation a[href*=list-item0]")
    //   ?.closest("span")
    //   ?.classList.add("selected");
    // }
  });
}
export const languages = [
  {
    id: 1,
    code: "en",
    name: "EN",
    country_code: "gb",
  },
  {
    id: 2,
    code: "ar",
    name: "AR",
    country_code: "sa",
    dir: "rtl",
  },
];
export function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export const getCustomerInfoFromStroge = () => {
  var localValue = localStorage.getItem("customerInfo");
  if (localValue !== null) {
    return JSON.parse(localValue);
  } else {
    return null;
  }
};
export function isValidURL(stringUrl: string) {
  // if(stringUrl !== undefined){
  var res = stringUrl.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
  // }
  // return false;
}

export const updateManifest = async (
  appName: string,
  iconPath: string,
  start_url: string,
  main_image: string
) => {
  const manifestUrl = "/manifest-3.json";
  var manifestData = {};

  fetch(manifestUrl)
    .then((response) => response.json())
    .then((manifestDataRes) => {
      console.log(manifestDataRes);
      manifestData = manifestDataRes;
    })
    .catch((error) => {
      console.error(error);
    });

  const updatedManifestData = {
    ...manifestData,
    name: appName,
    icons: [
      {
        src: iconPath,
        sizes: "64x64",
        type: "image/x-icon",
      },
      {
        src: iconPath,
        type: "image/png",
        sizes: "192x192",
      },
      {
        src: main_image,
        type: "image/png",
        sizes: "512x512",
      },
    ],
    start_url: start_url,
  };
  console.log(updatedManifestData);
  const updatedManifestUrl = URL.createObjectURL(
    new Blob([JSON.stringify(updatedManifestData)], {
      type: "application/json",
    })
  );

  document
    ?.querySelector('link[rel="manifest"]')
    ?.setAttribute("href", updatedManifestUrl);

  // document?.querySelector('#manifest-placeholder')?.setAttribute('href', start_url);
  /**  for  getting the values from the files uncommnet it if needed 
  const manifestUrl = `${window.location.origin}/manifest.json`;
  const response = await fetch(manifestUrl);
  const manifest = await response.json();
  manifest.name = appName;
  manifest.short_name = appName;
  manifest.start_url = start_url;
  manifest.icons[0].src = iconPath;
  console.log(manifest);




  // let manifest = { 
  //   name: "App name", 
  //   icons: [{ src: iconUrl, sizes: "512x512", type:"image/png" }] 
  // }; 

  /*
  let content = encodeURIComponent(JSON.stringify(manifest)); 
  let url = "data:application/manifest+json,"+content; 
  let element = document.createElement('link'); 
  element.setAttribute('rel', 'manifest'); 
  element.setAttribute('href', url); 
  document?.querySelector('head')?.appendChild(element);
  */

  /*     working code 
  https://meemapi.meemorder.io/apis/epay/topup/meemmf.json?b=nb-flowers&c=red
  const stringManifest = JSON.stringify(manifest);
  const blob = new Blob([stringManifest], {type: 'application/json'});
  // let content = encodeURIComponent(JSON.stringify(manifest)); 
  // let url = "data:application/manifest+json,"+content; 
  const manifestURL = URL.createObjectURL(blob);
//  
  document?.querySelector('#manifest-placeholder')?.setAttribute('href', manifestURL);
   */

  // if we want to manage it client side

  // console.log(document!.querySelector('#my-manifest-placeholder')!.setAttribute('href', '/manifes.json'))
  // const updatedManifest = JSON.stringify(manifest, null, 2);
  // const blob = new Blob([updatedManifest], { type: 'application/json' });
  // const manifestFile = new File([blob], 'manifest.json', { type: 'application/json' });
  // const formData = new FormData();
  // formData.append('file', manifestFile);
  // await fetch('/manifest.json', {
  //   headers:{token:``},
  //   method: 'POST',
  //   body: formData,
  // });
  //
};
export const setShopDataToStorage = (bussinessName: string, data: any) => {
  localStorage.setItem("shop/" + bussinessName, JSON.stringify(data));
};
export const getShopDataFromStrg = (finalPathName: string) => {
  var values;
  values = localStorage.getItem("shop" + finalPathName);
  if (values !== null) {
    var parsedValues = JSON.parse(values);
    return parsedValues;
  } else {
    return null;
  }
};
export const openCallHandler = (number: string | number | undefined) => {
  document.location.href = `tel:${number}`;
};
export const setThemeColor = (shopInfo: any) => {
  document.documentElement.style.setProperty(
    "--background-color-store",
    shopInfo?.resto_meta?.BUSINESS_COLORS?.GENERAL_COLOR_THEME
  );
  document.documentElement.style.setProperty(
    "--DELIVERY_PICKUP_COLOR",
    shopInfo?.resto_meta?.BUSINESS_COLORS?.DELIVERY_PICKUP_COLOR
  );
  document.documentElement.style.setProperty(
    "--HIGHLIGHTED_BACKGROUND_COLOR",
    shopInfo?.resto_meta?.BUSINESS_COLORS?.HIGHLIGHTED_BACKGROUND_COLOR
  );
  // DELIVERY_PICKUP_COLOR
};
export const setThemeColorTrackOrder = (trackOrderVal: any) => {
  document.documentElement.style.setProperty(
    "--background-color-store",
    trackOrderVal?.resto?.busniess_colors.GENERAL_COLOR_THEME
  );
  document.documentElement.style.setProperty(
    "--DELIVERY_PICKUP_COLOR",
    trackOrderVal?.resto?.busniess_colors.DELIVERY_PICKUP_COLOR
  );
  document.documentElement.style.setProperty(
    "--HIGHLIGHTED_BACKGROUND_COLOR",
    trackOrderVal?.resto?.busniess_colors.HIGHLIGHTED_BACKGROUND_COLOR
  );
  // DELIVERY_PICKUP_COLOR
};
export const hideMinOrder = (
  selectedOutlet: any,
  useData: any,
  order_type: any
) => {
  if (order_type === "p") return false;
  if (useData?.resto_meta?.DISABLED_LOCATION_SERVICES === "Yes") {
    return true;
  }
  if (selectedOutlet && Object.keys(selectedOutlet).length === 0) {
    return true;
  }

  return false;
};
export const saveLangByBussiness = (lang: string, bussinessName: string) => {
  let parsedData = getShopDataFromStrg("/" + bussinessName);
  if (parsedData !== null) {
    parsedData.userLang = lang;
    localStorage.setItem("shop/" + bussinessName, JSON.stringify(parsedData));
  }
};
export const saveDataToLocalStorage = (
  dateNumber: any,
  formatedDayName: any,
  localData: any,
  shopName: string,
  isCalender: boolean | undefined
) => {
  let parsedData: any = {};
  if (localData !== null) {
    parsedData = localData;
    parsedData.dateNumber = dateNumber;
    parsedData.selectedDate = "";
    parsedData.formatedDayName = formatedDayName;
    parsedData.selectedTime = "ASAP";
    // parsedData.isCalender = false;
    if (isCalender) {
      parsedData.isCalender = true;
    } else {
      parsedData.isCalender = false;
    }
    localStorage.setItem("shop/" + shopName, JSON.stringify(parsedData));
  }
};
export const getSelectedLanguage = (bussinessName: string) => {
  var localData = getShopDataFromStrg("/" + bussinessName);
  if (localData !== null) {
    if (localData.savedLanguage) {
      return localData.savedLanguage;
    } else {
      return "";
    }
  } else {
    return "";
  }
};
export const checkDiscountOnSelectedItem = (discount: any) => {
  if (discount === null) {
    return;
  }
  var applyDiscountOnItem: "all_items" | "selected_items" = "all_items";
  if (discount.items === "selected_items") {
    return (applyDiscountOnItem = "selected_items");
  }
  if (discount.items === "all_items") {
    return (applyDiscountOnItem = "all_items");
  }
  return applyDiscountOnItem;
};
export const functionChangeLanguge = (
  lngCode: string,
  bussinessName: string
) => {
  i18next.changeLanguage(lngCode, (error: any) => {
    // //console.log(error);
  });
  var localData = getShopDataFromStrg("/" + bussinessName);
  if (localData !== null) {
    localData.savedLanguage = lngCode;
    localStorage.setItem("shop/" + bussinessName, JSON.stringify(localData));
  }

  document
    .getElementsByTagName("html")[0]
    .setAttribute("dir", lngCode === "ar" ? "rtl" : "ltr");
  document
    .getElementsByTagName("body")[0]
    .setAttribute("dir", lngCode === "ar" ? "rtl" : "ltr");
};
export const hideNotificationBar = (
  selectedOutlet: any,
  useData: any,
  order_type: any
) => {
  if (Object.keys(selectedOutlet).length === 0) {
    return true;
  }
  if (
    useData?.resto_meta?.DISABLED_LOCATION_SERVICES === "Yes" &&
    order_type === "d" &&
    Object.keys(selectedOutlet).length === 0
  ) {
    return true;
  }
  // if(Object.keys(selectedOutlet).length === 0 ){
  //   return false;
  // }

  return false;
};
export const checkLanguageOption = (
  is_language_option_available: string | undefined
) => {
  if (is_language_option_available === "Yes") {
    return true;
  }
  if (is_language_option_available === "No") {
    return false;
  }
  return false;
};
// export const newMainfestFile = (appName) =>{

// }
export const DAYS_NAME_NUMBER = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
};
export function numberWithCommas(x: number | string) {
  let num = Number(x);
  let usFormat = num.toLocaleString("en-US").replace(".00", "");
  return usFormat;
}
export const checkCacheExpiry = (expiryDate: any, bussinessName: string) => {
  var isExpired = false;
  const currentDate = DateTime.now();
  // Property 'fromISOString' does not exist on type 'typeof DateTime'. Did you mean 'fromString'?ts(2551)
  //@ts-ignore
  const givenDate = DateTime.fromISO(expiryDate);
  // parsedData.expiredAt
  // const isGivenDateGreaterThanCurrentDate ;
  if (expiryDate && givenDate > currentDate) {
    console.log("given date is greater");
    isExpired = false;
  } else {
    console.log("given time is less");
    localStorage.removeItem("shop/" + bussinessName);
    isExpired = true;
  }
  return isExpired;
};
export const closeModal = () => {
  // popup_slide_remove
  // popup_slide
  const element = document.querySelector(".popup_slide");
  const slide_bg_class = document.querySelector(".pop_main_menu_bg");
  element?.classList.remove("popup_slide");
  element?.classList.add("popup_slide_remove");
  // background: rgba(0, 0, 0, 0.4);
  slide_bg_class?.classList?.add("pop_main_menu_bg_remove");
  // slide_bg_class?.setAttribute('backdrop-filter','blur(0px)');
  // slide_bg_class?.setAttribute('background','rgba(0, 0, 0, 0)');
};

export const TXT_DELIVERY = "delivery";
export const TXT_PICKUP = "pickup";
export const TXT_PREORDER = "preOrder";
export type OBJECT_KEY_DAYS = keyof typeof DAYS_NAME_NUMBER;

// response for when user is not created;
/**
 {
    "type": "success",
    "data": {
        "message": "Mujtaba is saved successfully",
        "customer_id": 361,
        "new_user": false
    },
    "message": ""
}
 */
// for validating the phone number
export const isValidNumber = (
  phoneNumber: string,
  countryCode: string,
  phoneNumberLenght: number
) => {
  /** */
  var isValid = true;
  var validatePhone = phoneNumber.replace(
    new RegExp(countryCode + "0", "i"),
    countryCode
  );
  var userPhoneLength = validatePhone.length - countryCode.length;

  if (validatePhone.length === 0 || userPhoneLength !== phoneNumberLenght) {
    // setShowError(true);
    isValid = false;
    return isValid;
  }
};

//functions for loyalty

export const setCustomerLoyaltyPoints = (
  points: number,
  bussinessName: string
) => {
  var getLocalData = getShopDataFromStrg("/" + bussinessName);
  if (getLocalData !== null) {
    getLocalData.loyaltiesPoints = parseFloat(points.toFixed(2));
    localStorage.setItem("shop/" + bussinessName, JSON.stringify(getLocalData));
  }
};
export const getCustomerLoyaltyPoints = (bussinessName: string) => {
  var getLocalData = getShopDataFromStrg("/" + bussinessName);
  var points = 0;
  if (getLocalData !== null && getLocalData.loyaltiesPoints) {
    points = getLocalData.loyaltiesPoints;
  }
  return points;
};
///// end functions for loyalty
/** Functions for gtag */
export const HttpCodes = {
  success: 200,
  notFound: 404,
  // etc
};
export const TrackGoogleAnalyticsEvent = (
  category: any,
  event_name: string | UaEventOptions,
  label: any,
  data: any
) => {
  console.log("GA event:", category, ":", event_name, ":", label);

  let event_params = {
    // category,
    // label,
    // event_modal:data,
    ...data,
  };
  // Send GA4 Event
  ReactGA4.event(event_name, event_params);
};
export const GA4EventsNames = {
  apiSuccess: "Dukany-API-Success",
  apiFailed: "Dukany-API-Fail",
};
export const ReqSuccessMessage = "success";
export const ReqFaliureMessage = "fail";
export const getReqJsonModalGA4 = (
  bussinessName: string,
  status: string,
  reason: string,
  apiurl: string,
  reqData?: any
) => {
  var jsonBody = {
    resto_name: bussinessName,
    status: status,
    reason: reason,
    apiurl: apiurl,
    reqData: reqData,
  };
  return jsonBody;
};
export const getReqJsonModalGA4WithoutReqData = (
  bussinessName: string,
  status: string,
  reason: string,
  apiurl: string,
  reqData?: any
) => {
  var jsonBody = {
    resto_name: bussinessName,
    status: status,
    reason: reason,
    apiurl: apiurl,
    ...reqData,
  };
  return jsonBody;
};
/**Functions for gtag end  */

export const getProxyData = async () => {
  //@ts-ignore
  if (window.userWifiIp !== "{userWifiIp}") {
    const resMeemApi = await axios.get(
      //@ts-ignore
      `https://orderapi.dukany.io/api/rjs/proxyIpCall?ip=${window.userWifiIp}`
    );
    return resMeemApi.data;
  }
};

export const saveUserBirthdayDiscount = (
  isBirthDayDiscount: string | null,
  mobile: string | null,
  coupen: string | null,
  dicount: string | null
) => {
  // https://order.dukany.io/d/test-resto?isdiscount=yes&mobile=923459635387&coupen=YH943A&dicount=39
  var birthdayDiscount = {
    isdiscount: isBirthDayDiscount,
    mobile: mobile,
    coupen: coupen,
    dicount: dicount,
  };
  localStorage.setItem("birthdayDiscount", JSON.stringify(birthdayDiscount));
};
export const removeUserBirthdayDiscount = () => {
  localStorage.removeItem("birthdayDiscount");
};
export const getUserBirthdayDiscount = () => {
  var birthdayDiscount = {
    isdiscount: "No",
    mobile: "",
    coupen: "",
    dicount: "",
  };
  var getData = localStorage.getItem("birthdayDiscount");
  if (getData !== null) {
    birthdayDiscount = JSON.parse(getData);
    return birthdayDiscount;
  }
};

export const getDiscountAlertMessage = (
  discountObj: any,
  selectedLanguageCode: any
) => {
  var message = "";
  if (discountObj) {
    console.log(discountObj);
    // discount_name
    if (discountObj?.discount_names) {
      message = discountObj?.discount_names[selectedLanguageCode];
      console.log(message);
      return message;
    }
  }
  // discount_name
  console.log(typeof discountObj?.discount_name[selectedLanguageCode]);
  console.log(discountObj?.discount_name[selectedLanguageCode]);
  message = discountObj?.discount_name[selectedLanguageCode];
  return message;
};
