/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import useItemDetail from "../../../hooks/useItemDetails";
import {
  decrement,
  setDataFromApi,
} from "../../../../setup/redux/action-creators/action-creator";
import { imagePlaceHolder } from "../../Home/utils";
import Loader from "../../../modules/Loader";
import { appSharedColors } from "../../../shared/styles/reactStyles";
interface ProductDetailProps {
  onPressClose: () => void;
  selectedRecipe: any;
  outletName: string;
  currentLanguage: string;
  restoDetails: any;
  userToken: any;
}
const priceStyle = {};
const MenuProductDetail: React.FC<ProductDetailProps> = (
  props: ProductDetailProps
) => {
  const {
    onPressClose,
    selectedRecipe,
    currentLanguage,
    outletName,
    restoDetails,
    userToken,
  } = props;
  const [value, setValue] = useState("1");
  const [customPrize, setCustomPrize] = useState(0);
  const [selectedItemPrice, setSelectedItemPrice] = useState<number>(0);
  const [selectedItemName, setSelectedName] = useState<string>("");
  const [showButton, setShowButton] = useState<boolean>(true);
  const { getItemDetailByid, itemDetail, loading } = useItemDetail();
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const onChangeInputPrice = (
    itemPrice: any,
    itemId: number,
    itemName: string
  ) => {
    setSelectedItemPrice(itemPrice);
    setSelectedItemId(itemId);
    setSelectedName(itemName);
    setCustomPrize(itemPrice * parseInt(value));
  };
  const addToBasket = async () => {
    let getLocalData = localStorage.getItem("shop" + outletName);
    let parseLocalData;
    let shopValues;
    if (getLocalData !== null) {
      parseLocalData = JSON.parse(getLocalData);
    }
    // console.log(parseLocalData);
    let cart = {
      id: selectedRecipe.shared_unique_id,
      price: customPrize,
      quantity: value,
      name: {
        ar_name: selectedRecipe.arabic_name,
        en_name: selectedRecipe.english_name,
      },
      single_item_name: selectedItemName,
      single_item_price: selectedItemPrice,
      single_options_id: selectedItemId,
    };
  };

  const testData = () => {
    // dispatch(decrement());
    dispatch(setDataFromApi());
  };
  useEffect(() => {
    console.log(selectedRecipe);
    getItemDetailByid(selectedRecipe.shared_unique_id, userToken, outletName);
  }, []);
  // useEffect(() => {
  //   $(document).ready(function () {
  //     $(".prod_count_min").on("click", function () {
  //       var $input = $(this).parent().find(".prod_counter");
  //       var count = parseInt($input.val()) - 1;
  //       count = count < 1 ? 1 : count;
  //       $input.val(count);
  //       $input.change();
  //       return false;
  //     });
  //     $(".prod_count_max").on("click", function () {
  //       var $input = $(this).parent().find(".prod_counter");
  //       $input.val(parseInt($input.val()) + 1);
  //       $input.change();
  //       return false;
  //     });
  //   });
  // }, []);

  useEffect(() => {
    console.log(selectedRecipe);
  }, []);
  return (
    <div id="pop_prod_detail" className="pop_up_maps_main pop_main_menu_bg">
      {showButton && (
        <a
          onClick={() => {
            onPressClose();
            setShowButton(false);
          }}
          className="close_popup slide_remove  glyphicon glyphicon-remove"
          // href="#Accordion_tabs"
        ></a>
      )}
      {!loading ? (
        <div className="popup  popup_slide">
          <div className="content" id="">
            <div
              className="backkground-image-style product_main_img_heigth "
              style={{
                backgroundImage: `url(${
                  itemDetail?.main_image !== null
                    ? itemDetail?.main_image
                    : imagePlaceHolder
                })`,
              }}
            />
            {/* <img src={selectedRecipe.img_popup} className="" alt="" /> */}
            <div className="body_warp">
              <div className="container">
                <div>
                  <h3 className="bussiness-name">
                    {selectedRecipe?.name[currentLanguage]}
                  </h3>
                  {selectedRecipe.is_customized === "No" && (
                    <div style={priceStyle} className="product_main_price">
                      {/* {recipe.price} د.ع} */}
                      {selectedRecipe.price}{" "}
                      {restoDetails.default_lang === "ar"
                        ? restoDetails?.resto_meta.BUSSINESS_CCY_LANG.ar
                        : restoDetails?.resto_meta.BUSSINESS_CCY_LANG.en}
                    </div>
                  )}

                  {/* <a className="read_more_t" href="#">
                  Read More
                </a> */}
                  <p className="light_grey_text">
                    {selectedRecipe.description[currentLanguage]}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="popup popup_from_bottom popup_slide loader-wrapper">
          <Loader
            height={40}
            width={40}
            colorProp={appSharedColors.primaryColor}
          />
        </div>
      )}
    </div>
  );
};

export default MenuProductDetail;
