import React, { useState } from "react";
import VoucherSvg from "../../assets/img/svgs/VoucherSvg";
import Loader from "../../modules/Loader";
interface Props {
  isDiscount: string;
  onPressRemove: () => void;
  onPressSubmit: (code: string) => void;
  discountObj: any;
  couponErrorMsg: string;
  loading: boolean;
}
const AddVoucher: React.FC<Props> = (props: Props) => {
  const {
    isDiscount,
    onPressRemove,
    onPressSubmit,
    discountObj,
    couponErrorMsg,
    loading,
  } = props;
  const [couponCode, setCouponCode] = useState<string>("");
  return (
    <div>
      <div style={styles.txtSaveOrder}>
        <text className="title_acc_cell">Add discount code</text>
      </div>
      <div style={{ ...styles.voucherWrapper }}>
        <div style={styles.displayFlex}>
          <VoucherSvg />

          {isDiscount === "No" ? (
            <input
              onChange={(e) => setCouponCode(e.target.value)}
              placeholder="Enter Voucher code"
              style={styles.inputStyles}
            />
          ) : (
            <span
              // style={{ marginLeft: 5, color: "var(--background-color-store)" }}
              style={styles.codeTxt}
            >
              {discountObj.discount_code}
            </span>
          )}
        </div>
        <div>
          {isDiscount === "No" ? (
            <>
              {!loading ? (
                <text
                  onClick={() => {
                    if (couponCode !== "") onPressSubmit(couponCode);
                  }}
                  style={styles.btnSubmit}
                >
                  {" "}
                  Submit
                </text>
              ) : (
                <Loader height={10} width={20} colorProp="black" />
              )}
            </>
          ) : (
            <text style={styles.btnRemove} onClick={onPressRemove}>
              Remove
            </text>
          )}
        </div>
      </div>

      {couponErrorMsg !== "" && (
        <span className="show-error-message">{couponErrorMsg}</span>
      )}
    </div>
  );
};
const styles = {
  codeTxt: {
    marginLeft: 5,
    color: "var(--background-color-store)",
  } as React.CSSProperties,
  btnSubmit: {
    cursor: "pointer",
  } as React.CSSProperties,
  btnRemove: {
    fontSize: "0.85rem",
    fontWeight: "600",
    cursor: "pointer",
    textTransform: "uppercase",
    lineHeight: "1.5",
    color: "var(--background-color-store)",
  } as React.CSSProperties,
  txtSaveOrder: {
    fontSize: 16,
    fontWeight: "600",
    paddingTop: 7,
    paddingBottom: 7,
  } as React.CSSProperties,
  displayFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  } as React.CSSProperties,
  voucherWrapper: {
    padding: "15px 15px ",
    borderRadius: "8px",
    display: "flex",
    border: "1px dashed var(--background-color-store)",
    justifyContent: "space-between",
    width: "100%",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "flex-start",
  } as React.CSSProperties,
  inputStyles: {
    fontSize: 14,
    marginTop: -2,
    paddingRight: 5,
    paddingLeft: 5,
    border: 0,
  } as React.CSSProperties,
};
export default AddVoucher;
