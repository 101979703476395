import React from "react";
import { SelectedOulet, StoreData, functionChangeLanguge } from "../../utils";
import AEIconSvg from "../../../../assets/img/svgs/AEIconSvg";
import ENIconSvg from "../../../../assets/img/svgs/ENIconSvg";
import { homeStyles } from "../../styles";
import BlockBussinessName from "./BlockBussinessName";
import { use } from "i18next";
interface Props {
  useData: StoreData | undefined;
  selectedLanguageCode: any;
  selectedOutlet: SelectedOulet;
  bussinessName: string;
  onPressLanguage: (item: any) => void;
  hideBussinessName?: boolean;
  showPickup?: boolean;
}

const BlockBussinessImage: React.FC<Props> = (props: Props) => {
  const {
    useData,
    selectedLanguageCode,
    selectedOutlet,
    bussinessName,
    hideBussinessName,
    onPressLanguage,
    showPickup,
  } = props;
  return (
    <>
      <div id="myCarousel" className="carousel slide " data-ride="carousel">
        {/* code for language switcher enable it when we implement the swict */}
        {useData?.is_language_option_available === "Yes" && (
          <div className="language_switch">
            {useData?.language_options?.length > 0 &&
              useData?.language_options?.map((item, index) => (
                <div
                  className={`language_switch_circle  ${
                    selectedLanguageCode !== item ? "select_lang" : ""
                  }`}
                  onClick={() => {
                    functionChangeLanguge(item, bussinessName);
                    onPressLanguage(item);
                  }}
                >
                  {item !== selectedLanguageCode && (
                    <span>{item === "ar" && <AEIconSvg />}</span>
                  )}
                  {item !== selectedLanguageCode && (
                    <span>{item === "en" && <ENIconSvg />}</span>
                  )}
                  {/* {item?.toUpperCase()} */}
                  {/* {"en".toUpperCase()} */}
                </div>
              ))}
          </div>
        )}

        {/* code for language switcher enable it when we implement the swict */}
        {/* Resto Image */}
        <div className="carousel-inner " role="listbox">
          <div
            // className="item active carousel_background_image"
            className="backkground-image-style caurosel_heigth_width"
            style={{
              backgroundImage: `url(${useData?.cover_image})`,
            }}
          ></div>
        </div>
      </div>

      {!hideBussinessName && (
        <BlockBussinessName
          useData={useData}
          selectedLanguageCode={selectedLanguageCode}
          selectedOutlet={selectedOutlet}
          showPickup={showPickup}
        />
      )}
    </>
  );
};

export default BlockBussinessImage;
