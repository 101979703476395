import React from "react";
interface IconPlusProps {
  height: string | number;
  width: string | number;
  color: string;
}
const IconPlus: React.FC<IconPlusProps> = (props: IconPlusProps) => {
  const { height, width, color } = props;
  return (
    <svg
      data-v-54a96833=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      fill={"#000"}
      data-spec="icon-plus"
      className="w-5 h-5"
      style={{ width: width, height: height }}
      // style="width: 16px; height: 16px;"
    >
      <path
        fill={color}
        d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"
      ></path>
    </svg>
  );
};

export default IconPlus;
