import React from "react";
import IconMinus from "./IconMinus";
import IconPlus from "./IconPlus";
interface DisplayBasketItem {
  t: any;
  itemProp: any;
  selectedLanguageCode: string;
  index: number;
  onUpdateBasket: (action: string, itemProp: any, index: number) => void;
  shopInfo: any;
}
const DisplayBasketItem: React.FC<DisplayBasketItem> = (
  props: DisplayBasketItem
) => {
  const { t, itemProp, selectedLanguageCode, index, shopInfo, onUpdateBasket } =
    props;
  return (
    <>
      <div className="item_img_basket cart_content_row">
        <div
          className="col_100_percent  flo_left"
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginBottom: 10,
          }}
        >
          <span
            className={`color choose-color basket_color_img`}
            style={{
              borderRadius: 5,
              marginLeft: 10,
              backgroundImage: `url(${itemProp?.color_size?.img_url})`,
            }}
          />
          <div style={{ flexDirection: "column" }}>
            <div className="title_acc_cell">
              {itemProp.name[selectedLanguageCode]}
            </div>
            <div
              className="light_grey_text font_s pad_bottom_20 font_ar_s1"
              dangerouslySetInnerHTML={{
                __html: itemProp.description[selectedLanguageCode],
              }}
            />
            <span className="prdSize">
              {t("size")}: {itemProp.color_size.size}
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="cart_content_col_30">
          <div
            className="align_opp"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span
              className="circle-icon-plus"
              style={{
                borderColor: shopInfo?.default_color,
              }}
              onClick={() => onUpdateBasket("sub", itemProp, index)}
            >
              <IconMinus
                height={14}
                width={14}
                color={shopInfo?.default_color}
              />
            </span>
            <input
              type="text"
              className="prod_counter"
              value={itemProp.quantity}
            />
            <span
              style={{
                borderColor: shopInfo?.default_color,
              }}
              className="circle-icon-plus"
              onClick={() => onUpdateBasket("add", itemProp, index)}
            >
              <IconPlus
                height={14}
                width={14}
                color={shopInfo?.default_color}
              />
            </span>
          </div>
          <div className="cart_product_list_price">
            {shopInfo?.resto_meta?.BUSSINESS_CCY_LANG[selectedLanguageCode]}{" "}
            {itemProp.price}
          </div>
        </div>
        {itemProp.color_size !== null && (
          <p>
            {/* <span className="text-muted prdSize">
              {t("size")}: {itemProp.color_size.size}
            </span> */}
          </p>
        )}
      </div>
    </>
  );
};

export default DisplayBasketItem;
