import React from "react";
import EmptyCartSvg1 from "../../assets/img/svgs/EmptyCartSvg1";
import EmptyCartSvg2 from "../../assets/img/svgs/EmptyCartSvg2";
import EmptyCartSvg3 from "../../assets/img/svgs/EmptyCartSvg3";
import SimpleButton from "./SimpleButton";
interface EmptyCartProps {
  onPress: () => void;
  t: any;
}
const EmptyCart: React.FC<EmptyCartProps> = (props: EmptyCartProps) => {
  const { onPress, t } = props;
  return (
    <div
      className="empty-cart-wrapper"
      style={{
        background: "#FFF7E5",
        display: "flex",
        alignItems: "center",
        height: "100vh",
        // overflow: "scroll",
        overflowX: "hidden",
        width: "100%",
        position: "fixed",
        // marginTop: 40,

        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ fontSize: 20, color: "#000000" }}>
        {t("cart_empty_txt_1")}
      </div>
      <div style={{ fontSize: 14, marginBottom: "7vh", marginTop: 3 }}>
        {t("cart_empty_txt_desc")}
      </div>
      <div style={{ marginTop: 100 }}>
        <EmptyCartSvg1 />
      </div>
      <div style={{ position: "absolute", marginTop: 100 }}>
        <span
          style={{
            position: "absolute",
            right: "-30px",
            top: "-44px",
          }}
        >
          <EmptyCartSvg3 />
        </span>
        <div style={{ marginBottom: 60 }}>
          <EmptyCartSvg2 />
        </div>
      </div>
      <div
        onClick={onPress}
        className="background-color-trash"
        style={{
          cursor: "pointer",
          marginTop: 43,
          borderRadius: 5,
          display: "flex",
          alignItems: "center",
          paddingTop: 15,
          paddingBottom: 15,
          justifyContent: "center",
          //   backgroundColor: "#FFA600",
          width: "90%",
        }}
      >
        <span style={{ color: "white", fontWeight: "bold" }} onClick={onPress}>
          {t("start_shopping")}
        </span>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default EmptyCart;
