import React from "react";

interface SlotsButtonProp {
  onPress: (
    dayName: string,
    newDateString: number,
    weekDayName: string
  ) => void;
  //   titleString: string;
  selectedDayName: string;
  isCustom: boolean;
  //   newDateString: number;
  slotsObject: {
    formatedDayName: string;
    newDate: number;
    weekDayName: string;
  };
}
const SlotsButton: React.FC<SlotsButtonProp> = (props: SlotsButtonProp) => {
  const { onPress, selectedDayName, slotsObject, isCustom } = props;
  return (
    <button
      onClick={() =>
        onPress(
          slotsObject.formatedDayName,
          slotsObject.newDate,
          slotsObject.weekDayName
        )
      }
      className={`select_date_option ${
        selectedDayName?.toLowerCase() ===
          slotsObject?.weekDayName?.toLowerCase() &&
        !isCustom &&
        "selcted_opt"
      }`}
    >
      {slotsObject.formatedDayName}
    </button>
  );
};

export default SlotsButton;
