import React, { useCallback, useEffect, useState } from "react";
// import SelecteDatePop from "./CreateNewCustomer";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useSendOtp from "../../../../../hooks/useSendOtp";
import WhatsappIcon from "../../../../../assets/img/svgs/WhatsappIcon";
import { useTranslation } from "react-i18next";
import useSelectedLanguge from "../../../../../hooks/useSelectedLanguge";
import VerificationInput from "react-verification-input";
import Loader from "../../../../../modules/Loader";
import SideLogoName from "../../../../../shared/components/SideLogoName";
import BackArrow from "../../../../../assets/img/svgs/BackArrow";
import { getShopDataFromStrg, setCustomerLoyaltyPoints } from "../../../utils";
import AlertNoLocation from "../../../../../shared/components/AlertNoLocation";
import useCustomerInfo from "../../../../../hooks/useCustomerInfo";
import SelectOtpMethod from "../../../../../shared/components/SelectOtpMethod/SelectOtpMethod";
import ChatIconSvg from "../../../../../assets/img/svgs/ChatIconSvg";
const PopPageValidateOtp: React.FC = () => {
  const {
    validOtpHandler,
    loading,
    customerData,
    sendOtpHandler,
    otpData,
    errorMessage,
  } = useSendOtp();
  const { bussinessName } = useParams();
  const navigate = useNavigate();
  const [timer, setTimer] = useState(60);
  const params = useLocation();
  const { selectedLanguageCode } = useSelectedLanguge();
  const { t } = useTranslation();
  var dataFromStrg = localStorage.getItem("otpDataTemp");
  const { getCustomerLoyaltyPoints } = useCustomerInfo();
  const valuesFromStorage = getShopDataFromStrg("/" + bussinessName!);
  const InputType = "tel";
  const shopInfo = valuesFromStorage !== null && valuesFromStorage?.shopInfo;
  const otpRes = dataFromStrg !== null && JSON.parse(dataFromStrg);
  const [otpMsidn, setOtpMsIdn] = useState<string>(params.state.phoneNumber);
  const [showAlert] = useState<boolean>(false);
  const [openOtpMethod, setOtpMethod] = useState<boolean>(false);
  const [otpMethodType, setOtpMethodType] = useState<"SMS" | "Whatsapp">(
    "Whatsapp"
  );
  const shopDataLocal = localStorage.getItem("shop/" + bussinessName);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );
  const onPressClose = () => {
    // var customer = getCustomerInfoFromStroge()
    navigate(`/${bussinessName}/send-otp`);
    // navigate(-1);
  };
  const resetTimer = async () => {
    setOtpMethod(true);
    // await sendOtpHandler(otpMsidn, params.state.countryCode);
  };
  // for dev purposes
  // const customerTemp = {
  //   Address: [],
  //   email: null,
  //   id: 16963,
  //   isNew: "0",
  //   mobile_number: "923105599915",
  //   name: "Muhammad Abdullah Rehan",
  // };

  const ShowErrorMessage = (errorMessage: string) => (
    <text>{errorMessage}</text>
  );
  const validateOtpFunction = (value4: string) => {
    let otpCode = value4;
    // uncommnet in production
    validOtpHandler(otpRes.data.req_id, otpMsidn, otpCode);
    // validOtpHandler(
    //   "3e587514-7f0e-458d-86d8-b887293d65f1",
    //   "923105599915",
    //   otpCode
    // );

    // for dev propus
    /** 
    localStorage.setItem("customerInfo", JSON.stringify(customerTemp));
    if (customerTemp.Address.length === 0 && customerTemp.isNew === "0") {
      // setShowAlert(true);
      navigate(`/${bussinessName}/place-order`, { replace: true });
    }
    */
  };
  const selectMethodHandler = async (type: "SMS" | "Whatsapp") => {
    setOtpMethodType(type);
    await sendOtpHandler(otpMsidn, params.state.countryCode, type);
    setOtpMethod(false);

    // alert(type);
  };
  const showSelectDateHandler = async () => {
    if (shopDataLocal !== null) {
      var customerInfo = {
        // ...tempData,
        ...customerData?.data,
      };
      // switch (tempData?.isNew)
      // if (1 == 1) {
      //   localStorage.setItem("customerInfo", JSON.stringify(customerInfo));
      //   navigate(`/${bussinessName}/create-customer`, { replace: true });
      //   return;
      // }
      switch (customerData?.data?.isNew) {
        case "0":
          // console.log(customerInfo);
          localStorage.removeItem("otpDataTemp");
          localStorage.setItem("customerInfo", JSON.stringify(customerInfo));
          // checkOrderCount(customerInfo, parsedData);
          if (shopInfo.loyalties !== null && customerInfo.id) {
            await getCustomerLoyaltyPoints(customerInfo.id.toString()).then(
              ({ success, res }: any) => {
                console.log(success, res);
                if (success && res) {
                  setCustomerLoyaltyPoints(
                    res.data.points_amount,
                    bussinessName!
                  );
                }
              }
            );
          }
          navigate(
            `/${bussinessName}/place-order`,
            { replace: true }
            //  { replace: true }
          );
          break;
        case "1":
          localStorage.setItem("customerInfo", JSON.stringify(customerInfo));
          navigate(`/${bussinessName}/create-customer`, { replace: true });
          break;

        default:
          //for dev
          /**
          localStorage.setItem("customerInfo", JSON.stringify(customerInfo));
          navigate(`/${bussinessName}/create-customer`, { replace: true });
          */
          break;
      }
    }
  };

  useEffect(() => {
    if (customerData !== undefined && customerData.type === "success") {
      showSelectDateHandler();
    }
  }, [customerData]);
  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);
  useEffect(() => {
    if (otpData) {
      if (!timer) {
        setTimer(60);
      }
    }
  }, [otpData]);
  // useEffect(()=>{

  // },[])
  useEffect(() => {
    const originalHeight = document.documentElement.clientHeight;
    const input = document.querySelector("input");
    //@ts-ignore
    input.addEventListener("focus", () => {
      document.documentElement.style.height = `${originalHeight}px`;
      document.body.style.height = `${originalHeight}px`;
    });
    // @ts-ignore
    input.addEventListener("blur", () => {
      document.documentElement.style.height = "auto";
      document.body.style.height = "auto";
    });
  }, []);

  return (
    <div lang={selectedLanguageCode}>
      <div id="pop_cart_page3" className="pop_up_maps_main pop_main_menu_bg">
        <div className="popup_cart_full popup_from_bottom  ">
          <div className="content" id="">
            <div className="cart_head_container">
              <div className="container">
                <div className="header_cart_page">
                  <div className="cart_heading">&nbsp;</div>
                  <span
                    onClick={onPressClose}
                    className="target_slide glyphicon"
                  >
                    <BackArrow />
                  </span>
                </div>
              </div>
            </div>
            {/* <!-- content start here --> */}
            <div className="cart_content">
              <div className="cart_content_to_marg">
                <div className="cart_content_row">
                  <div
                    style={{
                      color: "#000000",
                      fontSize: 22,
                      marginBottom: 9,
                    }}
                    className=""
                  >
                    {otpMethodType === "Whatsapp"
                      ? t("verify_number")
                      : t("check_your_inbox")}
                  </div>
                  <div className="disp_flex_row">
                    <span className="icon_sm">
                      {otpMethodType === "Whatsapp" ? (
                        <WhatsappIcon />
                      ) : (
                        <ChatIconSvg height={28} width={28} />
                      )}
                    </span>
                    {otpRes && (
                      <div>
                        <span
                          className="light_grey_text res_font_size1"
                          style={{ opacity: "65%", fontSize: 12 }}
                        >
                          {t("send_verification_message")} {"  "}
                        </span>
                        <div
                          style={{ opacity: "65%", fontSize: 12 }}
                          className="light_grey_text res_font_size1"
                        >
                          {otpMsidn}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <VerificationInput
                  validChars={"[0-9]{0,5}"}
                  length={4}
                  inputProps={{ type: InputType }}
                  autoFocus
                  onComplete={(value: string) => {
                    // alert(value);
                    validateOtpFunction(value);
                  }}
                  classNames={{
                    container: "input-otp-container",
                    character: "input-otp-character",
                    characterSelected: "input-otp-active",
                  }}
                />
                {errorMessage !== "" && !loading && (
                  <div
                    className={errorMessage !== "" ? "show-error-message" : ""}
                  >
                    {ShowErrorMessage(errorMessage)}
                    {/* <text>Pleae enter value</text> */}
                  </div>
                )}
                {loading && (
                  <Loader height={10} width={10} colorProp={"black"} />
                )}
                <div className="cart_content_row cart_content_to_marg">
                  {timer > 0 ? (
                    <div className="cart_content_alert">
                      {t("verifitcation_code_error")} | {timer}
                    </div>
                  ) : (
                    <button
                      className="cart_content_alert"
                      onClick={resetTimer}
                      disabled={loading}
                      style={{ width: "100%" }}
                    >
                      {t("get_new_code")}
                    </button>
                  )}
                </div>
              </div>
            </div>
            {openOtpMethod && (
              <SelectOtpMethod
                phoneNumber={otpMsidn}
                onSelectHandler={(type: "SMS" | "Whatsapp") =>
                  selectMethodHandler(type)
                }
                onBackDropClick={() => setOtpMethod(false)}
              />
            )}
            {/* <!-- content end here --> */}
          </div>
        </div>
      </div>
      {shopInfo && (
        <SideLogoName
          logoUrl={shopInfo?.site_logo}
          bussinessName={shopInfo?.name[selectedLanguageCode]}
        />
      )}
      {showAlert && (
        <AlertNoLocation
          onPressClose={() => {}}
          onBackDropClick={() => {}}
          headerMessage="Select Location"
          errorMessage="Do you want continue with  selected address or want to choose from  previous saved address"
        />
      )}
    </div>
  );
};

export default PopPageValidateOtp;
