import React, { useState, useEffect } from "react";
import Pusher from "pusher-js";
import { useTranslation } from "react-i18next";
import useCustomerInfo from "../../../../../hooks/useCustomerInfo";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import BottomButton from "../../../../../shared/components/BottomButton";
import LocationSvg from "../../../../../assets/img/svgs/LocationSvg";
import SideLogoName from "../../../../../shared/components/SideLogoName";
import {
  functionChangeLanguge,
  getShopDataFromStrg,
  setThemeColorTrackOrder,
} from "../../../utils";
import Loader from "../../../../../modules/Loader";
import HomeSvg from "../../../../../assets/img/svgs/HomeSvg";
import OrderReceipt from "./OrderReceipt";
import TrackOrderStatus from "./CartComponents/TrackOrderStatus";
import { appSharedColors } from "../../../../../shared/styles/reactStyles";
import { IPUSHERDATA } from "./utils";
const PopOrderStatus: React.FC = () => {
  const navigate = useNavigate();
  const { bussinessName } = useParams();
  const { trackingOrder, trackOrderVal, loading } = useCustomerInfo();
  let [searchParams] = useSearchParams();
  const orderId = searchParams.get("order");
  const showOrderDetails = searchParams.get("orderDetail");
  const [showReceipt, setShowReceipt] = useState<boolean>(false);
  const valuesFromStorage = getShopDataFromStrg("/" + bussinessName!);
  const shopInfo = valuesFromStorage !== null && valuesFromStorage?.shopInfo;
  const [selectedLanguageCode, setSelectedLanguage] = useState<string>(
    valuesFromStorage ? valuesFromStorage?.savedLanguage : "en"
  );
  const refId = searchParams.get("ref");
  const { t } = useTranslation();
  const openReceiptHandler = () => {
    setShowReceipt(true);
  };
  const getValues = (stopLoading?: boolean) => {
    trackingOrder(orderId!, refId!, selectedLanguageCode, stopLoading);
  };

  useEffect(() => {
    getValues();
  }, []);
  const onPressClose = () => {
    navigate(`/d/${bussinessName}`);
  };
  const openWhatsapp = (whatsappNumber: string) => {
    window.open(`https://wa.me/${whatsappNumber}`, "_blank");
  };
  const closeAllPops = () => {
    if ([214].includes(trackOrderVal?.order?.resto_id)) {
      openWhatsapp(trackOrderVal?.resto?.phone_number);
      return;
    }

    document.location.href = `tel:${trackOrderVal?.resto?.phone_number}`;
  };
  const closeReceiptModal = () => {
    if (null !== showOrderDetails && showOrderDetails === "yes") {
      onPressClose();
      return;
    }
    setShowReceipt(false);
  };
  useEffect(() => {
    if (null !== showOrderDetails && showOrderDetails === "yes") {
      openReceiptHandler();
    }
    window.addEventListener("popstate", (e) => {
      onPressClose();
    });

    return () => {
      window.removeEventListener("popstate", onPressClose, false);
    };
  }, []);
  useEffect(() => {
    if (trackOrderVal && shopInfo === false) {
      document.documentElement.style.setProperty(
        "--background-color-store",
        trackOrderVal?.resto?.default_color
      );
      setThemeColorTrackOrder(trackOrderVal);
      document
        .getElementsByTagName("html")[0]
        .setAttribute(
          "dir",
          trackOrderVal?.resto?.lang === "ar" ? "rtl" : "ltr"
        );
      document
        .getElementsByTagName("body")[0]
        .setAttribute(
          "dir",
          trackOrderVal?.resto?.lang === "ar" ? "rtl" : "ltr"
        );
      setSelectedLanguage(trackOrderVal?.resto?.lang);
      functionChangeLanguge(trackOrderVal?.resto?.lang, bussinessName!);
      const favicon = document.getElementById("favicon");

      //@ts-ignore
      favicon.href = trackOrderVal?.resto?.site_logo;
    }
  }, [trackOrderVal]);

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY!, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER!,
    });

    const channel = pusher.subscribe(process.env.REACT_APP_PUHER_APP_CHANNEL!);
    channel.bind(`App\\Events\\OrderNotification`, (data: IPUSHERDATA) => {
      if (
        data.order_id == orderId &&
        data.order_ref == refId &&
        data.notification_for == "update-order-status"
      ) {
        getValues(true);
      }
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);
  return (
    <>
      {!loading ? (
        <div
          id="pop_cart_page7"
          className="pop_up_maps_main"
          lang={selectedLanguageCode}
          // lang="ar"
        >
          <div className=" popup_cart_full popup_from_bottom">
            <div className="content" id="">
              <div className="cart_head_container">
                <div className="">
                  <div className="header_cart_page">
                    <div className="cart_heading">{t("track_your_order")}</div>

                    <div className="home_icon_svg" onClick={onPressClose}>
                      <HomeSvg />
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- content start here --> */}
              <div className="cart_content_alert ">
                {t("order_number")} :
                <span
                  onClick={openReceiptHandler}
                  className="openReceiptButton"
                >
                  #{refId}
                </span>
              </div>

              <div
                className="cart_content"
                style={{
                  direction: selectedLanguageCode === "en" ? "ltr" : "rtl",
                }}
              >
                <div
                  className="disp_flex pad_bot_10"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>
                    {/* <div className="cart_time_bg">18:15</div> */}
                    <div className="cart_time_bg">
                      {trackOrderVal?.resto?.estimated_time}
                    </div>
                  </div>
                  <div
                    className="cart_content_col_50"
                    style={{ lineHeight: "34px" }}
                  >
                    <div className="light_grey_text align_rig">
                      {t("estimated_time")}
                    </div>
                  </div>
                </div>
                <div className="disp_flex pad_bot_10 title_acc_cell">
                  {t("order_progress")}
                </div>
                {/*  */}
                {/* {trackOrderVal !== undefined && */}
                {/* // (trackOrderVal?.orderactivities[3]?.status !== "Rejected" ? ( */}
              </div>
              {trackOrderVal &&
                trackOrderVal.orderactivitiesNew &&
                trackOrderVal?.orderactivitiesNew.map(
                  (item: any, index: number, array: any) => {
                    return (
                      <TrackOrderStatus
                        backgroundColor={
                          trackOrderVal?.resto?.busniess_colors
                            ?.GENERAL_COLOR_THEME
                        }
                        array={array}
                        index={index}
                        shopInfo={shopInfo}
                        trackOrderVal={item}
                        selectedLanguageCode={selectedLanguageCode}
                      />
                    );
                  }
                )}
              {/* <!-- content end here --> */}
            </div>

            {new Array(10).fill(0).map(({ index }) => (
              <br key={index} />
            ))}
          </div>
          {showReceipt && (
            <OrderReceipt
              onPressClose={closeReceiptModal}
              orderDetails={trackOrderVal}
            />
          )}
          <SideLogoName
            logoUrl={trackOrderVal?.resto?.site_logo}
            bussinessName={trackOrderVal?.resto?.name}
          />
          <div className="">
            <div
              className="track_order_bussiness_details"
              style={{
                backgroundColor:
                  trackOrderVal?.resto?.busniess_colors
                    ?.HIGHLIGHTED_BACKGROUND_COLOR,
              }}
            >
              <div
                style={{
                  width: "7%",
                }}
                className="cart_content_col_15"
              >
                <LocationSvg height={26} width={19} />
              </div>
              <div>
                <div style={{ fontWeight: "600" }}>
                  {trackOrderVal?.resto?.nameNew[selectedLanguageCode]}
                </div>
                <div style={{ fontSize: 12 }}>
                  {trackOrderVal?.resto?.addressNew[selectedLanguageCode]}
                </div>
              </div>
            </div>
            <BottomButton
              displayBottomLine={false}
              titleProp={t("call_us")}
              onPress={closeAllPops}
              widthProp={375}
            />
          </div>
        </div>
      ) : (
        <div className={" loader-wrapper"}>
          <Loader
            width={40}
            height={40}
            colorProp={appSharedColors.primaryColor}
          />
        </div>
      )}
    </>
  );
};

export default PopOrderStatus;
