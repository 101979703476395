import moment from "moment";
import React, { useEffect, useState } from "react";
import SelectTimeModal from "../../pages/Home/Components/Popups/SetTimePages/SelectTimeModal";
import SelectAddressModal from "./SelectAddressModal";
import PopDelivery from "../../pages/Home/Components/Popups/PopDelivery";
import { useParams } from "react-router-dom";
import { DATE_FORMAT_STRING } from "../../pages/Home/Components/Popups/CartPages/utils";
import { getCustomerInfoFromStroge } from "../../pages/Home/utils";
import { useTranslation } from "react-i18next";
interface SelectedDateTimeProps {
  onPressClose: () => void;
  restoData: any;
  onSelectedOutlet: (selectedOutlet: any) => void;
  isNew: boolean;
  deliveryDateTimeChange: (selectedDateTime: any) => void;
  changePickup: () => void;
  orderType: string;
  selectedLanguageCode: string;
  onSelectedAddress: (receiver: any, buttonPressed: string | undefined) => void;
}
const SelectedDateTime: React.FC<SelectedDateTimeProps> = (
  props: SelectedDateTimeProps
) => {
  const {
    onPressClose,
    restoData,
    onSelectedOutlet,
    selectedLanguageCode,
    orderType,
    deliveryDateTimeChange,
    onSelectedAddress,
    changePickup,
  } = props;
  const [showDeliveryModal, setShowDeliveryModal] = useState<boolean>(false);
  const [showTimeModal, setShowTimeModal] = useState<boolean>(false);
  const [showAddress, setShowAddress] = useState<boolean>(false);
  const { t } = useTranslation();
  const { bussinessName } = useParams();
  let temp = localStorage.getItem("shop/" + bussinessName);
  var parsedData: any = temp !== null ? JSON.parse(temp) : null;
  const [userAddress, setUserAddress] = useState<string>("");
  const [userTime, setUserTime] = useState<string>("");
  var customerInfo = getCustomerInfoFromStroge();
  const fetchDataFromStrg = () => {
    if (parsedData !== null) {
      if (parsedData.selectedTime !== undefined) {
        setUserTime(
          moment(parsedData.dateNumber).format(DATE_FORMAT_STRING) +
            parsedData.selectedTime
        );
       
      }
      if (parsedData?.shopInfo?.resto_meta?.ENABLED_AS_SOON_AS_POSSIBLE == 'Yes'){
        setUserTime(t('delivery_time'))
      }
      if (parsedData.userAddressInfo) {
        setUserAddress(parsedData.userAddressInfo.address);
        return;
      }
      if (parsedData?.orderType === "pickup") {
        setUserAddress(
          parsedData.outletInfo.selectedLocationName[selectedLanguageCode]
        );
        return;
      }
      if (parsedData?.orderType === "delivery") {
        setUserAddress(parsedData.outletInfo.selectedLocationName);
        return;
      }
    }
  };
  const showDeliveryModalHandler = () => {
    if (orderType === "d") {
      // if (searcHParam.includes("outlet") && customerInfo !== null) {
      if (customerInfo !== null) {
        setShowAddress(true);
      } else {
        setShowDeliveryModal(true);
      }
    } else {
      changePickup();
    }
  };
  const showTimeModalHandler = () => {
    setShowTimeModal(true);
  };
  const closeDeliveryModal = () => {
    setShowDeliveryModal(false);
    onPressClose();
  };
  const closeTimeModal = (selectedDateTime: any) => {
    fetchDataFromStrg();
    // setShowTimeModal(false);
    deliveryDateTimeChange(selectedDateTime);
    onPressClose();
  };
  useEffect(() => {
    fetchDataFromStrg();
  }, []);
  return (
    <div id="selectedDateTime" className="pop_up_maps_main pop_main_menu_bg">
      <div
        className="popup_delivery popup_from_bottom pop_time_set_height popup_slide"
        style={{
          flex: 1,
          justifyItems: "flex-end",
          padding: "30px 27px 27px 27px",
        }}
      >
        <div
          onClick={onPressClose}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {orderType === "d" ? (
            //@ts-ignore
            <div style={styles.h4Margin}>{t("choose_delivery")}</div>
          ) : (
            //@ts-ignore
            <div style={styles.h4Margin}>{t("picking_up_from")}</div>
          )}
          <div
            style={{
              position: "relative",
              top: 17,
              color: "#000",
              fontSize: 24,
              lineHeight: "0",
            }}
          >
            <div className="slide_remove">&times;</div>
          </div>
        </div>
        {/* <h4> */}

        <div
          className="selected-datetime-wrapper"
          style={{ ...styles.timeBorderStyle, ...styles.paddingDateTime }}
        >
          <div style={{ display: "flex" }}>
            <div
              style={styles.locationSvg}
              className="glyphicon glyphicon-map-marker bordered_map"
            />
            <span style={styles.selectedTxt}>{userAddress}</span>
          </div>
          <div
            id="change"
            style={{ border: 0, cursor: "pointer" }}
            onClick={showDeliveryModalHandler}
            className="cart_content_col_25 cir_cart_butt"
            // onClick={addressModalHandler}
          >
            {t("change_time_location")}
          </div>
        </div>

        <div style={styles.h4Margin}>
          {/* @ts-ignore */}
          {orderType === "d" ? t("delivery_timing") : t("pickup_time")}
        </div>
        <div className="selected-datetime-wrapper">
          <div style={{ display: "flex" }}>
            {/* <div className="glyphicon glyphicon-map-marker bordered_map"></div> */}
            <span style={styles.selectedTxt} className="dir_ltr">
              {userTime !== "" ? userTime : "SELECT  DATE/TIME"}
            </span>
          </div>
          <div
            id="change"
            style={{ border: 0, cursor: "pointer" }}
            onClick={showTimeModalHandler}
            className="cart_content_col_25 cir_cart_butt"
            // onClick={addressModalHandler}
          >
            {t("change_time_location")}
          </div>
        </div>
      </div>
      {showAddress && (
        <SelectAddressModal
          selectedId={0}
          onPressClose={(selectedItem, buttonPress) => {
            onSelectedAddress(selectedItem, buttonPress);
            setShowAddress(false);
          }}
          customerId={customerInfo !== null && customerInfo?.id}
          accesssToken={temp !== null && JSON.parse(temp)?.accessToken}
          selectedLatLng={
            temp !== null && JSON.parse(temp)?.outletInfo.selectedLatLon
          }
        />
      )}
      {showDeliveryModal && (
        <PopDelivery
          onPressClose={closeDeliveryModal}
          restoData={restoData}
          onSelectedOutlet={(selectedOutlet) => {
            onSelectedOutlet(selectedOutlet);
            fetchDataFromStrg();
            // closeDeliveryModal();
          }}
        />
      )}
      {showTimeModal && (
        <SelectTimeModal
          onPressClose={(value) => closeTimeModal(value)}
          shopName={bussinessName!}
        />
      )}
    </div>
  );
};

export default SelectedDateTime;

const styles = {
  locationSvg: {
    fontSize: "14px",
    lineHeight: "18px",
  },
  selectedTxt: {
    fontSize: "14px",
    color: "rgb(78, 78, 78)",
    marginLeft: "3px",
  },
  timeBorderStyle: {
    border: "0.5px solid #676767",
    borderRadius: "7px",
    backgroundColor: "#F9F9F9",
  },
  paddingDateTime: {
    padding: "15px 17px 15px 20px",
  },
  h4Margin: {
    color: "#000000",
    marginTop: "12px",
    marginBottom: "10px",
    fontSize: "16px",
  },
};
