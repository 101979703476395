import React from "react";
import IconInfo from "../../../../../../assets/img/svgs/IconInfo";
import { numberWithCommas } from "../../../../utils";
interface PaymentRowProp {
  labelValue: string;
  actualValue: string;
  isShow: boolean;
  selectedLang: string;
  currency: string;
  isShowButton?: boolean;

  onPressIcon: () => void;
}
const PaymentRow: React.FC<PaymentRowProp> = (props: PaymentRowProp) => {
  const {
    labelValue,
    actualValue,
    isShow,
    onPressIcon,
    selectedLang,
    currency,
    isShowButton,
  } = props;
  return (
    <>
      {isShow && (
        <div className="cart_content_row">
          <div
            className="cart_content_col_70 flo_left"
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>{labelValue}</div>
            {!isShowButton && (
              <IconInfo
                onPress={onPressIcon}
                style={{ marginRight: 5, marginLeft: 5, cursor: "pointer" }}
              />
            )}
          </div>
          <div className="cart_content_col_30" style={{ color: "red" }}>
            {selectedLang === "en" && currency} {numberWithCommas(actualValue)}{" "}
            {selectedLang === "ar" && currency}
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentRow;
