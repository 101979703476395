import React from "react";
import WelcomeOffer from "../../assets/img/svgs/WelcomeOffer";
import BlockPhoneNumber from "./BlockPhoneNumber";
interface DiscountMesasgeProp {
  message: string;
  showIcon: boolean;
  whatsappNumber: number | undefined;
  landlineNumber: number | undefined;
}
const DisplayMessage: React.FC<DiscountMesasgeProp> = (
  props: DiscountMesasgeProp
) => {
  const { message, showIcon, whatsappNumber, landlineNumber } = props;

  return (
    <div id="preOrderMessage" className="displayMessageClass">
      {showIcon && <WelcomeOffer />}
      <div
        style={{ fontSize: "12px", paddingLeft: "5px", paddingRight: "5px" }}
        className=""
      >
        <span> {message}</span>

        {/* <span style={homeStyles.bussinessContactNumber}>97142234488</span> */}
      </div>
      <BlockPhoneNumber
        notOpenWhatsapp={true}
        landlineNumber={landlineNumber}
        whatsappNumber={whatsappNumber}
      />
    </div>
  );
};

export default DisplayMessage;
