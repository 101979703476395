import { configureStore } from "@reduxjs/toolkit";
import storeReducer from "./action-creators/action-creator";
import closeAllModal from "./action-creators/close-all-modal";
import outletInfo from "./action-creators/outlet-info";
import otpInformation from "./action-creators/otpInformation";
const ReduxStore = configureStore({
  reducer: {
    testData: storeReducer,
    closeModal: closeAllModal,
    shopInfo: outletInfo,
    otpInformation:otpInformation,
  },
});
export default ReduxStore;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof ReduxStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof ReduxStore.dispatch;
