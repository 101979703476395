import {  httpPostWithOutAuth } from "../../../setup/axios/axiosUtils";
import { useState } from "react";


import { IORDERRECEIPT } from "../../pages/Home/utils";
import { URL_CONSTANTS } from "../urlsLink";
const useItemReceipt = () => {
  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState<IORDERRECEIPT>();
  const [errorMessage, setErrorMessage] = useState("");
  const getItemReceipt = async (order_id: any, order_ref: any) => {
    try {
      setLoading(true);
      const res = await httpPostWithOutAuth(
        URL_CONSTANTS.get("URL_GET_ORDER_RECEIPT")!,
        {
          id: order_id,
          ref: order_ref,
        },
      );
    //   if (res.type === "success") {
        setOrderDetails(res);
    //   }
      setLoading(false);
    } catch (error: any) {
      setErrorMessage(error.response.data.message);

      setLoading(false);
    }
  };

  return {
    getItemReceipt,
    orderDetails,
    loading,
    errorMessage,
  };
};
export default useItemReceipt;
