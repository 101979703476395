import React from "react";
import BlockPhoneNumber from "./BlockPhoneNumber";
import WhatsappIconSvg from "../../assets/img/svgs/WhatsappIconSvg";
import PhonePreOrderSvg from "../../assets/img/svgs/PhonePreOrderSvg";
interface ErrorModalProps {
  onPressClose: () => void;
  errorMessage: string;
  headerMessage: string;
  showButton: boolean;
  landLinenumber?: number;
  whatsappNumber?: number;

  onBackDropClick?: () => void;
}
const SelectPhoneNumber: React.FC<ErrorModalProps> = (
  props: ErrorModalProps
) => {
  const {
    onPressClose,
    errorMessage,
    onBackDropClick,
    headerMessage,
    landLinenumber,
    whatsappNumber,
  } = props;
  const closeModal = () => {
    onPressClose();
  };
  const opneWhatsapp = (phoneNumber?: string) => {
    // if (!notOpenWhatsapp) {
    // document.location.href = `//api.whatsapp.com/send?phone=${phoneNumber}&text=Hello how are you i want to place a pre order ?`;
    // }
    // if (notOpenWhatsapp) {
    //   document.location.href = `tel:${phoneNumber}`;
    //   hideSelectorHandler();
    // }
    window.location.href = `tel:${phoneNumber}`;
  };

  const callUsHandler = (phoneNumber?: string) => {
    window.location.href = `tel:${phoneNumber}`;
    // if (!notOpenWhatsapp) {
    // document.location.href = `tel:${phoneNumber}`;
    // }
    // if (notOpenWhatsapp) {
    //   setShowSelector(true);
    //   hideSelectorHandler();
    // }
    // dispatch(setCloseAllModal());
  };
  return (
    <div
      onClick={onBackDropClick}
      id="pre_order_modal"
      style={{
        background: "rgba(0, 0, 0, 0.4)",
      }}
      className="pop_up_maps_main pop_main_menu_bg location-error-modal"
    >
      <div
        // style={{ bottom: "auto", top: 0 }}
        style={{
          bottom: "auto",
          width: "90%",
          borderRadius: "10px",
          zIndex: 2,
        }}
        //popup_slide
        className="popup_delivery popup_from_bottom pop_time_set_height "
      >
        <div
          className="content"
          id=""
          style={{ marginLeft: 10, marginRight: 10 }}
        >
          <div
          // className="container"
          >
            <div className="container">
              <div style={{ borderBottom: 0 }} className="head_burg_menu">
                <div className="calender_header"></div>
                <div className="delivery_head">{headerMessage}</div>
                <div className="delivery_time_text" style={{ marginTop: 10 }}>
                  {errorMessage}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 13,
                    cursor: "pointer",
                  }}
                >
                  <div
                    onClick={() => callUsHandler(landLinenumber?.toString())}
                    className="disp_flex_row"
                  >
                    <PhonePreOrderSvg />{" "}
                    <span className="phone-preorder-message">
                      {landLinenumber}
                    </span>
                  </div>
                  <div className="message-line" />
                  <div style={{ marginRight: 8 }} />
                  <div
                    onClick={() => opneWhatsapp(whatsappNumber?.toString())}
                    className="disp_flex_row"
                  >
                    <PhonePreOrderSvg />{" "}
                    <span className="phone-preorder-message">
                      {whatsappNumber}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default SelectPhoneNumber;
