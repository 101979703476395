import React from "react";
import BlockPhoneNumber from "./BlockPhoneNumber";
import ButtonAlertModal from "./Buttons/ButtonAlertModal";
interface ErrorModalProps {
  onPressClose: () => void;
  errorMessage: string;
  headerMessage: string;
  showButton: boolean;
  landLinenumber?: number;
  whatsappNumber?: number;

  onBackDropClick?: () => void;
}
const PreOrderModal: React.FC<ErrorModalProps> = (props: ErrorModalProps) => {
  const {
    onPressClose,
    showButton,
    errorMessage,
    onBackDropClick,
    headerMessage,
    landLinenumber,
    whatsappNumber,
  } = props;
  const closeModal = () => {
    onPressClose();
  };
  return (
    <div
      onClick={onBackDropClick}
      id="pre_order_modal"
      style={{
        background: "rgba(0, 0, 0, 0.4)",
      }}
      className="pop_up_maps_main pop_main_menu_bg location-error-modal"
    >
      <div
        // style={{ bottom: "auto", top: 0 }}
        style={{
          bottom: "auto",
          width: "90%",
          borderRadius: "10px",
          zIndex: 2,
        }}
        //popup_slide
        className="popup_delivery popup_from_bottom pop_time_set_height "
      >
        <div
          className="content"
          id=""
          style={{ marginLeft: 10, marginRight: 10 }}
        >
          <div
          // className="container"
          >
            <div className="container">
              <div style={{ borderBottom: 0 }} className="head_burg_menu">
                <div className="calender_header"></div>
                <div className="delivery_head">{headerMessage}</div>
                <div className="delivery_time_text" style={{ marginTop: 10 }}>
                  {errorMessage}
                </div>
                <BlockPhoneNumber
                  landlineNumber={landLinenumber}
                  whatsappNumber={whatsappNumber}
                />
              </div>
            </div>
          </div>
          <br />

          {showButton && (
            <ButtonAlertModal onPressHandler={closeModal} btnTitle="OK" />
          )}
        </div>
      </div>
    </div>
  );
};

export default PreOrderModal;
