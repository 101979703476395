import React, { useEffect, useState } from "react";
import { PopReciverInfoProp } from "./utils";
import useCustomerInfo from "../../../../../hooks/useCustomerInfo";
import BackArrow from "../../../../../assets/img/svgs/BackArrow";
import { useTranslation } from "react-i18next";
import Loader from "../../../../../modules/Loader";
const PopReciverInfo: React.FC<PopReciverInfoProp> = (
  props: PopReciverInfoProp
) => {
  const { onPressClose, accessToken, customerId, onReceiverAdded } = props;
  const [nameError, setShowNameError] = useState<boolean>(false);
  const [phoneError, setShowPhoneError] = useState<boolean>(false);
  const [receiverName, setReceiverName] = useState<string>("");
  const { saveNewReceipent, loading } = useCustomerInfo();
  const { t } = useTranslation();
  const [receiverPhone, setReceiverPhone] = useState<string>("");
  const [greetingMessage] = useState<string>("");
  const popGreetingShow = () => {
    // setShowModal(true);
  };

  const handleInput = (e: any) => {
    var result = e.target.value.replace(/\D/g, "");
    setReceiverPhone(result);
  };
  const saveReceiverHandler = () => {
    if (receiverName === "") {
      setShowNameError(true);
    } else if (receiverPhone === "") {
      setShowPhoneError(true);
    } else {
      saveNewReceipent(
        accessToken,
        receiverName,
        receiverPhone,
        customerId,
        greetingMessage
      ).then((response) => {
        if (response.success) {
          onReceiverAdded(response.newId);
          onPressClose();
        }
      });
    }
  };
  useEffect(() => {
    window.onpopstate = (e) => {
      e.preventDefault();
      onPressClose();
      //your code...
    };
  }, []);
  return (
    <div id="pop_cart_page8" className="pop_receiver pop_up_maps_main">
      <div className="popup_cart_full popup_from_bottom  popup_slide">
        <div className="content" id="">
          <div className="cart_head_container">
            <div className="container">
              <div className="header_cart_page">
                <div className="cart_heading">New Receiver</div>
                {/* <div
                  className="target_slide glyphicon glyphicon-chevron-left"
                  onClick={onPressClose}
                ></div> */}
                <span onClick={onPressClose} className="target_slide glyphicon">
                  <BackArrow />
                </span>
              </div>
            </div>
          </div>
          {/* <!-- content start here --> */}
          <div className="cart_content">
            <div className="">
              <div className="cart_content_row">
                <div className="title_acc_cell">Receiver Name</div>
                <div className="">
                  <input
                    type="text"
                    className={`cart_mobile_input_type ${
                      nameError ? "border-error" : ""
                    }`}
                    name="rec_name"
                    value={receiverName}
                    onChange={(e) => setReceiverName(e.target.value)}
                    placeholder="Enter name"
                  />
                </div>
              </div>
              <br />
              <div className="cart_content_row">
                <div className="title_acc_cell">Receiver Number</div>
                <div className="">
                  <input
                    type="tel"
                    className={`cart_mobile_input_type ${
                      phoneError ? "border-error" : ""
                    }`}
                    name="tel_reci"
                    placeholder="123-45-678"
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    required
                    value={receiverPhone}
                    onChange={(e) => handleInput(e)}
                  />
                </div>
              </div>
              <br />
              <div
                className="cart_button_order"
                style={{
                  backgroundColor: "white",

                  padding: "26px 0px",
                  position: "relative",
                }}
              >
                <button
                  disabled={loading}
                  // style={{ zIndex: 1000 }}
                  // style={{ width: "100%" }}
                  className="cart_foot_button simple_button_wrapper"
                  onClick={saveReceiverHandler}
                >
                  {!loading ? (
                    <div className="">{t("save_receiver")}</div>
                  ) : (
                    <Loader height={15} width={15} colorProp={"white"} />
                  )}
                </button>
              </div>
              {/* <SimpleButton
                // width={"90%"}
                titleProp={t("save_receiver")}
                loading={loading}
                onPressHandler={saveReceiverHandler}
                t={t}
              /> */}
              {/* <div className="cart_content_row">
                <div className="title_acc_cell">Greeting Message</div>
                <textarea
                  name="comment_page_8"
                  className="cart_text_area"
                  rows={5}
                  placeholder="message (Optional)"
                  value={greetingMessage}
                  onChange={(e) => setGreetingMessage(e.target.value)}
                ></textarea>
              </div> */}
            </div>
          </div>
          {/* <!-- content end here --> */}

          {/* <div className="cart_foot">
            <button
              className="cart_foot_button"
              onClick={saveReceiverHandler}
              disabled={loading}
            >
              <div className="">Save Receiver</div>
            </button>
          </div> */}
        </div>
      </div>
      {/* {showModal && <PopGreeting onPressClose={popGreetingHide} />} */}
    </div>
  );
};

export default PopReciverInfo;
