import React from "react";

const EmptyCartSvg1: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="520"
      height="184.403"
      viewBox="0 0 520 184.403"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_473"
            data-name="Rectangle 473"
            width="520"
            height="184.403"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-3">
          <rect
            id="Rectangle_359"
            data-name="Rectangle 359"
            width="107.832"
            height="4.69"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-5">
          <rect
            id="Rectangle_467"
            data-name="Rectangle 467"
            width="56.906"
            height="2.933"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-6">
          <rect
            id="Rectangle_468"
            data-name="Rectangle 468"
            width="46.564"
            height="2.933"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-7">
          <rect
            id="Rectangle_470"
            data-name="Rectangle 470"
            width="76.746"
            height="4.643"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-8">
          <rect
            id="Rectangle_471"
            data-name="Rectangle 471"
            width="100.435"
            height="4.643"
            fill="none"
          />
        </clipPath>
      </defs>
      <g
        id="Group_8444"
        data-name="Group 8444"
        opacity="0.41"
        // style="mix-blend-mode: luminosity;isolation: isolate"
      >
        <g id="Group_8443" data-name="Group 8443" clip-path="url(#clip-path)">
          <rect
            id="Rectangle_358"
            data-name="Rectangle 358"
            width="520"
            height="66.331"
            transform="translate(0 118.072)"
            fill="#c6bccc"
          />
          <g id="Group_8428" data-name="Group 8428">
            <g
              id="Group_8427"
              data-name="Group 8427"
              clip-path="url(#clip-path)"
            >
              <g
                id="Group_8426"
                data-name="Group 8426"
                transform="translate(247.507 144.694)"
                opacity="0.71"
                // style="mix-blend-mode: multiply;isolation: isolate"
              >
                <g id="Group_8425" data-name="Group 8425">
                  <g
                    id="Group_8424"
                    data-name="Group 8424"
                    clip-path="url(#clip-path-3)"
                  >
                    <path
                      id="Path_9197"
                      data-name="Path 9197"
                      d="M584.317,280.9c0,1.295-24.139,2.345-53.916,2.345s-53.916-1.05-53.916-2.345,24.139-2.345,53.916-2.345,53.916,1.05,53.916,2.345"
                      transform="translate(-476.485 -278.557)"
                      fill="#ddd7e0"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <rect
            id="Rectangle_361"
            data-name="Rectangle 361"
            width="27.261"
            height="8.715"
            transform="translate(425.621 31.997)"
            fill="#c6bccc"
          />
          <rect
            id="Rectangle_362"
            data-name="Rectangle 362"
            width="27.261"
            height="8.715"
            transform="translate(19.762 67.349)"
            fill="#c6bccc"
          />
          <rect
            id="Rectangle_363"
            data-name="Rectangle 363"
            width="115.265"
            height="58.822"
            transform="translate(11.686 71.706)"
            fill="#c6bccc"
          />
          <rect
            id="Rectangle_364"
            data-name="Rectangle 364"
            width="115.265"
            height="58.822"
            transform="translate(272.07 76.347)"
            fill="#c6bccc"
          />
          <rect
            id="Rectangle_365"
            data-name="Rectangle 365"
            width="72.045"
            height="81.984"
            transform="translate(392.79 35.895)"
            fill="#c6bccc"
          />
          <rect
            id="Rectangle_366"
            data-name="Rectangle 366"
            width="52.951"
            height="59.73"
            transform="translate(354.392 57.349)"
            fill="#ddd7e0"
          />
          <rect
            id="Rectangle_367"
            data-name="Rectangle 367"
            width="52.951"
            height="49.371"
            transform="translate(401.468 70.314)"
            fill="#ddd7e0"
          />
          <rect
            id="Rectangle_368"
            data-name="Rectangle 368"
            width="6.72"
            height="7.669"
            transform="translate(411.918 77.11)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_369"
            data-name="Rectangle 369"
            width="6.72"
            height="7.669"
            transform="translate(423.126 77.11)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_370"
            data-name="Rectangle 370"
            width="6.72"
            height="7.669"
            transform="translate(433.432 77.11)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_371"
            data-name="Rectangle 371"
            width="6.72"
            height="7.669"
            transform="translate(411.918 88.821)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_372"
            data-name="Rectangle 372"
            width="6.72"
            height="7.669"
            transform="translate(423.126 88.821)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_373"
            data-name="Rectangle 373"
            width="6.72"
            height="7.669"
            transform="translate(433.432 88.821)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_374"
            data-name="Rectangle 374"
            width="6.72"
            height="7.669"
            transform="translate(411.918 100.072)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_375"
            data-name="Rectangle 375"
            width="6.72"
            height="7.669"
            transform="translate(423.126 100.072)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_376"
            data-name="Rectangle 376"
            width="6.72"
            height="7.669"
            transform="translate(433.432 100.072)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_377"
            data-name="Rectangle 377"
            width="56.972"
            height="7.976"
            transform="translate(352.124 55.128)"
            fill="#ddd7e0"
          />
          <rect
            id="Rectangle_378"
            data-name="Rectangle 378"
            width="6.72"
            height="7.669"
            transform="translate(360.507 65.441)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_379"
            data-name="Rectangle 379"
            width="6.72"
            height="7.669"
            transform="translate(371.716 65.441)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_380"
            data-name="Rectangle 380"
            width="6.72"
            height="7.669"
            transform="translate(383.3 65.441)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_381"
            data-name="Rectangle 381"
            width="6.72"
            height="7.669"
            transform="translate(394.509 65.441)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_382"
            data-name="Rectangle 382"
            width="6.72"
            height="7.669"
            transform="translate(360.507 75.407)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_383"
            data-name="Rectangle 383"
            width="6.72"
            height="7.669"
            transform="translate(371.716 75.407)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_384"
            data-name="Rectangle 384"
            width="6.72"
            height="7.669"
            transform="translate(383.3 75.407)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_385"
            data-name="Rectangle 385"
            width="6.72"
            height="7.669"
            transform="translate(394.509 75.407)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_386"
            data-name="Rectangle 386"
            width="6.72"
            height="7.669"
            transform="translate(360.507 84.986)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_387"
            data-name="Rectangle 387"
            width="6.72"
            height="7.669"
            transform="translate(371.716 84.986)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_388"
            data-name="Rectangle 388"
            width="6.72"
            height="7.669"
            transform="translate(383.3 84.986)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_389"
            data-name="Rectangle 389"
            width="6.72"
            height="7.669"
            transform="translate(394.509 84.986)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_390"
            data-name="Rectangle 390"
            width="6.72"
            height="7.669"
            transform="translate(443.774 77.11)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_391"
            data-name="Rectangle 391"
            width="6.72"
            height="7.669"
            transform="translate(443.774 88.821)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_392"
            data-name="Rectangle 392"
            width="6.72"
            height="7.669"
            transform="translate(443.774 100.072)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_393"
            data-name="Rectangle 393"
            width="52.951"
            height="114.924"
            transform="translate(177.516 4.008)"
            fill="#ddd7e0"
          />
          <rect
            id="Rectangle_394"
            data-name="Rectangle 394"
            width="52.951"
            height="59.73"
            transform="translate(236.124 58.159)"
            fill="#ddd7e0"
          />
          <rect
            id="Rectangle_395"
            data-name="Rectangle 395"
            width="52.951"
            height="76.394"
            transform="translate(287.167 40.938)"
            fill="#ddd7e0"
          />
          <rect
            id="Rectangle_396"
            data-name="Rectangle 396"
            width="56.972"
            height="7.976"
            transform="translate(234.294 54.006)"
            fill="#ddd7e0"
          />
          <rect
            id="Rectangle_397"
            data-name="Rectangle 397"
            width="31.156"
            height="8.311"
            transform="translate(194.664 0.001)"
            fill="#ddd7e0"
          />
          <rect
            id="Rectangle_398"
            data-name="Rectangle 398"
            width="31.156"
            height="8.311"
            transform="translate(291.861 37.595)"
            fill="#ddd7e0"
          />
          <rect
            id="Rectangle_399"
            data-name="Rectangle 399"
            width="6.72"
            height="7.669"
            transform="translate(184.406 35.528)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_400"
            data-name="Rectangle 400"
            width="6.72"
            height="7.669"
            transform="translate(195.614 35.528)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_401"
            data-name="Rectangle 401"
            width="6.72"
            height="7.669"
            transform="translate(241.027 64.755)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_402"
            data-name="Rectangle 402"
            width="6.72"
            height="7.669"
            transform="translate(252.235 64.755)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_403"
            data-name="Rectangle 403"
            width="6.72"
            height="7.669"
            transform="translate(263.819 64.755)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_404"
            data-name="Rectangle 404"
            width="6.72"
            height="7.669"
            transform="translate(275.028 64.755)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_405"
            data-name="Rectangle 405"
            width="6.72"
            height="7.669"
            transform="translate(205.895 35.528)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_406"
            data-name="Rectangle 406"
            width="6.72"
            height="7.669"
            transform="translate(217.103 35.528)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_407"
            data-name="Rectangle 407"
            width="6.72"
            height="7.669"
            transform="translate(184.284 13.42)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_408"
            data-name="Rectangle 408"
            width="6.72"
            height="7.669"
            transform="translate(195.492 13.42)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_409"
            data-name="Rectangle 409"
            width="6.72"
            height="7.669"
            transform="translate(205.773 13.42)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_410"
            data-name="Rectangle 410"
            width="6.72"
            height="7.669"
            transform="translate(216.981 13.42)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_411"
            data-name="Rectangle 411"
            width="6.72"
            height="7.669"
            transform="translate(293.299 47.46)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_412"
            data-name="Rectangle 412"
            width="6.72"
            height="7.669"
            transform="translate(304.508 47.46)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_413"
            data-name="Rectangle 413"
            width="6.72"
            height="7.669"
            transform="translate(293.299 61.607)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_414"
            data-name="Rectangle 414"
            width="6.72"
            height="7.669"
            transform="translate(304.508 61.607)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_415"
            data-name="Rectangle 415"
            width="6.72"
            height="7.669"
            transform="translate(184.925 57.635)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_416"
            data-name="Rectangle 416"
            width="6.72"
            height="7.669"
            transform="translate(196.133 57.635)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_417"
            data-name="Rectangle 417"
            width="6.72"
            height="7.669"
            transform="translate(206.414 57.635)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_418"
            data-name="Rectangle 418"
            width="6.72"
            height="7.669"
            transform="translate(217.622 57.635)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_419"
            data-name="Rectangle 419"
            width="6.72"
            height="7.669"
            transform="translate(184.284 79.687)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_420"
            data-name="Rectangle 420"
            width="6.72"
            height="7.669"
            transform="translate(195.492 79.687)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_421"
            data-name="Rectangle 421"
            width="6.72"
            height="7.669"
            transform="translate(205.773 79.687)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_422"
            data-name="Rectangle 422"
            width="6.72"
            height="7.669"
            transform="translate(216.981 79.687)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_423"
            data-name="Rectangle 423"
            width="6.72"
            height="7.669"
            transform="translate(240.788 74.762)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_424"
            data-name="Rectangle 424"
            width="6.72"
            height="7.669"
            transform="translate(251.996 74.762)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_425"
            data-name="Rectangle 425"
            width="6.72"
            height="7.669"
            transform="translate(263.581 74.762)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_426"
            data-name="Rectangle 426"
            width="6.72"
            height="7.669"
            transform="translate(274.789 74.762)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_427"
            data-name="Rectangle 427"
            width="6.72"
            height="7.669"
            transform="translate(240.788 84.727)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_428"
            data-name="Rectangle 428"
            width="6.72"
            height="7.669"
            transform="translate(251.996 84.727)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_429"
            data-name="Rectangle 429"
            width="6.72"
            height="7.669"
            transform="translate(263.581 84.727)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_430"
            data-name="Rectangle 430"
            width="6.72"
            height="7.669"
            transform="translate(274.789 84.727)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_431"
            data-name="Rectangle 431"
            width="6.72"
            height="7.669"
            transform="translate(240.788 94.307)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_432"
            data-name="Rectangle 432"
            width="6.72"
            height="7.669"
            transform="translate(251.996 94.307)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_433"
            data-name="Rectangle 433"
            width="6.72"
            height="7.669"
            transform="translate(263.581 94.307)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_434"
            data-name="Rectangle 434"
            width="6.72"
            height="7.669"
            transform="translate(274.789 94.307)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_435"
            data-name="Rectangle 435"
            width="52.951"
            height="59.73"
            transform="translate(112.444 57.12)"
            fill="#ddd7e0"
          />
          <rect
            id="Rectangle_436"
            data-name="Rectangle 436"
            width="52.951"
            height="95.294"
            transform="translate(45.309 23.22)"
            fill="#ddd7e0"
          />
          <rect
            id="Rectangle_437"
            data-name="Rectangle 437"
            width="6.72"
            height="7.669"
            transform="translate(52.063 29.404)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_438"
            data-name="Rectangle 438"
            width="6.72"
            height="7.669"
            transform="translate(63.271 29.404)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_439"
            data-name="Rectangle 439"
            width="6.72"
            height="7.669"
            transform="translate(73.577 29.404)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_440"
            data-name="Rectangle 440"
            width="6.72"
            height="7.669"
            transform="translate(84.785 29.404)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_441"
            data-name="Rectangle 441"
            width="31.156"
            height="8.311"
            transform="translate(51.375 19.065)"
            fill="#ddd7e0"
          />
          <rect
            id="Rectangle_442"
            data-name="Rectangle 442"
            width="6.72"
            height="7.669"
            transform="translate(52.076 45.906)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_443"
            data-name="Rectangle 443"
            width="6.72"
            height="7.669"
            transform="translate(63.284 45.906)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_444"
            data-name="Rectangle 444"
            width="6.72"
            height="7.669"
            transform="translate(73.564 45.906)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_445"
            data-name="Rectangle 445"
            width="6.72"
            height="7.669"
            transform="translate(84.773 45.906)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_446"
            data-name="Rectangle 446"
            width="6.72"
            height="7.669"
            transform="translate(52.076 62.409)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_447"
            data-name="Rectangle 447"
            width="6.72"
            height="7.669"
            transform="translate(63.284 62.409)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_448"
            data-name="Rectangle 448"
            width="6.72"
            height="7.669"
            transform="translate(73.564 62.409)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_449"
            data-name="Rectangle 449"
            width="6.72"
            height="7.669"
            transform="translate(84.773 62.409)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_450"
            data-name="Rectangle 450"
            width="6.72"
            height="7.669"
            transform="translate(52.076 78.912)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_451"
            data-name="Rectangle 451"
            width="6.72"
            height="7.669"
            transform="translate(63.284 78.912)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_452"
            data-name="Rectangle 452"
            width="6.72"
            height="7.669"
            transform="translate(73.564 78.912)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_453"
            data-name="Rectangle 453"
            width="6.72"
            height="7.669"
            transform="translate(84.773 78.912)"
            fill="#bbafc0"
          />
          <rect
            id="Rectangle_454"
            data-name="Rectangle 454"
            width="6.72"
            height="7.669"
            transform="translate(118.998 63.79)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_455"
            data-name="Rectangle 455"
            width="6.72"
            height="7.669"
            transform="translate(130.206 63.79)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_456"
            data-name="Rectangle 456"
            width="6.72"
            height="7.669"
            transform="translate(141.791 63.79)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_457"
            data-name="Rectangle 457"
            width="6.72"
            height="7.669"
            transform="translate(152.999 63.79)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_458"
            data-name="Rectangle 458"
            width="6.72"
            height="7.669"
            transform="translate(118.998 73.756)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_459"
            data-name="Rectangle 459"
            width="6.72"
            height="7.669"
            transform="translate(130.206 73.756)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_460"
            data-name="Rectangle 460"
            width="6.72"
            height="7.669"
            transform="translate(141.791 73.756)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_461"
            data-name="Rectangle 461"
            width="6.72"
            height="7.669"
            transform="translate(152.999 73.756)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_462"
            data-name="Rectangle 462"
            width="6.72"
            height="7.669"
            transform="translate(118.998 83.335)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_463"
            data-name="Rectangle 463"
            width="6.72"
            height="7.669"
            transform="translate(130.206 83.335)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_464"
            data-name="Rectangle 464"
            width="6.72"
            height="7.669"
            transform="translate(141.791 83.335)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_465"
            data-name="Rectangle 465"
            width="6.72"
            height="7.669"
            transform="translate(152.999 83.335)"
            fill="#eeecf1"
          />
          <rect
            id="Rectangle_466"
            data-name="Rectangle 466"
            width="520"
            height="6.036"
            transform="translate(0 114.97)"
            fill="#ddd7e0"
          />
          <g id="Group_8442" data-name="Group 8442">
            <g
              id="Group_8441"
              data-name="Group 8441"
              clip-path="url(#clip-path)"
            >
              <g
                id="Group_8431"
                data-name="Group 8431"
                transform="translate(191.731 116.423)"
                opacity="0.71"
                // style="mix-blend-mode: multiply;isolation: isolate"
              >
                <g id="Group_8430" data-name="Group 8430">
                  <g
                    id="Group_8429"
                    data-name="Group 8429"
                    clip-path="url(#clip-path-5)"
                  >
                    <path
                      id="Path_9198"
                      data-name="Path 9198"
                      d="M426.014,225.6c0,.81-12.739,1.466-28.453,1.466s-28.453-.657-28.453-1.466,12.739-1.466,28.453-1.466,28.453.657,28.453,1.466"
                      transform="translate(-369.108 -224.129)"
                      fill="#ddd7e0"
                    />
                  </g>
                </g>
              </g>
              <g
                id="Group_8434"
                data-name="Group 8434"
                transform="translate(108.788 117.048)"
                opacity="0.71"
                // style="mix-blend-mode: multiply;isolation: isolate"
              >
                <g id="Group_8433" data-name="Group 8433">
                  <g
                    id="Group_8432"
                    data-name="Group 8432"
                    clip-path="url(#clip-path-6)"
                  >
                    <path
                      id="Path_9199"
                      data-name="Path 9199"
                      d="M255.995,226.8c0,.81-10.424,1.466-23.282,1.466s-23.283-.657-23.283-1.466,10.424-1.467,23.283-1.467,23.282.657,23.282,1.467"
                      transform="translate(-209.431 -225.333)"
                      fill="#ddd7e0"
                    />
                  </g>
                </g>
              </g>
              <path
                id="Path_9200"
                data-name="Path 9200"
                d="M11.011,259.158H5.817a.26.26,0,1,1,0-.519h5.194a.26.26,0,1,1,0,.519"
                transform="translate(-2.67 -124.29)"
                fill="#fff"
              />
              <path
                id="Path_9201"
                data-name="Path 9201"
                d="M518.3,259.158h-10.27a.26.26,0,1,1,0-.519H518.3a.26.26,0,1,1,0,.519m-20.541,0h-10.27a.26.26,0,1,1,0-.519h10.27a.26.26,0,0,1,0,.519m-20.541,0h-10.27a.26.26,0,1,1,0-.519h10.27a.26.26,0,0,1,0,.519m-20.541,0H446.4a.26.26,0,0,1,0-.519h10.27a.26.26,0,1,1,0,.519m-20.54,0h-10.27a.26.26,0,1,1,0-.519h10.27a.26.26,0,0,1,0,.519m-20.54,0H405.322a.26.26,0,1,1,0-.519h10.271a.26.26,0,0,1,0,.519m-20.541,0H384.781a.26.26,0,0,1,0-.519h10.271a.26.26,0,0,1,0,.519m-20.541,0H364.241a.26.26,0,1,1,0-.519h10.271a.26.26,0,1,1,0,.519m-20.541,0H343.7a.26.26,0,0,1,0-.519h10.27a.26.26,0,0,1,0,.519m-20.541,0h-10.27a.26.26,0,1,1,0-.519h10.27a.26.26,0,0,1,0,.519m-20.541,0h-10.27a.26.26,0,1,1,0-.519h10.27a.26.26,0,1,1,0,.519m-20.541,0h-10.27a.26.26,0,0,1,0-.519h10.27a.26.26,0,0,1,0,.519m-20.54,0h-10.27a.26.26,0,1,1,0-.519h10.27a.26.26,0,1,1,0,.519m-20.541,0H241a.26.26,0,1,1,0-.519h10.27a.26.26,0,1,1,0,.519m-20.541,0h-10.27a.26.26,0,1,1,0-.519h10.27a.26.26,0,1,1,0,.519m-20.54,0h-10.27a.26.26,0,1,1,0-.519h10.27a.26.26,0,1,1,0,.519m-20.541,0H179.374a.26.26,0,1,1,0-.519h10.271a.26.26,0,1,1,0,.519m-20.541,0h-10.27a.26.26,0,1,1,0-.519H169.1a.26.26,0,1,1,0,.519m-20.541,0h-10.27a.26.26,0,1,1,0-.519h10.27a.26.26,0,1,1,0,.519m-20.541,0h-10.27a.26.26,0,1,1,0-.519h10.27a.26.26,0,1,1,0,.519m-20.541,0H97.21a.26.26,0,1,1,0-.519h10.27a.26.26,0,1,1,0,.519m-20.54,0H76.67a.26.26,0,1,1,0-.519H86.94a.26.26,0,1,1,0,.519m-20.541,0H56.129a.26.26,0,1,1,0-.519H66.4a.26.26,0,1,1,0,.519m-20.541,0H35.588a.26.26,0,1,1,0-.519h10.27a.26.26,0,1,1,0,.519"
                transform="translate(-16.977 -124.29)"
                fill="#fff"
              />
              <path
                id="Path_9202"
                data-name="Path 9202"
                d="M989.835,259.158h-5.194a.26.26,0,0,1,0-.519h5.194a.26.26,0,1,1,0,.519"
                transform="translate(-473.05 -124.29)"
                fill="#fff"
              />
              <path
                id="Path_9203"
                data-name="Path 9203"
                d="M92.815,229.738H24.693a.9.9,0,1,1,0-1.806H92.815a.9.9,0,1,1,0,1.806"
                transform="translate(-11.432 -109.534)"
                fill="#d1c8d6"
              />
              <rect
                id="Rectangle_469"
                data-name="Rectangle 469"
                width="520"
                height="33.165"
                transform="translate(0 151.237)"
                fill="#ddd7e0"
              />
              <g
                id="Group_8437"
                data-name="Group 8437"
                transform="translate(32.82 171.498)"
                opacity="0.71"
                // style="mix-blend-mode: multiply;isolation: isolate"
              >
                <g id="Group_8436" data-name="Group 8436">
                  <g
                    id="Group_8435"
                    data-name="Group 8435"
                    clip-path="url(#clip-path-7)"
                  >
                    <path
                      id="Path_9204"
                      data-name="Path 9204"
                      d="M139.929,332.477c0,1.282-17.18,2.321-38.373,2.321s-38.372-1.039-38.372-2.321,17.18-2.322,38.372-2.322,38.373,1.04,38.373,2.322"
                      transform="translate(-63.184 -330.155)"
                      fill="#ddd7e0"
                    />
                  </g>
                </g>
              </g>
              <g
                id="Group_8440"
                data-name="Group 8440"
                transform="translate(370.724 170.978)"
                opacity="0.71"
                // style="mix-blend-mode: multiply;isolation: isolate"
              >
                <g id="Group_8439" data-name="Group 8439">
                  <g
                    id="Group_8438"
                    data-name="Group 8438"
                    clip-path="url(#clip-path-8)"
                  >
                    <path
                      id="Path_9205"
                      data-name="Path 9205"
                      d="M814.128,331.477c0,1.282-22.483,2.321-50.217,2.321s-50.217-1.039-50.217-2.321,22.483-2.322,50.217-2.322,50.217,1.04,50.217,2.322"
                      transform="translate(-713.693 -329.155)"
                      fill="#ddd7e0"
                    />
                  </g>
                </g>
              </g>
              <path
                id="Path_9206"
                data-name="Path 9206"
                d="M803.305,282.1H727.978l1.9-3.216h70.935Z"
                transform="translate(-349.834 -134.02)"
                fill="#fff"
              />
              <path
                id="Path_9207"
                data-name="Path 9207"
                d="M803.062,271.233h-68.82l1.736-3.215h64.808Z"
                transform="translate(-352.844 -128.798)"
                fill="#fff"
              />
              <path
                id="Path_9208"
                data-name="Path 9208"
                d="M802.858,260.363H739.477l1.6-3.215h59.685Z"
                transform="translate(-355.36 -123.574)"
                fill="#fff"
              />
              <path
                id="Path_9209"
                data-name="Path 9209"
                d="M802.7,249.681H743.645l1.489-3.216h55.609Z"
                transform="translate(-357.363 -118.44)"
                fill="#fff"
              />
              <path
                id="Path_9210"
                data-name="Path 9210"
                d="M802.508,239.418H748.484l1.362-3.216h50.874Z"
                transform="translate(-359.688 -113.508)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EmptyCartSvg1;
