import React, { useEffect, useState } from "react";
import LoyaltySvg from "../../assets/img/svgs/LoyaltySvg";
import {
  getCustomerInfoFromStroge,
  getCustomerLoyaltyPoints,
  // setCustomerLoyaltyPoints,
} from "../../pages/Home/utils";
import useCustomerInfo from "../../hooks/useCustomerInfo";
import { useParams } from "react-router-dom";
interface LoyaltyProp {
  message: string;
  showIcon: boolean;
  loyaltyPoints?: string | number;
  title?: string;
  wrapperStyles?: React.CSSProperties;
  makeApiCall?: boolean;
  setUserLoyaltyPoints: (points: number) => void;
}
// setUserLoyaltyPoints(res.data.points_amount)
const Loyalty: React.FC<LoyaltyProp> = (props: LoyaltyProp) => {
  const { getCustomerLoyaltyPoints, loading } = useCustomerInfo();
  const { bussinessName } = useParams();
  const [customerInfo, setCustomerInfo] = useState(getCustomerInfoFromStroge());
  const {
    message,
    showIcon,
    title,
    loyaltyPoints,
    makeApiCall,
    wrapperStyles,
    setUserLoyaltyPoints,
  } = props;
  useEffect(() => {
    if (customerInfo !== null && makeApiCall) {
      getCustomerLoyaltyPoints(customerInfo.id).then(
        ({ success, res }: any) => {
          console.log(success, res);
          if (success && res) {
            console.log(success, res);
            setUserLoyaltyPoints(res.data.points_amount);
            // setCustomerLoyaltyPoints(res.data.points_amount, bussinessName!);
          }
        }
      );
    }
  }, []);
  return (
    <div
      className="discountWrapper"
      style={{
        marginTop: 10,
        marginBottom: 10,
        paddingTop: 14,
        paddingBottom: 14,
        alignItems: "center",
        ...wrapperStyles,
      }}
    >
      <span style={{ margin: "0 4px" }}>{showIcon && <LoyaltySvg />}</span>
      <div>
        <div
          style={{ fontSize: "14px", color: "black", fontWeight: "600" }}
          className=""
        >
          <span>{title}</span>
        </div>

        <div style={{ fontSize: "12px", color: "black" }} className="">
          <span>{message}</span>
        </div>
      </div>
    </div>
  );
};

export default Loyalty;
