import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OtpRespone } from "../../../app/pages/Home/Components/Popups/CartPages/utils";
const initialState  = {}  as OtpRespone;
export const StoreSlice = createSlice({
  name: "otpInformation", 
  initialState,
  
  reducers: {
    setOtpResponse: (data, action: PayloadAction<OtpRespone>) => {
        console.log(action,data)
      return {
        ...data,
        ...action.payload,
      };
    },
  },
});
export const { setOtpResponse } = StoreSlice.actions;
export default StoreSlice.reducer;