import React from "react";
// import { ReactComponent as DoneSvg } from "../../../assets/images/done-icon.svg";
interface DoneSvgProps {
  height: number | string;
  width: number | string;
  wrapperHeigth: number | string;
  wrapperWidth: number | string;
  wrapperBgColor: string;
}
const DoneIconSvg: React.FC<DoneSvgProps> = (props: DoneSvgProps) => {
  const { height, width, wrapperWidth, wrapperHeigth, wrapperBgColor } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height={wrapperHeigth}
      width={wrapperWidth}
      // width="28"
      // height="28"

      viewBox="0 0 28 28"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_276"
            data-name="Rectangle 276"
            width="28"
            height="28"
            // fill={wrapperBgColor}
            // fill="#ffa600"
          />
        </clipPath>
      </defs>
      <g id="Group_8355" data-name="Group 8355" transform="translate(-80 -326)">
        <g id="Group_8337" data-name="Group 8337" transform="translate(80 326)">
          <g id="Group_8335" data-name="Group 8335" clip-path="url(#clip-path)">
            <path
              id="Path_9134"
              data-name="Path 9134"
              d="M0,14.875v-1.75c.061-.468.107-.938.186-1.4A13.776,13.776,0,0,1,4.033,4.187,13.72,13.72,0,0,1,11.589.215C12.1.126,12.613.071,13.125,0h1.75c.477.062.956.108,1.43.189a13.735,13.735,0,0,1,6.948,3.321,13.771,13.771,0,0,1,4.532,8.077c.091.509.144,1.025.215,1.537v1.75a1.53,1.53,0,0,0-.045.211,13.359,13.359,0,0,1-1.939,6.077,13.839,13.839,0,0,1-9.605,6.623c-.507.1-1.023.144-1.535.214h-1.75c-.342-.042-.684-.079-1.024-.128A14.056,14.056,0,0,1,.218,16.439C.124,15.921.072,15.4,0,14.875m12.674,1.336c-.113-.136-.181-.234-.264-.318Q11.1,14.577,9.784,13.264a1.255,1.255,0,0,0-.833-.406,1.169,1.169,0,0,0-1.09.668,1.123,1.123,0,0,0,.272,1.32q1.85,1.86,3.712,3.707a1.275,1.275,0,0,0,.447.3,1.208,1.208,0,0,0,1.345-.388q3.024-3.042,6.063-6.07c.489-.49.986-.973,1.452-1.483A1.088,1.088,0,0,0,21.1,9.435a1.148,1.148,0,0,0-1.709.031q-2.92,2.915-5.835,5.835c-.282.283-.557.573-.885.911"
              // fill="#ffa600"
              fill={wrapperBgColor}
            />
          </g>
        </g>
        <g
          id="Ellipse_49"
          data-name="Ellipse 49"
          transform="translate(80 326)"
          fill="none"
          // stroke="#ffa600"
          stroke={wrapperBgColor}
          stroke-width="1"
        >
          <circle cx="14" cy="14" r="14" stroke="none" />
          <circle cx="14" cy="14" r="13.5" fill="none" />
        </g>
      </g>
    </svg>

    // <div
    //   className="tick-svg-wrapper"
    //   style={{
    //     height: wrapperHeigth,
    //     width: wrapperWidth,
    //     backgroundColor: wrapperBgColor ? wrapperBgColor : "#4bae4f",
    //     // backgroundColor: wrapperBgColor,
    //   }}
    // >
    //   <svg
    //     style={{ paddingRight: 4, paddingTop: 1, paddingLeft: 4 }}
    //     xmlns="http://www.w3.org/2000/svg"
    //     width={width}
    //     height={height}
    //     fill="#ffffff"
    //     className="bi bi-check2"
    //     viewBox="0 0 16 16"
    //   >
    //     <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
    //   </svg>
    // </div>
  );
};

export default DoneIconSvg;
