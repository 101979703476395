import React from "react";
import { homeStyles } from "../../styles";
import { SelectedOulet, StoreData } from "../../utils";
interface Props {
  useData: StoreData | undefined;
  selectedLanguageCode: any;
  selectedOutlet: SelectedOulet;
  showPickup?: boolean;
}
const BlockBussinessName: React.FC<Props> = (props: Props) => {
  const { useData, selectedOutlet, selectedLanguageCode, showPickup } = props;
  return (
    <div className="body_warp" style={{ marginLeft: 22, marginRight: 22 }}>
      <div className="">
        <div className="">
          <div
            className="bussiness-name"
            style={{
              marginTop: 12,
              fontSize: 22,
            }}
          >
            {useData?.name[selectedLanguageCode]}
          </div>
          <p
            className=" res_font_size1 margin-top-five"
            style={{ fontSize: 12 }}
          >
            {useData?.short_description[selectedLanguageCode]}
          </p>
          {!showPickup && Object.keys(selectedOutlet).length > 0 && (
            <p
              className="light_grey_text res_font_size1 margin-top-five"
              style={homeStyles.outletAddress}
            >
              {selectedOutlet?.outlet_address[selectedLanguageCode]}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlockBussinessName;
