import axios from "axios";

export const baseURL = "https://orderapi.dukany.io/api/"; //new baseurl for meemorder

//@ts-ignore
// export const baseURL = window.PROD_API_URL
//@ts-ignore
// export const baseURL = 'https://devsrvorderapi.dukany.io/api/';//dev server

export const instance = axios.create({
  baseURL,
  timeout: 100000,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default instance;
