import { useTranslation } from "react-i18next";
import React from "react";
interface ReceiverAlertProp {
  onPressClose: (action: string) => void;
  titleString: string;
  errorMessage: string;
  alertType: string;
}
const ReceiverAlert: React.FC<ReceiverAlertProp> = (props) => {
  const buttonStyle: React.CSSProperties = {
    fontSize: 14,
    cursor: "pointer",
    marginRight: 15,
    borderRadius: 5,
    marginBottom: 8,
    padding: "10px 12rem",
    color: "white",
  };
  const { onPressClose, errorMessage, alertType, titleString } = props;
  const { t } = useTranslation();
  const closeModal = (action: string) => {
    onPressClose(action);
  };
  return (
    <div
      id="ErroModal"
      style={{ background: "rgba(0, 0, 0, 0.5)" }}
      className="pop_up_maps_main pop_main_menu_bg alert-enter alert-enter-active"
    >
      <div
        // style={{ bottom: "auto", top: 0 }}
        className="popup_delivery popup_from_bottom pop_time_set_height "
      >
        <div className="content" id="">
          <div className="container">
            <div className="container">
              <div
                style={{
                  borderBottom: 0,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                className="head_burg_menu"
              >
                <div
                  className="calender_close"
                  style={
                    {
                      // paddingTop: 10,
                      // position: "absolute",
                      // bottom: "35%",
                      // right: "45%",
                    }
                  }
                >
                  <a
                    onClick={() => closeModal("close")}
                    className="slide_remove"
                    //  href="#Accordion_tabs"
                  >
                    &times;
                  </a>
                </div>
                <div className="calender_header"></div>
                {alertType === "ConfirmCOD" && (
                  <div className="delivery_head">{titleString}</div>
                )}
                <div
                  className={`${
                    alertType !== "ConfirmCOD" ? "delivery_head" : ""
                  }`}
                  style={{
                    color:
                      alertType === "ConfirmCOD" ? "rgb(104, 104, 104" : "",
                  }}
                >
                  {errorMessage}
                </div>
                <div className="delivery_time_text">{""}</div>
                {alertType !== "ConfirmCOD" ? (
                  <div
                    style={{
                      marginTop: 15,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      className="background-color-trash"
                      style={buttonStyle}
                      onClick={() => closeModal("Yes")}
                    >
                      {t("yes")}
                    </span>
                    <span
                      onClick={() => closeModal("Skip")}
                      className="border-color text-color-store "
                      // className="background-color-trash"
                      style={{ ...buttonStyle }}
                    >
                      {t("skip")}
                    </span>
                  </div>
                ) : (
                  <div style={{ marginTop: 15 }}>
                    <span
                      className="background-color-trash"
                      style={{
                        ...buttonStyle,
                        padding: "14px 12rem",
                        marginRight: 0,
                        fontWeight: "bolder",
                      }}
                      onClick={() => closeModal("ConfirmCOD")}
                    >
                      {t("confirm")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <br />
          <br />
          {/* <br />
          <br /> */}
        </div>
        {/* <BottomButton onPress={closeModal} titleProp="Change Location" /> */}
      </div>
    </div>
  );
};

export default ReceiverAlert;
