/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "../../styles.css";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useTranslation } from "react-i18next";
import GoogleMapsBox from "../../../../shared/components/GoogleMapsBox";
import { useAppSelector } from "../../../../hooks/useRedux";

interface PopDeliveryProps {
  onPressClose: () => void;
  onSelectedOutlet: (selectedOutlet: any) => void;
  restoData: any;
}
const PopDelivery: React.FC<PopDeliveryProps> = (props: PopDeliveryProps) => {
  const shopInfo = useAppSelector((state) => state.shopInfo);
  const { onPressClose, restoData, onSelectedOutlet } = props;
  const [center, setCenter] = useState<google.maps.LatLngLiteral>();
  const { t } = useTranslation();
  const [showGoogleMap, setShowGoogleMap] = useState(false);
  const openMapWithCL = () => {
    setCenter(undefined);
    setShowGoogleMap(true);
  };
  const closeModalWithAnimation = () => {
    onPressClose();
  };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      //  region:shopInfo.country_short_name,
      componentRestrictions: { country: shopInfo.country_short_name },
    },
    debounce: 300,
  });
  // const ref = useOnclickOutside(() => {
  //   // When user clicks outside of the component, we can dismiss
  //   // the searched suggestions by calling this method
  //   clearSuggestions();
  // });

  const handleInput = (e: any) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };
  const onLocationFoundFunc = (selectedOutlet: any) => {
    onPressClose();
    onSelectedOutlet(selectedOutlet);
  };
  const handleSelect =
    ({ description }: any) =>
    async () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      var newLatLng = {} as google.maps.LatLngLiteral;
      await getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        console.log("📍 Coordinates: ", { lat, lng });
        newLatLng = { lat: lat, lng: lng };
      });
      setCenter(newLatLng);
      setShowGoogleMap(true);
    };
  const renderSuggestions = () =>
    data.map((suggestion, index) => {
      const {
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div
          key={index}
          className="delivery_searched_items_box"
          id="delivery_searched_items_box"
          onClick={handleSelect(suggestion)}
        >
          <div className="disp_flex">
            <div className="delivery_searched_loaction">
              <div className="glyphicon glyphicon-map-marker bordered_map"></div>
              {/* <div className="searched_loaction_text_foot">93 km</div> */}
            </div>
            <div className="delivery_searched_loaction_text">
              <div className="searched_loaction_text_head">{main_text}</div>
              <div className="searched_loaction_text_foot">
                {secondary_text}
              </div>
            </div>
          </div>
          <hr></hr>
        </div>
      );
    });
  React.useEffect(() => {
    window.onpopstate = (e) => {
      onPressClose();
    };
  }, []);
  // useEffect(() => {
  //   // @ts-ignore
  //   inputElement.current.onfocus = () => {
  //     window.scrollTo(0, 0);
  //     document.body.scrollTop = 0;
  //   };
  // });

  // useEffect(() => {
  //   function forceScrollTop() {
  //     var scrollTop = $(window).scrollTop();
  //     if (scrollTop != 0) {
  //       $(window).scrollTop(0);
  //       $("selector").css("opacity", 1);
  //       $(window).off("scroll", forceScrollTop);
  //     }
  //   }
  //   // when an input is focused ...
  //   $("selector").css("opacity", 0);
  //   $(window).on("scroll", forceScrollTop);
  // }, []);
  return (
    <div id="pop_delivery" className="pop_up_maps_main pop_main_menu_bg">
      <div className="popup_delivery popup_from_bottom popup_slide">
        <div className="content" id="">
          <br />
          <div className="container">
            <div className="container">
              <div className="delivery_head">{t("select_location")}</div>
              <div className="delivery_close">
                <a className="slide_remove" onClick={closeModalWithAnimation}>
                  &times;
                </a>
              </div>
            </div>
            <div className="container">
              <div className="delivery_content">
                <div className="delivery_search">
                  <div className="form-group has-feedback has-search">
                    <span className="glyphicon glyphicon-search form-control-feedback"></span>

                    <div>
                      <input
                        // ref={inputElement}
                        id="pac-input"
                        className="controls delivery_search_input form-control"
                        value={value}
                        autoComplete="off"
                        onChange={(e) => handleInput(e)}
                        disabled={!ready}
                        type="text"
                        placeholder={t("select_map_location")}
                      />
                    </div>
                  </div>
                </div>
                <div className="delivery_search">
                  <div className="form_group" onClick={openMapWithCL}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="delivery_current_loaction">
                        <img
                          height={14}
                          width={14}
                          style={{
                            objectFit: "contain",
                          }}
                          src={
                            "https://dukany.io/public/assets/img/locationicon-1.svg"
                          }
                        />
                        {/* <div className="current_location_hor"></div>
                        <div className="current_location_ver"></div>
                        <div className="current_location_circle"></div> */}
                      </div>
                      <div className="delivery_current_loaction_text">
                        <div className="current_loaction_text_head">
                          {/* {t("search_box_text")} */}
                          {t("user_your_location")}
                        </div>
                      </div>
                    </div>
                    <div className="delivery_current_loaction_arow">
                      {/* Should be replace with button */}
                      <button
                        onClick={openMapWithCL}
                        style={{
                          background: "none",
                          color: "inherit",
                          border: "none",
                          padding: 0,
                          font: "inherit",
                          cursor: "pointer",
                          outline: "inherit",
                        }}
                      >
                        <span className="glyphicon glyphicon-menu-right"></span>
                      </button>
                    </div>
                  </div>
                </div>
                <hr></hr>

                {status === "OK" && (
                  <div className="google-map-box">{renderSuggestions()}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <PopGoogleMap visible={showGoogleMap} /> */}
      {showGoogleMap ? (
        <GoogleMapsBox
          onlocationFound={(selectedOutlet: any) =>
            // addressString: string,
            // foundOutLet: any,
            // deliveryZonesData: any,
            // lat_lng: any
            {
              onLocationFoundFunc(selectedOutlet);

              // console.log(deliveryZonesData);
              // onPressClose();
              // onChangeLocation(
              //   addressString,
              //   foundOutLet,
              //   deliveryZonesData,
              //   lat_lng
              // );
            }
          }
          onPressClose={() => {
            setShowGoogleMap(false);
            setValue("");
          }}
          selectedLatLng={center}
          restoData={restoData}
          // deliveryZonesInfo={restoData.delivery_zones}
        />
      ) : (
        // <PopGoogleMap
        //   onlocationFound={(
        //     addressString: string,
        //     foundOutLet: any,
        //     deliveryZonesData: any,
        //     lat_lng: any
        //   ) => {
        //     // console.log(deliveryZonesData);
        //     onPressClose();
        //     onChangeLocation(
        //       addressString,
        //       foundOutLet,
        //       deliveryZonesData,
        //       lat_lng
        //     );
        //   }}
        //   onPressClose={() => setShowGoogleMap(false)}
        //   selectedLatLng={center}
        //   restoData={restoData}
        //   deliveryZonesInfo={restoData.delivery_zones}
        // />
        <></>
      )}
    </div>
  );
};

export default PopDelivery;
