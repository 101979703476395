/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../hooks/useRedux";
import { useParams } from "react-router-dom";
import useItemDetail from "../../../../hooks/useItemDetails";
import { Zoom, Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { useTranslation } from "react-i18next";
import HandSvg from "../../../../assets/img/svgs/HandSvg";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import SelectAddressModal from "../../../../shared/components/SelectAddressModal";
import {
  BUSSINESSTYPEOBJ,
  checkDiscountOnSelectedItem,
  DiscountAvailable,
  getCustomerInfoFromStroge,
  getSelectedLanguage,
  getShopDataFromStrg,
  imagePlaceHolder,
  numberWithCommas,
  ObjectKeyLng,
  SelectedOulet,
  SingleItem,
  SingleItemExtras,
} from "../../utils";
import { Swiper, SwiperSlide } from "swiper/react";
import PopDelivery from "./PopDelivery";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/zoom";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import IconPlus from "../../../../shared/components/IconPlus";
import IconMinus from "../../../../shared/components/IconMinus";
import Loader from "../../../../modules/Loader";
import { appSharedColors } from "../../../../shared/styles/reactStyles";
import BottomButton from "../../../../shared/components/BottomButton";
import { AddressInterface, discountedValue } from "./CartPages/utils";
import { isEmptyObject } from "jquery";
import DeleteIcon from "../../../../assets/img/svgs/DeleteIcon";
import DisplayMessage from "../../../../shared/components/DisplayMessage";
import Seperator from "../../../../shared/components/Seperator";
import Accordion from "../../../../shared/components/Accordion/Accordion";
interface ProductDetailProps {
  onPressClose: () => void;
  selectedRecipe: any;
  hideAddressModal: (
    receiver: AddressInterface | undefined,
    buttonPress: string | undefined
  ) => void;
  outletName: string;
  selectedOutlet: SelectedOulet;
  currentLanguage: string;
  restoDetails: any;
  userToken: any;
  showPreOrder: boolean;
  onChangeOutlet: (selected: any, showTimeModal: boolean) => void;
  isLocationSelectedFlag: any;
  isDiscountAvailable: DiscountAvailable;
}
type CurrentParentArray = {
  id: number;
  currentCount: number;
};
const ProductDetail: React.FC<ProductDetailProps> = (
  props: ProductDetailProps
) => {
  const {
    onPressClose,
    selectedRecipe,
    showPreOrder,
    outletName,
    hideAddressModal,
    selectedOutlet,
    restoDetails,
    userToken,
    onChangeOutlet,
    isDiscountAvailable,
    isLocationSelectedFlag,
  } = props;
  const { bussinessName } = useParams();
  const [value, setValue] = useState("1");
  const [customPrize, setCustomPrize] = useState(0);
  const errorRef = useRef(null);
  const { t } = useTranslation();
  const { getItemDetailByid, itemDetail, loading } = useItemDetail();
  const [selectedItemPrice, setSelectedItemPrice] = useState<number>(0);
  const [showButton, setShowButton] = useState<boolean>(true);
  const [selectedItemName, setSelectedName] = useState<string>("");
  const [showUserAddress, setShowUserAddress] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const valuesFromStorage = getShopDataFromStrg("/" + bussinessName!);
  let parseLocalData: any = valuesFromStorage;
  const selectedLanguageCode: ObjectKeyLng = getSelectedLanguage(
    bussinessName!
  );
  const shopInfo = useAppSelector((state) => state.shopInfo);
  const [multipleOption, setMulitipleOption] = useState<any>([]);
  const prdSelectedOptionsArr = useRef([
    { id: 0, currentCount: 0, childId: 0 },
  ]);
  const mandotaryItemsArray = useRef([{ id: 0, maxCount: 0 }]);
  const [selectedColor, setSelectedColor] = useState<string>("");
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>("");
  const [selectedSize, setSelectedSize] = useState<string>("");
  var cutomerInfo = getCustomerInfoFromStroge();
  const [prevCustomPrice, setPreviousCustomPrice] = useState<number>(0);
  const [showHandErro, setShowHandError] = useState<boolean>(false);
  const [showSizeError, setShowSizeError] = useState<boolean>(false);
  const isWholeOrder =
    shopInfo?.discounts !== null ? shopInfo?.discounts.is_whole_order : "No";
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [showDelivery, setShowDelivery] = useState(false);

  const CCY = restoDetails?.resto_meta.BUSSINESS_CCY_LANG[selectedLanguageCode];
  // const [isValidDiscount] = useState<boolean>(
  //   isDiscountAvailable.available &&
  //     isDiscountAvailable.discountType !== "whole_order_discounts"
  // );
  const [isValidDiscount, setIsValidDiscount] = useState<boolean>(false);
  const checkPrdAvailable = useCallback(() => {
    if (itemDetail) {
      if (itemDetail?.is_available == "No") {
        return false;
      }
      if (itemDetail?.is_available == "Yes") {
        // if (
        //   selectedOutlet.unavailable_products.includes(
        //     itemDetail.shared_unique_id
        //   )
        // ) {
        //   return false;
        // }
        // if (selectedOutlet.out_of_stock.includes(itemDetail.shared_unique_id)) {
        //   return false;
        // }
        return true;
      }
    }
    return true;
  }, [itemDetail]);

  const [isAvailable, setIsAvailable] = useState<boolean>(true);

  const checkColorSize = () => {
    var newObj = {};
    if (
      isEmptyObject(itemDetail?.colors) &&
      isEmptyObject(itemDetail?.color_image) &&
      !Array.isArray(itemDetail?.sizes)
    ) {
      return null;
    } else {
      newObj = {
        color: selectedColor,
        size: selectedSize,
        img_url: selectedImageUrl !== "" ? selectedImageUrl : undefined,
      };
      return newObj;
    }
  };
  const getDiscountPrice = (actualPrice: any, getDiscountOnly?: boolean) => {
    if (isValidDiscount) {
      var res = discountedValue(
        actualPrice,
        //@ts-ignore
        shopInfo[isDiscountAvailable.discountType]?.discount_type,
        //@ts-ignore
        shopInfo[isDiscountAvailable.discountType]?.amount,
        //@ts-ignore
        shopInfo[isDiscountAvailable.discountType],
        getDiscountOnly
      );

      return res;
    } else {
      return undefined;
    }
  };
  const checkIsAllItemDiscountOrNot = () => {
    if (isValidDiscount && isDiscountAvailable.available) {
      return "Yes";
    }
    if (!isValidDiscount && isDiscountAvailable.available) {
      return "No";
    }
    if (!isDiscountAvailable.available) {
      return "No";
    }
    return "No";
  };
  const addToBasket = async () => {
    // var actual_price =
    //   itemDetail?.actual_price !== "0" ? itemDetail?.price : selectedItemPrice;
    var actual_price =
      itemDetail?.actual_price !== "0" ? itemDetail?.price : selectedItemPrice;
    let shopValues;
    var numberOfItems = value !== "" ? value : "1";
    var totalDiscountOnItem = getDiscountPrice(actual_price, true);
    var finalDiscountOnItem = totalDiscountOnItem
      ? (parseFloat(totalDiscountOnItem) * parseInt(numberOfItems)).toFixed(2)
      : undefined;

    let cart = {
      actual_price: actual_price,
      final_item_price: customPrize / parseInt(numberOfItems),
      // is_discount: isValidDiscount ? "Yes" : "No",
      is_discount: checkIsAllItemDiscountOrNot(),
      is_for_whole_order: isWholeOrder,
      id: itemDetail?.shared_unique_id,
      price: parseFloat(customPrize.toFixed(2)),
      quantity: numberOfItems,
      name: itemDetail?.name,
      single_item_name: selectedItemName,
      discounted_amount: finalDiscountOnItem,
      //@ts-ignore
      discount_amount: shopInfo[isDiscountAvailable.discountType]?.amount,

      discount_id:
        //@ts-ignore
        shopInfo[isDiscountAvailable.discountType]?.id,
      discount_type: isDiscountAvailable.available
        ? //@ts-ignore
          shopInfo[isDiscountAvailable.discountType]?.discount_type ===
          "percentage"
          ? "%"
          : "fixed"
        : undefined,
      single_item_price: selectedItemPrice,
      // single_options_id: selectedItemId,
      single_options: selectedItemId,
      multiple_option: multipleOption,
      description: itemDetail?.description,
      color_size: checkColorSize(),
      affiliation: parseLocalData.campainType,
      item_brand: bussinessName,
      item_category: "",
      item_category2: "",
      item_category3: "",
      item_category4: "",
      item_category5: "",
    };
    var newArray = [];
    newArray.push(cart);
    if (parseLocalData?.cart !== undefined) {
      shopValues = {
        ...parseLocalData,
        cart: [...newArray, ...parseLocalData?.cart],
      };
    } else {
      shopValues = {
        ...parseLocalData,
        cart: newArray,
      };
    }

    localStorage.setItem("shop" + outletName, JSON.stringify(shopValues));
    onPressClose();
  };
  const chooseSizeHandler = (item: string) => {
    setSelectedSize(item);
    setShowSizeError(false);
  };
  const checkDiscountOnSelectedItemHandler = (itemId: string) => {
    var isSelectedItem = false;
    var checkDisOnItems = checkDiscountOnSelectedItem(
      restoDetails[isDiscountAvailable.discountType]
    );

    // all_items

    if (isDiscountAvailable.available && checkDisOnItems === "selected_items") {
      var selectedItemsDiscounts =
        restoDetails[isDiscountAvailable.discountType]?.discount_item;

      selectedItemsDiscounts.forEach((element: any) => {
        if (element === itemId) {
          isSelectedItem = true;

          return;
        }
      });
      setIsValidDiscount(isSelectedItem);
      // return isSelectedItem;
    }
    /**
    if (isDiscountAvailable.available && checkDisOnItems === "all_items") {
      isSelectedItem = true;
      setIsValidDiscount(isSelectedItem);
      return;
    }
     */
  };
  const onSelectedOutletHandler = (selectedOutlet: SelectedOulet) => {
    onChangeOutlet(selectedOutlet, true);
  };
  const addExtraOption = (
    itemA: any,
    action: string,
    parentItem: SingleItemExtras
  ) => {
    // setShowError(false);
    const maxAmount = parentItem.mandatory_amount ?? 0;
    const isMandotary = parentItem.is_mandatory;
    var currentElementLength = 0;
    const existingItem = prdSelectedOptionsArr.current.find(
      (item) => item.id === parentItem.id
    );
    // multipleOption.length > 0
    //   ? multipleOption.find((item: any) => item.id === itemA.id)?.length
    //   : 0;
    if (multipleOption.length === 0) {
      currentElementLength = 0;
    }
    if (multipleOption.length > 0) {
      currentElementLength =
        multipleOption.find((item: any) => item.id === itemA.id)?.length || 0;
    }
    console.log(multipleOption);
    console.log(
      "maxAmount: ",
      maxAmount,
      "currentElementLength: ",
      currentElementLength
    );

    if (action === "add") {
      if (isMandotary === "Yes") {
        if (!existingItem) {
          prdSelectedOptionsArr.current.push({
            id: parentItem.id,
            currentCount: 1,
            childId: itemA.id,
          });

          setMulitipleOption((prev: any) => [
            ...prev,
            { ...itemA, parent_name: parentItem.name_lang },
          ]);
          setCustomPrize((prev) => prev + itemA.actual_price);
          setPreviousCustomPrice((prev) => prev + itemA.actual_price);
        }
        if (existingItem && existingItem.currentCount < maxAmount) {
          prdSelectedOptionsArr.current.push({
            id: parentItem.id,
            currentCount: (existingItem.currentCount += 1),
            childId: itemA.id,
          });
          setMulitipleOption((prev: any) => [
            ...prev,
            { ...itemA, parent_name: parentItem.name_lang },
          ]);
          setCustomPrize((prev) => prev + itemA.actual_price);
          setPreviousCustomPrice((prev) => prev + itemA.actual_price);
        }

        return;
      }
      if (isMandotary === "No") {
        setMulitipleOption((prev: any) => [
          ...prev,
          { ...itemA, parent_name: parentItem.name_lang },
        ]);
        setCustomPrize((prev) => prev + itemA.actual_price);
        setPreviousCustomPrice((prev) => prev + itemA.actual_price);
        return;
      }
    } else {
      if (existingItem) {
        prdSelectedOptionsArr.current = prdSelectedOptionsArr.current.filter(
          (item) => item.id != parentItem.id
        );
      }
      setCustomPrize(
        (prev) =>
          prev -
          itemA.actual_price * checkMultipleOptionArr(itemA.id).totalNumber
      );
      setPreviousCustomPrice(
        (prev) =>
          prev -
          itemA.actual_price * checkMultipleOptionArr(itemA.id).totalNumber
      );
      setMulitipleOption((prev: any) =>
        prev.filter((value: any) => value.id !== itemA.id)
      );
    }
  };
  const onChangeInputPrice = (
    itemPrice: any,
    itemId: number,
    itemName: string,
    parentItem: SingleItemExtras,
    childItem: any
  ) => {
    var checkDiscountedPrice = parseFloat(
      getDisValueDisplay(itemPrice).toFixed(2)
    );
    setShowError(false);
    setSelectedItemPrice(itemPrice);

    if (itemDetail?.actual_price === "0") {
      setCustomPrize(checkDiscountedPrice * parseInt(value));
    } else {
      setCustomPrize(prevCustomPrice + checkDiscountedPrice);
    }
    if (
      mandotaryItemsArray.current.length === 1 &&
      itemDetail?.is_customized === "Yes"
    ) {
      prdSelectedOptionsArr.current = prdSelectedOptionsArr.current = [
        {
          id: parentItem.id,
          currentCount: 1,
          childId: itemId,
        },
      ];
      setMulitipleOption((prev: any) => [
        { ...childItem, parent_name: parentItem.name_lang },
      ]);
      // setMulitipleOption((prev: any) => [...prev, childItem]);
      return;
    }
    if (mandotaryItemsArray.current.length === 2) {
      prdSelectedOptionsArr.current = prdSelectedOptionsArr.current = [
        {
          id: parentItem.id,
          currentCount: 1,
          childId: itemId,
        },
      ];
      setMulitipleOption((prev: any) => [
        { ...childItem, parent_name: parentItem.name_lang },
      ]);
      // setMulitipleOption((prev: any) => [...prev, childItem]);
      return;
    }
    if (mandotaryItemsArray.current.length > 2) {
      const isPrevExist = prdSelectedOptionsArr.current.some(
        (prev) => prev.id === parentItem.id
      );

      if (isPrevExist) {
        // Find the existing entry for the parent in `prdSelectedOptionsArr`
        const existingEntry = prdSelectedOptionsArr.current.find(
          (prev) => prev.id === parentItem.id
        );

        // Remove the corresponding child from `multipleOption`
        setMulitipleOption((prev: any) =>
          prev.filter((option: any) => option.id !== existingEntry?.childId)
        );

        // Remove the previous entry for the parent from `prdSelectedOptionsArr`
        prdSelectedOptionsArr.current = prdSelectedOptionsArr.current.filter(
          (prev) => prev.id !== parentItem.id
        );
      }

      // Add the new parent and child information
      prdSelectedOptionsArr.current.push({
        id: parentItem.id,
        currentCount: 1,
        childId: itemId,
      });

      // Add the new child item to `multipleOption`
      setMulitipleOption((prev: any) => [
        ...prev,
        { ...childItem, parent_name: parentItem.name_lang },
      ]);

      return;
    }
  };
  const RenderImageWitZoom = ({ imageUrl }: any) => {
    return (
      <TransformWrapper
        initialScale={1}
        panning={{
          disabled:
            restoDetails?.resto_meta?.PRORUDCT_DETAILS_IMG_ZOOM === "Yes"
              ? false
              : true,
        }}
        maxScale={
          restoDetails?.resto_meta?.PRORUDCT_DETAILS_IMG_ZOOM === "Yes"
            ? 1.5
            : 1
        }
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <TransformComponent>
              <div
                className="backkground-image-style product_main_img_heigth "
                style={{
                  backgroundImage: `url(${
                    imageUrl !== null ? imageUrl : imagePlaceHolder
                  })`,
                }}
              />
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    );
  };

  const checkMultipleOptionArr = (checkId: any) => {
    var totalNumber = 0;
    var tempArr = [];
    var displayButton = false;
    multipleOption.forEach((element: any) => {
      if (element.id === checkId) {
        displayButton = true;
        tempArr.push(element);
      }
    });
    totalNumber = tempArr.length;
    return { displayButton, totalNumber };
  };

  // Function to validate mandatoryItems
  function validateItems(mandatoryItems: any, selectedItems: any) {
    var isValidate = true;
    // Reduce mandatoryItems to combine duplicate entries by ID
    console.log("selectedItems: ", selectedItems);
    console.log("mandatoryItems: ", mandatoryItems);

    var combinedMandatoryItems = mandatoryItems.reduce(
      (acc: any, item: any) => {
        // Check if the ID already exists in the accumulator
        const exists = acc.some((m: any) => m.id === item.id);
        if (!exists) {
          acc.push(item); // Add item if it's not a duplicate
        }
        return acc;
      },
      []
    );
    combinedMandatoryItems = combinedMandatoryItems.filter(
      (item: any) => item.id !== 0
    );
    console.log(combinedMandatoryItems);
    // Validate each mandatory item
    combinedMandatoryItems.forEach((mandatoryItem: any) => {
      // Find corresponding selectedItem by ID
      const selectedItem = selectedItems.find(
        (item: any) => item.id === mandatoryItem.id
      );

      if (!selectedItem) {
        isValidate = false;
        return;
        // return isisValidate;
        // throw new Error(
        //   `Mandatory item with id ${mandatoryItem.id} is missing in selectedItems`
        // );
      }

      // Check if currentCount equals maxCount
      if (selectedItem.currentCount !== mandatoryItem.maxCount) {
        console.log("selectedItem.currentCount: ", selectedItem.currentCount);
        console.log("mandatoryItem.maxCount: ", mandatoryItem.maxCount);
        isValidate = false;
        // throw new Error(
        //   `Item with id ${mandatoryItem.id} has currentCount ${selectedItem.currentCount} but requires maxCount ${mandatoryItem.maxCount}`
        // );
        return;
      }
    });
    return isValidate;
    // console.log("Validation passed! All mandatory items are valid.");
  }

  const onPressButton = () => {
    if (itemDetail?.is_customized === "Yes") {
      if (
        validateItems(
          mandotaryItemsArray.current,
          prdSelectedOptionsArr.current
        )
      ) {
        addToBasket();
      } else {
        setShowError(true);
        //@ts-ignore
        errorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    } else {
      if (
        restoDetails?.resto_meta?.BUSSINESS_TYPE ===
          BUSSINESSTYPEOBJ.clothesStore &&
        selectedColor === "" &&
        (itemDetail?.colors !== null || itemDetail?.color_image !== null)
      ) {
        setShowError(true);

        // @ts-ignore
        errorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } else if (
        restoDetails?.resto_meta?.BUSSINESS_TYPE ===
          BUSSINESSTYPEOBJ.clothesStore &&
        itemDetail?.sizes !== undefined &&
        selectedSize === ""
      ) {
        setShowSizeError(true);
        // @ts-ignore
        errorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } else {
        addToBasket();
      }
      // alert(
      //   "Will add basket logic in coming day for now it is working for values only"
      // );
    }
  };
  const checkLocationSelected = () => {
    var element = document.getElementById("pd_cart_foot_button");
    var elementSelectLocation = document.getElementById(
      "pd_button_select_location"
    );
    if (!isLocationSelectedFlag && element !== null) {
      element.style.zIndex = "0";
      elementSelectLocation!.style.zIndex = "0";
      setShowHandError(true);
    }
  };
  const showLocationModal = () => {
    if (itemDetail?.is_available === "Yes") {
      setShowHandError(false);

      if (cutomerInfo === null) {
        setShowDelivery(true);
      } else {
        setShowUserAddress(true);
      }
    }
  };
  const chooseColorHandler = (item: string, img_url?: string | undefined) => {
    if (img_url) {
      setSelectedImageUrl(img_url);
    }
    setSelectedColor(item);
    setShowError(false);
  };
  const addManuallyHandler = (event: any, value: string) => {
    console.log(event, value);
    if (value !== "") {
      setValue(value);
      if (customPrize !== 0) {
        // setCustomPrize(selectedItemPrice * (parseInt(value) + 1));
        if (value !== "0") {
          setCustomPrize(selectedItemPrice * parseInt(value));
          setPreviousCustomPrice(selectedItemPrice * parseInt(value));
        }
      }
    } else {
      setValue("");
      setCustomPrize(selectedItemPrice);
    }
  };
  const numberOfItems = (type: string) => {
    var singlePrice = customPrize / parseInt(value);
    switch (type) {
      case "add":
        setValue((parseInt(value) + 1).toString());
        if (itemDetail?.actual_price && customPrize !== 0) {
          var getDiscountedPrice = getDisValueDisplay(singlePrice.toString());
          // if (itemDetail?.actual_price !== "0") {
          setCustomPrize((prev) => prev + getDiscountedPrice);
          setPreviousCustomPrice((prev) => prev + getDiscountedPrice);
          // } else {
          //   setCustomPrize((prev) => prev + selectedItemPrice);
          //   setPreviousCustomPrice((prev) => prev + selectedItemPrice);
          // }
        }
        break;
      case "sub":
        parseInt(value) > 1 && setValue((parseInt(value) - 1).toString());
        if (
          itemDetail?.actual_price &&
          customPrize !== 0 &&
          parseInt(value) > 1
        ) {
          // if (itemDetail?.actual_price !== "0") {
          setPreviousCustomPrice((prev) => prev - singlePrice);
          setCustomPrize((prev) => prev - singlePrice);

          // } else {
          //   setCustomPrize((prev) => prev - selectedItemPrice);
          //   setPreviousCustomPrice((prev) => prev - selectedItemPrice);
          // }
        }
        break;
      default:
        break;
    }
  };
  const getDisValueDisplay = (actualPrice: string) => {
    var discoutedPrice = getDiscountPrice(actualPrice);
    if (discoutedPrice !== undefined) {
      var newPrice = parseFloat(discoutedPrice).toFixed(2);
      return parseFloat(newPrice);
    } else {
      return parseFloat(actualPrice);
    }
  };
  useEffect(() => {
    getItemDetailByid(selectedRecipe, userToken, outletName);
  }, []);
  useEffect(() => {
    if (itemDetail) {
      setIsAvailable(checkPrdAvailable());
      checkDiscountOnSelectedItemHandler(itemDetail?.shared_unique_id);
      if (itemDetail.extra_options) {
        Object.keys(itemDetail?.extra_options).forEach((key, index) => {
          var objectItem = itemDetail?.extra_options[key];
          if (objectItem.is_mandatory === "Yes") {
            if (mandotaryItemsArray.current[index].id !== objectItem.id)
              mandotaryItemsArray.current.push({
                id: objectItem.id,
                maxCount: objectItem.mandatory_amount,
              });
          }
        });
      }
    }
  }, [itemDetail]);
  useEffect(() => {
    if (itemDetail !== undefined) {
      var discoutedPrice = getDisValueDisplay(itemDetail?.price);
      // console.log(discoutedPrice);
      // setCustomPrize(parseInt(itemDetail?.price));
      setCustomPrize(discoutedPrice);
      // setPreviousCustomPrice(parseInt(itemDetail?.price));
      setPreviousCustomPrice(discoutedPrice);
      if (itemDetail.price !== "0") {
        setSelectedItemPrice(parseInt(itemDetail?.price));
      }
    }
  }, [itemDetail, isValidDiscount]);

  useEffect(() => {
    window.onpopstate = (e) => {
      e.preventDefault();
      onPressClose();
      //your code...
    };
  }, []);

  return (
    <div
      id="pop_prod_detail"
      className="productDetail pop_up_maps_main pop_main_menu_bg "
    >
      {showButton && (
        <a
          onClick={() => {
            onPressClose();
            setShowButton(false);
          }}
          className="close_popup   glyphicon glyphicon-remove"
        ></a>
      )}
      {!loading ? (
        <div className=" popup  popup_slide">
          <div className="content" id="">
            {itemDetail !== undefined && itemDetail?.gallery.length !== 0 ? (
              <Swiper
                zoom={true}
                modules={[Navigation, Pagination, Scrollbar, A11y, Zoom]}
                spaceBetween={7}
                slidesPerView={1.3}
              >
                {itemDetail?.gallery?.map((fadeImage, index) => {
                  return (
                    <SwiperSlide className="swiper prdSwiper">
                      {restoDetails?.resto_meta?.PRORUDCT_DETAILS_IMG_ZOOM ===
                      "Yes" ? (
                        <div className="swiper-zoom-container">
                          <img src={fadeImage} />
                        </div>
                      ) : (
                        <div
                          className="swiper-slide swiper-zoom-container"
                          style={{
                            backgroundImage: `url(${fadeImage})`,
                          }}
                        ></div>
                      )}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : (
              <RenderImageWitZoom imageUrl={itemDetail?.main_image} />
            )}

            <div className="body_warp" ref={errorRef}>
              <div className="container">
                <div className="container" style={{ paddingLeft: "10px" }}>
                  <h3 className="bussiness-name" style={{ marginBottom: 8 }}>
                    {/* {selectedLanguageCode === "ar"
                      ? itemDetail?.arabic_name
                      : itemDetail?.english_name} */}
                    {itemDetail?.name[selectedLanguageCode]}
                  </h3>
                  {itemDetail !== undefined && (
                    <span
                      className="light_grey_text read_more"
                      dangerouslySetInnerHTML={{
                        __html: itemDetail?.description[selectedLanguageCode],
                      }}
                    />
                  )}
                  <div
                    className="number"
                    style={{
                      paddingBottom: "15px",
                      borderBottom: "1px solid #F6F6F6",
                      display: "flex",
                      marginTop: 5,
                      justifyContent:
                        itemDetail?.price != "0" ? "space-between" : "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {itemDetail?.price &&
                      itemDetail?.show_recipe_main_price !== 0 && (
                        <span>
                          {isValidDiscount && (
                            <span>
                              {numberWithCommas(
                                getDisValueDisplay(itemDetail?.price).toFixed(2)
                              )}
                            </span>
                          )}

                          <span
                            style={{
                              margin: isValidDiscount ? 2 : undefined,
                            }}
                            className={`product_main_price ${
                              isValidDiscount
                                ? "product_main_price_discount"
                                : "product_price_without_discount"
                            }`}
                          >
                            {" "}
                            {selectedLanguageCode === "en" && CCY}{" "}
                            {numberWithCommas(itemDetail?.price)}{" "}
                            {selectedLanguageCode === "ar" && CCY}
                          </span>
                        </span>
                      )}

                    {!showPreOrder && (
                      <div
                        className={`${value === "0" ? "border-error" : ""}`}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="circle-icon-plus"
                          style={{
                            borderColor:
                              value == "1"
                                ? "#e2e8f0"
                                : restoDetails?.default_color,
                          }}
                          onClick={() => numberOfItems("sub")}
                        >
                          <IconMinus
                            height={14}
                            width={14}
                            color={
                              value == "1"
                                ? "#e2e8f0"
                                : restoDetails?.default_color
                            }
                          />
                        </span>
                        <input
                          style={{
                            width: value.length > 2 ? 64 : 36,
                          }}
                          pattern="[0-9]{0,5}"
                          type="text"
                          className="prod_counter"
                          value={value}
                          disabled={
                            restoDetails?.resto_meta?.EDIT_ADD_ITEMS === "Yes"
                              ? false
                              : true
                          }
                          onChange={(event) => {
                            const enteredValue = event.target.value;
                            const numericValue = enteredValue.replace(
                              /\D/g,
                              ""
                            ); // Remove non-numeric characters

                            addManuallyHandler(event, numericValue);
                          }}
                        />

                        <span
                          style={{
                            borderColor: restoDetails?.default_color,
                          }}
                          className="circle-icon-plus"
                          onClick={() => numberOfItems("add")}
                        >
                          <IconPlus
                            height={14}
                            width={14}
                            color={restoDetails?.default_color}
                          />
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                {!showPreOrder && (
                  <>
                    {restoDetails?.resto_meta?.BUSSINESS_TYPE ===
                      BUSSINESSTYPEOBJ.clothesStore && (
                      <div
                        id="sizeColor"
                        className="container pad_bottom_20"
                        style={{ paddingLeft: "0px", flexDirection: "column" }}
                      >
                        {(itemDetail?.colors !== null ||
                          itemDetail?.color_image !== null) && (
                          <div
                            ref={errorRef}
                            className={`color-section popup_radio_label ${
                              showError && "border-error"
                            }`}
                          >
                            <h3 className="text-muted">{t("choose_color")}</h3>
                            <div className="colors">
                              {itemDetail?.colors !== null &&
                                itemDetail?.colors?.map((item, index) => {
                                  return (
                                    <span
                                      className={`color choose-color ${
                                        item === selectedColor && "active"
                                      }`}
                                      style={{ backgroundColor: item }}
                                      onClick={() => chooseColorHandler(item)}
                                    ></span>
                                  );
                                })}

                              {itemDetail?.color_image !== null &&
                                itemDetail?.color_image?.map((item, index) => {
                                  return (
                                    <span
                                      className={`color choose-color color_img_heigth ${
                                        item.color === selectedColor && "active"
                                      }`}
                                      style={{
                                        borderRadius: 5,
                                        marginLeft: 10,
                                        backgroundImage: `url(${item?.img_url})`,
                                      }}
                                      onClick={() =>
                                        chooseColorHandler(
                                          item.color,
                                          item?.img_url
                                        )
                                      }
                                    />
                                  );
                                })}
                            </div>
                          </div>
                        )}

                        {itemDetail?.sizes !== undefined && (
                          <div
                            ref={errorRef}
                            className={`size-section popup_radio_label ${
                              showSizeError && "border-error"
                            }`}
                          >
                            <h3 className="text-muted">{t("choose_size")}</h3>
                            <div className="sizes">
                              {itemDetail?.sizes !== undefined &&
                                itemDetail?.sizes?.map((item, index) => {
                                  return (
                                    <span
                                      className={`size choose-size ${
                                        item === selectedSize && "active"
                                      }`}
                                      onClick={() => chooseSizeHandler(item)}
                                    >
                                      {item}
                                    </span>
                                  );
                                })}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
                {itemDetail !== undefined && itemDetail.extra_options && (
                  <div
                    className="container pad_bottom_20"
                    style={{ paddingLeft: "0px" }}
                  >
                    <form action="">
                      <br />
                      {Object.entries(itemDetail.extra_options).map(
                        (item, index) => {
                          var isMandotary = mandotaryItemsArray.current.some(
                            (mndItem) => mndItem.id == item[1].id
                          );
                          var isSelected = prdSelectedOptionsArr.current.some(
                            (selectedPrd) => selectedPrd.id == item[1].id
                          );

                          return (
                            <div key={index}>
                              <div
                                className={`popup_radio_label ${
                                  !isSelected && isMandotary && showError
                                    ? "border-error"
                                    : ""
                                }`}
                              >
                                <div className="col_70_percent">
                                  <div
                                    style={{
                                      color:
                                        !isSelected && isMandotary && showError
                                          ? "red"
                                          : "black",
                                    }}
                                    className={`Customize `}
                                  >
                                    <>
                                      {!showError && (
                                        <b>
                                          {
                                            item[1].name_lang[
                                              selectedLanguageCode
                                            ]
                                          }
                                        </b>
                                      )}
                                      {showError && (
                                        <b>
                                          {isMandotary && !isSelected ? (
                                            "Select " +
                                            item[1].mandatory_amount +
                                            " option"
                                          ) : (
                                            <b>
                                              {
                                                item[1].name_lang[
                                                  selectedLanguageCode
                                                ]
                                              }
                                            </b>
                                          )}
                                        </b>
                                      )}
                                    </>
                                  </div>
                                </div>
                                {item[1].is_mandatory === "Yes" && (
                                  <div className="col_30_percent ">
                                    <div className="radio_right_req">
                                      {t("required_txt") +
                                        ` ${item[1].mandatory_amount}`}
                                    </div>
                                  </div>
                                )}
                              </div>

                              {item[1]?.items?.map((itemA, index) => {
                                return (
                                  <div>
                                    <div
                                      className="popup_radio_label"
                                      onClick={checkLocationSelected}
                                    >
                                      <div className="width-50-per">
                                        {multipleOption?.length > 0 &&
                                          itemA.item_type === "check-box" &&
                                          checkMultipleOptionArr(itemA.id)
                                            .displayButton && (
                                            <span
                                              style={{
                                                color: "red",
                                                fontSize: 12,
                                                marginRight: 7,
                                                marginLeft: 7,
                                              }}
                                            >
                                              {selectedLanguageCode ===
                                                "ar" && <span>x</span>}

                                              <span>
                                                {
                                                  checkMultipleOptionArr(
                                                    itemA.id
                                                  ).totalNumber
                                                }
                                                {selectedLanguageCode ===
                                                  "en" && <span>x</span>}
                                              </span>
                                            </span>
                                          )}
                                        <div
                                          onClick={() => {
                                            itemA.item_type === "check-box" &&
                                              // isLocationSelectedFlag &&
                                              addExtraOption(
                                                itemA,
                                                "add",
                                                item[1]
                                              );
                                          }}
                                          className="title_opt_product"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              itemA.name_lang[
                                                selectedLanguageCode
                                              ],
                                          }}
                                        />
                                      </div>

                                      <div
                                        className={`width-50-per ml-3 ${
                                          !isValidDiscount
                                            ? "price_product_detail_without_dis"
                                            : ""
                                        }`}
                                      >
                                        <label className="light_grey_text font_s product_price_prdDetai">
                                          {itemA.price !== 0 && (
                                            <span>
                                              <span
                                                style={{
                                                  marginLeft: 2,
                                                  marginRight: 2,
                                                }}
                                              >
                                                {selectedLanguageCode ===
                                                  "en" && CCY}{" "}
                                              </span>
                                              {getDisValueDisplay(
                                                itemA.price.toString()
                                              )}{" "}
                                              {selectedLanguageCode === "ar" &&
                                                CCY}
                                            </span>
                                          )}
                                          {}
                                        </label>
                                        {isValidDiscount && (
                                          <label className="light_grey_text font_s">
                                            {
                                              <span className="product_main_price_discount">
                                                {itemA.price.toString()}
                                              </span>
                                            }
                                          </label>
                                        )}
                                        {restoDetails?.resto_meta
                                          .DISABLED_LOCATION_SERVICES !==
                                          "Yes" && (
                                          <>
                                            {isLocationSelectedFlag &&
                                              itemA.item_type === "option" && (
                                                <input
                                                  type="radio"
                                                  id={itemA.id.toString()}
                                                  value={itemA.price}
                                                  className="radio_right"
                                                  onChange={() =>
                                                    onChangeInputPrice(
                                                      itemA.price,
                                                      itemA.id,
                                                      itemA.name,
                                                      item[1],
                                                      itemA
                                                    )
                                                  }
                                                  checked={multipleOption.some(
                                                    (selectItem: any) =>
                                                      selectItem.id == itemA.id
                                                  )}
                                                />
                                              )}
                                          </>
                                        )}
                                        {restoDetails?.resto_meta
                                          .DISABLED_LOCATION_SERVICES ===
                                          "Yes" && (
                                          <>
                                            {itemA.item_type === "option" && (
                                              <input
                                                // style={{ marginTop: "5px" }}
                                                // inputMode=""
                                                type="radio"
                                                checked={
                                                  multipleOption.some(
                                                    (selectItem: any) =>
                                                      selectItem.id == itemA.id
                                                  )
                                                  // selectedItemId === itemA.id
                                                }
                                                id={itemA.id.toString()}
                                                // name="parent_opt_1"
                                                value={itemA.price}
                                                className="radio_right"
                                                onChange={() =>
                                                  onChangeInputPrice(
                                                    itemA.price,
                                                    itemA.id,
                                                    itemA.name_lang[
                                                      selectedLanguageCode
                                                    ],
                                                    item[1],
                                                    itemA
                                                  )
                                                }
                                              />
                                            )}
                                          </>
                                        )}

                                        {restoDetails?.resto_meta
                                          .DISABLED_LOCATION_SERVICES !==
                                          "Yes" && (
                                          <>
                                            {isLocationSelectedFlag &&
                                              itemA.item_type === null && (
                                                <input
                                                  type="radio"
                                                  checked={multipleOption.some(
                                                    (selectItem: any) =>
                                                      selectItem.id == itemA.id
                                                  )}
                                                  id={itemA.id.toString()}
                                                  value={itemA.price}
                                                  className="radio_right"
                                                  onChange={() =>
                                                    onChangeInputPrice(
                                                      itemA.price,
                                                      itemA.id,
                                                      itemA.name_lang[
                                                        selectedLanguageCode
                                                      ],
                                                      item[1],
                                                      itemA
                                                    )
                                                  }
                                                />
                                              )}
                                          </>
                                        )}
                                        {restoDetails?.resto_meta
                                          .DISABLED_LOCATION_SERVICES ===
                                          "Yes" && (
                                          <>
                                            {itemA.item_type === null && (
                                              <input
                                                // style={{ marginTop: "3px" }}
                                                // inputMode=""
                                                type="radio"
                                                checked={
                                                  multipleOption.some(
                                                    (selectItem: any) =>
                                                      selectItem.id == itemA.id
                                                  )
                                                  // selectedItemId === itemA.id
                                                }
                                                id={itemA.id.toString()}
                                                // name="parent_opt_1"
                                                value={itemA.price}
                                                className="radio_right"
                                                onChange={() =>
                                                  onChangeInputPrice(
                                                    itemA.price,
                                                    itemA.id,
                                                    itemA.name_lang[
                                                      selectedLanguageCode
                                                    ],
                                                    item[1],
                                                    itemA
                                                  )
                                                }
                                              />
                                            )}
                                          </>
                                        )}

                                        {restoDetails?.resto_meta
                                          .DISABLED_LOCATION_SERVICES !==
                                          "Yes" && (
                                          <>
                                            {isLocationSelectedFlag &&
                                              multipleOption?.length > 0 &&
                                              itemA.item_type === "check-box" &&
                                              checkMultipleOptionArr(itemA.id)
                                                .displayButton && (
                                                <>
                                                  <DeleteIcon
                                                    onPressHandler={() =>
                                                      addExtraOption(
                                                        itemA,
                                                        "delete",
                                                        item[1]
                                                      )
                                                    }
                                                  />
                                                </>
                                              )}
                                          </>
                                        )}

                                        {restoDetails?.resto_meta
                                          .DISABLED_LOCATION_SERVICES ===
                                          "Yes" && (
                                          <>
                                            {multipleOption?.length > 0 &&
                                              itemA.item_type === "check-box" &&
                                              checkMultipleOptionArr(itemA.id)
                                                .displayButton && (
                                                <>
                                                  <DeleteIcon
                                                    onPressHandler={() =>
                                                      addExtraOption(
                                                        itemA,
                                                        "delete",
                                                        item[1]
                                                      )
                                                    }
                                                  />
                                                </>
                                              )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        }
                      )}
                    </form>
                  </div>
                )}
                {itemDetail !== undefined &&
                  restoDetails?.resto_meta?.ENABLED_PRODUCT_FAQS === "Yes" &&
                  itemDetail?.product_faqs?.length !== 0 && (
                    <>
                      {itemDetail &&
                        (itemDetail.extra_options ||
                          itemDetail?.colors !== null ||
                          itemDetail?.color_image !== null) && <Seperator />}
                      <Accordion
                        defautlLang={selectedLanguageCode}
                        dataQuestion={itemDetail?.product_faqs}
                      />
                    </>
                  )}
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

                <div>
                  {!showPreOrder && (
                    <div
                      className="container fotter_pad"
                      style={{ borderTop: "1px solid #eeee" }}
                    >
                      {restoDetails?.resto_meta.DISABLED_LOCATION_SERVICES !==
                        "Yes" && (
                        <>
                          {isLocationSelectedFlag && (
                            <button
                              className="add_cart_btn"
                              onClick={onPressButton}
                              disabled={!isAvailable}
                            >
                              <div className="prod_counted_price">
                                {selectedLanguageCode === "en" && CCY}{" "}
                                {numberWithCommas(customPrize.toFixed(2))}{" "}
                                {selectedLanguageCode === "ar" && CCY}
                              </div>
                              {/* <div className="prod_add_to_cart">
                              {t("add_to_basket")}
                            </div> */}
                              {itemDetail?.is_available === "Yes" && (
                                <div className="prod_add_to_cart">
                                  {t("add_to_basket")}
                                </div>
                              )}
                              {!isAvailable && (
                                <div className="prod_add_to_cart">
                                  {
                                    itemDetail?.inventory_un_available_reason[
                                      selectedLanguageCode
                                    ]
                                  }
                                </div>
                              )}
                            </button>
                          )}
                          {showHandErro && (
                            <HandSvg onPressHandler={showLocationModal} />
                          )}
                          {!isLocationSelectedFlag &&
                            !showDelivery &&
                            !showUserAddress && (
                              <BottomButton
                                titleProp={
                                  !isAvailable
                                    ? itemDetail?.inventory_un_available_reason[
                                        selectedLanguageCode
                                      ] || ""
                                    : t("select_location_maps")
                                }
                                description={t("select_location_first")}
                                displayBottomLine={true}
                                onPress={showLocationModal}
                                showHandError={showHandErro}
                                widthProp={375}
                              />
                            )}
                        </>
                      )}

                      {restoDetails?.resto_meta.DISABLED_LOCATION_SERVICES ===
                        "Yes" && (
                        <button
                          className="add_cart_btn"
                          onClick={onPressButton}
                          disabled={!isAvailable}
                        >
                          <div>{selectedRecipe.is_available === "No"}</div>
                          <div className="prod_counted_price">
                            {selectedLanguageCode === "en" && CCY}{" "}
                            {parseFloat(customPrize.toFixed(2))}{" "}
                            {selectedLanguageCode === "ar" && CCY}
                          </div>
                          {itemDetail?.is_available === "Yes" && (
                            <div className="prod_add_to_cart">
                              {t("add_to_basket")}
                            </div>
                          )}
                          {!isAvailable && (
                            <div className="prod_add_to_cart">
                              {
                                itemDetail?.inventory_un_available_reason[
                                  selectedLanguageCode
                                ]
                              }
                            </div>
                          )}
                        </button>
                      )}
                    </div>
                  )}
                </div>

                {showPreOrder && (
                  <div
                    className="container fotter_pad"
                    style={{
                      borderTop: "1px solid #eeee",
                      padding: "10px 0px",
                    }}
                  >
                    <DisplayMessage
                      whatsappNumber={restoDetails?.whatsapp_number}
                      landlineNumber={restoDetails?.phone}
                      showIcon={false}
                      message={
                        selectedLanguageCode === "en"
                          ? "For pre order kindly contact us on our phone numbers to place an order"
                          : "يرجى التواصل معنا عبر ارقام الهاتف للتواصي"
                      }
                    />
                  </div>
                )}
                {showUserAddress && (
                  <SelectAddressModal
                    selectedId={
                      parseLocalData !== null
                        ? parseLocalData?.userAddressInfo?.id
                        : 0
                    }
                    selectedLatLng={
                      selectedOutlet !== undefined
                        ? selectedOutlet?.selectedLatLon
                        : undefined
                    }
                    onPressClose={(
                      receiver: AddressInterface | undefined,
                      buttonPress: string | undefined
                    ) => {
                      // console.log(receiver);
                      hideAddressModal(receiver, buttonPress);
                      setShowUserAddress(false);
                    }}
                    accesssToken={userToken}
                    customerId={cutomerInfo?.id}
                  />
                )}
                {showDelivery && (
                  <PopDelivery
                    restoData={restoDetails}
                    onSelectedOutlet={(selectedOutlet: any) => {
                      onSelectedOutletHandler(selectedOutlet);
                    }}
                    onPressClose={() => {
                      setShowDelivery(false);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="popup popup_from_bottom popup_slide loader-wrapper">
          <Loader
            height={40}
            width={40}
            colorProp={appSharedColors.primaryColor}
          />
        </div>
      )}
    </div>
  );
};

export default ProductDetail;
