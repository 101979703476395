import React, { useState } from "react";
import PhonePreOrderSvg from "../../assets/img/svgs/PhonePreOrderSvg";
import WhatsappIconSvg from "../../assets/img/svgs/WhatsappIconSvg";
import { useScrollLock } from "../../hooks/useScrollLock";
import SelectPhoneNumber from "./SelectPhoneNumber";
interface Props {
  landlineNumber: number | undefined;
  whatsappNumber: number | undefined;
  notOpenWhatsapp?: boolean;
}
const BlockPhoneNumber: React.FC<Props> = (props: Props) => {
  // const { lockScroll, unlockScroll } = useScrollLock();
  const { landlineNumber, whatsappNumber, notOpenWhatsapp } = props;
  const [showSelector, setShowSelector] = useState<boolean>(false);
  const opneWhatsapp = (phoneNumber?: string) => {
    // if (!notOpenWhatsapp) {
    document.location.href = `//api.whatsapp.com/send?phone=${phoneNumber}&text=Hello how are you i want to place a pre order ?`;
    // }
    // if (notOpenWhatsapp) {
    //   document.location.href = `tel:${phoneNumber}`;
    //   hideSelectorHandler();
    // }
  };
  const hideSelectorHandler = () => {
    // unlockScroll();
    setShowSelector(false);
  };
  const callUsHandler = (phoneNumber?: string) => {
    // lockScroll();
    setShowSelector(true);
    // if (!notOpenWhatsapp) {
    // document.location.href = `tel:${phoneNumber}`;
    // document.location.href = "tel:" + landlineNumber + whatsappNumber;
    // }
    // if (notOpenWhatsapp) {
    //   setShowSelector(true);
    //   hideSelectorHandler();
    // }
    // dispatch(setCloseAllModal());
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginTop: 13,
        cursor: "pointer",
      }}
    >
      <div
        onClick={() => callUsHandler(landlineNumber?.toString())}
        className="disp_flex_row"
      >
        <PhonePreOrderSvg />{" "}
        <span className="phone-preorder-message">{landlineNumber}</span>
      </div>
      <div className="message-line" />
      <div style={{ marginRight: 8 }} />
      <div
        onClick={() => opneWhatsapp(whatsappNumber?.toString())}
        className="disp_flex_row"
      >
        <WhatsappIconSvg />
        <span className="phone-preorder-message">{whatsappNumber}</span>
      </div>
      {showSelector && (
        <SelectPhoneNumber
          showButton={false}
          landLinenumber={landlineNumber}
          whatsappNumber={whatsappNumber}
          onBackDropClick={hideSelectorHandler}
          onPressClose={hideSelectorHandler}
          headerMessage={"Select Number"}
          errorMessage={""}
        />
      )}
    </div>
  );
};

export default BlockPhoneNumber;
