import { httpGetWithAuth } from "../../../setup/axios/axiosUtils";
import { useState } from "react";
import { URL_CONSTANTS } from "../urlsLink";
import  useBussiness from '../useBussiness';
import { SingleItem } from "../../pages/Home/utils";
import { json } from "stream/consumers";
import { getUserTokenFromLocal } from "../../pages/Home/Components/Popups/CartPages/utils";
const useItemDetail = () => {
  const {getUserToken,userToken} = useBussiness()
  const [loading, setLoading] = useState(false);
  const [itemDetail, setItemDetail] = useState<SingleItem>();
 
  // const {getUserToken,userToken} = useBussiness();
  const getItemDetailByid = async (itemId: any, userTokenParam: string,bussinessName:string) => {
  
    setLoading(true);
    try {
      const res = await httpGetWithAuth(`${URL_CONSTANTS.get('URL_Bussienss_Recipe')!}?id=${itemId}`,{}, userTokenParam);
      // console.log(res);
      setLoading(false);
      if (res.type === "success") {
        setItemDetail(res.data);
      }
      if(res.token === "expired"){
        // console.log('called')
        // getUserToken(bussinessName).then(() =>{
        //   getItemDetailByid(itemId,userToken!,bussinessName);
        // });
      }
    } catch (error: any) {
      console.log(error.message);
      setLoading(false);
    }
  };
  return {
    getItemDetailByid,
    loading,
    itemDetail,
  };
};
export default useItemDetail;
