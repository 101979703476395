import { DeliveryZoneObj, SelectedOulet, StoreData } from "../pages/Home/utils";

export const getDeliveryZonseInfo = async (
  deliveryZonesInfo: any,
  center: any
) => {
  console.log(center);
  var is_found = false;
  var foun_name = "";
  var deliveryZoneData = {};
  try {
    $.each(deliveryZonesInfo, function (outleName, outLetData) {
      // eslint-disable-next-line no-undef
      const eachOutletLatLng = new google.maps.Polygon({
        paths: JSON.parse(outLetData.lat_lng),
      });
      // eslint-disable-next-line no-undef
      is_found = google.maps.geometry.poly.containsLocation(
        center!,
        eachOutletLatLng
      )
        ? true
        : false;
      if (is_found) {
        // @ts-ignore

        foun_name = outleName;
        deliveryZoneData = outLetData;
        // eslint-disable-next-line no-throw-literal
        throw "Found";
      }
    });
  } catch (e) {
    if (e !== "Found") throw e;
  }
  return { is_found, center, foun_name, deliveryZoneData };
  /**   if (is_found) {
      getGeocodeLoc(center!);
      onPressClose();
      // console.log(deliveryZoneData);
      onlocationFound(currentAddress, foun_name, deliveryZoneData, center!);
    } else {
      // alert("Location not found please select another one");
      setShowError(true);
    }*/
};
export const getGeocodeLoc = async (position: google.maps.LatLngLiteral) => {
  // eslint-disable-next-line no-undef
  const geocoder = new google.maps.Geocoder();
  let converted = "";
  await geocoder
    .geocode({ location: position })
    .then((response) => {
      // console.log(response)
      if (response.results[0]) {
        converted = response.results[0].formatted_address;
        //   console.log(converted);
        //   setCurrentAddress(converted);
      } else {
        //   window.alert("No results found");
      }
    })
    .catch(
      (e) => console.log("Geocoder failed due to: " + e)
      // window.alert("Geocoder failed due to: " + e)
    );
  // console.log('helper function' + converted);
  return converted;
};
export const selectedOutInfo = async (
  outLetName: string,
  deliveryZonesData: any,
  locationText: string,
  lat_lng: any,
  useData: StoreData | undefined,
  // shopDataFromStrg:any,
  finalPathName: string
) => {
  let shopData: any = {};
  var outlets = useData!.outlets;
  const shopDataFromStrg = localStorage.getItem("shop" + finalPathName);
  var tempSelected = {} as SelectedOulet;
  Object.entries(outlets).forEach(function (item, index) {
    try {
      Object.entries(item[1].area).forEach(function (itemJ, keyJ) {
        if (itemJ[1].area_name === deliveryZonesData.area_name) {
          // @ts-ignore
          tempSelected = {
            ...itemJ[1],
            selectedLocationName: locationText,
            selectedOutLetName: outLetName,
            selectedLatLon: lat_lng,
            deliveryZone: deliveryZonesData,
            outlet_pickup_payment_methods:
              item[1].outlet_pickup_payment_methods,
            outlet_delivery_payment_methods:
              item[1].outlet_delivery_payment_methods,
            // outlet_pickup_payment_methods:item[1].outlet_pickup_payment_methods,
            out_let_phone_number: item[1].out_let_phone_number,
            outlet_address: item[1].outlet_address,
            outlet_name: item[1].outlet_name,
            outlet_delivery_slot_length: item[1].outlet_delivery_slot_length,
            outlet_address_arabic: item[1].outlet_address_arabic,
            outlet_id: item[1].outlet_id,
            delivery_days: item[1].delivery_days,
            preparation_delivery_type: item[1].preparation_delivery_type,
            business_hours: item[1].business_hours,
            pauseorder_flag: item[1].pauseorder_flag,
            vat_tax: item[1].vat_tax,
            delivery_time: item[1].delivery_time,
            closed_days: item[1].closed_days,
            pickup_time: item[1].pickup_time,
            time_zone: item[1].time_zone,
            estimated_time_v1: item[1].estimated_time_v1,
            out_of_stock: item[1].out_of_stock,
            unavailable_products: item[1].unavailable_products,
            estimated_time: item[1].estimated_time,
            //@ts-ignore
            delivery_fee: deliveryZonesData.delivery_fee,
          };
          //console.log("tempSelected");

          throw "Found";
        }
      });
    } catch (e) {
      if (e !== "Found") throw e;
    }
  });

  if (shopDataFromStrg !== null) {
    // console.log("Shop String");
    // console.log(JSON.parse(shopDataFromStrg));

    //  console.log("outLetName");
    // console.log(selectedOutInfo);
    let pardedData: any = {};
    pardedData = JSON.parse(shopDataFromStrg);
    var outname = outLetName.split("_ao_")[0];
    var areaName = outLetName.split("_ao_")[1];
    pardedData.orderType = "delivery";
    pardedData.outletUrl = `/d${finalPathName}?outlet=${outname}&area=${areaName}`;
    shopData = {
      ...pardedData,
      outletInfo: tempSelected,
    };
  }
  // console.log("shopData");
  // console.log(shopData);
  localStorage.setItem("shop" + finalPathName, JSON.stringify(shopData));
  // setSelectedOutlet(tempSelected);
  return tempSelected;
};
export const saveLabelAndAddressToStorage = async (
  selectedAddress: any,
  bussinessName: string
) => {
  const shopDataFromStrg = localStorage.getItem("shop/" + bussinessName);
  let shopData: any = {};
  var tempData = {
    address: selectedAddress.address,
    label: selectedAddress.label,
    instrunctions: selectedAddress.instrunctions,
    id: selectedAddress.id,
  };

  if (shopDataFromStrg !== null) {
    let pardedData: any = {};
    pardedData = JSON.parse(shopDataFromStrg);
    shopData = {
      ...pardedData,
      userAddressInfo: tempData,
    };
  }
  localStorage.setItem("shop/" + bussinessName, JSON.stringify(shopData));
};
