import React, { useEffect } from "react";
import ExclamationCircle from "../../assets/img/svgs/ExclamationCircle";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useCustomerInfo from "../../hooks/useCustomerInfo";
import { getShopDataFromStrg } from "../Home/utils";

const PaymentFail: React.FC = () => {
  const buttonStyles: React.CSSProperties = {
    backgroundColor: "#FFA600",
    color: "white",
    textAlign: "center",
    width: "97%",
    marginTop: 15,
    padding: 10,
    borderRadius: 5,
    cursor: "pointer",
  };
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { bussinessName } = useParams();
  const paymentLink = searchParams.get("url");
  const orderType = searchParams.get("orderType");
  const orderId = searchParams.get("order");
  const { updateOrderStatus } = useCustomerInfo();
  const payment = searchParams.get("payment");
  const refId = searchParams.get("ref");
  const reason = searchParams.get("reason");
  const localData = getShopDataFromStrg("/" + bussinessName);
  const goToHomePage = () => {
    if (orderType === "paymentlink" && paymentLink !== null) {
      window.location.replace(paymentLink);
      return;
    }
    navigate(`/${bussinessName}/place-order`);
  };
  const tryAgainHandler = () => {
    if (orderType === "paymentlink" && paymentLink !== null) {
      window.location.replace(paymentLink);
      return;
    }
    navigate(`/${bussinessName}/place-order`);
  };

  useEffect(() => {
    if (orderType !== "paymentlink") {
      updateOrderStatus(orderId!, refId!, "Fail", localData?.accessToken);
    }
  }, []);

  return (
    <div className="pop_up_maps_main pop_main_menu_bg" lang="en">
      <div
        className="popup_cart_full popup_from_bottom  popup_slide "
        style={{ paddingLeft: "2rem", background: "#f3f3f3" }}
      >
        <div style={{ display: "flex", marginTop: "6rem" }}>
          <div>
            <ExclamationCircle />
          </div>
          <div>
            <div style={{ flexDirection: "column", marginLeft: "15px" }}>
              <div
                style={{ color: "#000", fontSize: "20px", fontWeight: "bold" }}
              >
                Payment Failed
              </div>
              <div style={{ marginTop: 10, fontSize: 12 }}>
                {
                  "This payment has been declined by your card issuer.Errorcode : CSC_7200015"
                }
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            background: "white",
            padding: "15px",
            borderRadius: 5,
            marginRight: 10,
            marginTop: 25,
          }}
        >
          <div style={{ color: "#000", fontSize: "14px", fontWeight: "800" }}>
            Suggestions
          </div>
          <div style={{ marginTop: 10, fontSize: 12 }}>
            Contact your card issuer or selected different payment method
          </div>
        </div>
        <div style={{ ...buttonStyles }} onClick={tryAgainHandler}>
          Try again
        </div>
        <div
          onClick={goToHomePage}
          style={{
            ...buttonStyles,
            background: "",
            color: "#FFA600",
            border: "0.7px solid #FFA600",
            // border: 1px solid;
          }}
        >
          Back to Homepage
        </div>
      </div>
    </div>
  );
};

export default PaymentFail;
