import React from "react";

const EmptyCartSvg2: React.FC = () => {
  return (
    <svg
      id="Group_8421"
      data-name="Group 8421"
      xmlns="http://www.w3.org/2000/svg"
      //  xmlns:xlink="http://www.w3.org/1999/xlink"
      width="194.205"
      height="181.578"
      viewBox="0 0 194.205 181.578"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="-8.952"
          y1="1"
          x2="-8.87"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#1a1d23" />
          <stop offset="0.3" stop-color="#232c3d" />
          <stop offset="0.64" stop-color="#172339" />
          <stop offset="1" stop-color="#142138" />
        </linearGradient>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_353"
            data-name="Rectangle 353"
            width="194.205"
            height="181.578"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-4">
          <path
            id="Path_9179"
            data-name="Path 9179"
            d="M109.759,133.494a8.086,8.086,0,1,0,8.085-8.086,8.086,8.086,0,0,0-8.085,8.086"
            transform="translate(-109.759 -125.408)"
            fill="url(#linear-gradient)"
          />
        </clipPath>
        <clipPath id="clip-path-6">
          <rect
            id="Rectangle_347"
            data-name="Rectangle 347"
            width="7.575"
            height="7.575"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-7">
          <path
            id="Path_9180"
            data-name="Path 9180"
            d="M113.018,132.455a3.787,3.787,0,1,0,3.788-3.787,3.788,3.788,0,0,0-3.788,3.787"
            transform="translate(-113.018 -128.668)"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient-4"
          x1="-19.679"
          y1="1.567"
          x2="-19.505"
          y2="1.567"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#eef4fb" />
          <stop offset="0.341" stop-color="#e2ecf7" />
          <stop offset="1" stop-color="#cfe1f3" />
        </linearGradient>
        <clipPath id="clip-path-8">
          <path
            id="Path_9182"
            data-name="Path 9182"
            d="M47.88,133.494a8.086,8.086,0,1,0,8.086-8.086,8.086,8.086,0,0,0-8.086,8.086"
            transform="translate(-47.88 -125.408)"
            fill="url(#linear-gradient)"
          />
        </clipPath>
        <clipPath id="clip-path-11">
          <path
            id="Path_9183"
            data-name="Path 9183"
            d="M51.139,132.455a3.787,3.787,0,1,0,3.788-3.787,3.788,3.788,0,0,0-3.788,3.787"
            transform="translate(-51.139 -128.668)"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient-6"
          x1="-8.905"
          y1="1.567"
          x2="-8.73"
          y2="1.567"
          // xlink:href="#linear-gradient-4"
        />
      </defs>
      <g id="Group_8420" data-name="Group 8420" clip-path="url(#clip-path)">
        <g id="Group_8399" data-name="Group 8399">
          <g id="Group_8398" data-name="Group 8398" clip-path="url(#clip-path)">
            <path
              id="Path_9177"
              data-name="Path 9177"
              d="M42.99,40.194,189.83,48.81a1.042,1.042,0,0,1,.919,1.394l-7.734,21.4-14.727,40.751L62.914,130.643,35.862,12.3a5.6,5.6,0,0,0-4.527-4.356L4.438,2"
              transform="translate(1.415 0.637)"
              fill="none"
              stroke="#9ebcc1"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <line
              id="Line_3"
              data-name="Line 3"
              x1="19.201"
              y2="66.58"
              transform="translate(157.565 48.519)"
              fill="none"
              stroke="#9ebcc1"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
            />
            <line
              id="Line_4"
              data-name="Line 4"
              x1="15.294"
              y2="69.681"
              transform="translate(145.537 47.506)"
              fill="none"
              stroke="#9ebcc1"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
            />
            <line
              id="Line_5"
              data-name="Line 5"
              x1="11.215"
              y2="72.766"
              transform="translate(133.62 46.488)"
              fill="none"
              stroke="#9ebcc1"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
            />
            <line
              id="Line_6"
              data-name="Line 6"
              x1="6.967"
              y2="75.836"
              transform="translate(121.81 45.467)"
              fill="none"
              stroke="#9ebcc1"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
            />
            <line
              id="Line_7"
              data-name="Line 7"
              x1="2.55"
              y2="78.893"
              transform="translate(110.106 44.442)"
              fill="none"
              stroke="#9ebcc1"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
            />
            <line
              id="Line_8"
              data-name="Line 8"
              x2="2.036"
              y2="81.935"
              transform="translate(96.472 43.413)"
              fill="none"
              stroke="#9ebcc1"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
            />
            <line
              id="Line_9"
              data-name="Line 9"
              x2="6.789"
              y2="84.963"
              transform="translate(80.225 42.38)"
              fill="none"
              stroke="#9ebcc1"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
            />
            <line
              id="Line_10"
              data-name="Line 10"
              x2="17.287"
              y2="87.977"
              transform="translate(58.334 41.343)"
              fill="none"
              stroke="#9ebcc1"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
            />
          </g>
        </g>
        <line
          id="Line_11"
          data-name="Line 11"
          x2="132.968"
          y2="4.083"
          transform="translate(51.463 68.156)"
          fill="none"
          stroke="#9ebcc1"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <line
          id="Line_12"
          data-name="Line 12"
          y1="8.701"
          x2="118.256"
          transform="translate(58.51 95.043)"
          fill="none"
          stroke="#9ebcc1"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <g id="Group_8401" data-name="Group 8401">
          <g id="Group_8400" data-name="Group 8400" clip-path="url(#clip-path)">
            <path
              id="Path_9178"
              data-name="Path 9178"
              d="M51.564,99.535,40.319,129.943a2.894,2.894,0,0,0,2.6,4.157h97.7"
              transform="translate(12.765 31.745)"
              fill="none"
              stroke="#9ebcc1"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
        </g>
        <g
          id="Group_8403"
          data-name="Group 8403"
          transform="translate(144.765 165.405)"
        >
          <g
            id="Group_8402"
            data-name="Group 8402"
            clip-path="url(#clip-path-4)"
          >
            <rect
              id="Rectangle_345"
              data-name="Rectangle 345"
              width="16.171"
              height="16.173"
              transform="translate(0)"
              fill="url(#linear-gradient)"
            />
          </g>
        </g>
        <g id="Group_8410" data-name="Group 8410">
          <g id="Group_8409" data-name="Group 8409" clip-path="url(#clip-path)">
            <g
              id="Group_8408"
              data-name="Group 8408"
              transform="translate(149.063 169.704)"
              // style="mix-blend-mode: multiply;isolation: isolate"
            >
              <g id="Group_8407" data-name="Group 8407">
                <g
                  id="Group_8406"
                  data-name="Group 8406"
                  clip-path="url(#clip-path-6)"
                >
                  <g
                    id="Group_8405"
                    data-name="Group 8405"
                    transform="translate(0 0)"
                  >
                    <g
                      id="Group_8404"
                      data-name="Group 8404"
                      clip-path="url(#clip-path-7)"
                    >
                      <rect
                        id="Rectangle_346"
                        data-name="Rectangle 346"
                        width="7.575"
                        height="7.575"
                        transform="translate(0)"
                        fill="url(#linear-gradient-4)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <path
              id="Path_9181"
              data-name="Path 9181"
              d="M117.509,135.653h0a4.9,4.9,0,0,0,4.728-3.622l1.965-7.282H110.815l1.965,7.282a4.9,4.9,0,0,0,4.728,3.622"
              transform="translate(35.343 39.787)"
              fill="#9ebcc1"
            />
          </g>
        </g>
        <g
          id="Group_8412"
          data-name="Group 8412"
          transform="translate(63.151 165.405)"
        >
          <g
            id="Group_8411"
            data-name="Group 8411"
            clip-path="url(#clip-path-8)"
          >
            <rect
              id="Rectangle_349"
              data-name="Rectangle 349"
              width="16.173"
              height="16.173"
              transform="translate(0)"
              fill="url(#linear-gradient)"
            />
          </g>
        </g>
        <g id="Group_8419" data-name="Group 8419">
          <g id="Group_8418" data-name="Group 8418" clip-path="url(#clip-path)">
            <g
              id="Group_8417"
              data-name="Group 8417"
              transform="translate(67.449 169.704)"
              //  style="mix-blend-mode: multiply;isolation: isolate"
            >
              <g id="Group_8416" data-name="Group 8416">
                <g
                  id="Group_8415"
                  data-name="Group 8415"
                  clip-path="url(#clip-path-6)"
                >
                  <g
                    id="Group_8414"
                    data-name="Group 8414"
                    transform="translate(0 0)"
                  >
                    <g
                      id="Group_8413"
                      data-name="Group 8413"
                      clip-path="url(#clip-path-11)"
                    >
                      <rect
                        id="Rectangle_350"
                        data-name="Rectangle 350"
                        width="7.575"
                        height="7.575"
                        transform="translate(0)"
                        fill="url(#linear-gradient-6)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <path
              id="Path_9184"
              data-name="Path 9184"
              d="M55.629,135.653h0a4.9,4.9,0,0,0,4.728-3.622l1.964-7.282H48.937l1.964,7.282a4.9,4.9,0,0,0,4.728,3.622"
              transform="translate(15.608 39.787)"
              fill="#9ebcc1"
            />
            <path
              id="Path_9185"
              data-name="Path 9185"
              d="M47.613,111.156l99.83-19.225,7.073-6.262"
              transform="translate(15.186 27.323)"
              fill="none"
              stroke="#9ebcc1"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="Path_9186"
              data-name="Path 9186"
              d="M1.2,6.041,21.1,8.7a1.385,1.385,0,0,0,1.556-1.19L23.1,4.226a1.385,1.385,0,0,0-1.19-1.556L2.008.012A1.386,1.386,0,0,0,.45,1.2L.012,4.485A1.385,1.385,0,0,0,1.2,6.041"
              transform="translate(0 0)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EmptyCartSvg2;
