import React from "react";
import ButtonAlertModal from "./Buttons/ButtonAlertModal";

interface AlertNoLocationProps {
  onPressClose: () => void;
  errorMessage: string;
  headerMessage: string;
  onBackDropClick?: () => void;
}
const AlertNoLocation: React.FC<AlertNoLocationProps> = (
  props: AlertNoLocationProps
) => {
  const { errorMessage, onBackDropClick, headerMessage } = props;

  return (
    <div
      onClick={onBackDropClick}
      id="pre_order_modal"
      style={{
        background: "rgba(0, 0, 0, 0.4)",
      }}
      className="pop_up_maps_main pop_main_menu_bg location-error-modal"
    >
      <div
        style={{
          bottom: "auto",
          width: "90%",
          borderRadius: "10px",
          zIndex: 2,
        }}
        className="popup_delivery popup_from_bottom pop_time_set_height "
      >
        <div
          className="content"
          id=""
          style={{ marginLeft: 10, marginRight: 10 }}
        >
          <div className="container">
            <div style={{ borderBottom: 0 }} className="head_burg_menu">
              <div className="calender_header"></div>
              <div className="delivery_head">{headerMessage}</div>
              <div className="delivery_time_text" style={{ marginTop: 10 }}>
                {errorMessage}
              </div>
            </div>
          </div>
        </div>
        <br />
        <ButtonAlertModal onPressHandler={() => {}} btnTitle="Continue" />
        <ButtonAlertModal onPressHandler={() => {}} btnTitle="Change Address" />
      </div>
    </div>
  );
};

export default AlertNoLocation;
