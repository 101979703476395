import React from "react";
interface IconMinusProps {
  height: string | number;
  width: string | number;
  color: string;
}
const IconMinus: React.FC<IconMinusProps> = (props: IconMinusProps) => {
  const { height, width, color } = props;
  return (
    <svg
      data-v-54a96833=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      fill={color}
      data-spec="icon-minus"
      className="w-5 h-5"
      style={{ width: width, height: height }}
    >
      <path
        fill={color}
        d="M376 232H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h368c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"
      ></path>
    </svg>
  );
};

export default IconMinus;
