import React from "react";
import SearchSvg from "../../../../assets/img/svgs/SearchSvg";
import {
  ObjectKeyLng,
  OutLets,
  OutLetsObj,
  getSelectedLanguage,
} from "../../utils";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
interface Props {
  allOutlets: OutLets | undefined;
  onSearchPickup: (text: string) => void;
  setPickupLocation: (itemPickup: OutLetsObj, outletName: string) => void;
}
const BlockPickup: React.FC<Props> = (props: Props) => {
  const { allOutlets, onSearchPickup, setPickupLocation } = props;
  const { t } = useTranslation();
  const { bussinessName } = useParams();
  const selectedLanguageCode = getSelectedLanguage(
    bussinessName!
  ) as ObjectKeyLng;
  return (
    <div>
      <div className="pickup_search_wrapper">
        <SearchSvg fillColor="orange" />
        <input
          onChange={(e) => onSearchPickup(e.target.value)}
          type="text"
          className="pickup_input_style"
          placeholder={t("search_by_name")}
        />
      </div>
      {allOutlets !== undefined &&
        Object.entries(allOutlets).map((item, index, arr) => {
          // //console.log(item);
          return (
            <div
              className="tab-pane"
              id="tab_default_2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (!item[1].pauseorder_flag.pickup) {
                  setPickupLocation(item[1], item[0]);
                }
              }}
            >
              <div className="pickup_search_box">
                <div
                  className="delivery_searched_items_box"
                  id="pickup_searched_items_box"
                >
                  <div
                    className={`delivery_searched_item ${
                      item[1].pauseorder_flag.pickup
                        ? "disabled_pickup_tab"
                        : ""
                    }`}
                  >
                    <div className="delivery_searched_loaction_text">
                      <div className="searched_loaction_text_head ">
                        {item[1]?.outlet_name_translation[selectedLanguageCode]}
                      </div>
                      <div className="searched_loaction_text_foot limit-two-lines">
                        {/* @ts-ignore */}
                        {item[1]?.outlet_address[selectedLanguageCode]}
                      </div>

                      {item[1].pauseorder_flag.pickup && (
                        <div className="not_accepting_order_pickup">
                          <span>Not accepting orders</span>
                        </div>
                      )}
                    </div>
                    <div className="pickup_searched_loaction_min">
                      <div className="searched_loaction_text_head">
                        {item[1]?.pickup_time}
                      </div>
                      <div className="searched_loaction_text_foot">
                        {t("minutes")}
                      </div>
                    </div>
                  </div>

                  {arr.length - 1 !== index && <hr></hr>}
                </div>
              </div>
            </div>
          );
        })}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default BlockPickup;
