import React, { useState } from "react";
import AccordionItem from "./AccordionItem";
import { useTranslation } from "react-i18next";
interface AccordionProps {
  defautlLang: string;
  dataQuestion: any;
}

const Accordion: React.FC<AccordionProps> = (props: AccordionProps) => {
  const { defautlLang, dataQuestion } = props;
  const [clicked, setClicked] = useState("");
  const { t } = useTranslation();
  const handleToggle = (index: any) => {
    if (clicked === index) {
      return setClicked("");
    }
    setClicked(index);
  };

  return (
    <div className="accordion">
      <div
        style={{
          direction: defautlLang === "en" ? "ltr" : "rtl",
          marginTop: 13,
          marginBottom: 5,
          fontSize: 16,
          fontWeight: "bold",
        }}
      >
        {/* FAQ's */}
        {/* @ts-ignore */}
        {t("faqs")}
        {/* {faqs !== undefined && translations?.faqs[defautlLang]} */}
      </div>
      {dataQuestion?.map((faq: any, index: number) => (
        <AccordionItem
          key={index}
          //@ts-ignore
          faq={faq}
          active={parseInt(clicked) === index}
          defautlLang={defautlLang}
          onToggle={() => handleToggle(index)}
        />
      ))}
    </div>
  );
};

export default Accordion;
